import React from 'react'
import { useIntl } from 'react-intl'
import { Loader } from '@ubnt/ui-components'

import styled from 'theme/styled'
import { Description } from 'components/Description'

import { GenericMFAModal } from '../GenericMFAModal'
import { REAUTH_MFA_MODAL_ID } from '.'

interface Props {
  isLoading: boolean
}

export const DefaultReauthForm: React.FC<Props> = ({ isLoading }) => {
  const intl = useIntl()

  return (
    <GenericMFAModal
      modalId={REAUTH_MFA_MODAL_ID}
      title={intl.formatMessage({
        id: 'REAUTH_MODAL_TITLE',
      })}
      isLoading={isLoading}
    >
      {isLoading ? (
        <Loader size="large" type="spinner" />
      ) : (
        <StyledDescription>
          {intl.formatMessage({ id: 'REAUTH_NO_MFA_ERROR' })}
        </StyledDescription>
      )}
    </GenericMFAModal>
  )
}

const StyledDescription = styled(Description)`
  font-size: 14px;
  color: ${({ theme }) => theme.neutral08};
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
