import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isEqual, pick } from 'lodash-es'

import { QuestionTile } from 'components/support/QuestionTile'
import { Bubble } from 'components/support/Bubble'
import { ExtraSelector } from 'components/support/ExtraSelector'
import { TilesWrapper } from 'components/support/Wrappers'
import usePrevious from 'utils/usePrevious'

import { useGetItemsToRender } from './utils/useGetItemsToRender'
import { BubbleProps, FormValue } from './types'
import { extraTech } from './config/ConfigOptions'
import { Tech, UniFiStateValues } from './config/types'
import { getNextSection } from './handleClicks'

export const TechBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.console])

  const { getItemsToRender, getExtraItemsToRender } = useGetItemsToRender()
  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const [isAutoSelected, setIsAutoSelected] = useState(false)

  const previousValues = usePrevious(values)

  useEffect(() => {
    if (
      !isEqual(
        pick(previousValues, [
          UniFiStateValues.ASSISTANCE,
          UniFiStateValues.CONSOLE,
        ]),
        pick(values, [UniFiStateValues.ASSISTANCE, UniFiStateValues.CONSOLE])
      )
    ) {
      setIsOtherSelected(false)
    }
  }, [previousValues, values])

  const handleClickOther = useCallback(() => {
    if (values.tech) {
      handleChange(UniFiStateValues.TECH, undefined)
    }
    setIsOtherSelected(true)
  }, [handleChange, values.tech])

  const handleClick = useCallback(
    (value: FormValue) => {
      const section = getNextSection(
        config,
        value,
        UniFiStateValues.TECH,
        values
      )

      if (value === Tech.other) {
        return handleClickOther()
      }

      const isExtraOption = extraTech.some((option) => option.id === value)

      !isExtraOption && setIsOtherSelected(false)
      handleChange(UniFiStateValues.TECH, value, section)
    },
    [config, handleChange, handleClickOther, values]
  )

  const itemsToRender = getItemsToRender(UniFiStateValues.TECH, values.console)
  const extraItemsToRender = getExtraItemsToRender(
    UniFiStateValues.TECH,
    values,
    handleClick
  )

  const allItems = [...itemsToRender, ...extraItemsToRender].filter(
    (item) => item.id !== Tech.other
  )

  useEffect(() => {
    if (allItems.length === 1 && !values.tech) {
      setIsAutoSelected(true)
      handleClick(allItems[0].id)
    }
    if (allItems.length > 1) {
      setIsAutoSelected(false)
    }
  }, [allItems, handleClick, values.tech])

  if (isAutoSelected) return null

  return (
    <Bubble question={config.tech.fieldTitle} ref={ref}>
      <TilesWrapper>
        {itemsToRender.map(({ id, icon, label }) => (
          <QuestionTile
            key={id}
            icon={icon}
            label={label}
            onClick={() => handleClick(id)}
            isActive={
              values.tech === id || (id === Tech.other && isOtherSelected)
            }
          />
        ))}
      </TilesWrapper>
      {isOtherSelected && <ExtraSelector items={extraItemsToRender} />}
    </Bubble>
  )
}
