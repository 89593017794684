import type Stripe from 'stripe'
import { loadStripe } from '@stripe/stripe-js'
import { fetchRequestWithCookies } from 'api/axios'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { ExpandedSubscription } from 'features/subscriptions/module/types'
import { BrowserUtils } from 'utils/browserUtils'

type QueryParams3DS = {
  subscriptionId?: string | null
  url?: string | null
  region?: StripeRegionCode | null
}

const {
  api: { billing },
  STRIPE_PUBLISHABLE_KEYS,
} = __CONFIG__

export const get3DSDataFromQuery = (): QueryParams3DS => {
  const subscriptionId = BrowserUtils.getQueryValue(
    window.location.href,
    'subscription_id'
  )

  const url = BrowserUtils.getQueryValue(window.location.href, 'return_url')

  const region = BrowserUtils.getQueryValue<StripeRegionCode>(
    window.location.href,
    'region'
  )

  return { subscriptionId, url, region }
}

export const getSubscriptionFromId = async (
  id: string,
  region: StripeRegionCode
) => {
  const url = new URL(`${billing.paths.subscriptions}/${id}`, billing.base)
  url.searchParams.append('region', region)
  url.searchParams.append('expand[]', 'latest_invoice.payment_intent')

  const { data } = await fetchRequestWithCookies<{
    [key in StripeRegionCode]: ExpandedSubscription
  }>(url.href)

  return data
}

export const initiate3DSFlow = async (
  region: StripeRegionCode,
  url: string,
  clientSecret: string,
  paymentMethod:
    | string
    | Stripe.PaymentMethod
    | Stripe.CustomerSource
    | Stripe.DeletedCustomerSource
) => {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEYS[region])

  const res = await stripe?.confirmCardPayment(
    clientSecret,
    {
      payment_method:
        typeof paymentMethod === 'string' ? paymentMethod : paymentMethod.id,
      return_url: url,
    },
    { handleActions: false }
  )

  if (res?.paymentIntent?.next_action?.redirect_to_url?.url) {
    window.location.href = res.paymentIntent.next_action.redirect_to_url.url
  }
}
