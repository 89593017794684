import axios from 'axios'

const {
  api: { accountBE },
} = __CONFIG__

export const createOrUpdateZendeskUser = async () => {
  const url = new URL(accountBE.paths.createOrUpdateZendeskUser, accountBE.base)
  await axios.post(url.href, {}, { withCredentials: true })
}
