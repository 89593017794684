import { useEffect, useState } from 'react'

const MAX_PROGRESS = 100

const useFakeStatus = (
  isUploading?: boolean,
  step = 10,
  stepTimeout = 1000,
  initialProgress = 0
) => {
  const [prevIsUploading, setPrevIsUploading] = useState(isUploading)
  const [progress, setProgress] = useState(initialProgress)

  useEffect(() => {
    let timer: any

    setPrevIsUploading(isUploading)

    if (!isUploading && prevIsUploading) {
      setProgress(MAX_PROGRESS)
      if (timer) clearTimeout(timer)
    } else {
      timer = setTimeout(() => {
        if (progress + step < MAX_PROGRESS) {
          setProgress(progress + step)
        }
      }, stepTimeout)
    }

    return () => clearTimeout(timer)
  }, [isUploading, prevIsUploading, progress, step, stepTimeout])

  return [progress]
}

export default useFakeStatus
