import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import TextArea from '@ubnt/ui-components/TextArea/TextArea'

import { typography } from 'theme'
import styled from 'theme/styled'
import { HeaderFadeIn } from 'components/animations'

interface Props {
  handleChange: (value: string) => void
  value: string
  error?: string | boolean
  hideKeyDetails?: boolean
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
}

export const Description: React.FC<Props> = ({
  handleChange,
  value,
  error,
  hideKeyDetails,
  onBlur,
}) => {
  const intl = useIntl()

  return (
    <Wrapper>
      <TextArea
        placeholder={intl.formatMessage({
          id: 'SUPPORT_DESCRIPTION_PLACEHOLDER',
        })}
        variant="secondary"
        onChange={(_e, value) => handleChange(value)}
        width="100%"
        maxWidth="100%"
        height="200px"
        value={value}
        invalid={error}
        onBlur={onBlur}
      />
      <InfoMessages>
        {!hideKeyDetails && (
          <Message>
            <FormattedMessage id="SUPPORT_DESCRIPTION_INFO_1" />
          </Message>
        )}
        <Message>
          <FormattedMessage id="SUPPORT_DESCRIPTION_INFO_2" />
        </Message>
      </InfoMessages>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  animation: ${HeaderFadeIn} 0.2s linear;
`

const InfoMessages = styled.div`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text3};
`
