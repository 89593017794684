import React from 'react'
import moment from 'moment'

interface Props {
  dt: number
}

export const DateCell: React.FC<Props> = ({ dt }) => {
  if (!dt) return <>{`---`}</>
  return <>{moment(dt * 1000).format('MMM D, YYYY')}</>
}
