import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { designToken } from '@ubnt/ui-components'

import LoginImageLow from 'assets/images/login_screen_low.webp'
import LoginImage from 'assets/images/login_screen_high.webp'
import styled from 'theme/styled'
import AuthFooter from 'features/auth/ui/AuthFooter'
import { BrowserUtils } from 'utils/browserUtils'
import { MaintenanceInfo } from 'features/status/ui'
import { media, typography } from 'theme'
import LogoWithSlogan from 'features/auth/ui/LogoWithSlogan'
import { Page } from 'components/Page'
import { selectProfileData } from 'modules/profile'
import { FlexWrapper } from 'components/SharedComponents'
import { useStatusQuery } from 'store/queries/useStatusQuery'
import { HARDCODED_MAINTENANCE } from 'api/constants'

interface StateProps {
  showLogo?: boolean
  title?: string
  subtitle?: string
  message?: string
  hasError?: boolean
  registerPage?: boolean
  children: React.ReactNode
  formHeight?: string
}

const DIRECT_ACCOUNT_PORTAL_IDS = [
  'QW0cIxL7tdXTp4FWhdg4zrt362gN8VtXinn0iKHi',
  'HIpDPGtjATMZH2HFgD9KcwySz3dlnOZfuJLcwMqI',
  '6GCm4tFG8quZrGhQpSReOmzdbtzt4UEipE9BKXZ2',
  'pCZq16Z7qSCJiwvdRksvmRAJFVkWNyKUfgjH4vrL',
]

const { mobileLarge, mobileXLarge, small: tablet } = media

const PublicPage: React.FC<StateProps> = ({
  showLogo = true,
  children,
  registerPage,
  title,
  subtitle,
  message,
  formHeight,
}) => {
  const profile = useSelector(selectProfileData)
  const { upcomingMaintenance, uiIncidents } = useStatusQuery()

  useEffect(() => {
    if (profile?.is_verified) {
      localStorage.removeItem('storedToastShown')
      localStorage.removeItem('isMFAPromptSeen')
    }
  }, [profile?.is_verified])

  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src = LoginImage
    imageToLoad.onload = () => setImageLoaded(true)
  }, [])

  return (
    <Page publicPage={true} overflow={true}>
      {(uiIncidents || upcomingMaintenance || HARDCODED_MAINTENANCE) && (
        <FlexWrapper width="100%" justifyContent="center" padding={20}>
          <MaintenanceInfo loginPage omitMarginBottom />
        </FlexWrapper>
      )}
      <Background $imageLoaded={imageLoaded} />
      <Wrapper>
        <FormContainer
          registerPage={registerPage}
          showLogo={showLogo}
          $height={formHeight}
        >
          <FormWrapper>
            <Header>
              {showLogo && <LogoWithSlogan />}
              {title && <Title>{title}</Title>}
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              {message && <Message>{message}</Message>}
            </Header>
            <ContentContainer>{children}</ContentContainer>
          </FormWrapper>
        </FormContainer>
        <AuthFooter />
      </Wrapper>
    </Page>
  )
}
const mapStateToProps = (_state: any, _props: any) => {
  const clientId = BrowserUtils.getLocationInnerQueryValue(
    'redirect',
    'client_id'
  )
  const isDirectPortal =
    clientId && DIRECT_ACCOUNT_PORTAL_IDS.includes(clientId)
  return {
    redirected: !!isDirectPortal,
  }
}
export default connect(mapStateToProps)(withRouter(PublicPage as any) as any)

const ContentContainer = styled.div`
  width: 276px;
  @media (max-width: ${mobileLarge}) {
    width: 100%;
  }
`
const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 2;
  background: transparent;

  @media (min-width: 1470px) {
    margin-left: 0px;
  }
  @media (max-width: ${tablet}) {
    margin-left: 0;
  }
  @media (max-width: 420px) {
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  @media (max-width: 730px) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`
const FormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${tablet}) {
    width: 90%;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 276px;
  @media (max-width: ${tablet}) {
    margin-top: ${designToken['desktop-spacing-base']};
  }
`

const Title = styled.span`
  font: ${designToken['desktop-heading-xlarge-1']};
  margin: -${designToken['desktop-spacing-base-06']} 0 ${designToken['desktop-spacing-base-06']};
  text-align: center;
`

const Subtitle = styled.span`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
  margin-top: ${designToken['desktop-spacing-base-12']};
  text-align: center;
`

const Message = styled.span`
  width: 100%;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text3};
  margin-bottom: 48px;
`

const FormContainer = styled.div<{
  registerPage?: boolean
  showLogo?: boolean
  $height?: string
}>`
  height: ${({ registerPage, $height }) => {
    if ($height) {
      return $height
    }
    if (registerPage) {
      return '812px'
    }
    return '597px'
  }};
  max-height: 90%;
  width: 402px;
  margin-left: 10%;
  padding: ${({ showLogo, registerPage }) => {
    if (registerPage && showLogo) {
      return '12px 64px 64px'
    }
    if (showLogo) {
      return '40px 64px 64px'
    }
    return '64px'
  }};
  border-radius: ${designToken['desktop-radius-medium']};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${(p) => p.theme.neutral00};
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: ${mobileXLarge}) {
    width: 100%;
    margin-left: 0;
    padding: 32px 32px 64px;
    height: 100%;
  }
`

const Background = styled.div<{ $imageLoaded: boolean }>`
  position: absolute;
  background-color: #cccccc;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1;
  background-image: url(${({ $imageLoaded }) =>
    $imageLoaded ? LoginImage : LoginImageLow});
  background-size: cover;
  background-position: center;
  @media (max-width: ${mobileXLarge}) {
    display: none;
  }
`
