import React from 'react'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { getCurrencySymbol } from 'features/invoices/ui/utils'
import { NumberWrapper } from '../Styles'

export const SubscriptionCharge: React.FC<{
  fullSubscription: FullSubscription
}> = ({ fullSubscription }) => {
  const { isSuspended, latestInvoice, currency } = fullSubscription

  const intl = useIntl()

  let suffix = ''
  if (isSuspended && latestInvoice?.status === 'draft') {
    suffix = ` (${intl.formatMessage({
      id: 'SETTINGS_SUBSCRIPTIONS_TABLE_IN_PROGRESS',
    })})`
  }

  const subscriptionCurrency = latestInvoice?.currency ?? currency

  return (
    <NumberWrapper>
      <NumericFormat
        value={fullSubscription.upcomingAmount}
        displayType={'text'}
        thousandSeparator={true}
        prefix={getCurrencySymbol(subscriptionCurrency || 'usd')}
        suffix={suffix}
        decimalScale={2}
        fixedDecimalScale
      />
    </NumberWrapper>
  )
}
