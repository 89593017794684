import { useEffect, useState } from 'react'

type Device = {
  guids: string[]
  triplets: []
  shortnames: string[]
  product: { name: string }
  line: { id: string; name: string }
  icon: { id: string; resolutions: [][] }
}

type ApiResponse = {
  devices: Device[]
}

const {
  api: { fingerprint },
} = __CONFIG__

// docs: https://ubiquiti.atlassian.net/wiki/spaces/UNIFINET/pages/1918927363/UI+discovery+DB
const useDeviceImage = (shortName: string) => {
  const [devices, set] = useState<Device[]>([])
  const [icon, setIcon] = useState<string | null>(null)
  useEffect(() => {
    fetch(`${fingerprint.base}${fingerprint.paths.public}`)
      .then((res) => res.json())
      .then(({ devices }: ApiResponse) => set(devices))
  }, [])

  useEffect(() => {
    const device = devices.find((d: any) => d.shortnames.includes(shortName))
    if (!device) return
    const {
      icon: { id },
    } = device
    setIcon(
      `${fingerprint.base}${fingerprint.paths.icons}/${id}${fingerprint.urlParts.medium}`
    )
  }, [devices, shortName])

  return icon
}

export default useDeviceImage
