import styled from 'theme/styled'
import { UserImage } from 'components/avatar/UserImage'

const ProfilePicture = styled(UserImage)<{ isClickable: boolean }>`
  position: relative;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  margin-right: 25px;
`

export default ProfilePicture
