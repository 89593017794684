import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Alert as UICAlert } from '@ubnt/ui-components/Alert'

import styled from 'theme/styled'
import { media } from 'theme'
import { StyledDropdownArrow } from 'features/shared/styles'

import ProductHeader from './ProductHeader'

interface Props {
  itemExpanded: boolean
  handleToggle: () => void
  isFailedSubscription: boolean
  cardNotFound: boolean
  productName: string
  cancelAtPeriodEnd: boolean
}

const getWarningText = ({
  isFailedSubscription,
  cancelAtPeriodEnd,
}: {
  isFailedSubscription?: boolean
  cancelAtPeriodEnd?: boolean
}): string => {
  if (cancelAtPeriodEnd) {
    return 'SETTINGS_SUBSCRIPTIONS_CANCELED_AT_MONTH_END'
  }
  if (isFailedSubscription) {
    return 'SETTINGS_SUBSCRIPTIONS_PAYMENT_FAILED'
  }
  return 'SETTINGS_SUBSCRIPTIONS_PAYMENT_CARD_NOT_FOUND'
}

export const CurrentCycleGroup: React.FC<Readonly<Props>> = ({
  itemExpanded,
  handleToggle,
  isFailedSubscription,
  cardNotFound,
  productName,
  cancelAtPeriodEnd,
}) => {
  const intl = useIntl()

  const Alert = useMemo(() => {
    if (isFailedSubscription || cardNotFound || cancelAtPeriodEnd) {
      return (
        <StyledAlert
          type="warning"
          text={intl.formatMessage({
            id: getWarningText({ isFailedSubscription, cancelAtPeriodEnd }),
          })}
        />
      )
    }
    return null
  }, [cancelAtPeriodEnd, cardNotFound, intl, isFailedSubscription])

  return (
    <OuterContainer onClick={handleToggle}>
      {Alert}
      <Container>
        <ProductHeader productName={productName} />
        <StyledDropdownArrow
          size="original"
          variant="fill"
          $isDropdownOpen={itemExpanded}
        />
      </Container>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  cursor: pointer;
`

const StyledAlert = styled(UICAlert)`
  margin-top: 4px;
  @media (max-width: ${media.mobileXLarge}) {
    margin: 16px 0;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
`
