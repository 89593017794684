import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { selectVisibleModal } from 'modules/modals'
import { selectMFASetupTOTP } from 'modules/mfaSetup'
import { selectMFAAuthenticators } from 'modules/mfa'
import { totpAdded } from 'modules/mfaAdded'
import { getErrorMessage } from 'utils/mfa'

import { FormValues, GenericVerifyTokenModal } from '../GenericVerifyTokenModal'
import {
  selectVerifyTOTPErrors,
  selectVerifyTOTPIsDone,
  selectVerifyTOTPIsError,
  selectVerifyTOTPIsLoading,
  verifyMFATOTP,
} from './modules/verifyTOTP'

export const TOTP_VERIFY_CODE_MODAL_ID = 'TOTP_VERIFY_CODE_MODAL_ID'

export const TOTPVerifyCodeModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const requestIsLoading = useSelector(selectVerifyTOTPIsLoading)
  const requestIsDone = useSelector(selectVerifyTOTPIsDone)
  const requestErrors = useSelector(selectVerifyTOTPErrors)
  const isError = useSelector(selectVerifyTOTPIsError)
  const { id } = useSelector(selectMFASetupTOTP)
  const visibleModal = useSelector(selectVisibleModal)
  const authenticators = useSelector(selectMFAAuthenticators)

  const [isRequestFired, setIsRequestFired] = useState(false)
  const [apiError, setApiError] = useState<string>('')

  useEffect(() => {
    setApiError(
      getErrorMessage({
        apiError: requestErrors,
        defaultMessage: intl.formatMessage({
          id: 'GENERIC_ERROR_BOUNDARY_TITLE',
        }),
        fields: ['detail'],
      }) || ''
    )
  }, [requestErrors, intl])

  useEffect(() => setApiError(''), [visibleModal])

  useEffect(() => {
    if (requestIsDone && !requestIsLoading && !isError && isRequestFired) {
      const active = authenticators.filter((auth) => auth.status === 'active')
      if (active.length) {
        dispatch(totpAdded(false))
      } else {
        dispatch(totpAdded(true))
      }

      //reset state
      setIsRequestFired(false)
    }
  }, [
    requestIsDone,
    requestIsLoading,
    isError,
    dispatch,
    isRequestFired,
    authenticators,
  ])

  const handleOnSubmit = useCallback(
    ({ token }: FormValues) => {
      if (!token || !id) return // FIX better handling for this
      dispatch(verifyMFATOTP(id, token))
      setIsRequestFired(true)
    },
    [dispatch, id]
  )

  return (
    <GenericVerifyTokenModal
      modalId={TOTP_VERIFY_CODE_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_TOTP_MODAL_TITLE' })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_VERIFY_CODE_DESCRIPTION',
      })}
      handleOnSubmit={handleOnSubmit}
      isLoading={requestIsLoading}
      errorMessage={apiError}
      onAfterClose={() => setIsRequestFired(false)}
    />
  )
}
