import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { BasicToast, ToastContext } from '@ubnt/ui-components'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import Yup from 'validators/yupLocaleConfig'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { selectFetchAddressIsLoading } from 'features/addresses/module/addresses'
import { StripeElements } from 'features/stripe/ui/StripeElements'
import { ValidationError } from 'components/Input'
import { omit } from 'lodash-es'
import { CardType } from '../modules/types'
import { checkAddress, handleCaughtAddressError, parseMonth } from './utils'
import { Wrapper } from './Components'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { CardAddressForm } from './forms/CardAddressForm'
import { CardInfoForm } from './forms/CardInfoForm'
import { validStripeRegionMap } from 'features/stripe/ui/regions'
import { useUpdateCard } from 'store/mutations/billingCards/useUpdateCard'
import { getStripeMutationErrorMessage } from 'store/mutations/utils'

interface Props {
  card?: CardType
  cardRegion?: string
}

const EditPaymentMethodForm: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl, card }) => {
  const {
    isUpdateCardLoading,
    updateCardError,
    resetUpdateCardErrors,
    updateCard,
  } = useUpdateCard()
  const [editError, setEditError] = useState<string | null>(null)
  const [expiryDateError, setExpiryDateError] = useState<boolean>(false)
  const [addressError, setAddressError] = useState<{
    [key: string]: string | string[]
  } | null>(null)
  const { isBillingCustomerLoading } = useBillingCustomerQuery()
  const addressIsLoading = useSelector(selectFetchAddressIsLoading)

  const { createNotification, removeNotification } = useContext(ToastContext)

  const cardData = card?.card

  const isLoading =
    isUpdateCardLoading || isBillingCustomerLoading || addressIsLoading

  const addressData = card?.billing_details?.address

  const selectedRegion = card && validStripeRegionMap.get(card.region)

  const {
    values,
    touched,
    setFieldTouched,
    isSubmitting,
    handleSubmit,
    handleBlur,
    setValues,
    handleChange,
    errors,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: card?.billing_details?.name || '',
      country: addressData?.country || 'us',
      line1: addressData?.line1 || '',
      line2: addressData?.line2 || '',
      city: addressData?.city || '',
      state: addressData?.state || '',
      postal_code: addressData?.postal_code || '',
      exp_month: parseMonth(cardData?.exp_month.toString()), // month requires parsing on initial value only
      exp_year: cardData?.exp_year.toString(),
    },
    onSubmit: async (values) => {
      const {
        name,
        country,
        line1,
        line2,
        city,
        state,
        postal_code,
        exp_month,
        exp_year,
      } = values

      const addressValues = omit(values, 'exp_month', 'exp_year')

      try {
        await checkAddress(addressValues)
      } catch (error) {
        handleCaughtAddressError(error, intl, setAddressError)
        return
      }

      if (card?.id) {
        updateCard({
          id: card.id,
          owner: {
            name,
            address: {
              country,
              line1,
              line2,
              city,
              state,
              postal_code,
            },
          },
          card: {
            exp_month,
            exp_year,
          },
          region: card.region,
        })
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required().label('SETTINGS_PAYMENTS_NAME_ON_CARD'),
      country: Yup.string().required().max(2).label('COMMON_LABEL_COUNTRY'),
      line1: Yup.string().required().label('COMMON_LABEL_STREET_ADDRESS'),
      city: Yup.string().required().label('COMMON_LABEL_CITY'),
      state: Yup.string().required().label('COMMON_LABEL_STATE'),
      postal_code: Yup.string().required().label('COMMON_LABEL_POSTAL_CODE'),
    }),
  })

  useEffect(() => {
    setEditError(null)
  }, [values])

  useEffect(() => {
    const error = getStripeMutationErrorMessage(updateCardError)

    if (error?.apiError === 'Duplicate card is not allowed.') {
      return setEditError(
        intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_DUPLICATED_CARD' })
      )
    }
    if (error?.apiError === 'Invalid card country.') {
      return setEditError(
        intl.formatMessage(
          { id: 'STRIPE_PAYMENT_ERROR_UNSUPORTED_CARD' },
          {
            region: selectedRegion?.name,
          }
        )
      )
    }
    if (
      error?.stripeError?.includes(
        'Your card could not be authorized using the postal code provided.'
      )
    ) {
      return setEditError(
        intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_POSTAL_CODE' })
      )
    }
    if (error?.stripeError) {
      return setEditError(error?.stripeError)
    }
    if (error?.apiError) {
      return setEditError(intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE' }))
    }
    setEditError(null)
  }, [selectedRegion?.name, intl, updateCardError])

  useEffect(() => {
    if (editError) {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
          details={editError}
        />
      )
    }
  }, [editError, createNotification, removeNotification])

  const isButtonDisabled = useMemo(() => {
    const hasAddressError =
      !!addressError && !!Object.values(addressError).length
    const hasFormikError = !!Object.values(errors).length
    const hasAnyError = hasAddressError || hasFormikError || expiryDateError

    if (isLoading || isSubmitting || hasAnyError) {
      return true
    }
    return false
  }, [addressError, expiryDateError, errors, isSubmitting, isLoading])

  if (!card) return null

  const handleClose = () => {
    onClose?.()
    resetUpdateCardErrors()
  }

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={handleClose}
      title={
        <FormattedMessage
          id="SETTINGS_PAYMENTS_MODAL_TITLE_EDIT"
          tagName="div"
        />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: handleClose,
          variant: 'default',
          disabled: isLoading || isSubmitting,
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_SUBMIT' }),
          type: 'submit',
          variant: 'primary',
          onClick: handleSubmit as any,
          disabled: isButtonDisabled,
          loader: isOpen && (isLoading || isSubmitting) ? 'spinner' : undefined,
          style: { outline: 'none' },
        },
      ]}
    >
      <form onSubmit={handleSubmit}>
        <Wrapper>
          <CardInfoForm
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            cardRegion={card.region}
            cardData={cardData}
            editError={editError}
            setFieldValue={setFieldValue}
            expiryDateError={expiryDateError}
            setExpiryDateError={setExpiryDateError}
          />
          <CardAddressForm
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setValues={setValues as any}
            touched={touched}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isEditPaymentMethod={true}
            submitError={editError}
            addressError={addressError}
            setAddressError={setAddressError}
            cardRegion={card.region}
          />
        </Wrapper>
        {addressError?.['detail'] ? (
          <ValidationError>
            <FormattedMessage id="SETTINGS_PAYMENTS_ADDRESS_VALIDATION_FAILED" />
          </ValidationError>
        ) : null}
      </form>
    </GenericModal>
  )
}

const EditPaymentMethodModalConnected = injectIntl(EditPaymentMethodForm)

export const EDIT_PAYMENT_METHOD_MODAL_ID = 'EDIT_PAYMENT_METHOD_MODAL_ID'

const StripeWrappedModal: React.FC<Props & ModalProps> = (props) => {
  return (
    <StripeElements region={props.cardRegion}>
      <EditPaymentMethodModalConnected {...props} />
    </StripeElements>
  )
}

export default () => (
  <ModalWrapper modalId={EDIT_PAYMENT_METHOD_MODAL_ID}>
    <StripeWrappedModal />
  </ModalWrapper>
)
