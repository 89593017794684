import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import styled from 'theme/styled'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { setVisibleModal } from 'modules/modals'

interface Props extends ModalProps {
  sourceModalId?: string
}

const LearnMoreModal: React.FC<Props> = ({
  isOpen,
  onClose,
  sourceModalId,
}) => {
  const dispatch = useDispatch()

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<FormattedMessage id="SETTINGS_EARLY_ACCESS_TERMS_OF_SERVICE" />}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: sourceModalId
            ? () => {
                dispatch(setVisibleModal(sourceModalId))
              }
            : onClose,
          variant: 'default',
        },
      ]}
    >
      <Container>
        <p>
          <FormattedMessage id="SETTINGS_EARLY_ACCESS_LEARN_MORE_TEXT_ONE" />
        </p>
        <p>
          <FormattedMessage id="SETTINGS_EARLY_ACCESS_LEARN_MORE_TEXT_TWO" />
        </p>
        <p>
          <FormattedMessage id="SETTINGS_EARLY_ACCESS_LEARN_MORE_TEXT_THREE" />
        </p>
        <p>
          <FormattedMessage id="SETTINGS_EARLY_ACCESS_LEARN_MORE_TEXT_FOUR" />
        </p>
      </Container>
    </GenericModal>
  )
}

export const EARLY_ACCESS_LEARN_MORE_MODAL_ID =
  'EARLY_ACCESS_LEARN_MORE_MODAL_ID'

export const WrappedLearnMoreModal = connect()(() => (
  <ModalWrapper modalId={EARLY_ACCESS_LEARN_MORE_MODAL_ID}>
    <LearnMoreModal />
  </ModalWrapper>
))

const Container = styled('div')`
  text-align: left;
  p {
    font-size: 14px;
    color: ${(p) => p.theme.neutral08};
    line-height: 22px;
  }
`
