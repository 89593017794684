import { fetchRequestWithCookies } from 'api/axios'
import { ZendeskTicketsResponse } from './types'

const {
  api: { accountBE },
} = __CONFIG__

export class TicketsApi {
  getAllOpen = async ({ pageParam: page }: { pageParam: number }) => {
    const url = new URL(accountBE.paths.zendeskOpenTickets, accountBE.base)
    url.searchParams.append('page', page.toString())

    const { data } = await fetchRequestWithCookies<ZendeskTicketsResponse>(
      url.href
    )
    return data
  }

  getAllClosed = async ({ pageParam: page }: { pageParam: number }) => {
    const url = new URL(accountBE.paths.zendeskClosedTickets, accountBE.base)
    url.searchParams.append('page', page.toString())
    const { data } = await fetchRequestWithCookies<ZendeskTicketsResponse>(
      url.href
    )
    return data
  }

  getAllCcd = async () => {
    const url = new URL(accountBE.paths.zendeskCcdTickets, accountBE.base)
    const { data } = await fetchRequestWithCookies<ZendeskTicketsResponse>(
      url.href
    )
    return data
  }
}
