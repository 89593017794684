import React from 'react'
import { useStatusQuery } from 'store/queries/useStatusQuery'
import theme from 'theme'
import styled from 'theme/styled'

interface Props {
  children?: React.ReactNode
  renderFooter?: any
  inline?: boolean
  overflow?: boolean
  publicPage?: boolean
  noCenter?: boolean
}

export const Page: React.FC<Props> = ({
  children,
  renderFooter,
  inline,
  overflow,
  publicPage,
  noCenter = true,
}) => {
  const { upcomingMaintenance } = useStatusQuery()
  if (inline) {
    return (
      <>
        {children}
        {renderFooter ? renderFooter() : null}
      </>
    )
  } else {
    const Component = overflow ? OverflowWrapper : Wrapper
    return (
      <Component
        publicPage={publicPage}
        noCenter={noCenter}
        maintenanceBanner={!!upcomingMaintenance}
      >
        {children}
        {renderFooter ? renderFooter() : null}
      </Component>
    )
  }
}

const Wrapper = styled.div<{
  publicPage?: boolean
  noCenter?: boolean
  maintenanceBanner?: boolean
}>`
  flex: 1 100%;
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.publicPage ? '100vh' : 'inherit')};
  @media (max-width: ${(p) => p.theme.media.medium}) {
    padding-left: 0;
    width: 100%;
  }
  ${(props) => !props.noCenter && 'align-items: center'};
  @media (max-width: ${theme.media.mobileLarge}) {
    margin-top: ${({ maintenanceBanner, publicPage }) =>
      maintenanceBanner && !publicPage ? '92px' : '0'};
  }
`

const OverflowWrapper = styled(Wrapper)`
  overflow: visible;
  @media (max-width: ${(p) => p.theme.media.small}) {
    overflow: visible;
  }
`
