import React from 'react'
import styled from 'theme/styled'

interface Props {
  croppedPicture: string
}

const ProfilePicturePreview: React.FC<Props> = ({ croppedPicture }) => (
  <Container>
    <img src={croppedPicture} alt="Avatar" />
  </Container>
)

export default ProfilePicturePreview

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;

  img {
    border-radius: 50%;
  }
`
