import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillingSubscriptionsApi } from 'api/billing/billingSubscriptions'
import { closeVisibleModal } from 'modules/modals'
import { useDispatch } from 'react-redux'
import { QueryKey } from 'store/types'

const billingSubscriptionsApi = new BillingSubscriptionsApi()

export const useCancelEnhancedThreatSubscription = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const cancelEnhancedThreatSubscription = useMutation({
    mutationFn: billingSubscriptionsApi.cancelEnhancedThreat,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_INVOICES],
      })
      dispatch(closeVisibleModal())
    },
  })

  return {
    cancelEnhancedThreatSubscription: cancelEnhancedThreatSubscription.mutate,
    isCancelEnhancedThreatSubscriptionLoading:
      cancelEnhancedThreatSubscription.isPending,
    cancelEnhancedThreatSubscriptionErrors:
      cancelEnhancedThreatSubscription.error,
    resetCancelEnhancedThreatSubscriptionErrors:
      cancelEnhancedThreatSubscription.reset,
  }
}
