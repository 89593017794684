import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillingSubscriptionsApi } from 'api/billing/billingSubscriptions'
import { useToasts } from 'features/subscriptions/ui/hooks/useToasts'
import { QueryKey } from 'store/types'

const billingSubscriptionsApi = new BillingSubscriptionsApi()

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient()
  const { updateSubscriptionErrorToast } = useToasts()

  const updateSubscription = useMutation({
    mutationFn: billingSubscriptionsApi.update,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      }),
    onError: () => updateSubscriptionErrorToast(),
  })

  return {
    updateSubscription: updateSubscription.mutate,
    isSubscriptionUpdateLoading: updateSubscription.isPending,
  }
}
