import { Input } from '@ubnt/ui-components'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useFormik } from 'formik'
import { editMfa } from 'modules/mfaEdit'
import { MFAType } from 'modules/types'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import Yup from 'validators/yupLocaleConfig'

export const EDIT_MFA_NAME_MODAL = 'EDIT_MFA_NAME_MODAL'

const MIN_CHARS_ALLOWED = 1
const MAX_CHARS_ALLOWED = 100

type EditMFANameModalProps = {
  mfaName?: string
  type?: MFAType
  mfaId?: string
}

export const EditMFANameModal: React.FC<ModalProps & EditMFANameModalProps> = ({
  isOpen,
  onClose,
  mfaName,
  type,
  mfaId,
}) => {
  const intl = useIntl()
  const { values, errors, handleChange, touched, handleBlur, handleSubmit } =
    useFormik<{
      name: string
    }>({
      initialValues: { name: mfaName ?? '' },
      onSubmit: () => {
        dispatch(
          editMfa(
            { name: values.name, type, mfaId },
            {
              pick: ['name'],
            }
          )
        )
      },
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .required()
          .min(MIN_CHARS_ALLOWED)
          .max(MAX_CHARS_ALLOWED)
          .label(
            intl.formatMessage({
              id: 'SETTINGS_MFA_UPDATE_NAME_MODAL_ERROR_MESSAGE',
            })
          ),
      }),
    })
  const dispatch = useDispatch()

  return (
    <GenericModal
      onRequestClose={onClose}
      isOpen={isOpen}
      title={<FormattedMessage id="SETTINGS_MFA_UPDATE_NAME_MODAL_TITLE" />}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
          variant: 'default',
        },
        {
          variant: 'primary',
          text: <FormattedMessage id="COMMON_ACTION_CHANGE" />,
          onClick: () => handleSubmit(),
        },
      ]}
      size="small"
      overrideFullScreen
    >
      <Input
        name="name"
        variant="secondary"
        value={values.name}
        full
        onChange={handleChange}
        invalid={touched.name && errors.name}
        onBlur={handleBlur}
      />
    </GenericModal>
  )
}

export const WrappedEditMFANameModal = () => (
  <ModalWrapper modalId={EDIT_MFA_NAME_MODAL}>
    <EditMFANameModal />
  </ModalWrapper>
)
