import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from '@ubnt/ui-components/Button'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import PublicPage from 'pages/PublicPage'
import { MfaFeedbackInfo } from './MfaFeedbackInfo'
import { selectCanSubmitFeedback } from 'features/auth/modules/auth'

export const MfaFeedbackSuccess = () => {
  const intl = useIntl()
  const history = useHistory()
  const canSubmitMfaFeedback = useSelector(selectCanSubmitFeedback)

  if (!canSubmitMfaFeedback) {
    history.push('/login')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PublicPage formHeight="280px" showLogo={false}>
      <Container>
        <MfaFeedbackInfo
          titleMessageId="LOGIN_MFA_FEEDBACK_SUCCESS_TITLE"
          descriptionMessageId="LOGIN_MFA_FEEDBACK_SUCCESS_DESCRIPTION"
        />
        <Link to="/login">
          <Button variant="primary">
            {intl.formatMessage({ id: 'COMMON_ACTION_OK' })}
          </Button>
        </Link>
      </Container>
    </PublicPage>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
