import React, { useCallback, useContext } from 'react'
import { ArrowLeftPrimaryIcon, ArrowRightPrimaryIcon } from '@ubnt/icons'
import Button from '@ubnt/ui-components/Button/Button'
import { FormattedMessage } from 'react-intl'

import styled from 'theme/styled'
import { media } from 'theme'
import { useSessionStorage } from 'utils/useSessionStorage'

import {
  BackupsContext,
  LISTINGS_PER_PAGE_OPTIONS,
  initialSettings,
} from './BackupsContext'
import ChangeDeviceNumberButtons from './ChangeDeviceNumberButtons'

interface Props {
  totalDevices: number
  selectedDevice: string
  handleNextClick: () => void
  handlePreviousClick: () => void
  isArchived: boolean
}

export const BackupsPagination: React.FC<Props> = ({
  totalDevices,
  selectedDevice,
  handleNextClick,
  handlePreviousClick,
  isArchived,
}) => {
  const [devicesPerPage, setStoredDevicesPerPage] = useSessionStorage(
    'devicesPerPage',
    initialSettings.devicesPerPage
  )
  const [devicesPerPageRemoved, setStoredDevicesPerPageRemoved] =
    useSessionStorage(
      'devicesPerPageRemoved',
      initialSettings.devicesPerPageRemoved
    )
  const {
    setDevicesPerPage,
    setSelectedDevice,
    currentPage,
    setCurrentPage,
    // Archived Backups
    setDevicesPerPageRemoved,
    setSelectedDeviceRemoved,
    currentPageRemoved,
    setCurrentPageRemoved,
  } = useContext(BackupsContext)

  const devicesPerPageView = isArchived ? devicesPerPageRemoved : devicesPerPage
  const currentPageView = isArchived ? currentPageRemoved : currentPage

  const changeNumberOfDevices = useCallback(
    (newNumber: number) => {
      if (isArchived) {
        if (newNumber === devicesPerPageRemoved) return
        setCurrentPageRemoved?.(initialSettings.currentPageRemoved)
        setStoredDevicesPerPageRemoved(newNumber)
        setDevicesPerPageRemoved?.(newNumber)
        setSelectedDeviceRemoved?.(initialSettings.selectedDeviceRemoved)
        return
      } else {
        if (newNumber === devicesPerPage) return
        setCurrentPage?.(initialSettings.currentPage)
        setStoredDevicesPerPage(newNumber)
        setDevicesPerPage?.(newNumber)
        setSelectedDevice?.(initialSettings.selectedDevice)
      }
    },
    [
      isArchived,
      devicesPerPageRemoved,
      devicesPerPage,
      setCurrentPage,
      setCurrentPageRemoved,
      setDevicesPerPage,
      setDevicesPerPageRemoved,
      setSelectedDevice,
      setSelectedDeviceRemoved,
      setStoredDevicesPerPage,
      setStoredDevicesPerPageRemoved,
    ]
  )

  const isNextPage = devicesPerPageView * currentPageView < totalDevices

  const totalNumberOfPageDevices =
    currentPageView * devicesPerPageView > totalDevices
      ? totalDevices
      : currentPageView * devicesPerPageView

  const numberOfFirstDeviceOfPage =
    devicesPerPageView * (currentPageView - 1) + 1

  const showPagination =
    selectedDevice === 'all' && totalDevices > devicesPerPageView

  const isOnFirstPage = currentPageView === 1

  return (
    <PaginationContainer>
      <DeviceNumberWrapper>
        <Text>
          <FormattedMessage id="SETTINGS_BACKUPS_LISTINGS_PER_PAGE" />
        </Text>
        <ChangeDeviceNumberButtons
          options={LISTINGS_PER_PAGE_OPTIONS}
          changeNumberOfDevices={changeNumberOfDevices}
          devicesPerPage={devicesPerPageView}
        />
      </DeviceNumberWrapper>
      {showPagination && (
        <PageSelectWrapper>
          <Text>
            <FormattedMessage
              id="SETTINGS_BACKUPS_NUMBER_OF_DEVICES"
              values={{
                firstDeviceOnPage: numberOfFirstDeviceOfPage,
                totalDevicesOnPage: totalNumberOfPageDevices,
                totalDevices: totalDevices,
              }}
            />
          </Text>
          <ButtonLeft
            size="small"
            variant="inline"
            onClick={handlePreviousClick}
            nextPage={isNextPage}
            disabled={isOnFirstPage}
          >
            <StyledArrowLeft />
          </ButtonLeft>
          <ButtonRight
            size="small"
            variant="inline"
            onClick={handleNextClick}
            disabled={!isNextPage}
          >
            <StyledArrowRight />
          </ButtonRight>
        </PageSelectWrapper>
      )}
    </PaginationContainer>
  )
}

const DeviceNumberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${media.mobileLarge}) {
    margin-bottom: 8px;
  }
`

const StyledArrowRight = styled(ArrowRightPrimaryIcon)`
  color: ${({ theme }) => theme.neutral07};
`
const StyledArrowLeft = styled(ArrowLeftPrimaryIcon)`
  color: ${({ theme }) => theme.neutral07};
`

const PageSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 36px;
`

const PaginationContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: ${media.mobileLarge}) {
    align-items: end;
    flex-direction: column;
  }
`

const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.text3};
  margin: auto 12px auto 0;
`

const StyledButton = styled(Button)`
  width: 26px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `1px solid ${theme.neutral04}`};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  font-size: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.neutral02};
  }
`

const ButtonLeft = styled(StyledButton)<{ nextPage: boolean }>`
  border-radius: 4px 0px 0px 4px;
  border-right: ${({ theme, nextPage }) =>
    nextPage ? 'none' : `1px solid ${theme.neutral04}`};
`

const ButtonRight = styled(StyledButton)`
  margin-right: 4px;
  border-radius: 0px 4px 4px 0px;
`
