import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import axios, { AxiosError } from 'axios'
import Button from '@ubnt/ui-components/Button/Button'

import { typography } from 'theme'
import styled from 'theme/styled'
import { getFormattedCloseTicketMessage } from 'pages/requests/utils'
import { FlexWrapper } from 'components/SharedComponents'

import { RequestTitle } from './RequestTitle'
import { RequestBody } from './RequestBody'
import { RequestLoader } from './SkeletonLoaders/RequestLoader'
import { RequestError } from './RequestError'
import RequestComment from './RequestComment'
import { TicketStatus, ZendeskTicketWithComments } from 'api/zendesk/types'

const {
  api: { accountBE },
} = __CONFIG__

export const RequestTicket = () => {
  const { ticketId } = useParams<{ ticketId: string }>()
  const [{ ticket, loading }, setTicketWithComments] = useState<{
    loading: boolean
    ticket?: ZendeskTicketWithComments
  }>({ loading: true, ticket: undefined })
  const [fetchError, setFetchError] = useState<AxiosError | null>(null)
  const intl = useIntl()

  useEffect(() => {
    const getComment = async () => {
      try {
        const url = `${accountBE.base}/${accountBE.paths.zendeskComments}/${ticketId}`
        const { data } = await axios.get(url, { withCredentials: true })
        setTicketWithComments({ loading: false, ticket: data.ticket })
      } catch (e) {
        setFetchError(e as AxiosError)
        setTicketWithComments({ loading: false, ticket: undefined })
      }
    }
    getComment()
  }, [ticketId])

  if (loading && !ticket) return <RequestLoader />

  if (!ticket && fetchError)
    return (
      <RequestError
        isNotFound={
          fetchError.response?.status === 403 ||
          fetchError.response?.status === 404
        }
      />
    )

  if (!ticket) return null

  const statusThatAllowsComments = [TicketStatus.Answered, TicketStatus.Open]

  const isTicketOpen = statusThatAllowsComments.includes(ticket.status)

  return (
    <FlexWrapper column gap={16}>
      <RequestTitle {...ticket} setTicket={setTicketWithComments} />
      <RequestBody
        comments={ticket.comments}
        isTicketOpen={isTicketOpen}
        isCCedTicket={!!ticket.collaborator_ids?.length}
      />
      {isTicketOpen ? (
        <RequestComment
          ticketId={ticket.id}
          setTicketWithComments={setTicketWithComments}
        />
      ) : (
        <FlexWrapper justifyContent="space-between" alignItems="center">
          <ClosedMessage>
            {getFormattedCloseTicketMessage(ticket.updated_at, intl)}
          </ClosedMessage>
          <Link
            to={{
              pathname: '/supportform',
              state: {
                followUpTicketId: ticket.id,
                followUpTicketDesc: ticket.description,
              },
            }}
          >
            <Button variant="inline" size="small">
              <FormattedMessage id="REQUEST_CREATE_FOLLOW_UP" />
            </Button>
          </Link>
        </FlexWrapper>
      )}
    </FlexWrapper>
  )
}

const ClosedMessage = styled.div`
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text3};
`
