import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CheckmarkIcon } from '@ubnt/icons'
import { Button } from '@ubnt/ui-components'

import styled from 'theme/styled'
import { typography } from 'theme'
import { selectProfileData } from 'modules/profile'

export const SubmitSuccess: React.FC<{
  isUnifiForm: boolean
  customHeight?: string
  children?: React.ReactNode
}> = ({ isUnifiForm, customHeight, children }) => {
  const history = useHistory()
  const profile = useSelector(selectProfileData)

  const handleViewRequests = () => {
    history.push('/requests')
  }

  return (
    <Wrapper $isUnifiForm={isUnifiForm} $height={customHeight}>
      <ContentWrapper>
        <ContentBlock>
          <StyledCheckmarkIcon size={48} variant="fill" />
          <Header>
            <FormattedMessage id="SUPPORT_SUBMIT_SUCCESS_TITLE" />
          </Header>
        </ContentBlock>
        <ContentBlock>
          <Text>
            <FormattedMessage
              id="SUPPORT_SUBMIT_SUCCESS_CONFIRMATION_EMAIL"
              values={{
                b: (text) => <Email>{text}</Email>,
                email: profile?.email,
              }}
            />
          </Text>
          {children ?? (
            <Text secondary>
              <FormattedMessage id="SUPPORT_SUBMIT_SUCCESS_AGENT_IN_TOUCH_SOON" />
            </Text>
          )}
        </ContentBlock>
        <Button variant="primary" onClick={handleViewRequests}>
          <FormattedMessage id="SUPPORT_SUBMIT_SUCCESS_VIEW_REQUESTS" />
        </Button>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isUnifiForm: boolean; $height?: string }>`
  margin-top: ${({ $isUnifiForm }) => ($isUnifiForm ? '-98px' : '-150px')};
  width: 100%;
  height: ${({ $height }) => $height ?? '364px'};
  padding: 48px 24px;
  border-radius: 8px;
  z-index: 10;
  background: ${({ theme }) => theme.neutral00};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.media.mobileMedium}) {
    padding: 16px;
    top: 240px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const Header = styled.span`
  font: ${typography['success-header']};
  color: ${({ theme }) => theme.text0};
`

const Text = styled.span<{ secondary?: boolean }>`
  font: ${typography['header-label']};
  color: ${({ theme, secondary }) =>
    secondary ? theme.neutral08 : theme.text2};
`

const Email = styled.span`
  display: block;
  font: ${typography['device-name']};
  text-align: center;
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  color: ${({ theme }) => theme.green06};
`
