import React, { useContext, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Dropdown, DropdownOption } from '@ubnt/ui-components/Dropdown'
import { ADD_PAYMENT_VIEW_QUERY, ALL_KEY, ALL_LABEL } from 'sharedConstants'
import { RoundFlag } from 'components/RoundFlag'
import { Section, SectionHeader } from 'components/Section.styles'
import { selectIsLoading } from 'features/auth/modules/auth'
import { selectProfileData } from 'modules/profile'
import { validStripeCountries } from 'features/stripe/ui/regions'
import {
  selectAddresses,
  selectFetchAddressIsLoading,
} from 'features/addresses/module/addresses'
import { BrowserUtils } from 'utils/browserUtils'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { TextWrap, TitleSection } from './PaymentCards.styles'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import { PaymentMethods } from '../PaymentMethods'

const PaymentCards = () => {
  const { isCardsLoading, customerCards, cardCountries } =
    useBillingCardsQuery()
  const { billingCustomer } = useBillingCustomerQuery()
  const isAuthLoading = useSelector(selectIsLoading)
  const profile = useSelector(selectProfileData)
  const isAddressesLoading = useSelector(selectFetchAddressIsLoading)
  const addresses = useSelector(selectAddresses)
  const [filter, setFilter] = useState(ALL_KEY)

  const { isMaxMobileLarge } = useContext(MediaSizingContext)

  const hasMultipleCountries = useMemo(() => {
    return cardCountries.length > 1
  }, [cardCountries])

  const addPaymentViewQuery = BrowserUtils.getQueryValue(
    window.location.toString(),
    ADD_PAYMENT_VIEW_QUERY
  )

  const dataNotReady = useMemo(() => {
    const noAddresses = !Object.keys(addresses).length

    return [
      !billingCustomer && isCardsLoading,
      !customerCards.length && isCardsLoading,
      !profile && isAuthLoading,
      noAddresses && isAddressesLoading,
    ].some(Boolean)
  }, [
    isCardsLoading,
    billingCustomer,
    customerCards,
    profile,
    isAuthLoading,
    addresses,
    isAddressesLoading,
  ])

  const countryFilters = useMemo(() => {
    return validStripeCountries
      .filter(({ code }) => cardCountries.some((region) => region === code))
      .map(({ name, code }) => ({
        label: name,
        value: code,
        image: <RoundFlag countryCode={code} />,
      }))
  }, [cardCountries])

  const filterOptions = useMemo(() => {
    return [{ label: ALL_LABEL, value: ALL_KEY }, ...countryFilters]
  }, [countryFilters])

  if (dataNotReady) {
    return null
  }

  return (
    <Section $omitMarginTop>
      <TitleSection>
        <TextWrap>
          <SectionHeader $marginBottom={4}>
            <FormattedMessage id="COMMON_SIDE_NAV_PAYMENTS" />
          </SectionHeader>
        </TextWrap>

        {hasMultipleCountries && (
          <Dropdown
            options={filterOptions}
            value={filter}
            variant="secondary"
            width={isMaxMobileLarge ? '100%' : '200px'}
            onChange={(option: DropdownOption) =>
              setFilter(option.value as string)
            }
          />
        )}
      </TitleSection>

      <PaymentMethods
        addPaymentView={!!addPaymentViewQuery}
        filter={filter}
        hasMultipleCountries
      />
    </Section>
  )
}

export default PaymentCards
