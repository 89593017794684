import React, { useCallback, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'

import { RootState } from 'types/types'
import {
  newToken,
  selectIsLocked,
  selectReloadChallenge,
} from 'features/auth/modules/auth'

const { GOOGLE_RECAPTCHA_SECRET_SCORE } = __CONFIG__

type RecaptchaType = ReCAPTCHA & { executeAsync: () => Promise<string> }

window.recaptchaOptions = {
  useRecaptchaNet: true,
}

const ReCaptchaContext = React.createContext<RecaptchaType | null>(null)
const ReCaptchaTokenContext = React.createContext<string | null>(null)

export function useReCaptcha() {
  const reCaptchaContext = useContext(ReCaptchaContext)
  const reCaptchaTokenContext = useContext(ReCaptchaTokenContext)

  return {
    ref: reCaptchaContext,
    token: reCaptchaTokenContext,
  }
}

interface ReCaptchaProviderProps {
  children: React.ReactNode
  isLocked?: boolean
  reloadChallenge?: boolean
  newToken: typeof newToken
}

const ReCaptchaProvider = ({
  children,
  isLocked,
  reloadChallenge,
  newToken,
}: ReCaptchaProviderProps) => {
  const ref = React.createRef<any>()
  const [savedRef, setSavedRef] = useState<null | RecaptchaType>(null)
  const [token, setToken] = useState<null | string>(null)

  useEffect(() => {
    if (ref.current) setSavedRef(ref.current)
  }, [ref])

  const handleChange = useCallback((token: string | null) => {
    setToken(token)
  }, [])

  const createNewChallenge = useCallback(async () => {
    await ref.current.reset()
    const loginToken: string = await ref.current.executeAsync()
    loginToken &&
      newToken({ captcha: loginToken, site_key: GOOGLE_RECAPTCHA_SECRET_SCORE })
  }, [newToken, ref])

  useEffect(() => {
    if (isLocked && reloadChallenge) {
      createNewChallenge()
    }
  }, [createNewChallenge, isLocked, reloadChallenge])

  const handleChangeNull = useCallback(() => setToken(null), [])

  return (
    <>
      <ReCAPTCHA
        ref={ref}
        sitekey={GOOGLE_RECAPTCHA_SECRET_SCORE}
        size="invisible"
        onChange={handleChange}
        onErrored={handleChangeNull}
        onExpired={handleChangeNull}
      />
      <ReCaptchaContext.Provider value={savedRef}>
        <ReCaptchaTokenContext.Provider value={token}>
          {children}
        </ReCaptchaTokenContext.Provider>
      </ReCaptchaContext.Provider>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLocked: selectIsLocked(state),
  reloadChallenge: selectReloadChallenge(state),
})

const mapDispatchToProps = {
  newToken,
}

export const ReCaptchaProviderConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReCaptchaProvider)
