import { handle } from 'redux-pack'
import {
  all,
  call,
  delay,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { History } from 'history'
import { isPlainObject } from 'lodash-es'
import { api } from 'api'
import axios, { AxiosResponse } from 'axios'

import getQueryVariable from 'utils/getQueryVariable'
import {
  LoginData,
  LoginErrors,
  LoginMFAData,
  ProfileData,
  ReduxAction,
  RootState,
} from 'types/types'
import { MFAAuthenticator } from 'modules/types'
import { isAxiosError } from 'utils/isAxiosError'

export const dataKey = 'auth'

export type AuthError = LoginErrors | Record<string, never> | null

export interface AuthState {
  error: AuthError
  isAuthenticated: boolean
  isLoading: boolean
  isLocked: boolean
  isNotVerified: boolean
  unverifiedEmail?: string
  reloadChallenge?: boolean
  savedLoginData: SavedLoginData | null
  toggleReset: boolean
  authenticators?: MFAAuthenticator[] | null
  defaultMFA?: string | null
  emailChangeable?: boolean | null
  feedbackSubmittable?: boolean | null
}

interface SavedLoginData {
  loginData: LoginData
  history: History | undefined
}

export const checkAuthCookie = async () => {
  try {
    await api.getProfile()
  } catch (e) {
    if (!isAxiosError(e)) return
    if (e.response?.status === 401) logout()
  }
}

const LOGIN = `${dataKey}/LOGIN`
export const login = (
  data: Partial<LoginData>,
  meta?: { history: History }
) => ({
  type: LOGIN,
  payload: data,
  meta,
})

const NEW_TOKEN = `${dataKey}/NEW_TOKEN`
export const newToken = (
  data: { captcha: string; site_key: string } | null
) => {
  return {
    type: NEW_TOKEN,
    payload: { captcha: data?.captcha, site_key: data?.site_key },
  }
}

const LOGOUT = `${dataKey}/LOGOUT`
export const logout = () => ({
  type: LOGOUT,
  promise: api.logout(),
})

const LOGIN_MFA = `${dataKey}/LOGIN_MFA`
export const loginMFA = (data: LoginMFAData, meta?: { history: History }) => ({
  type: LOGIN_MFA,
  payload: data,
  meta,
})

const LOGIN_MFA_DONE = `${dataKey}/LOGIN_MFA_DONE`
const LOGIN_MFA_FAILED = `${dataKey}/LOGIN_MFA_FAILED`

const REAUTH_MFA = `${dataKey}/REAUTH_MFA`

const REAUTH_MFA_DONE = `${dataKey}/REAUTH_MFA_DONE`
const reauthDone = () => ({
  type: REAUTH_MFA_DONE,
})

const REAUTH_MFA_FAILED = `${dataKey}/REAUTH_MFA_FAILED`
const reauthMFAFailed = (payload: AuthError) => ({
  type: REAUTH_MFA_FAILED,
  payload,
})

export const LOGIN_DONE = `${dataKey}/LOGIN_DONE`
const loginDone = (data: ProfileData) => ({
  type: LOGIN_DONE,
  payload: data,
})

export const CHECK_AUTH = `${dataKey}/CHECK_AUTH`
export const checkAuth = () => ({
  type: CHECK_AUTH,
  promise: api.getProfile(),
})

const MERGE_AUTH_STATE = `${dataKey}/MERGE_AUTH_STATE`
const mergeAuthState = (state: Partial<AuthState>) => ({
  type: MERGE_AUTH_STATE,
  payload: state,
})

const RESET_AUTH_ERRORS = `${dataKey}/RESET_AUTH_ERRORS`
export const resetAuthErrors = () => ({
  type: RESET_AUTH_ERRORS,
})

const DO_DOUBLE_LOGIN = `${dataKey}/DO_DOUBLE_LOGIN`
export const doDoubleLogin = (qs: string = window.location.search) => ({
  type: DO_DOUBLE_LOGIN,
  payload: qs,
})

const LOGIN_AUTH_USER = `${dataKey}/LOGIN_AUTH_USER`
export const loginAuthUser = (uri: string) => ({
  type: LOGIN_AUTH_USER,
  payload: uri,
})

const selectData = (state: RootState) => state[dataKey]

export const selectIsAuthenticated = (state: RootState) =>
  selectData(state).isAuthenticated

export const selectErrors = (state: RootState) => selectData(state).error

export const selectIsLoading = (state: RootState) => selectData(state).isLoading

export const selectIsLocked = (state: RootState) => selectData(state).isLocked

export const selectDefaultMFA = (state: RootState) =>
  selectData(state).defaultMFA

export const selectAuthenticators = (state: RootState) =>
  selectData(state).authenticators

export const selectCanChangeEmail = (state: RootState) =>
  selectData(state).emailChangeable && !selectData(state).isLoading

export const selectCanSubmitFeedback = (state: RootState) =>
  selectData(state).feedbackSubmittable && !selectData(state).isLoading

export const selectIsNotVerified = (state: RootState) =>
  selectData(state).isNotVerified

export const selectUnverifiedEmail = (state: RootState) =>
  selectData(state).unverifiedEmail

const selectSavedLoginData = (state: RootState) =>
  selectData(state).savedLoginData

export const selectReloadChallenge = (state: RootState) =>
  selectData(state).reloadChallenge

export const selectIsDoneAuthenticating = (state: RootState) =>
  selectData(state).isAuthenticated && !selectData(state).isLoading

function* loginSaga({ payload, meta = {} }: ReduxAction<LoginData>) {
  yield put(mergeAuthState({ isLoading: true, error: null }))

  const savedLoginData: SavedLoginData | null =
    yield select(selectSavedLoginData)

  let loginData: LoginData

  if (payload.captcha && payload.site_key && savedLoginData) {
    loginData = {
      username: savedLoginData.loginData.username,
      password: savedLoginData.loginData.password,
      captcha: payload.captcha,
      site_key: payload.site_key,
    }
  } else {
    loginData = payload
  }

  let loginResponse: AxiosResponse
  try {
    loginResponse = yield call(api.login, loginData)
  } catch (e) {
    let error: AuthError = {}

    if (!axios.isAxiosError(e)) {
      console.error(e)
      return
    }

    //api contract states to only return objects, ignore anything else
    if (isPlainObject(e.response?.data)) {
      error = e.response?.data
    }

    const prevIsLocked: boolean = yield select(selectIsLocked)
    const isLocked = e.response?.status === 423

    const savedHistory =
      meta && meta.history
        ? meta.history
        : savedLoginData && savedLoginData.history
    if (e.response?.status === 499) {
      savedHistory &&
        savedHistory.push(
          savedHistory.location.search
            ? `/login/mfa/${savedHistory.location.search}`
            : '/login/mfa'
        )
    }

    yield put(
      mergeAuthState({
        isLoading: isLocked && !prevIsLocked && !payload.captcha,
        isAuthenticated: false,
        isLocked,
        error: e.response?.status === 423 ? undefined : error,
        savedLoginData: isLocked ? { loginData, history: meta.history } : null,
        reloadChallenge: isLocked && !prevIsLocked,
        isNotVerified:
          e?.response?.data?.details === 'Unverified user' ? true : false,
        unverifiedEmail: undefined,
        authenticators:
          e.response?.status === 499 ? error?.authenticators : null,
        defaultMFA:
          e.response?.status === 499 && typeof error?.user !== 'string'
            ? error?.user?.default_mfa
            : null,
        emailChangeable:
          e.response?.status === 499 && typeof error?.user !== 'string'
            ? error?.user?.email_changeable
            : null,
        feedbackSubmittable:
          e.response?.status === 499 && typeof error?.user !== 'string'
            ? error?.user?.feedback_submittable
            : null,
      })
    )
    if (isLocked) {
      yield delay(60000)
      yield put(
        mergeAuthState({
          savedLoginData: null,
          unverifiedEmail: undefined,
          reloadChallenge: false,
        })
      )
    }
    return
  }

  if (!loginResponse.data.is_verified) {
    yield put(
      mergeAuthState({
        isNotVerified: true,
        unverifiedEmail: undefined,
        isAuthenticated: false,
        isLoading: false,
        isLocked: false,
        savedLoginData: null,
        toggleReset: false,
        reloadChallenge: false,
      })
    )
    return
  }
  yield put(loginDone(loginResponse.data))
  yield put(doDoubleLogin())
}

function* subscribeToLoginSaga() {
  yield takeLatest([LOGIN, NEW_TOKEN], loginSaga)
}

function* reauthMFASaga({ payload }: ReduxAction<LoginMFAData>) {
  try {
    yield call(api.loginMFA, payload)
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      console.error(error)
      return
    }

    const errorMessage = isPlainObject(error.response?.data)
      ? error.response?.data
      : {}

    yield put(reauthMFAFailed(errorMessage))
    return
  }
  yield put(reauthDone())
}

function* loginMFASaga({ payload, meta = {} }: ReduxAction<LoginMFAData>) {
  yield put(mergeAuthState({ isLoading: true, error: null }))

  let loginResponse: AxiosResponse
  try {
    // Select default mfa and send token to it
    loginResponse = yield call(api.loginMFA, payload)
  } catch (e) {
    const getLoginError = () => {
      if (!axios.isAxiosError(e)) {
        console.error(e)
        return
      }
      switch (e.response?.status) {
        case 401:
          return { detail: 'unauthorized' }
        case 403:
        case 429:
          return { detail: e.response?.data?.detail }
        default:
          return null
      }
    }
    yield put(
      mergeAuthState({
        isLoading: false,
        isAuthenticated: false,
        error: getLoginError(),
        isLocked: false,
        unverifiedEmail: undefined,
        reloadChallenge: false,
      })
    )
    return
  }

  if (!loginResponse.data.is_verified) {
    yield put(
      mergeAuthState({
        isNotVerified: true,
        isAuthenticated: false,
        isLoading: false,
        isLocked: false,
        unverifiedEmail: loginResponse.data.email,
        reloadChallenge: false,
      })
    )
    meta.history &&
      meta.history.push(
        meta.history.location.search
          ? `/login/${meta.history.location.search}`
          : '/login'
      )
    return
  }

  yield put(loginDone(loginResponse.data))
  yield put(doDoubleLogin())
}

function* subscribeToLoginMFASaga() {
  yield takeEvery(LOGIN_MFA, loginMFASaga)
}

function* subscribeToReauthMFASaga() {
  yield takeEvery(REAUTH_MFA, reauthMFASaga)
}

function* doDoubleLoginSaga({ payload: qs }: ReturnType<typeof doDoubleLogin>) {
  let jwtResponse: AxiosResponse
  try {
    jwtResponse = yield call(api.getJWTToken)
  } catch (e) {
    yield put(mergeAuthState({ isLoading: false }))

    if (!axios.isAxiosError(e)) {
      console.error(e)
      return
    }

    console.error('Unexpected response status while fetching SSO JWT token', {
      status: e?.response?.status,
      data: e?.response?.data,
    })

    if (__DEV__) {
      console.log('🤖 Double login skipped on local build!') // eslint-disable-line no-console
      yield put(mergeAuthState({ isAuthenticated: true, isLoading: false }))
    }
    return
  }

  api.redirectJWT({
    token: jwtResponse.data.token,
    qs,
  })
}

function* subscribeToDoDoubleLoginSaga() {
  yield takeEvery(DO_DOUBLE_LOGIN, doDoubleLoginSaga)
}

function* loginAuthUserSaga({ payload: qs }: ReturnType<typeof loginAuthUser>) {
  const hasRedirectQs = getQueryVariable('redirect', qs)

  if (!hasRedirectQs) {
    return
  }
  while (true) {
    const selfResponse: ReduxAction<AxiosResponse['data']> =
      yield take(CHECK_AUTH)
    if (selfResponse.error) break
    if (
      selfResponse.meta?.['redux-pack/LIFECYCLE'] &&
      selfResponse.meta['redux-pack/LIFECYCLE'] === 'success'
    ) {
      yield put(doDoubleLogin(qs))
      break
    }
  }
}

function* subscribeToResetAuthErrorsSaga() {
  yield takeEvery(RESET_AUTH_ERRORS, resetAuthErrorsSaga)
}

function* resetAuthErrorsSaga() {
  yield put(mergeAuthState({ error: null }))
}

function* subscribeToLoginAuthUserSaga() {
  yield takeEvery(LOGIN_AUTH_USER, loginAuthUserSaga)
}

export function* authRootSaga() {
  yield all([
    spawn(subscribeToLoginSaga),
    spawn(subscribeToLoginMFASaga),
    spawn(subscribeToDoDoubleLoginSaga),
    spawn(subscribeToLoginAuthUserSaga),
    spawn(subscribeToReauthMFASaga),
    spawn(subscribeToResetAuthErrorsSaga),
  ])
}

const initialState: AuthState = {
  error: null,
  isAuthenticated: false,
  isLoading: true,
  isLocked: false,
  isNotVerified: false,
  savedLoginData: null,
  toggleReset: false,
  authenticators: undefined,
  defaultMFA: undefined,
  emailChangeable: undefined,
  feedbackSubmittable: undefined,
  reloadChallenge: false,
}

export const authReducer = (state = initialState, action: ReduxAction) => {
  const { type, payload } = action

  switch (type) {
    case CHECK_AUTH:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          isLoading: true,
          error: null,
        }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        success: (prevState) => ({
          ...prevState,
          isAuthenticated: !!payload.is_verified,
          isLocked: false,
        }),
        failure: (prevState) => ({
          ...prevState,
          isAuthenticated: false,
          error: payload.response?.data || null,
        }),
      })
    case LOGOUT:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          isLoading: true,
          error: null,
        }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        success: () => initialState,
        failure: (prevState) => ({ ...prevState, error: payload }),
      })
    case MERGE_AUTH_STATE:
      return {
        ...state,
        ...payload,
      }
    case REAUTH_MFA:
    case LOGIN_MFA:
    case LOGIN_MFA_DONE:
    case REAUTH_MFA_DONE:
      return {
        ...state,
        error: null,
      }
    case LOGIN_MFA_FAILED:
    case REAUTH_MFA_FAILED:
      return {
        ...state,
        error: payload,
      }
    default:
      return state
  }
}
