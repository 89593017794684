import React, { useContext } from 'react'
import { IntlProvider } from 'react-intl'

import { DEFAULT_LANGUAGE, LocaleContext } from 'modules/LocaleContext'

export const AppIntlProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { language, messages } = useContext(LocaleContext)
  return (
    <IntlProvider
      textComponent={React.Fragment}
      defaultLocale={DEFAULT_LANGUAGE}
      locale={language}
      messages={messages}
      children={children}
    />
  )
}
