import { AxiosResponse, isAxiosError } from 'axios'

export const getMutationErrorMessage = (
  error: Error | unknown
): string | null => {
  if (isAxiosError(error) && error.response) {
    return error.response.data.message
  }
  return null
}

// If the mutation is done in Stripe we want to handle differently when the error comes directly from Stripe
// and when it comes from the billing api itself
export const getStripeMutationErrorMessage = (
  error: Error | unknown
): { apiError: string | null; stripeError: string | null } | null => {
  if (isAxiosError(error) && error.response) {
    if (error.response.data.metaData.dependencyMessage) {
      return {
        apiError: null,
        stripeError: error.response.data.metaData.dependencyMessage,
      }
    }
    if (error.response.data.message) {
      return {
        apiError: error.response.data.message,
        stripeError: null,
      }
    }
  }
  return null
}

export const getMutationErrorData = (
  error: Error | unknown
): AxiosResponse['data'] | null => {
  if (isAxiosError(error) && error.response) {
    return error.response.data
  }
  return null
}
