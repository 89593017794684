import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { QueryKey } from '../types'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { UmrDevice, UmrDevicesApi } from 'api/umrDevices'

const umrDevicesApi = new UmrDevicesApi()

export const useUmrDevicesQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const umrDevicesData = useQuery({
    queryKey: [QueryKey.UMR_DEVICES],
    queryFn: umrDevicesApi.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const umrDevices = useMemo(
    () =>
      umrDevicesData.data?.data?.filter(
        (console: UmrDevice) => console.application_name === 'umr'
      ) ?? [],
    [umrDevicesData.data?.data]
  )

  return { umrDevices, isUmrDevicesLoading: umrDevicesData.isFetching }
}
