import { useSelector } from 'react-redux'
import { omit, reduce } from 'lodash-es'

import { getRequester } from 'features/support/unifi/utils/requestUtils'
import { selectProfileData } from 'modules/profile'
import {
  FIELD_FALLBACK_VALUE_NA,
  ZENDESK_FOLLOW_UP_REQUEST_TAG,
  ZENDESK_REQUEST_TAG,
} from 'features/support/constants'
import { ZDCustomFields } from 'features/support/types'

import { CustomFields, SupportFormState } from '../types'
import { config } from '../config/formConfig'
import { OrderIssue } from '../config/types'

const { ZENDESK_STORE_FORM_KEY } = __CONFIG__

export const useFormatTicket = () => {
  const profile = useSelector(selectProfileData)

  const formatRequestedTicket = (
    values: SupportFormState,
    sendsafelyAttachment?: string,
    followup_source_id?: string
  ) => {
    if (!profile) return
    const requester = getRequester(profile)

    const initialValues: CustomFields = {
      country: values.country,
      orderIssue: values.orderIssue,
      orderNumber: values.orderNumber,
      attachments: sendsafelyAttachment,
    }

    const ticketFields = omit(config, 'description', 'info')

    const custom_fields = reduce<typeof ticketFields, ZDCustomFields>(
      ticketFields,
      (result, field, key) => {
        const id: number = field.fieldId
        const value = initialValues[key as keyof CustomFields]
        if (!value) return result
        return [
          ...result,
          {
            id,
            value,
          },
        ]
      },
      []
    )

    // TODO: This is here to bypass a current broken ZD field. It should be improved ASAP
    const isTaxExemption = values.orderIssue === OrderIssue.taxExemption
    if (isTaxExemption) {
      const missingValueId = 360036980953
      const isFoundMissingValue = !!custom_fields.find(
        (field) => field.id === missingValueId
      )
      if (!isFoundMissingValue) {
        custom_fields.push({
          id: missingValueId,
          value: FIELD_FALLBACK_VALUE_NA,
        })
      }
    }

    if (isTaxExemption) {
      const missingValueId = 360036980233
      const isFoundMissingValue = !!custom_fields.find(
        (field) => field.id === missingValueId
      )
      if (!isFoundMissingValue) {
        custom_fields.push({
          id: missingValueId,
          value: FIELD_FALLBACK_VALUE_NA,
        })
      }
    }

    return {
      followup_source_id: followup_source_id || FIELD_FALLBACK_VALUE_NA,
      ticket_form_id: ZENDESK_STORE_FORM_KEY,
      requester,
      tags: [
        ZENDESK_REQUEST_TAG,
        ...(followup_source_id ? [ZENDESK_FOLLOW_UP_REQUEST_TAG] : []),
      ],
      subject: '',
      comment: { body: values.description },
      custom_fields,
    }
  }

  return { formatRequestedTicket }
}
