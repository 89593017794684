export const DAY_IN_SECONDS = 86400
export const ALL_KEY = 'all'
export const BASE_KEY = 'base'
export const ALL_LABEL = 'All'
export const ADD_PAYMENT_VIEW_QUERY = 'addpaymentview'
export const STRIPE_CHARGE_AUTOMATICALLY_KEY = 'charge_automatically'

export enum StripeStatus {
  VOID = 'void',
  PAID = 'paid',
  OPEN = 'open',
  DRAFT = 'draft',
  UNCOLLECTIBLE = 'uncollectible',
}
