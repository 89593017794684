import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'
import axios from 'axios'

import createDataModule from 'utils/moduleCreator'
import { ReduxAction, RootState, SSOAPIError } from 'types/types'
import {
  AuthError,
  checkAuth,
  selectErrors as selectAuthErrors,
} from 'features/auth/modules/auth'
import { reauthMFADone } from 'features/auth/modules/reauth'
import { fetchMFA } from 'modules/mfa'

import {
  CustomPublicKeyCredentialRequestOptions,
  transformAssertionForServer,
  transformCredentialRequestOptions,
} from '../utils/transformCredentials'

const {
  api: { sso },
} = __CONFIG__

export const initiatePasskeyLoginDataKey = 'MFAinitiatePasskeyLogin'
export const passkeyLoginRequestOptionsDataKey = 'MFAPasskeyLoginRequestOptions'
export const verifyLoginPasskeyDataKey = 'MFALoginVerifyPasskey'

const loginPasskeyActions = {
  initiateLogin: `LOGIN_PASSKEY`,
  loginDone: `LOGIN_PASSKEY_DONE`,
  loginFailed: `LOGIN_PASSKEY_FAILED`,
  getRequestOptions: `${passkeyLoginRequestOptionsDataKey}/CREATE`,
  verifyLogin: `${verifyLoginPasskeyDataKey}/CREATE`,
}

type PasskeyRequestOptionsState =
  | {
      publicKeyCredentialRequestOptions: CustomPublicKeyCredentialRequestOptions
    }
  | Record<string, never>

const dataModuleRequestOptions = createDataModule<
  PasskeyRequestOptionsState,
  SSOAPIError
>(
  passkeyLoginRequestOptionsDataKey,
  `${sso.paths.mfa}/webauthn/challenge`,
  api.ssoBase
)

export const resetLoginRequestPasskeyData = dataModuleRequestOptions.resetData
export const selectLoginRequestPasskeyErrors =
  dataModuleRequestOptions.selectErrors

const dataModuleVerify = createDataModule<undefined, SSOAPIError>(
  verifyLoginPasskeyDataKey,
  `${sso.paths.mfa}/webauthn/login`,
  api.ssoBase
)

export const {
  resetData: resetLoginVerifyPasskeyData,
  selectErrors: selectLoginVerifyPasskeyErrors,
} = dataModuleVerify

export const loginPasskey = () => {
  return {
    type: loginPasskeyActions.initiateLogin,
  }
}

const loginPasskeyDone = () => {
  return {
    type: loginPasskeyActions.loginDone,
  }
}

const loginPasskeyFailed = (error: Error) => {
  return {
    type: loginPasskeyActions.loginFailed,
    payload: { error },
  }
}

const getRequestOptions = () => {
  return {
    type: loginPasskeyActions.getRequestOptions,
  }
}

const verifyLoginPasskey = (publicKeyCredential: any) => {
  return {
    type: loginPasskeyActions.verifyLogin,
    payload: { publicKeyCredential },
  }
}

function* loginPasskeySaga() {
  const error: AuthError = yield select(selectAuthErrors)
  const requestOptions: any = error?.publicKeyCredentialRequestOptions
  try {
    if (requestOptions) {
      const credentials: PublicKeyCredential & {
        response: AuthenticatorAssertionResponse
      } = yield navigator.credentials.get({
        publicKey: transformCredentialRequestOptions(requestOptions),
      })
      yield put(verifyLoginPasskey(transformAssertionForServer(credentials)))
    } else {
      yield put(getRequestOptions())
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(loginPasskeyFailed(error))
    }
  }
}

function* subscribeToLoginPasskeySaga() {
  yield takeEvery(loginPasskeyActions.initiateLogin, loginPasskeySaga)
}

function* reFetchedRequestOptionSaga() {
  const data: ReturnType<typeof dataModuleRequestOptions.selectData> =
    yield select(dataModuleRequestOptions.selectData)
  const requestOptions = data?.publicKeyCredentialRequestOptions
  try {
    const credentials: PublicKeyCredential & {
      response: AuthenticatorAssertionResponse
    } = yield navigator.credentials.get({
      publicKey: transformCredentialRequestOptions(requestOptions),
    })
    yield put(verifyLoginPasskey(transformAssertionForServer(credentials)))
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(loginPasskeyFailed(error))
    }
  }
}

function* subscribeToreFetchedRequestOptionSaga() {
  yield takeEvery(
    dataModuleRequestOptions.CREATE_DONE,
    reFetchedRequestOptionSaga
  )
}

function* checkAuthSaga() {
  yield put(loginPasskeyDone())
  yield put(fetchMFA())
  yield put(reauthMFADone())
  yield put(checkAuth())
}

function* subscribeToCheckAuthSaga() {
  yield takeEvery(dataModuleVerify.CREATE_DONE, checkAuthSaga)
}

export function* loginPasskeyRootSaga() {
  yield all([
    spawn(dataModuleRequestOptions.rootSaga),
    spawn(dataModuleVerify.rootSaga),
    spawn(subscribeToLoginPasskeySaga),
    spawn(subscribeToreFetchedRequestOptionSaga),
    spawn(subscribeToCheckAuthSaga),
  ])
}

export const passkeyLoginRequestOptionsReducer =
  dataModuleRequestOptions.reducer
export const verifyPasskeyLoginReducer = dataModuleVerify.reducer

export interface InitiatePasskeyLoginState {
  isLoading: boolean
  error?: Error
}

const initialState = { isLoading: false, errors: undefined }

const selectData = (state: RootState) => state[initiatePasskeyLoginDataKey]

export const passkeyErrors = (state: RootState) => selectData(state).error

export const initatePasskeyLoginReducer = (
  state = initialState,
  action: ReduxAction
) => {
  const { type } = action

  switch (type) {
    case loginPasskeyActions.initiateLogin:
      return {
        isLoading: true,
        error: undefined,
      }
    case loginPasskeyActions.loginDone:
      return {
        isLoading: false,
        error: undefined,
      }
    case loginPasskeyActions.loginFailed:
      return {
        isLoading: false,
        error: action.payload?.error,
      }
    default:
      return state
  }
}
