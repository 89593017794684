import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AnnouncementsApi } from 'api/announcements'
import { QueryKey } from 'store/types'

const announcementsApi = new AnnouncementsApi()

export const useMarkAnnouncementAsRead = () => {
  const queryClient = useQueryClient()

  const markAnnouncementAsRead = useMutation({
    mutationFn: announcementsApi.markAsRead,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKey.ANNOUNCEMENTS] }),
  })

  return {
    markAnnouncementAsRead: markAnnouncementAsRead.mutate,
  }
}
