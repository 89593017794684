import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { EntityToast, Loader, ToastConsumer } from '@ubnt/ui-components'
import { ThreatManagementIcon } from '@ubnt/icons'
import { FooterAction } from '@ubnt/ui-components/Footer/Footer'

import styled from 'theme/styled'
import { closeVisibleModal } from 'modules/modals'
import PrintIcon from 'assets/svgs/print.svg?react'
import ClipboardIcon from 'assets/svgs/clipboard.svg?react'
import { motifPalette } from 'theme'

import { GenericMFAModal } from '../GenericMFAModal'
import {
  selectGenerateBackupData,
  selectGenerateBackupIsLoading,
} from './modules'

export const BACKUP_CODES_MODAL_ID = 'BACKUP_CODES_MODAL_ID'

export const BackupCodesModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isSelectBackupCodes = useSelector(selectGenerateBackupData)
  const isLoading = useSelector(selectGenerateBackupIsLoading)

  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    pageStyle: `
    @media print {
      html,
      body {
        font-size: 30px;
        margin: 20px 20px 0 0;
        color: ${motifPalette.light.text0};
        background: ${motifPalette.light.neutral00};
        padding: 6px 20px;
        width: inherit;
      }
    }
  `,
  })

  const supportsDownload: boolean =
    document.createElement('a').download !== 'undefined'

  const handleDownload = () => {
    const blob = new Blob([isSelectBackupCodes.join(', ')], {
      type: 'text/plain;charset=utf-8',
    })
    if (document.documentMode) {
      window.navigator.msSaveBlob(blob, 'backup_codes.txt')
    } else {
      const url = window.URL || window.webkitURL
      const a = document.createElement('a')
      a.download = 'backup_codes.txt'
      a.href = url.createObjectURL(blob)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  return (
    <ToastConsumer>
      {({ createNotification, removeNotification }) => {
        const actions: FooterAction[] = []

        actions.push({
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CLOSE',
          }),
          variant: 'tertiary',
          disabled: isLoading,
          onClick: () => {
            dispatch(closeVisibleModal())
          },
        })

        actions.push({
          text: intl.formatMessage({
            id: 'SETTINGS_MFA_SETUP_BACKUP_CODES_MODAL_BUTTON_PRINT',
          }),
          variant: 'tertiary',
          disabled: isLoading,
          onClick: () => handlePrint(),
          Icon: <PrintIcon />,
        })

        document.queryCommandSupported('copy') &&
          actions.push({
            text: intl.formatMessage({
              id: 'SETTINGS_MFA_SETUP_BACKUP_CODES_MODAL_BUTTON_COPY',
            }),
            variant: 'tertiary',
            disabled: isLoading,
            Icon: <ClipboardIcon />,
            onClick: () => {
              if (isSelectBackupCodes) {
                const textField = document.createElement('textarea')
                textField.innerText = isSelectBackupCodes.join(' ')
                document.body.appendChild(textField)
                textField.select()
                document.execCommand('copy')
                textField.remove()
                createNotification(
                  <StyledEntityToast
                    icon={<ThreatManagementIcon />}
                    stateIndicator="success"
                    title={intl.formatMessage({
                      id: 'SETTINGS_MFA_SETUP_BACKUP_CODES_TOAST_TITLE',
                    })}
                    duration={5000}
                    details={intl.formatMessage({
                      id: 'SETTINGS_MFA_SETUP_BACKUP_CODES_TOAST_DESCRIPTION',
                    })}
                    onClose={(e, id) => {
                      id && removeNotification(id)
                    }}
                  />
                )
              }
            },
          })

        actions.push({
          text: intl.formatMessage({
            id: 'COMMON_ACTION_DOWNLOAD',
          }),
          variant: 'primary',
          disabled: !supportsDownload || isLoading,
          onClick: handleDownload,
        })

        return (
          <GenericMFAModal
            modalId={BACKUP_CODES_MODAL_ID}
            title={intl.formatMessage({
              id: 'SETTINGS_MFA_SETUP_BACKUP_CODES_MODAL_TITLE',
            })}
            actions={actions}
            description={
              <DescriptionWrapper>
                <FormattedMessage
                  id={'SETTINGS_MFA_SETUP_BACKUP_CODES_MODAL_DESCRIPTION'}
                  values={{
                    b: (text) => (
                      <>
                        <br />
                        <b>{text}</b>
                      </>
                    ),
                  }}
                />
              </DescriptionWrapper>
            }
            isLoading={isLoading}
            footerHeight={180}
          >
            <Wrapper>
              {isSelectBackupCodes && !isLoading ? (
                <BackupCodesWrapper ref={printRef}>
                  <BackupCodesInnerWrapper>
                    {isSelectBackupCodes.map((v: string, i: number) => {
                      return (
                        <BackupCodeRow key={i}>
                          {isSelectBackupCodes[i]}
                        </BackupCodeRow>
                      )
                    })}
                  </BackupCodesInnerWrapper>
                </BackupCodesWrapper>
              ) : (
                <Loader />
              )}
            </Wrapper>
          </GenericMFAModal>
        )
      }}
    </ToastConsumer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  line-height: 1.5;
`

const BackupCodesWrapper = styled.div`
  width: 350px;
  margin: 10px 0;
  @media print {
    width: 100%;
    margin: 40px;
  }
`

const BackupCodesInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const BackupCodeRow = styled.div`
  width: calc(50% - 30px);
  margin: 3px 15px;
  padding: 3px 10px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.neutral02};
  font-family: 'Monaco', monospace;
  color: ${({ theme }) => theme.text1};
  white-space: nowrap;
`

const StyledEntityToast = styled(EntityToast)`
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    min-width: calc(100vw - 32px);
    margin: 0 16px;
  }
  @media (max-width: 400px) {
    max-width: calc(100vw - 32px);
    margin: 0 16px;
  }
`

const DescriptionWrapper = styled('div')`
  line-height: 24px;
`
