import { handle } from 'redux-pack'
import { createSelector } from 'reselect'
import { delay, put, takeEvery } from '@redux-saga/core/effects'
import { api } from 'api'

import {
  ApiUpdateError,
  ProfileData,
  ReduxAction,
  RootState,
  SelfData,
  UserSettings,
} from 'types/types'
import { closeVisibleModal } from 'modules/modals'
import { setProfilePicture } from 'features/profile-picture/module/profilePicture'
import {
  CHECK_AUTH,
  LOGIN_DONE,
  checkAuth,
  logout,
} from 'features/auth/modules/auth'

export const dataKey = 'profile'

const SET_PROFILE_DATA = `${dataKey}/SET_DATA`
const setProfileData = (data: SelfData) => ({
  type: SET_PROFILE_DATA,
  payload: data,
})

const UPDATE_PROFILE_DATA = `${dataKey}/UPDATE_PROFILE_DATA`
export const updateProfileData = (data: ProfileData) => ({
  type: UPDATE_PROFILE_DATA,
  promise: api.updateProfile(data),
  meta: { formData: data },
})

const UPDATE_PROFILE_SETTINGS = `${dataKey}/UPDATE_PROFILE_SETTINGS`
export const updateProfileSettings = (data: UserSettings) => ({
  type: UPDATE_PROFILE_SETTINGS,
  promise: api.updateUserSettings(data),
  meta: { formData: data },
})

const RESET_PROFILE_API_ERRORS = `${dataKey}/RESET_PROFILE_API_ERRORS`
export const resetProfileApiErrors = () => ({
  type: RESET_PROFILE_API_ERRORS,
})

const selectData = (state: RootState) => state[dataKey]

export const selectProfileData = (state: RootState) => selectData(state).data

export const selectProfileUuid = (state: RootState) => {
  const data = selectProfileData(state)
  return data ? data.uuid : undefined
}

export const selectProfileDefaultMFA = createSelector(
  selectProfileData,
  (data) => data?.default_mfa
)

export const selectProfileMailingList = createSelector(
  selectProfileData,
  (data) => data?.mailinglist
)

export const selectProfileDefaultAddress = createSelector(
  selectProfileData,
  (data) => data?.default_address
)

export const selectIsTwoFactorAuthEnabled = createSelector(
  selectProfileData,
  (data) => data?.twofa_enabled
)

export const selectProfileErrors = createSelector(selectData, (data) => {
  if (!data.errors) return {}

  //handle case when username is already taken
  if (data.responseHTTPStatus === 409) {
    return {
      username: 'Sorry, this username is unavailable.',
    }
  }

  const errors = Object.entries(data.errors).reduce<{
    [key: string]: string
  }>((acc, [key, value]) => {
    acc[key] = value && typeof value === 'string' ? value : value[0]
    return acc
  }, {})
  if (!errors.detail) {
    return errors
  } else {
    if (errors.detail.match(/Wrong reset uuid for user/)) {
      return {
        ...errors,
        detail: 'You entered the wrong password. Please try again.',
      }
    }
  }

  return data.errors
})

export const selectProfileIsLoading = (state: RootState) =>
  selectData(state).isLoading

export const selectIsPasswordChanged = (state: RootState) =>
  selectData(state).isPasswordChanged

export const selectIsEmailChanged = (state: RootState) =>
  selectData(state).isEmailChanged

export const selectIsEmployeeAccount = (state: RootState) => {
  const userEmail = selectData(state).data?.email
  const isVerified = selectData(state).data?.is_verified
  return (
    isVerified &&
    (userEmail?.endsWith('@ui.com') || userEmail?.endsWith('@ubnt.com'))
  )
}

function* setProfileDataSaga({ payload }: ReduxAction) {
  if (payload && payload.uuid) {
    yield put(setProfileData(payload))
    yield put(setProfilePicture({ picture: payload.picture }))
  }
}

export function* subscribeToSetProfileSaga() {
  yield takeEvery([CHECK_AUTH, LOGIN_DONE], setProfileDataSaga)
}

function* profileUpdateDoneSaga({ meta = {}, payload }: ReduxAction) {
  if (!meta) return
  if (meta['redux-pack/LIFECYCLE'] === 'success') {
    if (meta.formData && meta.formData.email) {
      yield delay(1000)
    }
    if (meta.formData && meta.formData.password) {
      yield delay(1000)
      yield put(logout())
    }
    yield put(checkAuth())
    yield put(closeVisibleModal())
  } else if (
    meta['redux-pack/LIFECYCLE'] === 'failure' &&
    payload?.response?.status === 409
  ) {
    yield put(closeVisibleModal())
  }
}

export function* subscribeProfileUpdateDoneSaga() {
  yield takeEvery(
    [UPDATE_PROFILE_DATA, UPDATE_PROFILE_SETTINGS],
    profileUpdateDoneSaga
  )
}

export interface ProfileState {
  errors: ApiUpdateError
  data: null | SelfData
  responseHTTPStatus: null | number
  isLoading: boolean
  isPasswordChanged: boolean
  isEmailChanged: boolean
}

const initialData: ProfileState = {
  errors: {},
  data: null,
  responseHTTPStatus: null,
  isLoading: false,
  isPasswordChanged: false,
  isEmailChanged: false,
}

export const profileReducer = (state = initialData, action: ReduxAction) => {
  const { type, payload, meta = {} } = action

  switch (type) {
    case UPDATE_PROFILE_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          errors: {},
          isLoading: true,
        }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        success: (prevState) => ({
          ...prevState,
          data: { ...state.data, ...(meta.formData || {}) },
          errors: {},
          responseHTTPStatus: payload?.response?.status || null,
          isPasswordChanged: meta.formData && !!meta.formData.password,
          isEmailChanged: meta.formData && !!meta.formData.email,
        }),
        failure: (prevState) => ({
          ...prevState,
          errors: payload.response.data,
          responseHTTPStatus: payload.response.status || null,
        }),
      })
    case UPDATE_PROFILE_SETTINGS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          errors: {},
          isLoading: true,
        }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        success: (prevState) => {
          return {
            ...prevState,
            data: state?.data
              ? { ...state.data, mailinglist: !state.data.mailinglist }
              : null,
            errors: {},
            responseHTTPStatus: payload?.response?.status || null,
          }
        },
        failure: (prevState) => ({
          ...prevState,
          errors: payload.response.data,
          responseHTTPStatus: payload.response.status || null,
        }),
      })
    case SET_PROFILE_DATA:
      return { ...state, data: payload }
    case RESET_PROFILE_API_ERRORS:
      return { ...state, errors: {}, responseHTTPStatus: null }
    default:
      return state
  }
}
