import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@ubnt/ui-components/Button'
import { typography } from '@ubnt/ui-components/styles/designToken'

import styled from 'theme/styled'
import IconWarning from 'assets/svgs/warningTriangle.svg?react'
import { setVisibleModal } from 'modules/modals'
import { selectMFAAuthenticators } from 'modules/mfa'
import { MFAAuthenticatorBackupCodes, MFAType } from 'modules/types'
import { extendAssuranceLevel } from 'features/auth/modules/assuranceLevel'
import { LockTooltip } from 'pages/security/LockTooltip'

import { createBackupCode } from '../BackupCodes/modules'
import { BACKUP_CODES_MODAL_ID } from '../BackupCodes/BackupCodesModal'
import { prepareData } from './prepareData'
import { useMotif } from '@ubnt/ui-components'

const Description = styled.div`
  font: ${typography['desktop-typography-caption']};
  color: ${({ theme }) => theme.text3};
  margin-top: 16px;
`
const StyledText = styled.div`
  text-decoration: underline;
`

const IconDescription = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.red06};

  svg {
    margin-right: 5px;
    > path {
      fill: ${(props) => props.theme.red06};
    }
  }
`

export const RecoveryCodes: React.FC = () => {
  const RECOVERY_CODE_WARNING_LIMIT = 5
  const intl = useIntl()
  const dispatch = useDispatch()
  const { motif } = useMotif()

  const MFAauthenticators = useSelector(selectMFAAuthenticators)

  const recoveryCode: MFAAuthenticatorBackupCodes = useMemo(() => {
    const authenticator = MFAauthenticators.find(
      (authenticator) => authenticator.type === MFAType.backupCodes
    )
    return authenticator as MFAAuthenticatorBackupCodes
  }, [MFAauthenticators])

  const preparedData =
    recoveryCode && prepareData(recoveryCode, intl, dispatch, motif)

  const generateRecoveryCode = () => {
    if (recoveryCode) {
      preparedData?.action.verify?.action()
    } else {
      dispatch(setVisibleModal(BACKUP_CODES_MODAL_ID))
      dispatch(createBackupCode())
    }
  }

  return (
    <>
      <Description>
        {intl.formatMessage(
          {
            id: 'SETTINGS_MFA_RECOVERY_CODES',
          },
          {
            generate: (
              <LockTooltip inline={true}>
                <Button
                  variant="inline"
                  onClick={() => {
                    dispatch(extendAssuranceLevel())
                    generateRecoveryCode()
                  }}
                >
                  <StyledText>
                    {intl.formatMessage({
                      id: 'SETTINGS_MFA_RECOVERY_CODES_LINK',
                    })}
                  </StyledText>
                </Button>
              </LockTooltip>
            ),
          }
        )}
      </Description>
      {recoveryCode &&
        recoveryCode.codes_left <= RECOVERY_CODE_WARNING_LIMIT && (
          <IconDescription>
            <IconWarning /> {preparedData?.description}
          </IconDescription>
        )}
    </>
  )
}
