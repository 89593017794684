import { console, umrSpecificIssue, userType } from './ConfigOptions'
import { FormConfig } from './types'

export const config: FormConfig = {
  console: {
    fieldId: 1500011190041,
    fieldTitle: 'SUPPORT_UMR_CONSOLE',
    options: console,
    nextSection: { section: 'umrSpecificIssue' },
  },
  umrSpecificIssue: {
    fieldId: 11570412372887,
    fieldTitle: 'SUPPORT_UMR_ISSUE',
    options: umrSpecificIssue,
    nextSection: { section: 'userType' },
  },
  userType: {
    fieldId: 9383100357271,
    fieldTitle: 'SUPPORT_UNIFI_USER_TYPE',
    options: userType,
    nextSection: { section: 'description' },
  },
  description: {
    fieldId: 0,
    fieldTitle: 'SUPPORT_STORE_FORM_DESCRIPTION',
    options: null,
    nextSection: null,
  },
  attachments: {
    fieldId: 10138128074775,
    fieldTitle: 'SUPPORT_ATTACHMENT_UPLOAD_RELEVANT',
    options: null,
    nextSection: null,
  },
  mac: {
    fieldId: 1500011311782,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
  rawData: {
    fieldId: 1500011191181,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
  supportFile: {
    fieldId: 7081318973463,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
}
