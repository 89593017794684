import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { BillingInvoicesApi } from 'api/billing/billingInvoices'
import { useToasts } from 'features/subscriptions/ui/hooks/useToasts'
import { QueryKey } from 'store/types'

const billingInvoicesApi = new BillingInvoicesApi()

export const usePayInvoice = () => {
  const queryClient = useQueryClient()
  const { payInvoiceSuccessToast, payInvoiceErrorToast } = useToasts()

  const payInvoice = useMutation({
    mutationFn: billingInvoicesApi.updateOne,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_INVOICES],
      })
      payInvoiceSuccessToast()
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        payInvoiceErrorToast(error.response?.data)
      }
    },
  })

  return {
    payInvoice: payInvoice.mutate,
  }
}
