import React from 'react'
import styled from 'theme/styled'

interface Props {
  isShown: boolean
  maxHeight?: number
  children: React.ReactNode
}

export const HideOptions: React.FC<Props> = ({
  maxHeight,
  isShown,
  children,
}) => {
  return (
    <HiddenOptions maxHeight={maxHeight} isShown={isShown}>
      {children}
    </HiddenOptions>
  )
}

const HiddenOptions = styled.div<{ isShown: boolean; maxHeight?: number }>`
  max-height: ${(p) => (p.isShown ? (p.maxHeight ?? 400) : 0)}px;
  overflow-y: ${(p) => (p.isShown ? 'scroll' : 'hidden')};
  transition: max-height 0.5s;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
