import { fetchRequestWithCookies } from 'api/axios'
import { STRIPE_FETCH_LIMIT } from 'api/constants'
import { CustomerProducts } from 'features/subscriptions/module/types'

const {
  api: { billing },
} = __CONFIG__

export class BillingProductsApi {
  public getAll = async () => {
    const url = new URL(billing.paths.products, billing.base)
    url.searchParams.append('limit', STRIPE_FETCH_LIMIT)

    const { data } = await fetchRequestWithCookies<CustomerProducts>(url.href)
    return data
  }
}
