import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Button } from '@ubnt/ui-components'
import { ArrowLeftPrimaryIcon } from '@ubnt/icons'
import { typography } from '@ubnt/ui-components/styles/designToken'

import { useAppView } from 'utils/useAppView'
import styled from 'theme/styled'

const AppViewHeader: React.FC<{ headerId?: string; backUrl?: string }> = ({
  headerId,
  backUrl = '/',
}) => {
  const [isAppView] = useAppView()

  if (!isAppView) return null

  return (
    <>
      <Container>
        <Link to={backUrl}>
          <StyledButton variant="tertiary">
            <StyledArrow size="small" />
            <FormattedMessage id="COMMON_ACTION_BACK" />
          </StyledButton>
        </Link>
      </Container>
      {headerId && (
        <Header>
          <FormattedMessage id={headerId} />
        </Header>
      )}
    </>
  )
}

export default AppViewHeader

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.neutral01};
  color: ${({ theme }) => theme.neutral12};
  display: inline-block;
  margin-bottom: 16px;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`

const StyledArrow = styled(ArrowLeftPrimaryIcon)`
  margin-right: 8px;
`

const Header = styled.div`
  font: ${typography['desktop-heading-xlarge-1']};
  margin-top: 8px;
  margin-bottom: 16px;
  @media (min-width: ${({ theme }) => theme.media.mediumV2}) {
    display: none;
  }
`
