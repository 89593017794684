import {
  all,
  call,
  put,
  race,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { api } from 'api'

import { ReduxAction, RootState } from 'types/types'
import createDataModule from 'utils/moduleCreator'
import UserSvg from 'assets/svgs/userPlaceholder.svg'

const {
  api: { sso },
} = __CONFIG__

export interface ProfilePictureState {
  picture?: string | null
}

export const dataKey = 'profilePicture'

const dataModule = createDataModule<ProfilePictureState>(
  dataKey,
  sso.paths.picture,
  api.ssoBase
)

const UPDATE_PROFILE_PICTURE = `${dataKey}/UPDATE_PROFILE_PICTURE`
export const updateProfilePicture = (file: File) => ({
  type: UPDATE_PROFILE_PICTURE,
  payload: file,
})

export const {
  setData: setProfilePicture,

  selectIsLoading: selectIsProfilePictureLoading,
  selectErrors: selectProfilePictureErrors,

  resetErrors: resetProfilePictureErrors,
} = dataModule

export const selectProfilePicture = (state: RootState) => {
  const { picture } = dataModule.selectData(state)
  if (!picture) return UserSvg
  return picture
}

async function blobToUri(blob: Blob) {
  let uri: string | undefined
  try {
    uri = await new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = function (e: any) {
        resolve(e.target.result)
      }
      reader.readAsDataURL(blob)
    })
  } catch (e) {
    console.error(e)
  }
  return uri
}

function* updateProfilePictureSaga({ payload: file }: ReduxAction) {
  yield put(dataModule.create(file))

  const { error } = yield race({
    success: take(dataModule.CREATE_DONE),
    error: take(dataModule.CREATE_FAILED),
  })

  if (error) return

  const rawUri: string = yield call(blobToUri, file)
  yield put(
    setProfilePicture({
      picture: rawUri,
    })
  )
}

function* subscribeToUpdateProfilePictureSaga() {
  yield takeEvery(UPDATE_PROFILE_PICTURE, updateProfilePictureSaga)
}

export function* profilePictureRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToUpdateProfilePictureSaga),
  ])
}

export const profilePictureReducer = (
  state = dataModule.initialState,
  action: ReduxAction
) => {
  switch (action.type) {
    case dataModule.SET_DATA: {
      if (state.data.picture && state.data.picture.startsWith('data:')) {
        return dataModule.reducer(state, {
          ...action,
          payload: {
            picture: `${action.payload.picture}?cachbust=${Date.now()}`,
          },
        })
      }
      return dataModule.reducer(state, action)
    }
    default:
      return dataModule.reducer(state, action)
  }
}
