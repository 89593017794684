import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { LoginIssueFeedbackApi } from 'api/loginIssueFeedback'
import {
  selectCanSubmitFeedback,
  selectDefaultMFA,
} from 'features/auth/modules/auth'
import { QueryKey } from '../types'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { useMemo } from 'react'

const loginIssueFeedbackApi = new LoginIssueFeedbackApi()

export const useLoginIssueFeedbackQuery = () => {
  const canSubmitFeedback = useSelector(selectCanSubmitFeedback)
  const mfaId = useSelector(selectDefaultMFA)

  const loginIssueFeedback = useQuery({
    queryKey: [QueryKey.LOGIN_ISSUE_FEEDBACK],
    queryFn: () => loginIssueFeedbackApi.get(mfaId),
    enabled: !!canSubmitFeedback,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const hasSubmittedMfaFeedbackRecently = useMemo(
    () => !loginIssueFeedback.data?.isAllowed,
    [loginIssueFeedback.data?.isAllowed]
  )

  return {
    hasSubmittedMfaFeedbackRecently,
  }
}
