import React, { FC, ReactNode } from 'react'
import { space } from '@ubnt/ui-components/styles/designToken'
import { EmptyResult } from '@ubnt/ui-components'
import styled from 'theme/styled'

export const InfoContainer = styled.div`
  display: flex;
  gap: ${space['desktop-spacing-base-03']};
  padding: ${space['desktop-spacing-base-04']};
  background-color: ${({ theme }) => theme.neutral01};
  border-radius: 4px;
`

export const StyledEmptyResult = styled(EmptyResult)`
  svg {
    min-height: 80px;
    min-width: 80px;
  }
`

interface FlexWrapperProps {
  alignContent?: string
  alignItems?: string
  justifyContent?: string
  gap?: number
  column?: boolean
  marginTop?: number
  marginLeft?: number
  margin?: number | string
  width?: string | number
  padding?: number
  children: React.ReactNode
}

const StyledFlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  ${({ column }) => (column ? `flex-direction: column;` : '')}
  ${({ alignContent }) =>
    alignContent ? `align-content: ${alignContent};` : ''}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
    ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent};` : ''}
    ${({ gap }) => (gap ? `gap: ${gap}px;` : '')}
    ${({ padding }) => (padding ? `padding: ${padding}px;` : '')}
    ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}px;` : '')}
    ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}px;` : '')}
       ${({ width }) =>
    width
      ? `width: ${Number.isNaN(Number(width)) ? width : `${width}px`};`
      : ''}
       ${({ margin }) =>
    margin
      ? `margin: ${Number.isNaN(Number(margin)) ? margin : `${margin}px`};`
      : ''}
`

export const FlexWrapper: FC<FlexWrapperProps> = ({
  column = false,
  alignContent = undefined,
  alignItems = undefined,
  justifyContent = undefined,
  gap = undefined,
  marginTop = undefined,
  marginLeft = undefined,
  margin = undefined,
  width = undefined,
  padding = undefined,
  children,
}) => {
  return (
    <StyledFlexWrapper
      justifyContent={justifyContent}
      alignContent={alignContent}
      alignItems={alignItems}
      gap={gap}
      column={column}
      marginTop={marginTop}
      marginLeft={marginLeft}
      width={width}
      margin={margin}
      padding={padding}
    >
      {children}
    </StyledFlexWrapper>
  )
}

export const DeviceLogoImage = styled.img<{ width?: number }>`
  object-fit: cover;
  width: ${({ width }) => (width ? width : 40)}px;
`

export const boldText = (chunks: ReactNode[]): ReactNode => <b>{chunks}</b>
