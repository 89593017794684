import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { IntlShape, useIntl } from 'react-intl'
import Dropdown from '@ubnt/ui-components/Dropdown/Dropdown'
import { InputLabel } from 'pages/profile/Profile.styles'
import { selectIsEmployeeAccount, selectProfileData } from 'modules/profile'
import styled from 'theme/styled'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

const ONE_MIN_IN_SECONDS = 60
const FIVE_MIN_IN_SECONDS = 300
const ONE_DAY_IN_SECONDS = 86400
const SEVEN_DAYS_IN_SECONDS = 604800
const THIRTY_DAYS_IN_SECONDS = 2592000

const getDropdownOptions = (isEmployee: boolean, intl: IntlShape) => {
  if (isEmployee) {
    return [
      {
        value: ONE_MIN_IN_SECONDS,
        label: intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_MIN' }),
      },
      {
        value: FIVE_MIN_IN_SECONDS,
        label: intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_FIVE_MIN' }),
      },
      {
        value: ONE_DAY_IN_SECONDS,
        label: intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_DAY' }),
      },
    ]
  }
  return [
    {
      value: ONE_DAY_IN_SECONDS,
      label: intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_DAY' }),
    },
    {
      value: SEVEN_DAYS_IN_SECONDS,
      label: intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_WEEK' }),
    },
    {
      value: THIRTY_DAYS_IN_SECONDS,
      label: intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_MONTH' }),
    },
  ]
}

export const WebSessionTimeout: React.FC<{
  setSessionLength: (t: number) => void
  sessionLength?: number
}> = ({ setSessionLength, sessionLength }) => {
  const isEmployee = useSelector(selectIsEmployeeAccount)
  const profileData = useSelector(selectProfileData)
  const intl = useIntl()
  const { isMaxSmall } = useContext(MediaSizingContext)

  useEffect(() => {
    if (profileData?.session_ttl_seconds && !sessionLength) {
      setSessionLength(profileData.session_ttl_seconds)
    }
  }, [profileData?.session_ttl_seconds, sessionLength, setSessionLength])

  return (
    <>
      <OuterWrapper>
        <InputLabel>
          {intl.formatMessage({ id: 'SETTINGS_SECURITY_SESSION_TIMEOUT' })}
        </InputLabel>
        <InnerWrapper>
          <Dropdown
            width={isMaxSmall ? '200px' : '336px'}
            options={getDropdownOptions(!!isEmployee, intl)}
            variant="secondary"
            value={sessionLength}
            onChange={({ value }) => setSessionLength(value)}
          />
        </InnerWrapper>
      </OuterWrapper>
    </>
  )
}

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 650px;
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 360px;
  @media (max-width: ${({ theme }) => theme.media.small}) {
    align-items: right;
    justify-content: flex-end;
    width: auto;
  }
`
