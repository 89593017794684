import { Country, OrderIssue, ZDFormValueType } from './types'

export const country: ZDFormValueType[] = [
  {
    id: Country.brazil,
    label: 'Brazil',
  },
  {
    id: Country.canada,
    label: 'Canada',
  },
  {
    id: Country.china,
    label: 'China',
  },
  {
    id: Country.europe,
    label: 'Europe',
  },
  {
    id: Country.india,
    label: 'India',
  },
  {
    id: Country.japan,
    label: 'Japan',
  },
  {
    id: Country.mexico,
    label: 'Mexico',
  },
  {
    id: Country.middleEast,
    label: 'Middle East',
  },
  {
    id: Country.singapore,
    label: 'Singapore',
  },
  {
    id: Country.southAfrica,
    label: 'South Africa',
  },
  {
    id: Country.taiwan,
    label: 'Taiwan',
  },
  {
    id: Country.uk,
    label: 'UK',
  },
  {
    id: Country.usa,
    label: 'USA',
  },
]

export const orderIssue: ZDFormValueType[] = [
  {
    id: OrderIssue.trackOrder,
    label: 'Track My Order',
  },
  {
    id: OrderIssue.cancelOrder,
    label: 'Cancel My Order',
  },
  {
    id: OrderIssue.returnOrder,
    label: 'Return My Order',
  },
  {
    id: OrderIssue.productAvailability,
    label: 'Product Availability',
  },
  {
    id: OrderIssue.taxExemption,
    label: 'Tax Exemption',
  },
  {
    id: OrderIssue.other,
    label: 'Other Order Issue',
  },
]
