import React, { Fragment, useContext, useMemo } from 'react'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'

import BackupsHeader from 'features/backups/BackupsHeader'
import ProtectedPage from 'pages/ProtectedPage'
import WrappedSubmitPasswordModal from 'features/backups/SubmitPasswordModal'
import AppViewHeader from 'components/AppViewHeader'
import { ALL_KEY } from 'sharedConstants'
import { BackupsContext } from 'features/backups/BackupsContext'
import { BackupsErrorPlaceholder } from 'features/backups/BackupsErrorPlaceholder'
import { BackupsList } from 'features/backups/BackupsList'
import { Section } from 'components/Section.styles'
import { StyledEmptyResult } from 'components/SharedComponents'
import { BackupsHeaderRemoved } from 'features/backups/BackupsHeaderRemoved'

import BackupsSkeleton from './BackupsSkeleton'
import { getSortingFunctionForBackupDevices } from './utils'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import { useNcaBackupsQuery } from 'store/queries/useNcaBackupsQuery'
import { useNcaArchivedBackupsQuery } from 'store/queries/useNcaArchivedBackupsQuery'
import { DeviceBackups } from 'api/nca/types'

const Backups: React.FC = () => {
  const { areNcaDevicesLoading, areNcaDevicesFetched } = useNcaDevicesQuery()
  const { backupsFromAllDevices, areNcaBackupsLoading, ncaBackupsError } =
    useNcaBackupsQuery()
  const {
    parsedNcaArchivedBackups,
    areNcaArchivedBackupsLoading,
    ncaArchivedBackupsError,
  } = useNcaArchivedBackupsQuery()

  const isLoading = areNcaArchivedBackupsLoading || areNcaBackupsLoading
  const hasError = ncaBackupsError || ncaArchivedBackupsError

  const {
    devicesPerPage,
    selectedDevice,
    currentPage,
    setCurrentPage,
    selectedSort,

    devicesPerPageRemoved,
    selectedDeviceRemoved,
    currentPageRemoved,
    setCurrentPageRemoved,
    selectedSortRemoved,
  } = useContext(BackupsContext)

  const displayedDevicesWithBackups = useMemo(() => {
    if (selectedDevice === ALL_KEY) {
      return backupsFromAllDevices
        ?.sort(getSortingFunctionForBackupDevices(selectedSort))
        .slice(devicesPerPage * (currentPage - 1), devicesPerPage * currentPage)
    }

    const foundDeviceBackup = backupsFromAllDevices?.find(
      ({ deviceId }: DeviceBackups) => selectedDevice === deviceId
    )

    return foundDeviceBackup ? [foundDeviceBackup] : []
  }, [
    backupsFromAllDevices,
    devicesPerPage,
    selectedSort,
    currentPage,
    selectedDevice,
  ])

  // Archieved Backups
  const displayedDevicesWithBackupsRemoved = useMemo(() => {
    if (selectedDeviceRemoved === ALL_KEY) {
      return parsedNcaArchivedBackups
        ?.sort(getSortingFunctionForBackupDevices(selectedSortRemoved))
        .slice(
          devicesPerPageRemoved * (currentPageRemoved - 1),
          devicesPerPageRemoved * currentPageRemoved
        )
    }

    const foundDeviceBackup = parsedNcaArchivedBackups?.find(
      ({ deviceId }: DeviceBackups) => selectedDeviceRemoved === deviceId
    )

    return foundDeviceBackup ? [foundDeviceBackup] : []
  }, [
    parsedNcaArchivedBackups,
    devicesPerPageRemoved,
    selectedSortRemoved,
    currentPageRemoved,
    selectedDeviceRemoved,
  ])

  const isPageLoading = useMemo(() => {
    return isLoading || areNcaDevicesLoading || !areNcaDevicesFetched
  }, [isLoading, areNcaDevicesLoading, areNcaDevicesFetched])

  const hasBackups = useMemo(() => {
    return backupsFromAllDevices?.length && isEmpty(hasError)
  }, [hasError, backupsFromAllDevices])

  const isSortFilterVisible = useMemo(() => {
    return backupsFromAllDevices?.length > 1 && selectedDevice === ALL_KEY
  }, [backupsFromAllDevices, selectedDevice])

  const handlePaginationClick = (page: number) => {
    setCurrentPage?.(page < 1 ? 1 : page)
  }

  const handlePaginationClickRemovedBackups = (page: number) => {
    setCurrentPageRemoved?.(page < 1 ? 1 : page)
  }

  const renderPageContent = () => {
    if (isPageLoading) {
      return <BackupsSkeleton />
    }

    if (
      (!backupsFromAllDevices?.length || !hasBackups) &&
      !parsedNcaArchivedBackups?.length
    ) {
      return isEmpty(hasError) ? (
        <StyledEmptyResult
          description={<FormattedMessage id="SETTINGS_BACKUPS_EMPTY" />}
          size="large"
          style={{ marginTop: '24px' }}
        />
      ) : (
        <BackupsErrorPlaceholder />
      )
    }

    return (
      <Fragment>
        <Section $omitMarginTop>
          <BackupsHeader
            displaySort={isSortFilterVisible}
            devices={backupsFromAllDevices}
          />
          <BackupsList
            handlePaginationClick={handlePaginationClick}
            selectedDevice={selectedDevice}
            devicesWithBackupsToDisplay={displayedDevicesWithBackups}
            devicesWithBackups={backupsFromAllDevices}
            currentPage={currentPage}
          />
        </Section>
        {!!parsedNcaArchivedBackups?.length && (
          <Section $omitMarginTop>
            <BackupsHeaderRemoved removedDevices={parsedNcaArchivedBackups} />
            <BackupsList
              handlePaginationClick={handlePaginationClickRemovedBackups}
              selectedDevice={selectedDeviceRemoved}
              devicesWithBackupsToDisplay={displayedDevicesWithBackupsRemoved}
              devicesWithBackups={parsedNcaArchivedBackups}
              currentPage={currentPageRemoved}
              isArchived
            />
          </Section>
        )}
      </Fragment>
    )
  }

  return (
    <ProtectedPage renderMargin={true}>
      <AppViewHeader headerId="COMMON_SIDE_NAV_CLOUD_BACKUPS" />
      {renderPageContent()}
      <WrappedSubmitPasswordModal />
    </ProtectedPage>
  )
}

export default Backups
