import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { AlertSlackChannelStateMap } from './AlertSlackChannelWrapper'
import { AlertSlackChannelState } from './config'

const {
  api: { accountBE },
} = __CONFIG__

export const AlertSlackChannel: React.FC = () => {
  const { ticketId } = useParams<{ ticketId: string }>()
  const [status, setStatus] = useState<AlertSlackChannelState>(
    AlertSlackChannelState.LOADING
  )

  useEffect(() => {
    const handleClick = async () => {
      try {
        const res = await axios.post(
          `${accountBE.base}/${accountBE.paths.alertSlackChannel}/${ticketId}`,
          {},
          { withCredentials: true }
        )

        setStatus(
          res.status === 204
            ? AlertSlackChannelState.SUCCESS
            : AlertSlackChannelState.FAILURE
        )
      } catch (err) {
        setStatus(AlertSlackChannelState.FAILURE)
      }
    }

    handleClick()
  }, [ticketId])

  return AlertSlackChannelStateMap[status]
}
