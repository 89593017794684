import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '@ubnt/ui-components'

import { selectRoles } from 'features/early-access/module/roles'
import { setVisibleModal } from 'modules/modals'
import { EARLY_ACCESS_LEARN_MORE_MODAL_ID } from 'features/early-access/ui/LearnMoreModal'
import { EARLY_ACCESS_ABOUT_MODAL_ID } from 'features/early-access/ui/AboutEarlyAccessModal'
import { EARLY_ACCESS_BENEFITS_MODAL_ID } from 'features/early-access/ui/BenefitsModal'
import {
  CheckIcon,
  Columns,
  ColumnsWithGap,
  GridWrapper,
  InputLabel,
  StyledInfoIcon,
  StyledTooltipDefault,
} from 'pages/profile/Profile.styles'

const BETA_TESTERS_KEY = 'Beta Testers'

const EarlyAccess = () => {
  const dispatch = useDispatch()
  const hasBetaRole = useSelector(selectRoles).includes(BETA_TESTERS_KEY)
  const intl = useIntl()

  const handleOnEnroll = useCallback(() => {
    dispatch(setVisibleModal(EARLY_ACCESS_ABOUT_MODAL_ID))
  }, [dispatch])

  return (
    <GridWrapper className="enroll">
      <InputLabel>
        {intl.formatMessage({ id: 'SETTINGS_EARLY_ACCESS_HEADER' })}
        <StyledTooltipDefault
          description={intl.formatMessage(
            { id: 'SETTINGS_EARLY_ACCESS_TOOL_TIP' },
            {
              link: (
                <>
                  <br />
                  <a
                    onClick={() =>
                      dispatch(
                        setVisibleModal(EARLY_ACCESS_LEARN_MORE_MODAL_ID)
                      )
                    }
                  >
                    {intl.formatMessage({
                      id: 'COMMON_ACTION_LEARN_MORE',
                    })}
                  </a>
                </>
              ),
            }
          )}
          tooltipClassName="override-index"
          portal
          isInteractive
          width={265}
        >
          <StyledInfoIcon size="original" isActive variant="twoTone" />
        </StyledTooltipDefault>
      </InputLabel>
      <Columns>
        {hasBetaRole ? (
          <>
            <CheckIcon variant="twoTone" isActive />
            <ColumnsWithGap>
              {intl.formatMessage(
                {
                  id: 'SETTINGS_EARLY_ACCESS_ABOUT_ENROLED',
                },
                {
                  link: (
                    <a
                      onClick={() =>
                        dispatch(
                          setVisibleModal(EARLY_ACCESS_BENEFITS_MODAL_ID)
                        )
                      }
                    >
                      <FormattedMessage id="SETTINGS_EARLY_ACCESS_VIEW_BENEFITS" />
                    </a>
                  ),
                }
              )}
            </ColumnsWithGap>
          </>
        ) : (
          <Button variant="inline" size="medium" onClick={handleOnEnroll}>
            <FormattedMessage id="SETTINGS_EARLY_ACCESS_ABOUT_DESCRIPTION" />
          </Button>
        )}
      </Columns>
    </GridWrapper>
  )
}

export default EarlyAccess
