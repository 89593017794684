import React, { useEffect, useState } from 'react'
import { ClassNames, Global, css } from '@emotion/react'
import { Modal, ModalProps } from '@ubnt/ui-components'
import { WithRef } from '@ubnt/ui-components/utils/withMotif'
import isMobile from 'is-mobile'

import { useMotif } from 'motif/useMotif'
import { motifPalette } from 'theme'

interface Props {
  footerHeight?: string
  appView?: boolean
  headerBorderBottom?: boolean
  isInvoiceModal?: boolean
  overrideFullScreen?: boolean
}

export const GenericModal: React.FC<
  ModalProps & Props & WithRef<ModalProps>
> = ({
  children,
  actions,
  footerHeight,
  isOpen,
  appView = false,
  isInvoiceModal = false,
  headerBorderBottom,
  overrideFullScreen = false,
  ...restProps
}) => {
  const isMobileDevice = isMobile()
  const showFullScreen = (isMobileDevice || appView) && !overrideFullScreen

  const [modalRef, setModalRef] = useState<HTMLDivElement>()

  const motif = useMotif()

  useEffect(() => {
    if (isOpen && modalRef) {
      modalRef.scrollTo(0, 0)
    }
  }, [isOpen, modalRef])

  return (
    <>
      <Global
        styles={css`
          body {
            overflow: hidden;
          }
        `}
      />
      <ClassNames>
        {({ css }) => (
          <Modal
            contentRef={(ref) => setModalRef(ref)}
            isOpen={isOpen}
            className={css`
              ${restProps.size === undefined && `max-width: 540px;`}
              .modalContent {
                scrollbar-width: thin;
              }
              ${headerBorderBottom &&
              `
            .invoice-header {
              padding: 20px 0 16px;
              border-bottom: 1.5px solid ${motifPalette[motif].neutral03};
              margin: 0 20px;
            }
            .invoice-content {
              padding: ${isInvoiceModal ? 0 : '0 20px'};
              margin: 16px 0;
            }
            `}
              ${showFullScreen &&
              `
              transition: none;
              max-width: 90vw;
              width: 90vw;
              height: 90vh;
              max-height: 90vh;
              justify-content: space-between;
            `};
              footer {
                width: 100%;
                ${showFullScreen &&
                `
                min-height: ${
                  actions ? actions.length * 50 : footerHeight ?? '100'
                }px;
                border: none;
                justify-content: flex-end;
                > div {
                  flex-direction: column-reverse;
                  flex-wrap: wrap;
                  justify-content: space-around;
                  width: 100%;
                  padding-bottom: 10px;
                  height: ${
                    actions ? actions.length * 50 : footerHeight ?? '100'
                  }px;
                }
                div div {
                  width: 100%;
                  margin-left: 0px;
                }
              `}
              }
              header {
                ${appView && 'display: none;'}
              }
            `}
            actions={actions}
            {...restProps}
          >
            {children}
          </Modal>
        )}
      </ClassNames>
    </>
  )
}
