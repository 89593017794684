import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { EntityToast, ToastContext } from '@ubnt/ui-components'
import { UserAccountIcon } from '@ubnt/icons'

import styled from 'theme/styled'

const ACCOUNT_DELETED_TOAST_ID = 'ACCOUNT_DELETED_TOAST_ID'

const DeleteAccountToast = () => {
  const { createNotification, removeNotification } = useContext(ToastContext)

  const intl = useIntl()

  useEffect(() => {
    const deactivatedData = localStorage.getItem('accountDeleted')
    let timer: ReturnType<typeof setTimeout> | null = null

    if (deactivatedData) {
      createNotification(
        <StyledEntityToast
          id={ACCOUNT_DELETED_TOAST_ID}
          icon={<UserAccountIcon />}
          stateIndicator="success"
          title={intl.formatMessage({
            id: 'SETTINGS_SECURITY_DELETE_SUCCESS_HEADER',
          })}
          duration={0}
          primaryButton={{
            label: intl.formatMessage({ id: 'COMMON_ACTION_OKAY' }),
            onClick: () => removeNotification(ACCOUNT_DELETED_TOAST_ID),
          }}
        />
      )
      //FIX: Remove when double logout is fixed
      timer = setTimeout(() => localStorage.removeItem('accountDeleted'), 3000)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [createNotification, intl, removeNotification])

  return <></>
}

export default DeleteAccountToast

const StyledEntityToast = styled(EntityToast)`
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    width: calc(100vw - 32px);
    margin: 0 16px;
  }
`
