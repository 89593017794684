import styled from 'theme/styled'

export const InputGroups = styled('div')`
  display: flex;
  flex-wrap: wrap;
`

interface InputGroupProps {
  full?: boolean
  noRightPadding?: boolean
}

export const InputGroup = styled.div<InputGroupProps>`
  flex: 1 100%;
  max-width: 100%;

  ${(p) =>
    !p.full &&
    `
    flex: 1 50%;
    max-width: 50%;
    padding-bottom: 20px;
    padding-right: 40px;
  `}

  ${({ noRightPadding }) => noRightPadding && `padding-right: 0;`}

  @media (max-width: ${(p) => p.theme.media.medium}) {
    flex: 1 100%;
    max-width: 100%;
    padding-right: 0;
  }
`
