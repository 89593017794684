import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { ArrowRightPrimaryIcon } from '@ubnt/icons'

import {
  IMobileNavigationConfig,
  IMobileNavigationKey,
  INavigationItem,
  ROUTES,
  Route,
} from 'types/routes'
import { useAppView } from 'utils/useAppView'

import {
  IconContainer,
  MobileNavigationContainer,
  StyledExternalLink,
  StyledMobileNavLink,
  StyledMobileNavLinkInner,
} from './Navigation.styles'
import { NAVIGATION_CONFIG } from './Navigation'

const OTHER_LINKS: INavigationItem[] = [
  {
    id: Route.UniFi,
    title: 'UniFi',
    link: ROUTES[Route.UniFi],
    newTab: true,
  },
  {
    id: Route.UISP,
    title: 'UISP',
    link: ROUTES[Route.UISP],
    newTab: true,
  },
  {
    id: Route.Community,
    title: <FormattedMessage id="COMMON_LABEL_COMMUNITY" />,
    link: ROUTES[Route.Community],
    newTab: true,
  },
  {
    id: Route.Store,
    title: <FormattedMessage id="COMMON_LABEL_STORE" />,
    link: ROUTES[Route.Store],
    newTab: true,
  },
]

const MAIN_KEY = 'main'
const OTHER_KEY = 'other'

export const MobileNavigation: React.FC = () => {
  const [isAppView] = useAppView()

  const mobileNavigationConfig: IMobileNavigationConfig = {
    [MAIN_KEY]: NAVIGATION_CONFIG,
    [OTHER_KEY]: OTHER_LINKS,
  }

  return (
    <Fragment>
      {Object.keys(mobileNavigationConfig).map((key) => {
        if (key === OTHER_KEY && isAppView) return null

        return (
          <MobileNavigationContainer key={key}>
            {mobileNavigationConfig[key as IMobileNavigationKey].map(
              ({ id, title, Icon, link, newTab }) => {
                if (key === MAIN_KEY) {
                  return (
                    <StyledMobileNavLink
                      key={id}
                      to={link}
                      activeClassName="active"
                      $isAppView={isAppView}
                    >
                      <StyledMobileNavLinkInner>
                        <IconContainer>{Icon && <Icon />}</IconContainer>
                        {title}
                      </StyledMobileNavLinkInner>
                      {isAppView && <ArrowRightPrimaryIcon size="small" />}
                    </StyledMobileNavLink>
                  )
                }
                return (
                  <StyledExternalLink
                    key={id}
                    href={link}
                    target={newTab ? '_blank' : '_self'}
                  >
                    {title}
                  </StyledExternalLink>
                )
              }
            )}
          </MobileNavigationContainer>
        )
      })}
    </Fragment>
  )
}
