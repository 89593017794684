import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AxiosResponse } from 'axios'
import styled from '@emotion/styled'
import { UnlockIcon } from '@ubnt/icons'
import { Button } from '@ubnt/ui-components/Button'

import { size, space } from 'theme'

type Props = {
  isLoading: boolean
  onClose: (() => void) | undefined
  handleSubmit: () => void
  logout: () => {
    type: string
    promise: Promise<AxiosResponse<any> | undefined>
  }
}

const SubmitPasswordModalCustomFooter: React.FC<Props> = ({
  isLoading,
  onClose,
  handleSubmit,
}) => (
  <FooterWrapper>
    <ButtonWrapper>
      <Button disabled={isLoading} onClick={onClose}>
        <FormattedMessage id="COMMON_ACTION_CANCEL" />
      </Button>
      <Button
        variant="primary"
        disabled={isLoading}
        type="submit"
        onClick={handleSubmit}
        loader={isLoading ? 'dots' : undefined}
      >
        <TextWrapper>
          <IconWrapper>
            <UnlockIcon size="original" />
          </IconWrapper>
          <FormattedMessage id="SETTINGS_LOCK_BUTTON_UNLOCK" />
        </TextWrapper>
      </Button>
    </ButtonWrapper>
  </FooterWrapper>
)

export default SubmitPasswordModalCustomFooter

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 ${size['desktop-sizing-base-05']} ${size['desktop-sizing-base-05']};
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${space['desktop-spacing-base']};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const IconWrapper = styled.div`
  margin-right: 5px;
  margin-left: -3px;
`
