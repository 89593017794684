import { createSelector } from 'reselect'
import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import createDataModule from 'utils/moduleCreator'
import { fetchMFA } from 'modules/mfa'
import { setVisibleModal } from 'modules/modals'

import { BACKUP_CODES_FAILED_MODAL_ID } from '../BackupCodesFailedModal'

const {
  api: { sso },
} = __CONFIG__

export const generateBackupDataKey = 'generateMFABackupCodes'

interface generateBackupState {
  backup_codes?: string[]
}

const dataModule = createDataModule<generateBackupState>(
  generateBackupDataKey,
  sso.paths.twoFaBackup,
  api.ssoBase
)

const { selectIsLoading, selectErrors, reducer, CREATE_DONE, CREATE_FAILED } =
  dataModule

export {
  selectIsLoading as selectGenerateBackupIsLoading,
  selectErrors as selectGenerateBackupErrors,
  reducer as generateBackupReducer,
}

const CREATE_BACKUP_CODE = `${generateBackupDataKey}/CREATE`
export const createBackupCode = () => ({
  type: CREATE_BACKUP_CODE,
})

export const selectGenerateBackupData = createSelector(
  dataModule.selectData,
  (data) => data.backup_codes || []
)

function* createFailedMFASaga() {
  yield put(setVisibleModal(BACKUP_CODES_FAILED_MODAL_ID))
}

function* subscribeToCreateFailedSaga() {
  yield takeEvery(CREATE_FAILED, createFailedMFASaga)
}

function* backupCodeDoneSaga() {
  yield put(fetchMFA())
}

function* subscribeToBackupCodeDoneSaga() {
  yield takeEvery(CREATE_DONE, backupCodeDoneSaga)
}

export function* generateBackupRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToCreateFailedSaga),
    spawn(subscribeToBackupCodeDoneSaga),
  ])
}
