import { keyframes } from '@emotion/react'

export const HeaderFadeIn = keyframes`
  from {
    transform: translateY(-56px);
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`
