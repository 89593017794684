import React, { createContext, useState } from 'react'
import { CssVariableProvider, Motif } from '@ubnt/ui-components'

export type ThemeOption = Motif | 'system'

const DEFAULT_MOTIF = 'light'

const {
  featureFlags: { isDarkModeEnabled },
} = __CONFIG__

export const parseThemeFromSystem = () => {
  const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches

  return isDarkTheme ? 'dark' : 'light'
}

const getMotif = () => {
  if (!isDarkModeEnabled) {
    return DEFAULT_MOTIF
  }

  const storedTheme = localStorage.getItem('theme') as ThemeOption | null

  if (storedTheme === 'system') {
    return parseThemeFromSystem()
  }

  return storedTheme ?? DEFAULT_MOTIF
}

export const MotifContext = createContext<{
  motif: Motif
  setMotif: (value: Motif) => void
}>({
  motif: getMotif(),
  setMotif: () => {},
})

export const MotifProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [motif, setMotif] = useState<Motif>(getMotif())

  return (
    <MotifContext.Provider value={{ motif, setMotif }}>
      <CssVariableProvider motif={motif}>{children}</CssVariableProvider>
    </MotifContext.Provider>
  )
}
