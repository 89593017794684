import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'

import styled from 'theme/styled'
import { Section, SectionBody, SectionHeader } from 'components/Section.styles'
import { StyledButton } from 'features/payment-methods/ui/Payments.styles'

import {
  AccountIconContainer,
  ColumnsContainer,
  RowsContainer,
} from './Card.styles'

interface CardInfo {
  headingId: string
  bodyId: string
  action: {
    textId: string
    url: string
  }
  icon: React.ReactNode
}

interface Props {
  cardInfo: CardInfo
}

const Card: React.FC<Props> = ({
  cardInfo: { headingId, bodyId, action, icon },
}) => {
  const history = useHistory()

  return (
    <Section $omitMarginBottom $omitMarginTop>
      <ColumnsContainer>
        <RowsContainer>
          <div>
            <SectionHeader $marginBottom={4}>
              <FormattedMessage id={headingId} />
            </SectionHeader>
            <StyledSectionBody>
              <FormattedMessage id={bodyId} />
            </StyledSectionBody>
          </div>
          <StyledButton
            onClick={() => history.push(action.url)}
            size="medium"
            variant="inline"
          >
            <FormattedMessage id={action.textId} />
          </StyledButton>
        </RowsContainer>
        <AccountIconContainer>{icon}</AccountIconContainer>
      </ColumnsContainer>
    </Section>
  )
}

const StyledSectionBody = styled(SectionBody)`
  max-width: 250px;
`

export default Card
