import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import { fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'

const {
  api: { sso },
} = __CONFIG__

export const removePendingPushDataKey = 'MFARemovePendingPush'

const removePendingPushActions = {
  remove: `${removePendingPushDataKey}/REMOVE`,
}

type RemovePendingPushState = Record<string, never>

const dataModule = createDataModule<RemovePendingPushState, SSOAPIError>(
  removePendingPushDataKey,
  `${sso.paths.mfa}/push`,
  api.ssoBase
)

const { REMOVE_DONE } = dataModule

export const removePendingMFAPush = (id: string) => {
  return {
    type: removePendingPushActions.remove,
    urlPath: `/${id}`,
  }
}

function* closeModalSaga() {
  yield put(fetchMFA())
}

function* subscribeToRemovedPendingPushDoneSaga() {
  yield takeEvery(REMOVE_DONE, closeModalSaga)
}

export function* removePendingPushRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToRemovedPendingPushDoneSaga),
  ])
}

export const removePendingPushReducer = dataModule.reducer
