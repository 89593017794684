import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CheckmarkIcon from '@ubnt/icons/CheckmarkIcon'
import ClosePrimaryIcon from '@ubnt/icons/ClosePrimaryIcon'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'
import { typography } from 'theme'

export const SupportFile: React.FC<{
  isSupportFileAllowed: boolean
  setIsSupportFileAllowed: React.Dispatch<React.SetStateAction<boolean>>
  supportFileDevice?: string
}> = ({ supportFileDevice, isSupportFileAllowed, setIsSupportFileAllowed }) => {
  const intl = useIntl()

  if (!isSupportFileAllowed) {
    return (
      <Info>
        <FormattedMessage id="SUPPORT_FILE_NO_FILE" />
        <Button variant="inline" onClick={() => setIsSupportFileAllowed(true)}>
          {' '}
          <FormattedMessage id="SUPPORT_FILE_ATTACH" />
        </Button>
      </Info>
    )
  }

  return (
    <Container>
      <Info>
        <StyledCheckmarkIcon variant="outline" size="medium" isActive />
        <FormattedMessage id="SUPPORT_FILE" />
      </Info>
      <File>
        <div>
          {intl.formatMessage(
            { id: 'SUPPORT_FILE_TITLE' },
            {
              device: supportFileDevice ? `(${supportFileDevice})` : '',
            }
          )}
        </div>
        <ClickableClosePrimaryIcon
          onClick={() => setIsSupportFileAllowed(false)}
        />
      </File>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font: ${typography['desktop-typography-link-small']};
  color: ${({ theme }) => theme.text3};
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  color: ${({ theme }) => theme.green06} !important;
  circle {
    color: ${({ theme }) => theme.green01} !important;
  }
`

const ClickableClosePrimaryIcon = styled(ClosePrimaryIcon)`
  cursor: pointer;
`

const File = styled.div`
  background: ${({ theme }) => theme.neutral03};
  border-radius: 6px;
  padding: 14px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.text2};
  line-height: 24px;
`
