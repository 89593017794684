import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillingTaxIdApi } from 'api/billing/billingTaxId'
import { QueryKey } from 'store/types'
import { useIntl } from 'react-intl'
import useTaxIdToast from 'features/payment-methods/ui/tax/useTaxIdToast'

const billingTaxIdApi = new BillingTaxIdApi()

export const useDeleteTaxId = () => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const { taxIdSuccessToast, taxIdErrorToast } = useTaxIdToast()

  const deleteTaxId = useMutation({
    mutationFn: billingTaxIdApi.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_TAX_ID],
      })
      taxIdSuccessToast('removed')
    },
    onError: () => {
      const errorMessage = intl.formatMessage({
        id: 'SETTINGS_PAYMENTS_TAX_ID_DELETE_FAILURE',
      })

      taxIdErrorToast('removed', errorMessage)
    },
  })

  return {
    deleteTaxId: deleteTaxId.mutate,
    isDeleteTaxIdLoading: deleteTaxId.isPending,
  }
}
