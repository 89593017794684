import React from 'react'
import { useIntl } from 'react-intl'
import { Input } from '@ubnt/ui-components/Input'
import { Button } from '@ubnt/ui-components/Button'
import styled from 'theme/styled'
import { useFormik } from 'formik'
import { Alert } from '@ubnt/ui-components/Alert'
import axios, { isAxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import Yup from 'validators/yupLocaleConfig'
import { api } from 'api'

const {
  api: { sso, ssoUi },
} = __CONFIG__

export const ChangeEmailForm: React.FC = () => {
  const intl = useIntl()
  const history = useHistory<{ email_changeable: boolean; email: string }>()

  const emailIsTakenMsg = intl.formatMessage({
    id: 'COMMON_AUTH_REGISTER_EMAIL_TAKEN',
  })

  const isEmailUnique = async (value: string) => {
    if (!value || !Yup.string().email().isValidSync(value)) {
      return false
    }

    if (!touched.email) {
      setTouched({ email: true, ...touched })
    }

    try {
      await api.checkEmail(encodeURIComponent(value))
      return false
    } catch (e) {
      return true
    }
  }

  const {
    values,
    handleChange,
    errors,
    setFieldError,
    handleSubmit,
    handleBlur,
    setTouched,
    touched,
  } = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    onSubmit: async () => {
      try {
        const url = new URL(sso.paths.emailOtp, ssoUi.base)
        const res = await axios.post(url.href, values, {
          withCredentials: true,
        })

        if (res.status === 204) {
          history.push({
            pathname: '/login/change-email/confirm',
            state: {
              email: values.email,
              email_changeable: true,
            },
          })
        } else {
          setFieldError('email', 'Something went wrong')
          setFieldError('password', 'Something went wrong')
        }
      } catch (error) {
        if (isAxiosError(error)) {
          setFieldError('email', error.response?.data?.detail)
          setFieldError('password', error.response?.data?.detail)
        }
      }
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required().label('COMMON_LABEL_PASSWORD'),
      email: Yup.string()
        .email()
        .required()
        .label('COMMON_LABEL_NEW_EMAIL')
        .test('email', emailIsTakenMsg, isEmailUnique),
    }),
  })

  return (
    <Form>
      <StyledInput
        variant="secondary"
        full
        data-testid="password"
        type="password"
        name="password"
        autoComplete="current-password"
        passwordToggle
        label={intl.formatMessage({ id: 'COMMON_LABEL_PASSWORD' })}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        invalid={touched.password && errors.password}
      />
      <StyledInput
        variant="secondary"
        full
        data-testid="email"
        type="text"
        name="email"
        autoComplete="email"
        label={intl.formatMessage({ id: 'COMMON_LABEL_NEW_EMAIL' })}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        invalid={touched.email && errors.email}
      />
      <Alert
        type="info"
        text={intl.formatMessage({ id: 'LOGIN_CHANGE_EMAIL_ALERT' })}
      />
      <StyledButton
        variant="primary"
        disabled={
          !!errors.email ||
          !!errors.password ||
          !values.email.length ||
          !values.password.length
        }
        onClick={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        {intl.formatMessage({ id: 'COMMON_ACTION_CHANGE' })}
      </StyledButton>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledInput = styled(Input)`
  height: 68px;
`

const StyledButton = styled(Button)`
  margin: 16px 0;
`
