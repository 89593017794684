import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useTracking = (trackingId?: string) => {
  const { listen } = useHistory() || {}

  useEffect(() => {
    if (!listen) return
    const unlisten = listen((location) => {
      if (!window.gtag || !trackingId) return

      window.gtag('config', trackingId, { page_path: location.pathname })
    })

    return unlisten
  }, [trackingId, listen])
}
