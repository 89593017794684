import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
} from 'features/support/constants'
import { object, string } from 'yup'

export const BillingFormValidationSchema = object({
  billingSpecificService: string().label('COMMON_LABEL_CATEGORY'),
  console: object().label('COMMON_LABEL_CATEGORY'),
  userType: string().label('COMMON_LABEL_USER_TYPE'),
  description: string()
    .max(DESCRIPTION_MAX_LENGTH)
    .min(DESCRIPTION_MIN_LENGTH)
    .required()
    .label('SETTINGS_SUPPORT_FORM_LABEL_DESCRIPTION'),
})
