import { StripeCountry, StripeRegionCode } from '../types'

export const auStripeCountries: Readonly<StripeCountry[]> = [
  {
    code: 'au',
    name: 'Australia',
    region: StripeRegionCode.AU,
  },
  {
    code: 'nz',
    name: 'New Zealand',
    region: StripeRegionCode.AU,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'cx',
    name: 'Christmas Island',
    region: StripeRegionCode.AU,
    taxId: [] as StripeCountry['taxId'],
  },
]
