import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { QueryKey } from '../types'
import { TrustedDevicesApi } from 'api/trustedDevices'

const trustedDevicesApi = new TrustedDevicesApi()

export const useTrustDeviceQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const trustDeviceInfo = useQuery({
    queryKey: [QueryKey.TRUST_DEVICE],
    queryFn: trustedDevicesApi.getDeviceData,
    enabled: isDoneAuthenticating,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    trustDeviceInfo: trustDeviceInfo.data,
    hasFetchedDeviceInfoOnce: trustDeviceInfo.isFetched,
    trustDeviceInfoErrors: trustDeviceInfo.error,
    isTrustDeviceInfoLoading: trustDeviceInfo.isFetching,
  }
}
