import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@ubnt/ui-components'
import styled from 'theme/styled'
import { LockTooltip } from 'pages/security/LockTooltip'
import { RemoveTrustedDeviceDialog } from './RemoveTrustedDeviceDialog'

interface Props {
  isManaging: boolean
  selectedIds: string[]
  currentDeviceId?: string
  toggleManaging: () => void
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const TrustedDevicesListFooter: React.FC<Props> = ({
  isManaging,
  selectedIds,
  currentDeviceId,
  toggleManaging,
  setSelectedIds,
}) => {
  return (
    <FooterButtons $hasMultipleButtons={isManaging}>
      {isManaging && (
        <RemoveTrustedDeviceDialog
          selectedIds={selectedIds}
          isCurrentDeviceSelected={selectedIds.some(
            (id) => id === currentDeviceId
          )}
          setSelectedIds={setSelectedIds}
        />
      )}
      <LockTooltip inline>
        <Button variant="inline" size="medium" onClick={toggleManaging}>
          {isManaging ? (
            <FormattedMessage id="COMMON_ACTION_DONE" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_MANAGE" />
          )}
        </Button>
      </LockTooltip>
    </FooterButtons>
  )
}

const FooterButtons = styled.div<{ $hasMultipleButtons: boolean }>`
  display: flex;
  justify-content: ${({ $hasMultipleButtons }) =>
    $hasMultipleButtons ? 'space-between' : 'flex-end'};
  margin-top: 8px;
  gap: 12px;
`
