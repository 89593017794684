import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { api } from 'api'
import { fetchMFA } from 'modules/mfa'
import { MFAType } from 'modules/types'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { MFAAuthenticatorStatus } from 'enums'
import { setupMFATOTP } from 'modules/mfaSetup'

const {
  api: { sso },
} = __CONFIG__

export const initiateTOTPDataKey = 'MFAinitiateTOTP'

const initiateTOTPActions = {
  create: `${initiateTOTPDataKey}/CREATE`,
}

export type InitiateTOTPState =
  | {
      provisioning_uri: string
      status: MFAAuthenticatorStatus
      last_success: string | null
      id: string
      created: string
      type: MFAType.totp
    }
  | Record<string, never>

const dataModule = createDataModule<InitiateTOTPState, SSOAPIError>(
  initiateTOTPDataKey,
  `${sso.paths.mfa}/totp`,
  api.ssoBase
)

const {
  selectData,
  selectIsLoading,
  selectErrors,
  selectIsError,
  resetData,
  CREATE_DONE,
} = dataModule

export {
  selectIsLoading as selectInitiateTOTPIsLoading,
  selectErrors as selectInitiateTOTPErrors,
  selectIsError as selectInitiateTOTPIsError,
  resetData as resetInitiateTOTPData,
}

export const selectInitiateTOTPData = createSelector(
  selectData,
  (data) => data ?? {} //data might be undefined, override it to be empty object to allow destructuring
)

export const initiateMFATOTP = (name?: string) => {
  return { type: initiateTOTPActions.create, payload: { name } }
}

function* initiateTOTPDoneSaga() {
  const { id } = yield select(selectData)
  yield put(setupMFATOTP(id))
  yield put(fetchMFA())
}

function* subscribeToInitiateTOTPDoneSaga() {
  yield takeEvery(CREATE_DONE, initiateTOTPDoneSaga)
}

export function* initiateTOTPRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToInitiateTOTPDoneSaga),
  ])
}

export const inititateTOTPReducer = dataModule.reducer
