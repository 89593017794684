import React from 'react'

import styled from 'theme/styled'
import useDeviceImage from 'utils/useDeviceImage'
import cloudSvg from 'assets/svgs/cloudConsole.svg'

type Props = {
  name: string
}

const DropdownIcon: React.FC<Props> = ({ name }) => {
  const src = useDeviceImage(name)

  if (src) return <DeviceImage src={src} />
  if (!src && name === 'CLOUD') return <DeviceImage src={cloudSvg} />

  return null
}

export default DropdownIcon

const DeviceImage = styled.img`
  width: 20px;
  vertical-align: middle;
`
