import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { DateTime } from 'luxon'

import { RootState } from 'types/types'
import {
  selectIsAuthenticated,
  selectIsLoading,
} from 'features/auth/modules/auth'
import {
  StoredRedirect,
  storedInnerRedirectKey,
  storedOuterRedirectKey,
} from 'features/redirect-after-register'

export const REDIRECT_QUERY_PARAM_NAME = 'innerRedirect'

const isResetPasswordUri = (location: Location): boolean =>
  location.pathname === '/' &&
  location.hash.startsWith('#reset-password/password-reset-uuid')

const isRegisterVerifyUri = (location: Location): boolean =>
  location.pathname === '/' &&
  location.hash.startsWith('#verify/verification-code')

const requiresAuth = connectedRouterRedirect({
  redirectPath: (state: RootState, { location }: { location: Location }) => {
    if (isResetPasswordUri(location) || isRegisterVerifyUri(location)) {
      return `/${location.hash.slice(1)}`
    }

    const hostUrl = new URL(document.location.href)
    const innerRedirectUrl = hostUrl.searchParams.get('innerRedirect')

    if (innerRedirectUrl) {
      const item: StoredRedirect = {
        url: innerRedirectUrl,
        createdAt: DateTime.utc().toISO(),
      }
      localStorage?.setItem(storedInnerRedirectKey, JSON.stringify(item))
      localStorage.removeItem(storedOuterRedirectKey)
    }

    return location.search ? `/login${location.search}` : '/login'
  },
  allowRedirectBack: (state: RootState, redirectPath: any): boolean =>
    !redirectPath.startsWith('/reset-password/password-reset-uuid') ||
    !redirectPath.startsWith('/verify/verification-code'),
  authenticatedSelector: (state: RootState) => selectIsAuthenticated(state),
  authenticatingSelector: (state: RootState) => selectIsLoading(state),
  wrapperDisplayName: 'RequiresAuth',
  redirectQueryParamName: REDIRECT_QUERY_PARAM_NAME,
})

export default requiresAuth
