import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { Input } from '@ubnt/ui-components/Input'
import { TextArea } from '@ubnt/ui-components/TextArea'
import { Button } from '@ubnt/ui-components/Button'
import { Text } from '@ubnt/ui-components/aria'
import { api } from 'api'
import { selectDefaultMFA } from 'features/auth/modules/auth'
import Yup from 'validators/yupLocaleConfig'
import { useGoogleReCaptchaV3 } from 'features/ReCaptchaV3/useGoogleReCaptchaV3'
import { CaptchaAction } from 'api/zendesk/types'
import { QueryKey } from 'store/types'

const { RECAPTCHA_FEEDBACK_SITE_KEY_ID } = __CONFIG__

const MESSAGE_MIN_LENGTH = 20
const MESSAGE_MAX_LENGTH = 500
const NAME_AND_EMAIL_MAX_LENGTH = 256

export const MfaFeedbackForm = () => {
  const intl = useIntl()
  const mfaId = useSelector(selectDefaultMFA)
  const history = useHistory()
  const [hasFeedbackApiError, setHasFeedbackApiError] = useState(false)
  const [hasSubmittedMfaFeedbackRecently, setHasSubmittedMfaFeedbackRecently] =
    useState(false)
  const { getReCaptchaToken } = useGoogleReCaptchaV3(
    RECAPTCHA_FEEDBACK_SITE_KEY_ID,
    CaptchaAction.FEEDBACK_SSO_ISSUE_LOGIN
  )
  const [isFeedbackSubmitting, setIsFeedbackSubmitting] = useState(false)
  const queryClient = useQueryClient()

  if (navigator.userAgent.indexOf('iPhone') > -1) {
    document
      ?.querySelector('[name=viewport]')
      ?.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1'
      )
  }

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    validateForm,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    onSubmit: async () => {
      try {
        setIsFeedbackSubmitting(true)
        if (mfaId) {
          const { isAllowed } =
            await api.getHasSubmittedMfaFeedbackRecently(mfaId)
          if (!isAllowed) {
            return setHasSubmittedMfaFeedbackRecently(true)
          }
        }
        const captchaToken = await getReCaptchaToken()

        const userAgent = window.navigator.userAgent
        const data = { ...values, userAgent, mfaId: mfaId ?? null }
        await api.submitMFALoginIssueFeedback(data, captchaToken)
        if (mfaId) {
          await api.mfaLoginIssueFeedbackIsSubmitted({ mfaId })
          queryClient.invalidateQueries({
            queryKey: [QueryKey.LOGIN_ISSUE_FEEDBACK],
          })
        }
        history.push('/login/mfa-feedback/success')
      } catch (error) {
        setHasFeedbackApiError(!!error)
        console.error(error, 'Failed to submit SSO login issue feedack')
      } finally {
        setIsFeedbackSubmitting(false)
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required()
        .max(NAME_AND_EMAIL_MAX_LENGTH)
        .emptyInput()
        .label('COMMON_LABEL_NAME'),
      email: Yup.string()
        .email()
        .required()
        .max(NAME_AND_EMAIL_MAX_LENGTH)
        .label('LOGIN_MFA_FEEDBACK_CONTACT_EMAIL'),
      message: Yup.string()
        .required()
        .min(MESSAGE_MIN_LENGTH)
        .max(MESSAGE_MAX_LENGTH)
        .emptyInput()
        .label('COMMON_LABEL_DESCRIPTION'),
    }),
  })

  useEffect(() => {
    // Needed to make sure the form immediatelly gets the empty fields errors and the send button gets disabled
    validateForm()
  }, [validateForm])

  const handleOnChange = (e: React.ChangeEvent) => {
    hasFeedbackApiError && setHasFeedbackApiError(false)
    hasSubmittedMfaFeedbackRecently && setHasSubmittedMfaFeedbackRecently(false)
    handleChange(e)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <StyledInput
        variant="secondary"
        full
        data-testid="name"
        type="text"
        name="name"
        label={intl.formatMessage({ id: 'COMMON_LABEL_NAME' })}
        value={values.name}
        onChange={handleOnChange}
        onBlur={handleBlur}
        invalid={touched.name && errors.name}
      />
      <StyledInput
        variant="secondary"
        full
        data-testid="email"
        type="email"
        name="email"
        autoComplete="email"
        label={intl.formatMessage({ id: 'COMMON_LABEL_CONTACT_EMAIL' })}
        value={values.email}
        onChange={handleOnChange}
        onBlur={handleBlur}
        invalid={touched.email && errors.email}
      />
      <StyledTextArea
        variant="secondary"
        full
        data-testid="message"
        name="message"
        autoComplete="message"
        placeholder={intl.formatMessage({
          id: 'LOGIN_MFA_FEEDBACK_DESCRIBE_ISSUE_PLACEHOLDER',
        })}
        label={intl.formatMessage({ id: 'LOGIN_MFA_FEEDBACK_DESCRIBE_ISSUE' })}
        value={values.message}
        onChange={handleOnChange}
        onBlur={handleBlur}
        invalid={touched.message && errors.message}
        $isInvalid={!!touched.message && !!errors.message}
      />
      <FormFooter>
        {hasFeedbackApiError && (
          <Text size="small" color="red-06">
            {intl.formatMessage({ id: 'LOGIN_MFA_FEEDBACK_GENERIC_ERROR' })}
          </Text>
        )}
        {hasSubmittedMfaFeedbackRecently && (
          <Text size="small" color="red-06">
            {intl.formatMessage({
              id: 'LOGIN_MFA_FEEDBACK_ERROR_SUBMITTED_RECENTLY',
            })}
          </Text>
        )}
        <Button
          variant="primary"
          type="submit"
          disabled={
            !!Object.keys(errors).length || hasSubmittedMfaFeedbackRecently
          }
          loader={isFeedbackSubmitting ? 'loading' : undefined}
        >
          {intl.formatMessage({ id: 'COMMON_ACTION_SEND' })}
        </Button>
        <Link to="/login">
          <Button variant="tertiary">
            {intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' })}
          </Button>
        </Link>
      </FormFooter>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledInput = styled(Input)`
  height: 68px;
`

const StyledTextArea = styled(TextArea)<{ $isInvalid: boolean }>`
  span {
    padding-top: 0;
  }
  ${({ $isInvalid, theme }) =>
    $isInvalid &&
    `textarea, textarea:hover {
        border: 1px solid ${theme.red06} !important
    }`}
`

const FormFooter = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
