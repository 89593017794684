import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import ProtectedPage from 'pages/ProtectedPage'
import { api } from 'api'
import { SubmitSuccess } from 'components/support/SubmitSuccess'
import { BasicToast, ToastContext } from '@ubnt/ui-components'
import { FormattedMessage } from 'react-intl'
import { handleCreateRequestError } from '../utils'
import { mapModelToZendeskDto, parseQueryParams } from './utils'
import { LayoutProvider } from './LayoutProvider'
import { QuickSupportContent } from './QuickSupportContent'
import {
  DisclaimerContainer,
  FormContainer,
  SubmitSuccessWrapper,
  Text,
} from './styles'
import { IQuickSupportModel, PlatformType } from './types'

export const QuickSupportPage: React.FC = () => {
  const { search } = useLocation()
  const { createNotification } = useContext(ToastContext)

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const model = useMemo<IQuickSupportModel>(() => {
    return parseQueryParams(search)
  }, [search])

  const hasValidPlatform = useMemo(() => {
    return Object.values(PlatformType).includes(model?.platform)
  }, [model])

  const submitTicket = async () => {
    if (!Object.values(model).every(Boolean)) {
      return
    }

    const dto = mapModelToZendeskDto(model)
    setIsLoading(true)

    try {
      await api.createZendeskRequest(dto)
      setIsFormSubmitted(true)
    } catch (error) {
      handleCreateRequestError(error, setError, dto, setIsFormSubmitted)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (error) {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={<FormattedMessage id={'SUPPORT_SUBMIT_FAILED_TOAST_TITLE'} />}
          details={<FormattedMessage id={error} />}
        />
      )
    }
  }, [createNotification, error])

  if (!hasValidPlatform) {
    return <Redirect to="/requests" />
  }

  return (
    <ProtectedPage hideUserSection>
      <FormContainer>
        <LayoutProvider>
          {isFormSubmitted ? (
            <SubmitSuccessWrapper>
              <SubmitSuccess isUnifiForm={false} customHeight="100%">
                <DisclaimerContainer>
                  <Text secondary>
                    <FormattedMessage id="SUPPORT_QUICK_LINK_DISCLAIMER" />
                  </Text>
                </DisclaimerContainer>
              </SubmitSuccess>
            </SubmitSuccessWrapper>
          ) : (
            <QuickSupportContent
              data={model}
              isLoading={isLoading}
              onSubmit={submitTicket}
            />
          )}
        </LayoutProvider>
      </FormContainer>
    </ProtectedPage>
  )
}
