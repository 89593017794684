import React from 'react'
import { FormattedMessage } from 'react-intl'
import { typography } from '@ubnt/ui-components/styles/designToken'

import styled from 'theme/styled'
import UIVerify from 'assets/images/uiVerify.png'
import { LockTooltip } from 'pages/security/LockTooltip'
import { StoreButtons } from './StoreButtons'

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  border: solid 1px ${({ theme }) => theme.neutral03};
  padding: 16px;
  border-radius: 10px;
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    flex-direction: column;
    align-items: start;
  }
`

const StyledUIVerify = styled.img`
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 11px;
  margin-right: 16px;
`

const Type = styled.div`
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.text2};
`

const Description = styled.div`
  font: ${typography['desktop-typography-caption']};
  color: ${({ theme }) => theme.text3};
`

const VerifyDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const VerifyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    margin-top: 16px;
    margin-left: 46px;
  }
`

export const DownloadUIVerify: React.FC = () => (
  <Wrapper id="popoverRoot">
    <InnerWrapper>
      <VerifyWrapper>
        <StyledUIVerify src={UIVerify} alt="UI Verify Icon" />
        <VerifyDescWrapper>
          <Type>
            <FormattedMessage id="SETTINGS_MFA_UI_VERIFY_DOWNLOAD_TITLE" />
          </Type>
          <Description>
            <FormattedMessage id="SETTINGS_MFA_UI_VERIFY_DOWNLOAD_DESC" />
          </Description>
        </VerifyDescWrapper>
      </VerifyWrapper>
      <LockTooltip message="">
        <IconSectionWrapper>
          <StoreButtons />
        </IconSectionWrapper>
      </LockTooltip>
    </InnerWrapper>
  </Wrapper>
)
