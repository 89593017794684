import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Button, ConfirmationCode, Loader } from '@ubnt/ui-components'
import UbiquitiLogo from '@ubnt/icons/UbiquitiLogo'

import styled from 'theme/styled'
import { DesktopAppHeader, StyledLink } from 'components/global-menu/styles'
import { RouteChildrenProps, useHistory } from 'react-router-dom'
import iconCheck from 'assets/svgs/iconCheck.svg'
import { UserPopoverConnected } from 'features/user-popover/ui/UserPopover'
import { storedInnerRedirectKey } from 'features/redirect-after-register'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import { useAppView } from 'utils/useAppView'

import useSubmit from './hooks/useSubmit'

type RouteProps = RouteChildrenProps<{ token: string }>

const INPUT_LENGTH = 5

const Activate: React.FC<RouteProps> = () => {
  const history = useHistory()
  const intl = useIntl()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const [isAppView] = useAppView()
  const [userInput, setUserInput] = useState('')
  const {
    handleSubmit,
    isLoading,
    error,
    loginSuccess,
    resetStates,
    data,
    sessionStorageTokenKey,
  } = useSubmit()

  useEffect(() => {
    localStorage.removeItem(storedInnerRedirectKey)
  }, [])

  useEffect(() => {
    const storedToken = sessionStorage.getItem(sessionStorageTokenKey)

    if (storedToken && storedToken.length === 5 && isAuthenticated) {
      handleSubmit(storedToken)
    }
    sessionStorage.removeItem(sessionStorageTokenKey)
  }, [handleSubmit, sessionStorageTokenKey, isAuthenticated])

  const handleChange = useCallback(
    (val: string): void => {
      if (val.length === INPUT_LENGTH) {
        handleSubmit(val)
        setUserInput('')
        return
      }
      setUserInput(val)
    },
    [handleSubmit]
  )

  const view = useMemo(() => {
    if (!loginSuccess) {
      return (
        <MainWrapper>
          <Title>
            {intl.formatMessage({ id: 'SETTINGS_ACTIVATE_ENTER_CODE' })}
          </Title>
          <Description>
            {intl.formatMessage({ id: 'SETTINGS_ACTIVATE_DESCRIPTION' })}
          </Description>
          <InputWrapper>
            <ConfirmationCode
              tokens={INPUT_LENGTH}
              type="text"
              helperMessage={error || undefined}
              invalid={!!error}
              onChange={handleChange}
              className="tokenInput"
              disabled={isLoading}
              value={userInput}
              data-testid={'activate-confirmation-code'}
            />
            {isLoading ? <StyledLoader type="spinner" size="large" /> : <></>}
          </InputWrapper>
        </MainWrapper>
      )
    } else {
      return (
        <>
          <Title>
            {intl.formatMessage({ id: 'SETTINGS_ACTIVATE_SUCCESS' })}
          </Title>
          <Description>
            {intl.formatMessage(
              { id: 'SETTINGS_ACTIVATE_LOGIN_SUCCESS' },
              { deviceName: data }
            )}
          </Description>
          <SuccessWrapper>
            <StyledImg src={iconCheck} />
          </SuccessWrapper>
          <ApproveButtonSection>
            <PrimaryButton onClick={() => history.push('/')} variant="primary">
              {intl.formatMessage({ id: 'SETTINGS_ACTIVATE_GO_TO_ACCOUNT' })}
            </PrimaryButton>
            <SecondaryButton onClick={resetStates} variant="tertiary">
              {intl.formatMessage({
                id: 'SETTINGS_ACTIVATE_LOGIN_ANOTHER_ACCOUNT',
              })}
            </SecondaryButton>
          </ApproveButtonSection>
        </>
      )
    }
  }, [
    loginSuccess,
    intl,
    error,
    handleChange,
    isLoading,
    userInput,
    data,
    resetStates,
    history,
  ])

  return (
    <>
      {!isAppView && (
        <Wrapper>
          <DesktopAppHeader
            logoWrapper={<StyledLink to="/" />}
            title="Account"
            size="standard"
            titleClassName="header-title"
            icon={UbiquitiLogo({ variant: 'neutral' })}
          >
            <UserPopoverWrapper isLoggedIn={isAuthenticated}>
              <UserPopoverConnected />
            </UserPopoverWrapper>
          </DesktopAppHeader>
        </Wrapper>
      )}
      <SectionWrapper>{view}</SectionWrapper>
    </>
  )
}

export default Activate

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    max-width: 225px;
  }
`

const StyledLoader = styled(Loader)`
  margin: 80px auto 0 auto;
`

const UserPopoverWrapper = styled.div<{ isLoggedIn?: boolean }>`
  margin-right: 20px;
  display: ${(props) => (props.isLoggedIn ? 'block' : 'none')};
`

const StyledImg = styled('img')`
  width: 40px;
  border-radius: 50%;
  opacity: 1;
  transition: visibility 1s ease-in-out, opacity 1s ease-in;
  margin: -10px auto 20px auto;
`
const InputWrapper = styled.div`
  align-items: center;
`

const StyledButton = styled(Button)`
  width: 220px;
  height: 40px;
  margin-bottom: 10px;
  font-weight: bold;
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    width: 100%;
  }
`

const PrimaryButton = styled(StyledButton)`
  font-size: 16px;
`

const SecondaryButton = styled(StyledButton)`
  color: ${({ theme }) => theme.text2};
  border: 1px solid ${(p) => p.theme.neutral04};
  :hover {
    border-color: ${({ theme }) => theme.neutral03};
  }
`

const ApproveButtonSection = styled.div`
  width: 230px;
  margin: auto;

  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    width: 100%;
    div {
      width: 100%;
    }
    flex-direction: column;
  }
`

const SuccessWrapper = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  margin: 8px 0;
  color: ${({ theme }) => theme.text3};
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  left: 0;
  transition: 200ms;
`

const SectionWrapper = styled.div`
  padding-top: 128px;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.text1};
`
const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 7px 0 46px 0;
  text-align: center;
  color: ${({ theme }) => theme.text3};
`
