import { Accordion } from '@ubnt/ui-components/Accordion'
import { radius, typography } from 'theme'
import styled from 'theme/styled'

const TableWrapper = styled.div`
  &:hover {
    .action-link {
      display: none;
    }
  }
`

const StyledAccordion = styled(Accordion)`
  .noMargin {
    margin-bottom: 0;
  }

  li {
    border-bottom: 1px solid ${({ theme }) => theme.neutral03} !important;
    margin-left: 16px;

    &[aria-expanded='true'] {
      padding-bottom: 16px;

      @media (max-width: ${({ theme }) => theme.media.xSmall}) {
        padding-bottom: 8px;
      }
    }

    &:last-of-type {
      border-bottom: none !important;
    }

    @media (max-width: ${({ theme }) => theme.media.xSmall}) {
      margin-left: 0;
    }
  }
`

const LabelRow = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.neutral00};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 0;
  width: 100%;
  will-change: background-color;
  transition: background-color 0.2s ease-in;

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    padding: 16px 0;
  }
`

const Label = styled.div`
  font: ${typography['desktop-typography-heading-medium']};
  color: ${({ theme }) => theme.text2};
`

const OuterContentWrap = styled.div`
  width: calc(100% - 24px);
  margin-right: 24px;

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    width: 100%;
    margin-right: 0;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.neutral00};
  border-bottom: 1px solid ${({ theme }) => theme.neutral03};
  transition: background-color 0.2s ease-in;
  will-change: background-color;

  &:hover {
    background-color: ${({ theme }) => theme.neutral01};
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const InvoicesTableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px 100px 64px 110px 68px 70px;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.media.small}) {
    grid-template-columns: 1fr 100px 64px 110px 68px 70px;
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    grid-template-columns: 1fr 100px 64px 110px 70px;
  }

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    grid-template-columns: 1fr 92px 64px 56px;
  }
`

const Content = styled.div`
  color: ${({ theme }) => theme.text2};
  display: flex;
  font: ${typography['desktop-body']};
`

const StatusPill = styled.div`
  background-color: ${({ theme }) => theme.neutral02};
  border-radius: ${radius['desktop-radius-large']};
  color: ${({ theme }) => theme.text3};
  font-size: ${typography['desktop-font-size-xsmall']};
  font-weight: ${typography['desktop-font-weight-bold']};
  line-height: 20px;
  padding: 0 9px;
`

const GreenStatusPill = styled(StatusPill)`
  background-color: ${({ theme }) => theme.green01};
  color: ${({ theme }) => theme.green07};
`

const RedStatusPill = styled(StatusPill)`
  background-color: ${({ theme }) => theme.red01};
  color: ${({ theme }) => theme.red06};
`

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  line-height: 20px;
  padding: 4px;

  &:last-of-type {
    flex-direction: row;
    justify-content: end;
  }

  @media (max-width: ${({ theme }) => theme.media.small}) {
    &.name-column {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    &.billed-column {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    &.date-column {
      display: none;
    }
  }
`

const ViewButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.ublue06};
  cursor: pointer;
  font: ${typography['desktop-typography-body']};
  outline: none;
  padding: 0;
`

const ValueContainer = styled.div<{ voided?: boolean }>`
  width: 100%;
  color: ${({ theme }) => theme.text2};
  text-decoration: ${({ voided }) => (voided ? 'line-through' : 'inherit')};
`

const TableHeaderWrapper = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.neutral03};
`

const TitleWrap = styled.div`
  padding: 4px;
  font: ${typography['desktop-typography-heading-medium']};
  color: ${({ theme }) => theme.text2};

  &:last-of-type {
    text-align: right;
  }

  @media (max-width: ${({ theme }) => theme.media.small}) {
    &.name-column {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    &.billed-column {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    &.date-column {
      display: none;
    }
  }
`

export {
  CellWrapper,
  Content,
  GreenStatusPill,
  InvoicesTableGrid,
  Label,
  LabelRow,
  OuterContentWrap,
  RedStatusPill,
  StatusPill,
  StyledAccordion,
  TableHeaderWrapper,
  TableWrapper,
  TitleWrap,
  ValueContainer,
  ViewButton,
  Wrapper,
}
