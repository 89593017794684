import React, { useContext } from 'react'
import { InputSearch, ToolbarSearch } from '@ubnt/ui-components/Search'

import styled from 'theme/styled'
import { StyledLoader } from 'pages/request/styles'

import { RequestsContext } from './RequestsContext'

type RequestSearchProps = {
  isSmallScreen?: boolean
}

export const RequestsSearch: React.FC<RequestSearchProps> = ({
  isSmallScreen,
}) => {
  const { handleSearch, userSearchValue, isSearchLoading } =
    useContext(RequestsContext)

  if (isSmallScreen) {
    return (
      <SearchContainer>
        <InputSearch
          onChange={handleSearch}
          value={userSearchValue}
          variant="secondary"
          width={'100%'}
          iconAfter={
            isSearchLoading ? (
              <StyledLoader variant="primary" type="spinner" />
            ) : null
          }
        />
      </SearchContainer>
    )
  }

  return (
    <ToolbarSearch
      onChange={handleSearch}
      value={userSearchValue}
      width={220}
      contentAfterInput={
        isSearchLoading ? (
          <StyledLoader variant="primary" type="spinner" />
        ) : null
      }
    />
  )
}

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
