import { FormConfig } from './config/types'
import {
  BubbleProps,
  FormSections,
  FormValue,
  SupportConsole,
  SupportFormState,
} from './types'

const isSupportConsole = (value: FormValue): value is SupportConsole => {
  return (value as SupportConsole)?.id !== undefined
}

export const getNextSection = (
  config: FormConfig,
  value: FormValue,
  currentSection: FormSections,
  values: SupportFormState
): FormSections | undefined => {
  const nextSectionData = config[currentSection].nextSection

  if (nextSectionData && nextSectionData.length > 1) {
    const section = nextSectionData.find((nextSection) => {
      if (nextSection.condition?.field === currentSection) {
        const parsedValue = isSupportConsole(value) ? value.id : value

        return nextSection.condition?.value.includes(parsedValue)
      }
      return nextSection.condition?.value.includes(
        values[nextSection.condition?.field as keyof SupportFormState]
      )
    })?.section
    return section
  }
  return nextSectionData?.[0].section
}

export const setNextSection = (
  config: FormConfig,
  value: FormValue,
  currentSection: FormSections,
  values: SupportFormState,
  handleChange: BubbleProps['handleChange']
) => {
  const section = getNextSection(config, value, currentSection, values)
  handleChange(currentSection, value, section)
}
