import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import ClosePrimaryIcon from '@ubnt/icons/ClosePrimaryIcon'
import PasskeyIconL from '@ubnt/icons/PasskeyIconL'
import { Text } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/Button'
import styled from 'theme/styled'
import theme from 'theme'

export const AddPasskeyBanner: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const history = useHistory()

  const handleEnable = () => {
    onClose()
    history.push('/security?addmfa=webAuthn')
  }

  return (
    <Alert>
      <Content>
        <PasskeyIconL variant="fill" size={20} isActive />
        <Text>
          <FormattedMessage
            id="WEB_AUTHN_BANNER_TEXT"
            values={{ b: (text) => <b>{text}</b> }}
          />
        </Text>
        <Button variant="link" onClick={handleEnable}>
          <FormattedMessage id="COMMON_ACTION_ENABLE" />
        </Button>
      </Content>
      <StyledButton
        Icon={ClosePrimaryIcon({ size: 20 })}
        variant="tertiary"
        onClick={onClose}
      />
    </Alert>
  )
}

const Alert = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: space-between;
  gap: 16px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.neutral01};
  background-opacity: 0.02;
  border-radius: 0px 0px 4px 4px;

  @media (max-width: ${theme.media.mobileXLarge}) {
    gap: 12px;
    padding: 8px 12px;
  }
`

const Content = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (max-width: ${theme.media.mobileXLarge}) {
    height: auto;
    gap: 8px;
  }
`

const StyledButton = styled(Button)`
  padding: 0;
  height: 20px;

  &:hover {
    background: transparent;
  }

  @media (max-width: ${theme.media.mobileXLarge}) {
    height: 100%;
  }
`
