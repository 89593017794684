import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ConfirmationCode, Loader } from '@ubnt/ui-components'
import isMobile from 'is-mobile'
import styled from 'theme/styled'
import isNumber from 'lodash-es/isNumber'

interface Props {
  setCurrentError: React.Dispatch<React.SetStateAction<string | undefined>>
  currentError: string | undefined
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  disabled?: boolean
  autoComplete?: string
  loading?: boolean
}

interface RefType {
  iRefs: Array<React.MutableRefObject<HTMLInputElement>>
  __clearvalues__: () => void
}

export const TokenInput: React.FC<Props> = ({
  setCurrentError,
  currentError,
  setFieldValue,
  disabled = false,
  autoComplete = 'off',
  loading,
}) => {
  const inputRef = useRef<RefType | null>(null)

  const [tokens, setTokens] = useState<string>('')

  useEffect(() => {
    if (currentError) {
      setFieldValue('token', '')
      setTokens('')
      inputRef?.current?.__clearvalues__()
    }
  }, [currentError, setFieldValue])

  // Set custom props
  useEffect(() => {
    const children = inputRef.current?.iRefs

    if (children?.length) {
      children.forEach((child) => {
        const childIndex = Number(child.current.getAttribute('data-id'))
        const index = childIndex ?? 6

        child.current.setAttribute('maxlength', index.toString())
        child.current.setAttribute('autocomplete', autoComplete)
      })
    }
  }, [autoComplete, inputRef])

  const isMobileDevice = isMobile()

  const handleOnAutofill = useCallback(
    (changedToken: string) => {
      const updatedTokens = tokens + changedToken
      setTokens(updatedTokens)
      if (updatedTokens.length === 6) {
        setFieldValue('token', updatedTokens)
        setCurrentError('')
      }
    },
    [setCurrentError, setFieldValue, tokens]
  )

  const handleOnChange = useCallback(
    (changedToken: string) => {
      setTokens(changedToken)
      setFieldValue('token', changedToken)
      setCurrentError('')
    },
    [setCurrentError, setFieldValue]
  )

  const renderFeedback = useMemo(() => {
    if (loading) {
      return <Spinner variant="primary" />
    }
    if (currentError) {
      if (currentError === 'Invalid credential')
        return <FormattedMessage id="COMMON_VALIDATION_INVALID_TOKEN" />
      return currentError
    }
    return null
  }, [currentError, loading])

  return (
    <Wrapper isMobile={isMobileDevice}>
      <ConfirmationCode
        tokens={6}
        type="number"
        onInputChange={(e) =>
          tokens.length === 0 &&
          isNumber(e.target.value) &&
          handleOnAutofill(e.target.value)
        }
        onChange={handleOnChange}
        className="tokenInput"
        disabled={disabled}
        value={tokens}
      />
      <FeedbackDiv>{renderFeedback}</FeedbackDiv>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isMobile?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(p) => (p.isMobile ? '100%' : '275px')};
  margin: 20px auto 0 auto;
  .tokenInput {
    & input {
      width: calc(100% / 6 - 1px);
    }
  }
`

const FeedbackDiv = styled.div`
  color: ${(p) => p.theme.red06};
  font-size: 14px;
  line-height: 24px;
  padding-top: 20px;
  height: auto;
  width: 100%;
  text-align: left;
`

const Spinner = styled(Loader)`
  margin: 0 auto;
`
