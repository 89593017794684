import React from 'react'

import { Section } from 'components/Section.styles'
import { SessionContent } from './components/Session/SessionContent'
import { SessionHeader } from './components/Session/SessionHeader'

export const SessionSection: React.FC = () => (
  <Section>
    <SessionHeader />
    <SessionContent />
  </Section>
)
