import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { QueryKey } from '../types'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { AnnouncementsApi } from 'api/announcements'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'

const announcementsApi = new AnnouncementsApi()

export const useAnnouncementsQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const announcements = useQuery({
    queryKey: [QueryKey.ANNOUNCEMENTS],
    queryFn: announcementsApi.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const unreadAnnouncements = useMemo(
    () =>
      announcements.data?.data
        ?.filter((announcement) => !announcement.isRead)
        .map((announcement) => ({
          ...announcement,
          id: announcement.announcementId,
        })) ?? [],
    [announcements.data?.data]
  )

  return {
    announcements: announcements.data,
    unreadAnnouncements,
    isAnnouncementsFetched: announcements.isFetched,
  }
}
