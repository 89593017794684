import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { FormikProps, withFormik } from 'formik'
import { useDebouncedCallback } from 'use-debounce'
import { api } from 'api'
import { AxiosError } from 'axios'
import { CheckmarkIcon } from '@ubnt/icons'
import { ApiUpdateError, RootState } from 'types/types'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import {
  selectIsPasswordChanged,
  selectProfileData,
  selectProfileErrors,
  selectProfileIsLoading,
  updateProfileData,
} from 'modules/profile'
import { Input } from 'components/Input'
import Yup from 'validators/yupLocaleConfig'
import styled from 'theme/styled'
import PasswordFeedback, {
  PasswordFeedbackData,
  passwordScoreBarWidth,
  passwordScoreColor,
  passwordScoreText,
} from 'features/auth/ui/PasswordFeedback'
import { GenericModal } from 'components/generic-modal/GenericModal'
import theme, { size, typography } from 'theme'
import { isAxiosError } from 'utils/isAxiosError'
import { PasswordRequirements } from 'features/auth/ui/PasswordRequirements'
import { PASSWORD_MIN_LENGTH } from 'features/auth/ui/checkPasswprdRequirements'

interface MappedProps {
  apiErrors: ApiUpdateError
}

interface Props extends ModalProps, MappedProps {
  isLoading?: boolean
  isPasswordChanged?: boolean
}

interface DispatchProps {
  updateProfileData: typeof updateProfileData
}

interface FormValues {
  oldPassword?: string
  password?: string
  confirmNewPassword?: string
}

const ChangePasswordModal: React.FC<
  Props & FormikProps<FormValues> & WrappedComponentProps
> = ({
  apiErrors,
  isOpen,
  isLoading,
  isPasswordChanged,
  onClose,
  intl,
  values,
  handleSubmit,
  handleBlur,
  handleChange,
  touched,
  errors,
}) => {
  const [passwordData, setPasswordData] = useState<PasswordFeedbackData | null>(
    null
  )
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [passwordCheckLoading, setPasswordCheckLoading] =
    useState<boolean>(false)
  const [samePassword, setSamePassword] = useState<boolean>(false)

  const userData = useSelector(selectProfileData)

  const passwordLongEnough = useMemo(() => {
    if (values.password) {
      if (values.password?.length >= PASSWORD_MIN_LENGTH) return true
      return false
    }
  }, [values.password])

  const checkPassword = useDebouncedCallback(async () => {
    const validator = Yup.string()
    if (
      !values.password ||
      !validator.isValidSync(values.password) ||
      errors.password
    )
      return
    try {
      const userInputs: string[] = [
        userData?.username,
        userData?.first_name,
        userData?.last_name,
        userData?.email,
        values?.oldPassword,
      ].filter((userInput): userInput is string => !!userInput)
      const { is_acceptable_password, suggestions, warning, score } =
        await api.checkPassword(values.password!, userInputs)
      setPasswordCheckLoading(false)

      warning && suggestions.unshift(warning)

      score !== undefined &&
        setPasswordData({
          is_acceptable_password: is_acceptable_password ?? null,
          suggestions: suggestions ?? null,
          score: score,
          barWidth: passwordScoreBarWidth(score),
          feedbackColor: passwordScoreColor(score),
          text: (
            <FormattedMessage
              id={passwordScoreText(score)}
              values={{
                b: (text) => <b className="intl-message">{text}</b>,
              }}
            />
          ),
          error: null,
        })
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<any>
        setPasswordCheckLoading(false)
        setPasswordData({
          is_acceptable_password: null,
          suggestions: null,
          score: null,
          barWidth: null,
          feedbackColor: null,
          text: null,
          error: error.response?.data?.detail || '',
        })
      }
      return
    }
  }, 200)

  useEffect(() => {
    if (values?.password && values?.password?.length >= PASSWORD_MIN_LENGTH) {
      setSamePassword(values.password === values.oldPassword)
    }
  }, [values?.password, values?.oldPassword, setSamePassword])

  useEffect(() => {
    if (passwordFocused) {
      checkPassword()
    }
  }, [checkPassword, passwordFocused, values.password])

  useEffect(() => {
    if (values?.password && values?.password.length >= PASSWORD_MIN_LENGTH) {
      setPasswordCheckLoading(true)
    }
  }, [checkPassword, values?.password])

  const invalidMessage = samePassword
    ? intl.formatMessage({
        id: 'SETTINGS_SECURITY_NEW_PASSWORD_SAME_PASSWORD',
      })
    : touched.password && errors.password

  return (
    <GenericModal
      isOpen={isOpen}
      title={<FormattedMessage id="COMMON_LABEL_CHANGE_PASSWORD" />}
      size="small"
      overrideFullScreen
      onRequestClose={() => onClose?.()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          disabled: isLoading,
          onClick: onClose,
          variant: 'tertiary',
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_CHANGE" />,
          variant: 'primary',
          disabled:
            isLoading ||
            passwordCheckLoading ||
            !passwordData?.score ||
            passwordData.score < 3 ||
            samePassword ||
            !!errors?.confirmNewPassword,
          type: 'submit',
          onClick: handleSubmit as any,
          loader: isLoading
            ? 'spinner'
            : isPasswordChanged
              ? 'success'
              : undefined,
        },
      ]}
    >
      <>
        <Subtitle>
          <FormattedMessage id="COMMON_LABEL_CHANGE_PASSWORD_MODAL_SUBTITLE" />
        </Subtitle>
        <StyledForm>
          <StyledInput
            type="password"
            name="oldPassword"
            variant="secondary"
            label={intl.formatMessage({
              id: 'COMMON_LABEL_CURRENT_PASSWORD',
            })}
            value={values.oldPassword}
            disabled={isLoading}
            invalid={
              touched.oldPassword && (errors.oldPassword || apiErrors.detail)
            }
            onChange={handleChange}
            onBlur={handleBlur}
            passwordToggle
            focus
            full
          />
          <Wrapper>
            <StyledInput
              type="password"
              name="password"
              variant="secondary"
              label={intl.formatMessage({
                id: 'SETTINGS_SECURITY_NEW_PASSWORD_LABEL',
              })}
              value={values.password}
              disabled={isLoading}
              invalid={!passwordFocused && invalidMessage}
              helperMessage={
                passwordLongEnough &&
                !errors.password &&
                !passwordFocused && (
                  <PasswordFeedback
                    passwordLongEnough={passwordLongEnough ?? false}
                    passwordCheckLoading={passwordCheckLoading}
                    passwordData={passwordData}
                    apiErrors={apiErrors}
                  />
                )
              }
              onFocus={() => setPasswordFocused(true)}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e)
                setPasswordFocused(false)
              }}
              passwordToggle
              full
            />
            {passwordFocused && (
              <PasswordRequirements
                passwordFocused={passwordFocused}
                password={values.password}
              />
            )}
          </Wrapper>
          <StyledInput
            type="password"
            name="confirmNewPassword"
            variant="secondary"
            label={intl.formatMessage({
              id: 'SETTINGS_SECURITY_CONFIRM_NEW_PASSWORD_LABEL',
            })}
            value={values.confirmNewPassword}
            disabled={isLoading}
            invalid={touched.confirmNewPassword && errors.confirmNewPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            contentAfter={
              values?.confirmNewPassword &&
              values.confirmNewPassword?.length >= PASSWORD_MIN_LENGTH &&
              !errors.confirmNewPassword && (
                <StyledCheckIcon variant="twoTone" isActive />
              )
            }
            passwordToggle
            full
            last
          />
        </StyledForm>
      </>
    </GenericModal>
  )
}

const enchance = withFormik<Props & DispatchProps, FormValues>({
  handleSubmit: (values, { props: { updateProfileData } }) =>
    updateProfileData &&
    updateProfileData({
      password: values.password,
      old_password: values.oldPassword,
    }),
  validationSchema: Yup.object().shape({
    oldPassword: Yup.string().required().label('COMMON_LABEL_CURRENT_PASSWORD'),
    password: Yup.string()
      .required()
      .min(PASSWORD_MIN_LENGTH)
      .password()
      .label('SETTINGS_SECURITY_NEW_PASSWORD_LABEL'),
    confirmNewPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .label('SETTINGS_SECURITY_CONFIRM_NEW_PASSWORD_LABEL'),
  }),
})

const ChangePasswordModalEnchanced = enchance(injectIntl(ChangePasswordModal))

export const CHANGE_PASSWORD_MODAL_ID = 'CHANGE_PASSWORD_MODAL_ID'

const mapStateToProps = (state: RootState) => ({
  apiErrors: selectProfileErrors(state),
  isLoading: selectProfileIsLoading(state),
  isPasswordChanged: selectIsPasswordChanged(state),
})

const mapDispatchToProps = {
  updateProfileData,
}

export const WrappedChangePasswordModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ apiErrors, isLoading, isPasswordChanged, updateProfileData }) => (
  <ModalWrapper modalId={CHANGE_PASSWORD_MODAL_ID}>
    <ChangePasswordModalEnchanced
      apiErrors={apiErrors}
      isLoading={isLoading}
      isPasswordChanged={isPasswordChanged}
      updateProfileData={updateProfileData}
    />
  </ModalWrapper>
))

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  > div {
    margin: 0;
  }
`

const Subtitle = styled.span`
  font: ${typography['desktop-typography-body']};
  text-align: left;
  color: ${({ theme }) => theme.text2};
`

const StyledInput = styled(Input)<{ last?: boolean }>`
  height: ${({ last }) =>
    last ? size['desktop-sizing-base-13'] : size['desktop-sizing-base-15']};
`

const StyledCheckIcon = styled(CheckmarkIcon)`
  position: absolute;
  transform: translate(-30%, -5%);
`

const StyledForm = styled.form`
  > div {
    margin: 0;
  }
  margin-bottom: 112px;

  @media (max-width: ${theme.media.mobileLarge}) {
    margin-bottom: 0;
    margin-top: 40px;
  }
`
