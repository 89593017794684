import { useQuery } from '@tanstack/react-query'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { useSelector } from 'react-redux'
import { QueryKey } from 'store/types'

export const usePasskeyAvailableQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const passkey = useQuery({
    queryKey: [QueryKey.PASSKEY_AVAILABLE],
    queryFn: PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable,
    staleTime: Infinity,
    enabled: isDoneAuthenticating,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return { isPasskeyAvailable: passkey.data }
}
