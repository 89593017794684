import { useEffect, useState } from 'react'

const { GOOGLE_RECAPTCHA_SCRIPT_URL } = __CONFIG__

export const useCheckboxReCaptcha = () => {
  const [recaptcha, setRecaptcha] = useState<ReCaptchaV2.ReCaptcha>()

  useEffect(() => {
    if (recaptcha) return
    if (window.grecaptcha) return setRecaptcha(window.grecaptcha.enterprise)

    const handleLoad = () => {
      window.grecaptcha.enterprise.ready(() =>
        setRecaptcha(window.grecaptcha.enterprise)
      )
    }

    const script = document.createElement('script')
    script.src = GOOGLE_RECAPTCHA_SCRIPT_URL
    script.addEventListener('load', handleLoad)
    document.body.appendChild(script)

    return () => script.removeEventListener('load', handleLoad)
  }, [recaptcha])

  return recaptcha
}
