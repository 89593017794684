import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { Global, css } from '@emotion/react'
import '@ubnt/webfonts/ui-sans-400.css'
import '@ubnt/webfonts/ui-sans-700.css'
import '@ubnt/webfonts/ui-sans-900.css'

import theme, { motifPalette } from 'theme'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import { RootState } from 'types/types'
import { MotifContext } from 'motif/MotifProvider'

interface Props {
  isAuthenticated: boolean
}

const GlobalStyles: React.FC<Props> = ({ isAuthenticated }) => {
  const { motif } = useContext(MotifContext)

  const globalStyles = css`
    body,
    html {
      box-sizing: border-box;
      background: ${motifPalette[motif].neutral00};

      font-synthesis: none;
      font-feature-settings: 'kern';
      -moz-font-feature-settings: 'kern';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      margin: 0;
      padding: 0;

      .paymentsPopoverCard {
        width: 111px;
        height: 64px;
        padding: 4px 0;
      }

      .override-index {
        z-index: 5;
      }

      .change-region__dropdown {
        box-shadow:
          0px 4px 12px ${motifPalette[motif].neutral02},
          0px 0px 1px ${motifPalette[motif].neutral02};
      }

      .intl-message {
        padding-left: 2px;
      }

      .sortPopover-wrapper {
        width: 207px;
        padding: 0;
        div:first-of-type {
          width: 207px;
          padding: 0;
        }
      }

      .customTooltipWidth {
        width: 240px !important;
      }

      @media (max-width: ${theme.media.small}) {
        &.mobile-open {
          overflow: hidden;
        }
      }
      @media (max-width: ${theme.media.mobileMedium}) {
        .cardWrapper {
          div {
            margin-left: 10px;
            width: calc(100vw - 50px);
          }
        }
      }
    }

    body,
    html,
    button {
      font-family: 'UI Sans', sans-serif;
    }

    html,
    body,
    #root {
      position: relative;
      min-height: 100vh;
      ${isAuthenticated ? 'height: 100%' : ''}
    }
    @media print {
      #root,
      html,
      body {
        height: 98%;
      }
    }
    img {
      image-rendering: -moz-crisp-edges; // Firefox
      image-rendering: -o-crisp-edges; //Opera
      image-rendering: -webkit-optimize-contrast; // Webkit
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; // IE
    }
    .hidden {
      display: none !important;
    }
  `
  return <Global styles={globalStyles} />
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: selectIsAuthenticated(state),
})

export default connect(mapStateToProps)(React.memo(GlobalStyles))
