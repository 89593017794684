import React from 'react'
import { FormattedMessage } from 'react-intl'

import { media, space, typography } from 'theme'
import styled from 'theme/styled'

const TABLE_HEADINGS = [
  { title: 'COMMON_LABEL_DESCRIPTION', className: 'name' },
  { title: 'SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_QUANTITY', className: 'qty' },
  { title: 'COMMON_LABEL_PRICE', className: 'price' },
  { title: 'COMMON_LABEL_SUBTOTAL', className: 'subtotal' },
]

export const InvoiceItemsTableHeader = () => {
  return (
    <TableHeader>
      {TABLE_HEADINGS.map(({ title, className }, index) => (
        <TableCell key={`${title}-${index}`} className={className || ''}>
          <FormattedMessage id={title} />
        </TableCell>
      ))}
    </TableHeader>
  )
}

const TableHeader = styled.div`
  display: grid;
  font: ${typography['desktop-typography-heading-large']};
  gap: ${space['desktop-spacing-base-06']};
  grid-template-columns: 1fr 168px 87px 100px;
  margin-bottom: 14px;

  @media (max-width: ${media.tablet}) {
    border-bottom: none;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 0;
  }
`

const TableCell = styled.div`
  padding: 2px;
  text-align: left;

  &.subtotal {
    text-align: right;
  }

  @media (max-width: ${media.tablet}) {
    &.name,
    &.price {
      display: none;
    }
  }
`
