import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@ubnt/ui-components'
import { ArrowRightPrimaryIcon } from '@ubnt/icons'
import { typography } from '@ubnt/ui-components/styles/designToken'

import styled from 'theme/styled'
import { ExternalLink } from 'utils/ExternalLink'
import heroImage from 'assets/images/hero.png'
import heroImageLow from 'assets/images/hero_low.png'
import heroImageMobile from 'assets/images/heroMobile.png'
import heroImageMobileLow from 'assets/images/heroMobile_low.png'

const Hero: React.FC = () => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [mobileImageLoaded, setMobileImageLoaded] = useState(false)

  useEffect(() => {
    const imageToLoad = new Image()
    const mobileImageToLoad = new Image()

    imageToLoad.src = heroImage
    mobileImageToLoad.src = heroImageMobile

    imageToLoad.onload = () => setImageLoaded(true)
    mobileImageToLoad.onload = () => setMobileImageLoaded(true)
  }, [])

  return (
    <HeroSection>
      <ContentContainer>
        <Content>
          <Title>
            <FormattedMessage id="COMMON_DESIGN_CENTER" />
          </Title>
          <ExternalLink link="https://design.ui.com">
            <StyledButton
              variant="inline"
              size="medium"
              iconPosition="right"
              Icon={ArrowRightPrimaryIcon}
            >
              <FormattedMessage id="COMMON_ACTION_START_YOUR_PROJECT" />
            </StyledButton>
          </ExternalLink>
        </Content>
      </ContentContainer>
      <Background $imageLoaded={imageLoaded && mobileImageLoaded} />
    </HeroSection>
  )
}

const HeroSection = styled.div`
  height: 200px;
  border: none;
  margin: 0;
  overflow: hidden;
  border-radius: 8px;
`

const Background = styled.div<{ $imageLoaded: boolean }>`
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: url(${({ $imageLoaded }) =>
    $imageLoaded ? heroImage : heroImageLow});
  background-size: cover;
  ${({ $imageLoaded }) =>
    !$imageLoaded &&
    `
      -webkit-filter: blur(6px);
      -moz-filter: blur(6px);
      -o-filter: blur(6px);
      -ms-filter: blur(6px);
      filter: blur(6px);
    `}
  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    background-image: url(${({ $imageLoaded }) =>
      $imageLoaded ? heroImageMobile : heroImageMobileLow});
  }
`

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    width: calc(100vw - 32px);
    padding: 27px 0;
    justify-content: flex-start;
    align-items: center;
  }
`

const ContentContainer = styled.div`
  z-index: 99;
  position: absolute;
  height: 200px;
`

const Title = styled.div`
  font: ${typography['desktop-heading-xlarge-3']};
`

const StyledButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    margin-left: 8px;
  }
`

export default Hero
