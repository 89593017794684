import { useState } from 'react'
import axios from 'axios'
import { useBillingInvoicesQuery } from 'store/queries/useBillingInvoicesQuery'

const {
  api: { accountBE },
} = __CONFIG__

export const useCreateInvoicePDF = (invoiceId?: string, region?: string) => {
  const [loading, setLoading] = useState(false)

  const url = `${accountBE.base}/${
    accountBE.paths.billingInvoice
  }/${invoiceId}/${region?.toUpperCase()}`

  const { getInvoice } = useBillingInvoicesQuery()

  const invoice = getInvoice(invoiceId ?? '')

  const downloadPDF = (pdf: string, fileName: string) => {
    const { userAgent = '' } = window.navigator
    const isAndroid = /(android)/i.test(userAgent)

    /*
    data:application/pdf download get blocked by ios safari for iphones and ipads
    so we had to check if its a safari for ios or ipad and change
    application/pdf to application/octet-stream to trigger the download
    */
    const linkSource = `data:application/${
      isAndroid ? 'pdf' : 'octet-stream'
    };base64,${isAndroid ? pdf : encodeURIComponent(pdf)}`
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = `${fileName}.pdf`

    // some broswers takes time to listen to the click on event since the a tag is not existing in file
    setTimeout(() => {
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }, 100)
  }

  const handleGeneratePdf = async () => {
    if (invoiceId && region) {
      setLoading(true)
      const data = await axios.get(url, { withCredentials: true })
      setLoading(false)
      if (data?.data) {
        downloadPDF(data.data, `${invoice?.number ?? 'invoice'}`)
      }
    }
  }

  return { handleGeneratePdf, createPDFLoading: loading }
}
