import React from 'react'
import { FormattedMessage } from 'react-intl'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'

const ExternalCancelSubscriptionUispCloudConsoleModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <TitleWrapper>
          <FormattedMessage
            id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
            tagName="div"
          />
        </TitleWrapper>
      }
      size="small"
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
        },
      ]}
    >
      <FormattedMessage
        id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_HEAD"
        tagName="div"
      />
      <ol>
        <li>
          <FormattedMessage
            id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_STEP_1"
            values={{
              uisplink: (
                <a href="https://uisp.ui.com" target="_blank" rel="noreferrer">
                  uisp.ui.com
                </a>
              ),
            }}
          />
        </li>
        <li>
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_STEP_2" />
        </li>
        <li>
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_STEP_3" />
        </li>
        <li>
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_STEP_4" />
        </li>
      </ol>
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_EXT_UISP_CLOUD_CONSOLE_MODAL_ID =
  'CANCEL_SUBSCRIPTION_EXT_UISP_CLOUD_CONSOLE_MODAL_ID'

const WrappedExternalCancelSubscriptionUispCloudConsoleModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_EXT_UISP_CLOUD_CONSOLE_MODAL_ID}>
    <ExternalCancelSubscriptionUispCloudConsoleModal />
  </ModalWrapper>
)

export default WrappedExternalCancelSubscriptionUispCloudConsoleModal

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
