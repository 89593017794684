import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'theme/styled'
import isMobile from 'is-mobile'
import { LockerIcon, UnlockIcon } from '@ubnt/icons'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { Loader } from '@ubnt/ui-components'

import {
  downgradeAssuranceLevel,
  selectAssuranceLevel,
  selectIsLoading,
} from 'features/auth/modules/assuranceLevel'
import { setVisibleModal } from 'modules/modals'

import { SUBMIT_PASSWORD_MODAL_ID } from './SubmitPasswordModal'

const LockHeader: React.FC = () => {
  const assuranceLevel = useSelector(selectAssuranceLevel)
  const isLoading = useSelector(selectIsLoading)
  const isMobileDevice = isMobile()

  const isLocked = useMemo(
    () => assuranceLevel.data.level !== 2,
    [assuranceLevel.data.level]
  )

  const [hover, setHover] = useState(false)
  const [clickedLock, setClickedLock] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isLoading) setClickedLock(false)
  }, [isLoading])

  const lockIcon = useMemo(() => {
    if (isMobileDevice) {
      return isLocked ? (
        <LockerIcon size="original" />
      ) : (
        <UnlockIcon size="original" />
      )
    }
    if (isLocked) {
      return hover ? (
        <UnlockIcon variant="fill" size="original" />
      ) : (
        <LockerIcon size="original" />
      )
    }
    if (!isLocked) {
      return hover ? (
        <LockerIcon variant="fill" size="original" />
      ) : (
        <UnlockIcon size="original" />
      )
    }
  }, [hover, isLocked, isMobileDevice])

  const handleClick = () => {
    setClickedLock(true)
    if (isLocked) return dispatch(setVisibleModal(SUBMIT_PASSWORD_MODAL_ID))
    return dispatch(downgradeAssuranceLevel())
  }

  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      $isLocked={isLocked}
      onClick={handleClick}
    >
      <LeftContent $isLocked={isLocked}>
        {isLoading && clickedLock ? (
          <StyledLoader
            isLocked={isLocked}
            unbordered={true}
            size="small"
            variant="secondary"
            type="dots"
          />
        ) : (
          <>
            {lockIcon}
            <StyledText>
              <FormattedMessage
                id={
                  isLocked
                    ? 'SETTINGS_LOCK_HEADER_LOCKED'
                    : 'SETTINGS_LOCK_HEADER_UNLOCKED'
                }
              />
            </StyledText>
          </>
        )}
      </LeftContent>
    </Wrapper>
  )
}

export default LockHeader

const Wrapper = styled.div<{ $isLocked: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${({ $isLocked, theme }) =>
    $isLocked ? theme.ublue06 : theme.neutral02};
  border-radius: 8px;
  cursor: pointer;
  padding: 12px 16px;
  transition: background-color 0.2s ease-in-out;

  @media (max-width: ${({ theme }) => theme.media.mobileMedium}) {
    padding: 12px 8px;
  }
`

const StyledLoader = styled(Loader, {
  shouldForwardProp: (props) => props !== 'isLocked',
})<{ isLocked: boolean }>`
  margin-left: 20px;
  color: ${({ isLocked, theme }) =>
    isLocked ? theme.neutral00 : theme.ublue06};
`

const LeftContent = styled.div<{ $isLocked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font: ${typography['desktop-typography-body']};
  color: ${({ $isLocked, theme }) =>
    $isLocked ? theme.neutral00 : theme.ublue06};
`
const StyledText = styled.p`
  margin: auto 0 auto 5px;
  padding-right: 20px;
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    padding-right: 0;
  }
  @media (max-width: ${({ theme }) => theme.media.mobileMedium}) {
    margin: auto 0 auto 2px;
  }
`
