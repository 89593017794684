import { api } from 'api'
import { IntlShape } from 'react-intl'

const tokenApiHandler = async (
  token: string,
  intl: IntlShape
): Promise<string> => {
  const response = await api.invokeSSOTokenSetup(token)
  await api.approveLoginToken(token)
  return (
    response?.data?.device_name ||
    intl.formatMessage({ id: 'ACTIVATE_UNNAMED_DEVICE' })
  )
}

export default tokenApiHandler
