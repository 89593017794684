import React from 'react'

import { space, typography } from 'theme'
import styled from 'theme/styled'

import { getSubscriptionIcon } from './utils'
interface Props {
  productName: string
  isMobile?: boolean
}

const ProductHeader: React.FC<Props> = ({ productName, isMobile }) => {
  return (
    <Container>
      {getSubscriptionIcon(productName, isMobile ? '20px' : '32px')}
      <NameContainer>
        <Name>{productName}</Name>
      </NameContainer>
    </Container>
  )
}

export default ProductHeader

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NameContainer = styled('div')`
  margin-left: ${space['desktop-spacing-base-04']};
  display: flex;
  flex-direction: column;
`

const Name = styled('div')`
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.text2};
`
