export type StripeRegionContextType = {
  region: StripeRegionCode
  setRegion?: (region: StripeRegionCode) => void
  country: string | null
  setCountry?: (country: string) => void
}

export type TaxIdType =
  | 'eu_vat'
  | 'gb_vat'
  | 'ch_vat'
  | 'li_uid'
  | 'no_vat'
  | 'is_vat'
  | 'ro_tin'
  | 'tr_tin'
  | 'rs_pib'
  | 'ge_vat'
  | 'ad_nrt'
  | 'ch_uid'
  | 'no_voec'

export type StripeCountryCode =
  | 'at'
  | 'au'
  | 'nz'
  | 'be'
  | 'bg'
  | 'ca'
  | 'hr'
  | 'cy'
  | 'cz'
  | 'dk'
  | 'ee'
  | 'fi'
  | 'fr'
  | 'de'
  | 'gr'
  | 'hu'
  | 'is'
  | 'ie'
  | 'it'
  | 'lv'
  | 'li'
  | 'lt'
  | 'lu'
  | 'mt'
  | 'nl'
  | 'no'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'sk'
  | 'si'
  | 'es'
  | 'se'
  | 'ch'
  | 'gb'
  | 'us'
  | 'pr'
  | 'as'
  | 'gu'
  | 'mh'
  | 'fm'
  | 'mp'
  | 'pw'
  | 'vi'
  | 'sg'
  | 'ae'
  | 'za'
  | 'id'
  | 'co'
  | 'ar'
  | 'ke'
  | 've'
  | 'ph'
  | 'iq'
  | 'ru'
  | 'cl'
  | 'pe'
  | 'sa'
  | 'do'
  | 'pk'
  | 'gt'
  | 'th'
  | 'cr'
  | 'in'
  | 'bo'
  | 'ec'
  | 'bg'
  | 'il'
  | 'jm'
  | 'ly'
  | 'hn'
  | 'vn'
  | 'eg'
  | 'my'
  | 'ng'
  | 'pa'
  | 'py'
  | 'ma'
  | 'hk'
  | 'mm'
  | 'kz'
  | 'uy'
  | 'gy'
  | 'ao'
  | 'sv'
  | 'gh'
  | 'ye'
  | 'ni'
  | 'ps'
  | 'tt'
  | 'kr'
  | 'na'
  | 'tz'
  | 'np'
  | 'bz'
  | 'bd'
  | 'by'
  | 'om'
  | 'af'
  | 'ht'
  | 'tg'
  | 'al'
  | 'uz'
  | 'lk'
  | 'ug'
  | 'pg'
  | 'md'
  | 'mv'
  | 'sn'
  | 'am'
  | 'dz'
  | 'mz'
  | 'az'
  | 'cw'
  | 'lb'
  | 'sr'
  | 'bb'
  | 'cv'
  | 'jo'
  | 'pf'
  | 'qa'
  | 'bs'
  | 'ga'
  | 'gq'
  | 'me'
  | 'cd'
  | 'kh'
  | 'mn'
  | 'mu'
  | 'mw'
  | 'sb'
  | 'sz'
  | 'bf'
  | 'bh'
  | 'bw'
  | 'fj'
  | 'gf'
  | 're'
  | 'zm'
  | 'ag'
  | 'bj'
  | 'gm'
  | 'mk'
  | 'ss'
  | 'zw'
  | 'gn'
  | 'kw'
  | 'mo'
  | 'nc'
  | 'tc'
  | 'aw'
  | 'bi'
  | 'cg'
  | 'ck'
  | 'cm'
  | 'dj'
  | 'et'
  | 'fm'
  | 'gd'
  | 'gp'
  | 'kg'
  | 'ki'
  | 'kn'
  | 'ky'
  | 'la'
  | 'mg'
  | 'mh'
  | 'ml'
  | 'mq'
  | 'ne'
  | 'pn'
  | 'rw'
  | 'sc'
  | 'so'
  | 'tj'
  | 'tn'
  | 'vg'
  | 'bm'
  | 'bq'
  | 'cf'
  | 'eh'
  | 'er'
  | 'lc'
  | 'mf'
  | 'mp'
  | 'mr'
  | 'nu'
  | 'pm'
  | 'sl'
  | 'sm'
  | 'sx'
  | 'td'
  | 'tm'
  | 'vc'
  | 'tr'
  | 'ci'
  | 'rs'
  | 'ba'
  | 'ge'
  | 'ad'
  | 'gl'
  | 'mc'
  | 'fo'
  | 'je'
  | 'ai'
  | 'bl'
  | 'bn'
  | 'bt'
  | 'bv'
  | 'cc'
  | 'dm'
  | 'fk'
  | 'gs'
  | 'gw'
  | 'hm'
  | 'io'
  | 'km'
  | 'lr'
  | 'ls'
  | 'ms'
  | 'nf'
  | 'nr'
  | 'sd'
  | 'sh'
  | 'sj'
  | 'st'
  | 'tf'
  | 'tk'
  | 'tl'
  | 'to'
  | 'tv'
  | 'ua'
  | 'va'
  | 'vu'
  | 'wf'
  | 'ws'
  | 'yt'
  | 'ax'
  | 'gg'
  | 'im'
  | 'gi'
  | 'cx'
  | 'jp'

export enum StripeRegionCode {
  US = 'us',
  CA = 'ca',
  GB = 'gb',
  EU = 'eu',
  AU = 'au',
  AE = 'ae',
  SG = 'sg',
  JP = 'jp',
  ROW = 'row',
}

type TaxId = {
  value: TaxIdType
  taxFormat: RegExp
  taxPlaceholder: string
}

export type StripeCountry = {
  code: StripeCountryCode
  name: string
  region: StripeRegionCode
  taxId?: TaxId[]
}

export type StripeRegion = {
  code: StripeRegionCode
  name: string
}

export type StripeCountryDropdownOption = Omit<
  StripeCountry,
  'taxId' | 'region'
> &
  TaxId
