import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setVisibleModal } from 'modules/modals'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'

export const useSubscriptionCancel = () => {
  const dispatch = useDispatch()

  const onCancelSubscription = useCallback(
    (cancelModalId: string, fullSubscription: FullSubscription) =>
      dispatch(
        setVisibleModal(cancelModalId, {
          fullSubscription,
        })
      ),
    [dispatch]
  )

  return { onCancelSubscription }
}
