import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import InfoIcon from '@ubnt/icons/InfoIcon'
import { Text } from '@ubnt/ui-components/aria'
import { FlexWrapper } from 'components/SharedComponents'
import { TrustedDevicesList } from './TrustedDevicesList'

export const TrustedDevices = () => {
  return (
    <FlexWrapper column gap={16} marginTop={16}>
      <Title variant="body-primary">
        <FormattedMessage id="SETTINGS_TRUSTED_DEVICES" />
        <Tooltip
          tooltipClassName="customTooltipWidth"
          description={
            <FormattedMessage id="SETTINGS_TRUSTED_DEVICES_TOOLTIP" />
          }
        >
          <InfoIcon size="navigation" isActive />
        </Tooltip>
      </Title>
      <Wrapper>
        <TrustedDevicesList />
      </Wrapper>
    </FlexWrapper>
  )
}

const Title = styled(Text)`
  display: flex;
  gap: 4px;
`

const Wrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.neutral03}`};
`
