import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { setVisibleModal } from 'modules/modals'

import { DELETE_BACKUP_MODAL_ID } from '../DeleteBackupModal'
import { BackupItem } from 'api/nca/types'

export const useBackupsGroupItem = (tableItems: BackupItem[]) => {
  const [isManageActive, setIsManageActive] = useState(false)
  const [checkedBackups, setCheckedBackups] = useState<string[]>([])
  const dispatch = useDispatch()

  const openDeleteBackupModal = useCallback(() => {
    const backupsToDelete = tableItems.filter((item: BackupItem) =>
      checkedBackups.includes(item.id)
    )
    if (backupsToDelete.length) {
      dispatch(
        setVisibleModal(DELETE_BACKUP_MODAL_ID, {
          backups: backupsToDelete,
        })
      )
    }
  }, [checkedBackups, dispatch, tableItems])

  const handleCheckBackup = useCallback(
    (id: string) => {
      if (checkedBackups.includes(id)) {
        setCheckedBackups(
          checkedBackups.filter((backupId: string) => backupId !== id)
        )
      } else {
        setCheckedBackups([...checkedBackups, id])
      }
    },
    [checkedBackups]
  )

  const isAllChecked = useMemo(() => {
    const allIds = [...tableItems.map((item) => item.id)]
    if (allIds.length === checkedBackups.length) return true
    else return false
  }, [checkedBackups, tableItems])

  const handleCheckAllBackups = useCallback(() => {
    if (isAllChecked) {
      setCheckedBackups([])
    } else {
      setCheckedBackups(tableItems.map((item) => item.id))
    }
  }, [isAllChecked, tableItems])

  const clearAllChecked = useCallback(() => {
    setCheckedBackups([])
  }, [])

  return {
    isManageActive,
    setIsManageActive,
    checkedBackups,
    openDeleteBackupModal,
    handleCheckBackup,
    isAllChecked,
    handleCheckAllBackups,
    clearAllChecked,
    tableItems,
  }
}
