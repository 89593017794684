import React from 'react'

import { BillingServiceBubble } from '../BillingService'
import { UserTypeBubble } from '../UserType'
import { DescriptionBubble } from '../Description'
import { BubbleProps } from '../types'
import { ConsoleBubble } from '../Console'

export const renderBillingSection = (
  sectionID: string,
  props: BubbleProps
): React.ReactElement => {
  const section = {
    billingSpecificService: <BillingServiceBubble key={sectionID} {...props} />,
    console: <ConsoleBubble key={sectionID} {...props} />,
    userType: <UserTypeBubble key={sectionID} {...props} />,
    description: <DescriptionBubble key={sectionID} {...props} />,
  }[sectionID]

  return section ?? <></>
}
