import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { last, sum, unionBy } from 'lodash-es'
import moment, { Moment } from 'moment'
import { BillingInvoicesApi, PageParams } from 'api/billing/billingInvoices'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { useSelector } from 'react-redux'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'
import {
  InvoiceData,
  Invoices,
  StripeInvoice,
} from 'features/subscriptions/module/types'
import { StripeRegionCode } from 'features/stripe/ui/types'

const billingInvoicesApi = new BillingInvoicesApi()

interface InvoiceItem {
  monthLabel: string
  id: string
  month: string
  rawTime: Moment
  total: number
  children: StripeInvoice[]
  comperator?: number
}

interface PaginationInfo {
  region: StripeRegionCode
  hasMore: boolean
  startingAfter: string
}

const INVOICES_DEFAULTS = {
  data: [],
  meta: { hasMore: false, startingAfter: '' },
}

const getNextPage = (prevData: InvoiceData) => {
  const nextPages: PageParams = []
  Object.entries(prevData).forEach(([region, { data, has_more }]) => {
    if (has_more) {
      nextPages.push({
        region: region as StripeRegionCode,
        startingAfter: data[data.length - 1].id,
      })
    }
  })

  return nextPages
}

export const useBillingInvoicesQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const billingInvoices = useInfiniteQuery({
    queryKey: [QueryKey.BILLING_INVOICES],
    queryFn: billingInvoicesApi.getPaginated,
    initialPageParam: [{ startingAfter: null, region: null }],
    getNextPageParam: getNextPage,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const mergedInvoices = useMemo(() => {
    if (!billingInvoices.data) return null
    const { pages } = billingInvoices.data

    const mergedData: Invoices = {
      us: INVOICES_DEFAULTS,
      ca: INVOICES_DEFAULTS,
      gb: INVOICES_DEFAULTS,
      eu: INVOICES_DEFAULTS,
      au: INVOICES_DEFAULTS,
      ae: INVOICES_DEFAULTS,
      sg: INVOICES_DEFAULTS,
      row: INVOICES_DEFAULTS,
      jp: INVOICES_DEFAULTS,
    }
    const regions = Object.keys(mergedData) as StripeRegionCode[]

    pages.forEach((page) => {
      regions.forEach((region) => {
        if (page[region]) {
          const regionalData = page[region].data
          const mergedRegionalData = mergedData[region].data
          const updatedData = unionBy(mergedRegionalData, regionalData, 'id')
          const hasMore = page[region].has_more

          mergedData[region as StripeRegionCode] = {
            data: updatedData,
            meta: {
              hasMore,
              startingAfter: hasMore
                ? regionalData[regionalData.length - 1].id
                : '',
            },
          }
        }
      })
    })

    return mergedData
  }, [billingInvoices.data])

  const invoicesPaginationInfo: PaginationInfo[] = useMemo(() => {
    if (!mergedInvoices) return []
    return Object.entries(mergedInvoices).map(([key, value]) => ({
      region: key as StripeRegionCode,
      ...value.meta,
    }))
  }, [mergedInvoices])

  const shouldInvoicesPaginate = useMemo(() => {
    return invoicesPaginationInfo.some((region) => region.hasMore)
  }, [invoicesPaginationInfo])

  const invoices = useMemo((): StripeInvoice[] => {
    if (!mergedInvoices) return []
    const invoiceRegions = Object.keys(mergedInvoices) as StripeRegionCode[]

    const userInvoices = invoiceRegions
      .map((region) => {
        return (
          mergedInvoices[region].data?.map((invoice) => {
            return { ...invoice, region }
          }) ?? []
        )
      })
      .flat()

    return userInvoices.sort((a, b) => {
      const billingDateA = a.status_transitions.finalized_at ?? 0
      const billingDateB = b.status_transitions.finalized_at ?? 0
      return billingDateB - billingDateA
    })
  }, [mergedInvoices])

  const getInvoice = (invoiceId: string) =>
    invoices.find(({ id }) => id === invoiceId)

  const invoiceTableItems = useMemo(() => {
    const filteredInvoices = invoices.filter(
      (invoice) => last(invoice.lines.data)?.plan
    )
    const itemsMap = filteredInvoices.reduce<{ [key: string]: InvoiceItem }>(
      (agg, invoice) => {
        const finalizedTs = invoice.status_transitions.finalized_at
          ? moment(invoice.status_transitions.finalized_at * 1000)
          : moment()
        const key = finalizedTs.format('M.Y')
        if (!agg[key]) {
          agg[key] = {
            id: key,
            monthLabel: `${finalizedTs.format('MMMM')} ${finalizedTs.format(
              'YYYY'
            )}`,
            month: finalizedTs.format('MMMM'),
            rawTime: finalizedTs,
            total: invoice.status === 'void' ? 0 : invoice.total,
            children: [invoice],
          }
          return agg
        }

        const item = agg[key]
        if (invoice.status !== 'void') {
          item.total += invoice.total
        }
        item.children.push(invoice)

        return agg
      },
      {}
    )

    return Object.keys(itemsMap).map((key) => {
      itemsMap[key].comperator = sum(key.split('.').map((n) => parseInt(n, 10)))
      return itemsMap[key]
    })
  }, [invoices])

  return {
    invoices,
    areInvoicesLoading: billingInvoices.isFetching,
    getInvoice,
    invoiceTableItems,
    invoicesPaginationInfo,
    shouldInvoicesPaginate,
    fetchNextInvoicesPage: billingInvoices.fetchNextPage,
  }
}
