import React, { useContext } from 'react'
import { UbiquitiLogo } from '@ubnt/icons'

import { INavigationItem } from 'types/routes'
import { SettingsLink } from 'types/types'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import { DesktopAppHeader, DesktopNavWrap, StyledLink } from './styles'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

interface Props {
  otherItems?: INavigationItem[]
  mobileItems?: SettingsLink[]
  navItems?: SettingsLink[]
  setMobileNavOpen?: (value: boolean) => void
  mobileNavOpen?: boolean
  isAuthenticated?: boolean
}

export const GlobalMenu: React.FC<Props> = ({
  otherItems = [],
  navItems = [],
  setMobileNavOpen,
  mobileNavOpen,
  isAuthenticated,
}) => {
  const { isMaxMediumV2 } = useContext(MediaSizingContext)

  return (
    <DesktopAppHeader
      logoWrapper={<StyledLink to="/" />}
      title="Account"
      size="standard"
      titleClassName="header-title"
      icon={UbiquitiLogo({ variant: 'neutral' })}
    >
      {isMaxMediumV2 ? (
        <Mobile
          items={navItems}
          isAuthenticated={isAuthenticated}
          setMobileNavOpen={setMobileNavOpen}
          mobileNavOpen={mobileNavOpen}
        />
      ) : (
        <DesktopNavWrap>
          <Desktop otherItems={otherItems} isAuthenticated={isAuthenticated} />
        </DesktopNavWrap>
      )}
    </DesktopAppHeader>
  )
}
