import React from 'react'
import { Tooltip } from '@ubnt/ui-components'
import isMobile from 'is-mobile'

interface Props {
  text?: string
  truncateAt: number
}

export const TruncatedText: React.FC<Props> = ({ text, truncateAt }) => {
  const isMobileDevice = isMobile()

  if (!text || text.length <= truncateAt) {
    return <span>{text}</span>
  }

  return (
    <span
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation()
      }}
    >
      <Tooltip
        width={200}
        description={text}
        position={isMobileDevice ? 'bottom' : 'top'}
      >
        {text.substring(0, truncateAt)}...
      </Tooltip>
    </span>
  )
}
