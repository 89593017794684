export enum MFAAuthenticatorStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  BLOCKED = 'blocked',
  BACKUP = 'backup',
  DEACTIVATED = 'deactivated',
}

export enum Country {
  CHINA = 'China',
}
