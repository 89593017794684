import { fetchRequestWithCookies } from './axios'

const {
  api: { status },
} = __CONFIG__

export type Components = {
  id: string
  name: string
  status: 'operational'
  created_at: string
  updated_at: string
  position: number
  description: null | string
  showcase: boolean
  start_date: null | string
  group_id: string
  page_id: string
  group: boolean
  only_show_if_degraded: boolean
}

type Maintenance = {
  id: string
  name: string
  status: 'scheduled' | 'completed' | 'in_progress'
  created_at: string
  updated_at: string
  monitoring_at: string | null
  resolved_at: string | null
  impact: 'maintenance'
  shortlink: string
  started_at: string
  page_id: string
  incident_updates: {
    id: string
    status: 'scheduled' | 'completed'
    body: string
    incident_id: string
    created_at: string
    updated_at: string
    display_at: string
    affected_components: {
      code: string
      name: string
      old_status: 'operational' | 'under_maintenance'
      new_status: 'operational' | 'under_maintenance'
    }[]
  }[]
  components: Components[]
  deliver_notifications: boolean
  custom_tweet: null
  tweet_id: null
  scheduled_for: string
  scheduled_until: string
}

type MaintenanceStatus = {
  page: {
    id: string
    name: string
    url: string
    time_zone: string
    updated_at: string
  }
  scheduled_maintenances: Maintenance[]
} | null

type Incident = {
  monitoring_at: string
  id: string
  name: string
  updated_at: string
}

type IncidentsStatus = {
  incidents?: Incident[]
} | null

export class StatusApi {
  private getUpcomingMaintenance = async () => {
    const url = new URL(status.paths.upcoming, status.base)
    const { data } = await fetchRequestWithCookies<MaintenanceStatus>(url.href)
    return data
  }

  private getActiveMaintenance = async () => {
    const url = new URL(status.paths.active, status.base)
    const { data } = await fetchRequestWithCookies<MaintenanceStatus>(url.href)
    return data
  }

  private getIncidents = async () => {
    const url = new URL(status.paths.incidents, status.base)
    const { data } = await fetchRequestWithCookies<IncidentsStatus>(url.href)
    return data
  }

  public getAll = async () => {
    const upcomingMaintenance = await this.getUpcomingMaintenance()
    const activeMaintenance = await this.getActiveMaintenance()
    const uiIncidents = await this.getIncidents()

    return {
      active: activeMaintenance,
      upcoming: upcomingMaintenance,
      uiIncidents,
    }
  }
}
