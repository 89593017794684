import React, { useEffect, useMemo, useState } from 'react'
import enTranslations from './translations/en/common.json'
import caTranslations from './translations/ca/common.json'
import daTranslations from './translations/da/common.json'
import deTranslations from './translations/de/common.json'
import esTranslations from './translations/es/common.json'
import nbTranslations from './translations/nb/common.json'
import nlTranslations from './translations/nl/common.json'
import svTranslations from './translations/sv/common.json'
import trTranslations from './translations/tr/common.json'

export enum SupportedLanguages {
  English = 'en',
  Catalan = 'ca',
  Danish = 'da',
  German = 'de',
  Spanish = 'es',
  Norwegian = 'nb',
  Dutch = 'nl',
  Swedish = 'sv',
  Turkish = 'tr',
}

const localStorageLocaleKey = 'lang'

export const DEFAULT_LANGUAGE = SupportedLanguages.English

type TranslationsObject = Record<string, string>

const mergeWithEnglish = (translation: TranslationsObject) => {
  return {
    ...enTranslations,
    ...translation,
  }
}

type Translation = {
  common: TranslationsObject
}

const translations: Record<SupportedLanguages, Translation> = {
  en: {
    common: enTranslations,
  },
  ca: {
    common: mergeWithEnglish(caTranslations),
  },
  da: {
    common: mergeWithEnglish(daTranslations),
  },
  de: {
    common: mergeWithEnglish(deTranslations),
  },
  es: {
    common: mergeWithEnglish(esTranslations),
  },
  nb: {
    common: mergeWithEnglish(nbTranslations),
  },
  nl: {
    common: mergeWithEnglish(nlTranslations),
  },
  sv: {
    common: mergeWithEnglish(svTranslations),
  },
  tr: {
    common: mergeWithEnglish(trTranslations),
  },
}

type LocaleContextType = {
  language: SupportedLanguages
  messages: TranslationsObject
  selectLanguage?: (language: SupportedLanguages) => void
}

export const getLanguageOptions = () => {
  return Object.keys(SupportedLanguages).map((key) => ({
    value: SupportedLanguages[key as keyof typeof SupportedLanguages],
    label: key,
  }))
}

export const LocaleContext = React.createContext<LocaleContextType>({
  language: DEFAULT_LANGUAGE,
  messages: translations.en.common,
})

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<SupportedLanguages>(
    (localStorage?.getItem(localStorageLocaleKey) as SupportedLanguages) ??
      DEFAULT_LANGUAGE
  )

  useEffect(() => {
    const storedLanguage = localStorage?.getItem(
      localStorageLocaleKey
    ) as SupportedLanguages

    if (!storedLanguage || language !== storedLanguage) {
      localStorage?.setItem(localStorageLocaleKey, language)
    }
  }, [language])

  const messages = useMemo(() => translations[language].common, [language])

  const selectLanguage = (language: SupportedLanguages) => setLanguage(language)

  return (
    <LocaleContext.Provider value={{ language, messages, selectLanguage }}>
      {children}
    </LocaleContext.Provider>
  )
}
