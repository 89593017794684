import React, { useEffect, useRef } from 'react'

import { Bubble } from 'components/support/Bubble'
import { TilesWrapper } from 'components/support/Wrappers'
import { QuestionTile } from 'components/support/QuestionTile'

import { config } from './config/formConfig'
import { BubbleProps, FormValue } from './config/types'
import { category } from './config/ConfigOptions'
import { SupportFormState } from './types'

export const CategoryBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.productLine])

  const getNextSection = (value: FormValue): string | undefined => {
    const nextSectionData = config.category.nextSection

    if (nextSectionData && nextSectionData.length > 1) {
      const section = nextSectionData.find((nextSection) => {
        if (nextSection.condition?.field === 'category') {
          return nextSection.condition?.value.includes(value)
        }
        return nextSection.condition?.value.includes(
          values[nextSection.condition?.field as keyof SupportFormState]
        )
      })?.section
      return section
    }
    return nextSectionData?.[0].section
  }

  const handleClick = (value?: string) => {
    const section = getNextSection(value)
    handleChange('category', value, section)
  }

  return (
    <Bubble question={config.category.fieldTitle} ref={ref}>
      <TilesWrapper>
        {category.map(({ label, id }) => (
          <QuestionTile
            key={id}
            label={label}
            onClick={() => handleClick(id)}
            isActive={values.category === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
