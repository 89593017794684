import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { setVisibleModal } from 'modules/modals'

import { GenericMFAModal } from '../GenericMFAModal'
import { createBackupCode } from './modules'
import { BACKUP_CODES_MODAL_ID } from './BackupCodesModal'

export const BACKUP_CODES_PROMPT_MODAL_ID = 'BACKUP_CODES_PROMPT_MODAL_ID'

export const BackupCodesPromptModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return (
    <GenericMFAModal
      modalId={BACKUP_CODES_PROMPT_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_SETUP_BACKUP_CODES_MODAL_TITLE',
      })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_BACKUP_CODE_PROMPT_DESCRIPTION',
      })}
      primaryButton={{
        text: intl.formatMessage({
          id: 'COMMON_ACTION_REGENERATE',
        }),
        variant: 'danger',
        onClick: () => {
          dispatch(createBackupCode())
          dispatch(setVisibleModal(BACKUP_CODES_MODAL_ID))
        },
      }}
    />
  )
}
