import React, { useEffect, useRef } from 'react'

import { Bubble } from 'components/support/Bubble'
import { QuestionTile } from 'components/support/QuestionTile'
import { TilesWrapper } from 'components/support/Wrappers'

import { BubbleProps } from './types'
import { talkVip } from './config/ConfigOptions'
import { UniFiStateValues } from './config/types'
import { setNextSection } from './handleClicks'

export const TalkBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  return (
    <Bubble question={config.talkVip.fieldTitle} ref={ref}>
      <TilesWrapper>
        {talkVip.map(({ id, label }) => (
          <QuestionTile
            key={id}
            label={label}
            onClick={() =>
              setNextSection(
                config,
                id,
                UniFiStateValues.TALK_VIP,
                values,
                handleChange
              )
            }
            isActive={values.talkVip === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
