import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'

import { useCheckboxReCaptcha } from './useCheckboxReCaptcha'

interface Props {
  paddingTop?: number
  paddingBottom?: number
  onVerify: (token: string) => void
}
const { GOOGLE_RECAPTCHA_SECRET_CHALLENGE } = __CONFIG__

export const CheckboxReCaptcha = ({
  onVerify,
  paddingBottom,
  paddingTop,
}: Props) => {
  const recaptcha = useCheckboxReCaptcha()
  const recaptchaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!recaptcha) return
    if (recaptchaRef?.current?.children?.length === 0) {
      recaptcha.render('recaptcha', {
        callback: onVerify,
        sitekey: GOOGLE_RECAPTCHA_SECRET_CHALLENGE,
        size: 'compact',
      })
    }
  }, [onVerify, recaptcha])

  return (
    <CheckboxCaptcha
      paddingTop={paddingTop ?? 20}
      paddingBottom={paddingBottom ?? 0}
    >
      <div id="recaptcha" ref={recaptchaRef} />
    </CheckboxCaptcha>
  )
}

const CheckboxCaptcha = styled.div<{
  paddingTop: number
  paddingBottom: number
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding-top: ${({ paddingTop }) => paddingTop}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
`
