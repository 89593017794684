import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { FooterProps } from '@ubnt/ui-components/Modal/Footer'

import { GenericModal } from 'components/generic-modal/GenericModal'
import { selectMFAAuthenticators } from 'modules/mfa'
import { MFAType } from 'modules/types'

import { isActive } from '../Utils'
import { removeMFAPush } from '../Push/modules/removePush'
import { removeMFAEmail } from '../Email/modules/removeEmail'
import { removeMFASMS } from '../SMS/modules/removeMFASMS'
import { removeMFAPasskey } from '../Passkey/modules/removePasskey'
import {
  removeMFATOTP,
  selectRemoveTOTPIsLoading,
} from '../TOTP/modules/removeTOTP'
import ModalContent from './ModalContent'

type Props = {
  title?: string
  isOpen?: boolean
  onClose?: () => void
  removeId?: string
  type: MFAType
}

const getRemovalMethod = (type: MFAType) => {
  switch (type) {
    case MFAType.email:
      return removeMFAEmail
    case MFAType.sms:
      return removeMFASMS
    case MFAType.totp:
      return removeMFATOTP
    case MFAType.webAuthn:
      return removeMFAPasskey
    default:
      return removeMFAPush
  }
}

export const GenericConfirmRemoveMFAModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  removeId = '',
  type,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const isLoading = useSelector(selectRemoveTOTPIsLoading)
  const authenticators = useSelector(selectMFAAuthenticators).filter(
    (auth) => auth.status === 'active'
  )

  const userHasTwoMFAMethodsAndIsActive = useMemo(
    () => authenticators.length === 2 && isActive(removeId, authenticators),
    [authenticators, removeId]
  )

  const userWillOnlyHaveWebAuthnActive = useMemo(() => {
    const authenticatorsAfterRemove = authenticators.filter(
      (auth) => auth.id !== removeId && auth.status === 'active'
    )

    return authenticatorsAfterRemove.every(
      (auth) => auth.type === MFAType.webAuthn
    )
  }, [authenticators, removeId])

  const [checked, setChecked] = useState(false)

  const isEmail = type === MFAType.email

  const handleOnSubmit = () => {
    if (!removeId) {
      console.error('Missing id to remove')
      return
    }
    const removeMFAMethod = getRemovalMethod(type)
    dispatch(removeMFAMethod(removeId))
  }

  const handleCheckbox = () => setChecked(!checked)

  const actions: FooterProps['actions'] = [
    {
      text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
      variant: 'tertiary',
      onClick: onClose,
      tabIndex: 1,
    },
    {
      text: intl.formatMessage({
        id: 'COMMON_ACTION_REMOVE',
      }),
      onClick: handleOnSubmit,
      variant: 'danger',
      disabled: (isEmail && !checked) || isLoading,
      loader: isLoading ? 'dots' : undefined,
    },
  ]

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      title={title}
      onRequestClose={onClose}
      onAfterClose={() => setChecked(false)}
      actions={actions}
    >
      <ModalContent
        userHasTwoMFAMethodsAndIsActive={userHasTwoMFAMethodsAndIsActive}
        userWillOnlyHaveWebAuthnActive={userWillOnlyHaveWebAuthnActive}
        isMFATypeEmail={isEmail}
        checked={checked}
        handleCheckbox={handleCheckbox}
      />
    </GenericModal>
  )
}
