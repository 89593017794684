import React, { useContext, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { InfoIconL, SortAscendingIcon, SortDescendingIcon } from '@ubnt/icons'
import { Popover, Tooltip } from '@ubnt/ui-components'

import { ALL_KEY } from 'sharedConstants'
import { useOutsideClick } from 'utils/useOutsideClick'

import {
  Divider,
  HeaderTitle,
  HeaderWrapper,
  PageHeader,
  SortContainer,
  StyledPopoverCardMenu,
  TitleWrapper,
} from './Backups.styles'
import { BackupsContext } from './BackupsContext'
import { BackupsFilter } from './BackupsFilter'
import { getSortOptions } from './getSortOptions'
import { DeviceBackupsArchived, SortType } from 'api/nca/types'

export const BackupsHeaderRemoved: React.FC<{
  removedDevices: DeviceBackupsArchived[]
}> = ({ removedDevices }) => {
  const {
    selectedDeviceRemoved,
    setSelectedDeviceRemoved,
    selectedSortRemoved,
    setSelectedSortRemoved,
  } = useContext(BackupsContext)
  const intl = useIntl()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const displaySort = useMemo(() => {
    return removedDevices?.length > 1 && selectedDeviceRemoved === ALL_KEY
  }, [removedDevices, selectedDeviceRemoved])

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setIsPopoverOpen(false))

  return (
    <>
      <PageHeader>
        <HeaderWrapper>
          <TitleWrapper>
            <HeaderTitle>
              <FormattedMessage id="SETTINGS_BACKUPS_TITLE_REMOVED_CONSOLES" />
              <Tooltip
                width={280}
                description={intl.formatMessage({
                  id: 'SETTINGS_BACKUPS_TITLE_REMOVED_INFO',
                })}
              >
                <InfoIconL size="medium" isActive />
              </Tooltip>
              {displaySort && (
                <Popover
                  onClick={() => {
                    setIsPopoverOpen(!isPopoverOpen)
                  }}
                  open={isPopoverOpen}
                  classNameCardWrap="sortPopover-wrapper"
                  renderToggle={() => (
                    <SortContainer>
                      {selectedSortRemoved.includes('desc-') ? (
                        <SortDescendingIcon isActive size="navigation" />
                      ) : (
                        <SortAscendingIcon isActive size="navigation" />
                      )}
                    </SortContainer>
                  )}
                  align="bottomRight"
                  renderCard={() => (
                    <StyledPopoverCardMenu
                      ref={wrapperRef}
                      items={getSortOptions(selectedSortRemoved)}
                      open={isPopoverOpen}
                      onClick={(item) => {
                        if (selectedSortRemoved !== item.value) {
                          setSelectedSortRemoved?.(
                            (
                              item as {
                                label: JSX.Element
                                value: SortType
                              }
                            ).value
                          )
                          setIsPopoverOpen(false)
                        }
                      }}
                    />
                  )}
                />
              )}
            </HeaderTitle>
          </TitleWrapper>
          {removedDevices?.length && (
            <BackupsFilter
              devices={removedDevices}
              selected={selectedDeviceRemoved}
              onChange={(option: string) => setSelectedDeviceRemoved?.(option)}
            />
          )}
        </HeaderWrapper>
      </PageHeader>
      <Divider />
    </>
  )
}
