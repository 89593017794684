import { category, productLine } from './ConfigOptions'
import { FormConfig, ProductLine } from './types'

export const config: FormConfig = {
  productLine: {
    fieldId: 6440782344727,
    fieldTitle: 'SUPPORT_UISP_PRODUCT_LINE',
    options: productLine,
    nextSection: [
      {
        section: 'description',
        condition: {
          field: 'productLine',
          value: [ProductLine.uispMobile, ProductLine.other],
        },
      },
      {
        section: 'category',
        condition: {
          field: 'productLine',
          value: [
            ProductLine.airMax,
            ProductLine.airFiber,
            ProductLine.ltu,
            ProductLine.edgemax,
            ProductLine.UFiber,
            ProductLine.uispCloud,
            ProductLine.uispSelfHosted,
          ],
        },
      },
    ],
  },
  category: {
    fieldId: 6440792555415,
    fieldTitle: 'SUPPORT_UISP_CATEGORY',
    options: category,
    nextSection: [
      {
        section: 'cloudHostname',
        condition: {
          field: 'productLine',
          value: [ProductLine.uispCloud],
        },
      },
      {
        section: 'description',
        condition: {
          field: 'productLine',
          value: [
            ProductLine.airMax,
            ProductLine.airFiber,
            ProductLine.ltu,
            ProductLine.edgemax,
            ProductLine.UFiber,
            ProductLine.uispCloud,
            ProductLine.uispSelfHosted,
          ],
        },
      },
    ],
  },
  cloudHostname: {
    fieldId: 6440821895063,
    fieldTitle: 'SUPPORT_UISP_CLOUD_HOSTNAME',
    options: null,
    nextSection: [{ section: 'description' }],
  },
  description: {
    fieldId: 20012826,
    fieldTitle: 'SUPPORT_UISP_DESCRIPTION',
    options: null,
    nextSection: null,
  },
  attachment: {
    fieldId: 10138128074775,
    fieldTitle: 'SUPPORT_ATTACHMENT_UPLOAD_RELEVANT',
    options: null,
    nextSection: null,
  },
  rawData: {
    fieldId: 1500011191181,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
}
