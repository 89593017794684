import React from 'react'
import { ArrowRightPrimaryIcon } from '@ubnt/icons'

import styled from 'theme/styled'
import { space } from 'theme'

const Wrapper = styled.div`
  text-align: left;
  display: flex;
  border-radius: 12px;
  padding: ${space['desktop-spacing-base-06']} 0;
  justify-content: space-between;
  cursor: pointer;
`

const InnerWrapper = styled.div`
  display: flex;
`

const IconWrapper = styled.div<{ iconBorder: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border: ${({ iconBorder, theme }) =>
    iconBorder ? `1px solid ${theme.neutral04}` : 'none'};
  border-radius: 8px;
  margin-right: 16px;
`

const Title = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.text1};
`

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const StyledArrow = styled(ArrowRightPrimaryIcon)`
  margin: auto 0;
`

const StyledImg = styled.img`
  max-width: 40px;
  max-height: 40px;
`

interface Props {
  iconLink?: string
  icon?: JSX.Element
  title: string
  description?: JSX.Element
  onClick(): void
  iconBorder?: boolean
  showBorder?: boolean
}

export const MFAOption: React.FC<Props> = ({
  iconLink,
  icon,
  title,
  description,
  onClick,
  iconBorder = true,
}) => {
  return (
    <>
      <Wrapper onClick={onClick}>
        <InnerWrapper>
          <IconWrapper iconBorder={iconBorder}>
            {iconLink && <StyledImg src={iconLink} alt={title} />}
            {icon && icon}
          </IconWrapper>
          <div>
            <BadgeWrapper>
              <Title>{title}</Title>{' '}
            </BadgeWrapper>
            {description}
          </div>
        </InnerWrapper>
        <StyledArrow height={16} />
      </Wrapper>
    </>
  )
}
