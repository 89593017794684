import Loader from '@ubnt/ui-components/Loader/Loader'

import styled from 'theme/styled'
import { typography } from 'theme'

export const StyledLoader = styled(Loader)`
  width: 14px;
  height: 14px;
  margin-left: 6px;
`

export const SecondaryText = styled.span`
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text3};

  .pending {
    color: ${({ theme }) => theme.orange06};
    font-weight: 700;
    margin-left: 8px;
  }
`

export const CommentWrapper = styled.div<{ $isUserComment: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ $isUserComment }) =>
    $isUserComment ? 'flex-end' : 'flex-start'};
  gap: 4px;
`

export const FirstRow = styled.div<{ $isUserComment: boolean }>`
  display: flex;
  justify-content: ${({ $isUserComment }) =>
    $isUserComment ? 'flex-end' : 'flex-start'};
  gap: 8px;
`

export const MessageWrapper = styled.div<{
  $isUserComment: boolean
  $isTicketOpen: boolean
  $isDarkTheme: boolean
}>`
  max-width: 300px;
  min-height: 36px;
  display: flex;
  flex-direction: column;
  align-items: ${({ $isUserComment }) =>
    $isUserComment ? 'flex-end' : 'flex-start'};
  padding: 8px;
  background-color: ${({
    $isUserComment,
    theme,
    $isTicketOpen,
    $isDarkTheme,
  }) => {
    if ($isDarkTheme && $isUserComment && $isTicketOpen) {
      return theme.ublue09
    }
    return $isUserComment && $isTicketOpen ? theme.ublue01 : theme.neutral03
  }};
  border-radius: ${({ $isUserComment }) =>
    $isUserComment ? '8px 0 8px 8px' : '0 8px 8px 8px'};
  word-break: break-word;

  img {
    max-width: 280px;
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    max-width: 200px;

    img {
      max-width: 180px;
    }
  }
`

export const Message = styled.span<{ $haslink?: boolean }>`
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.neutral10};
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }

  ${({ $haslink }) =>
    $haslink &&
    `.zd-comment {
      p:last-of-type {
        display: none;
      }
    }
  `}
`

export const MessageAuthor = styled.span`
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.neutral10};
`

export const LinkWrapper = styled.div`
  display: flex;
  gap: 4px;
  color: green;
`

export const Link = styled.a`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.ublue06};
`

export const StyledSecondaryText = styled(SecondaryText, {
  shouldForwardProp: (props) => props !== 'isUserComment',
})<{ isUserComment?: boolean }>`
  margin-left: ${({ isUserComment }) => (isUserComment ? '0' : '28px')};
  margin-right: ${({ isUserComment }) => (isUserComment ? '28px' : '0')};
`
