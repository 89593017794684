import {
  FormValue,
  SupportConsole,
  SupportFormState,
} from 'features/support/unifi/types'

import {
  app,
  extraApp,
  extraConsoles,
  extraTech,
  tech,
} from '../config/ConfigOptions'
import { Console, UniFiStateValues, ZDFormValueType } from '../config/types'

export const useGetItemsToRender = () => {
  const getItemsToRender = (
    type: UniFiStateValues.TECH | UniFiStateValues.APP,
    device?: SupportConsole
  ) => {
    const isOtherConsoleSelected = extraConsoles.some(
      (el) => el.id === device?.id
    )

    const displayOtherTile =
      device?.hasAccess ||
      device?.hasConnect ||
      device?.hasTalk ||
      device?.hasUID ||
      (isOtherConsoleSelected &&
        [
          Console.cloudGatewayUnlisted,
          Console.cloudKeyUnlisted,
          Console.unvrUnlisted,
          Console.unifiNas,
        ].includes((device?.id as Console) ?? ''))

    const items = type === UniFiStateValues.TECH ? tech : app

    if (isOtherConsoleSelected) {
      return items.filter((item) => {
        if (item.extraConditions) {
          return item.extraConditions.id.includes(device?.id ?? '')
        }
        return displayOtherTile && item.id === 'other'
      })
    }

    return items.filter((item) => {
      if (item.condition) {
        return device?.[item.condition.id as keyof SupportConsole]
      }
      return displayOtherTile && item.id === 'other'
    })
  }

  const parseRadioItems = (
    itemsToParse: ZDFormValueType[],
    section: UniFiStateValues.TECH | UniFiStateValues.APP,
    values: SupportFormState,
    handleChange: (value: FormValue) => void
  ) => {
    return itemsToParse.map(({ id, label }) => ({
      id,
      children: label,
      key: id,
      checked: values[section] === id,
      onChange: () => handleChange(id),
    }))
  }

  const getExtraItemsToRender = (
    type: UniFiStateValues.TECH | UniFiStateValues.APP,
    values: SupportFormState,
    handleChange: (value: FormValue) => void
  ) => {
    const { console } = values
    const isOtherConsoleSelected = extraConsoles.some(
      (el) => el.id === console?.id
    )

    const displayOtherTile =
      console?.hasAccess ||
      console?.hasConnect ||
      console?.hasTalk ||
      console?.hasUID ||
      (isOtherConsoleSelected &&
        [
          Console.cloudGatewayUnlisted,
          Console.cloudKeyUnlisted,
          Console.unvrUnlisted,
          Console.unifiNas,
        ].includes((console?.id as Console) ?? ''))

    if (!displayOtherTile) return []
    const extraItems = type === UniFiStateValues.TECH ? extraTech : extraApp
    const filteredExtraItems = isOtherConsoleSelected
      ? extraItems.filter((item) => {
          if (item.extraConditions) {
            return item.extraConditions.id.includes(console?.id ?? '')
          }
        })
      : extraItems.filter(
          (item) => console?.[item.condition?.id as keyof SupportConsole]
        )

    return parseRadioItems(filteredExtraItems, type, values, handleChange)
  }

  return {
    getItemsToRender,
    getExtraItemsToRender,
  }
}
