import type Stripe from 'stripe'
import { ProductName } from 'types/enums'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { StripeInvoice } from '../module/types'
import { useBillingSubscriptionsQuery } from 'store/queries/useBillingSubscriptionsQuery'
import { useBillingProductsQuery } from 'store/queries/useBillingProductsQuery'
import { getSubscriptionProductLine } from './utils'
import { useBillingUpcomingInvoicesQuery } from 'store/queries/useBillingUpcomingInvoices'
import { useBillingInvoicesQuery } from 'store/queries/useBillingInvoicesQuery'
import { UmrDeviceData } from 'api/umrDevice'
import { useUmrDeviceQuery } from 'store/queries/useUmrDeviceQuery'
import { getAmount } from 'features/invoices/ui/utils'

export interface FullSubscription {
  id: string
  name: ProductName
  upcomingAmount: number
  currency: string
  metadata: Stripe.Metadata
  status: Stripe.Subscription.Status
  plan: Stripe.Plan | null | undefined
  region: StripeRegionCode
  product: Stripe.Product | undefined
  current_period_end: number
  quantity: number | undefined
  latestInvoice: StripeInvoice | undefined
  items: Stripe.ApiList<Stripe.SubscriptionItem>
  upcomingInvoice: StripeInvoice | undefined
  deviceUmrInfo?: UmrDeviceData[]
  isSuspended: boolean
  isFailed: boolean
  default_source?: string | Stripe.CustomerSource | null
  default_payment_method?: string | Stripe.PaymentMethod | null
  display: boolean
  isInProgress: boolean
  isActive: boolean
  cardNotFound: boolean
  cancelAtPeriodEnd?: boolean
}

const SUBSCRIPTION_STATUSES_TO_SHOW = [
  'active',
  'past_due',
  'canceled',
  'trialing',
]

export enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIALING = 'trialing',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
}

export const useFullSubscriptions = () => {
  const { userSubscriptions } = useBillingSubscriptionsQuery()
  const { products } = useBillingProductsQuery()
  const { upcomingInvoices } = useBillingUpcomingInvoicesQuery()
  const { invoices } = useBillingInvoicesQuery()
  const { umrDeviceInfo } = useUmrDeviceQuery()

  const getFullSubscriptions = () => {
    const subscriptionsToDisplay = userSubscriptions.filter(({ status }) =>
      SUBSCRIPTION_STATUSES_TO_SHOW.includes(status)
    )

    const fullSubscriptions: FullSubscription[] =
      subscriptionsToDisplay.flatMap((subscription) => {
        const {
          region,
          status,
          current_period_end,
          plan,
          metadata,
          quantity,
          id,
          items,
          default_source,
          latest_invoice,
          default_payment_method,
          currency,
        } = subscription

        const findPossibleProducts = (
          possibleProduct:
            | string
            | Stripe.Product
            | Stripe.DeletedProduct
            | null
        ) =>
          products.find(
            ({ id }) =>
              id ===
              (typeof possibleProduct === 'string'
                ? possibleProduct
                : possibleProduct?.id)
          )

        // UISP and UniFi Cloud actually does not xcreate one subscription per instance, but adds more items to the existing subscriptions
        // for some reason this means that the plan in this case is null and the plan information is inside the items themselves
        const possibleProduct = plan?.product ?? items.data[0].plan.product
        const product: Stripe.Product | undefined =
          findPossibleProducts(possibleProduct)

        const productLine = getSubscriptionProductLine(
          product?.name ?? '',
          metadata?.ui_product_line
        )

        const latestInvoice =
          typeof latest_invoice === 'string'
            ? invoices.find(({ id }) => latest_invoice === id)
            : (latest_invoice as StripeInvoice)

        const isActiveInvoice =
          !!latestInvoice && latestInvoice.status !== 'void'
        const isInvoicePaid =
          !!latestInvoice?.paid || latestInvoice?.amount_remaining === 0

        const isSuspended =
          status === 'canceled' && isActiveInvoice && !isInvoicePaid
        const isFailed = status === 'past_due' || isSuspended
        const isInProgress = isSuspended && latestInvoice?.status === 'draft'

        const isActive =
          status === 'active' &&
          (productLine !== ProductName.UNIFI_TALK || !!quantity)

        const upcomingInvoice: StripeInvoice | undefined =
          upcomingInvoices?.find(
            (upcomingInvoice) => id === upcomingInvoice.subscription
          )

        let upcomingAmount = upcomingInvoice
          ? getAmount(upcomingInvoice?.amount_due, upcomingInvoice?.currency)
          : 0

        if (isActiveInvoice && !isInvoicePaid) {
          const amountDivided = getAmount(
            latestInvoice.amount_remaining,
            latestInvoice.currency
          )
          upcomingAmount = amountDivided ?? 0
        }

        const cardNotFound =
          productLine !== ProductName.UNIFI_TALK &&
          !isFailed &&
          !default_source &&
          !default_payment_method

        let display = true
        const noInvoicePayment =
          !latestInvoice || latestInvoice.status === 'void'
        if (status === 'canceled' && (isInvoicePaid || noInvoicePayment)) {
          display = false
        }

        const fullSubscription: FullSubscription = {
          id,
          name: productLine,
          upcomingAmount,
          currency,
          metadata,
          status,
          plan,
          region,
          cardNotFound,
          product,
          quantity,
          current_period_end,
          isInProgress,
          latestInvoice,
          items,
          upcomingInvoice,
          isSuspended,
          isFailed,
          default_source,
          default_payment_method,
          display,
          isActive,
          cancelAtPeriodEnd: subscription.cancel_at_period_end,
        }

        if (productLine === ProductName.MOBILITY) {
          fullSubscription.deviceUmrInfo = umrDeviceInfo
        }

        return fullSubscription
      })

    return fullSubscriptions
  }

  const fullSubscriptions = getFullSubscriptions()

  const fullSubscriptionsToDisplay = fullSubscriptions.filter(
    (subscription) => subscription.display
  )

  const getOngoingTalkRegions = () => {
    const talkRegions: Partial<Record<StripeRegionCode, boolean>> = {}
    for (const subscription of fullSubscriptionsToDisplay) {
      if (subscription.name === ProductName.UNIFI_TALK) {
        talkRegions[subscription.region] = true
      }
    }
    return talkRegions
  }

  return {
    fullSubscriptions,
    fullSubscriptionsToDisplay,
    getOngoingTalkRegions,
  }
}
