import {
  adoptionTrouble,
  app,
  assistance,
  device,
  extraApp,
  extraConsoles,
  extraTech,
  model,
  tech,
  trouble,
  userType,
} from './ConfigOptions'

const allValues = [
  ...assistance,
  ...extraConsoles,
  ...device,
  ...model,
  ...tech,
  ...extraTech,
  ...trouble,
  ...userType,
  ...adoptionTrouble,
  ...app,
  ...extraApp,
].map((value) => [value.id, value.label])

export const zdFieldValues: Record<string, string> =
  Object.fromEntries(allValues)
