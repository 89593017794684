import { StripeCountry, StripeRegionCode } from '../types'

export const gbStripeCountries: Readonly<StripeCountry[]> = [
  {
    code: 'gb',
    name: 'United Kingdom',
    region: StripeRegionCode.GB,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^XI\d{9}$/,
        taxPlaceholder: 'XI123456789',
      },
      {
        value: 'gb_vat',
        taxFormat: /^GB\d{9}$/,
        taxPlaceholder: 'GB123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'je',
    name: 'Jersey',
    region: StripeRegionCode.GB,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'gg',
    name: 'Guernsey',
    region: StripeRegionCode.GB,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'im',
    name: 'Isle of Man',
    region: StripeRegionCode.GB,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'gi',
    name: 'Gibraltar',
    region: StripeRegionCode.GB,
    taxId: [] as StripeCountry['taxId'],
  },
]
