import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { selectMFASetup } from 'modules/mfaSetup'
import { MFAType } from 'modules/types'

import { GenericConfirmRemoveMFAModal } from '../RemoveModal/GenericConfirmRemoveMFAModal'

export const PUSH_REMOVE_MODAL_ID = 'PUSH_REMOVE_MODAL_ID'

const PushRemoveModal: React.FC<ModalProps & WrappedComponentProps> = ({
  intl,
  ...restProps
}) => {
  const { removePush } = useSelector(selectMFASetup)

  return (
    <GenericConfirmRemoveMFAModal
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_PUSH_REMOVE_MODAL_TITLE',
      })}
      removeId={removePush.id}
      type={MFAType.push}
      {...restProps}
    />
  )
}

const PushRemoveModalConnected = injectIntl(PushRemoveModal)

export const WrappedPushRemoveModal = () => (
  <ModalWrapper modalId={PUSH_REMOVE_MODAL_ID}>
    <PushRemoveModalConnected />
  </ModalWrapper>
)
