import React, { FC } from 'react'
import styled from '@emotion/styled'

const StyledInput = styled('input')`
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -99999;
  position: absolute;
  left: 0;
  top: 0;
`

const StyledLabel = styled('label')`
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -99999;
  position: absolute;
  left: 0;
  top: 0;
`

/**
 * Long story short - Chrome tries to autofill the first `type="password"` or type="username" field it sees.
 * In case of login page, the behaviour is desired but in other places we don't want it.
 *
 * By adding a dummy input field we trick Chrome into filling it but hide it visually. Also
 * tabIndex is set to -1 so tabbing keeps working as expected.
 * */
export const BrowserAutofillBuster: FC = () => (
  <>
    <StyledLabel htmlFor="username">Username</StyledLabel>
    <StyledInput
      name="username"
      type="username"
      id="username"
      autoComplete="username"
      tabIndex={-1}
    />
  </>
)
