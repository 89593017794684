import React from 'react'

import { Bubble } from 'components/support/Bubble'
import { TilesWrapper } from 'components/support/Wrappers'
import { QuestionTile } from 'components/support/QuestionTile'

import { BubbleProps, SupportConsole } from './types'
import { config } from './config/formConfig'
import { umrSpecificIssue } from './config/ConfigOptions'

export const UmrIssueBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
}) => {
  const getNextSection = (): string | undefined =>
    config.umrSpecificIssue.nextSection?.section

  const handleClick = (value?: string | boolean | SupportConsole) => {
    const section = getNextSection()
    handleChange('umrSpecificIssue', value, section)
  }

  return (
    <Bubble question={config.umrSpecificIssue.fieldTitle}>
      <TilesWrapper>
        {umrSpecificIssue.map(({ icon, label, id }) => (
          <QuestionTile
            key={id}
            icon={icon}
            label={label}
            onClick={() => handleClick(id)}
            isActive={values.umrSpecificIssue === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
