import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { Bubble } from 'components/support/Bubble'
import { Description } from 'components/support/Description'

import { DESCRIPTION_MAX_LENGTH, DESCRIPTION_MIN_LENGTH } from '../constants'
import { BubbleProps, FormValue } from './types'
import { UniFiStateValues } from './config/types'

export const DescriptionBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [touched, setTouched] = useState(false)
  const intl = useIntl()

  const fieldName = 'Description'

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.userType])

  const onChange = (value: FormValue) => {
    handleChange(UniFiStateValues.DESCRIPTION, value)
  }

  const getDescriptionError = () => {
    if (!touched) return
    if (!values.description) {
      return intl.formatMessage(
        {
          id: 'COMMON_VALIDATION_EMPTY',
        },
        {
          name: fieldName,
        }
      )
    }
    if (values.description.length < DESCRIPTION_MIN_LENGTH) {
      return intl.formatMessage(
        {
          id: 'COMMON_VALIDATION_MIN_CHARS',
        },
        {
          name: fieldName,
          min: DESCRIPTION_MIN_LENGTH,
        }
      )
    }
    if (values.description.length > DESCRIPTION_MAX_LENGTH) {
      return intl.formatMessage(
        {
          id: 'COMMON_VALIDATION_MAX_CHARS',
        },
        {
          name: fieldName,
          max: DESCRIPTION_MAX_LENGTH,
        }
      )
    }
  }

  return (
    <Bubble question={config.description.fieldTitle} ref={ref}>
      <Description
        handleChange={onChange}
        value={values.description || ''}
        error={getDescriptionError()}
        onBlur={() => setTouched(true)}
      />
    </Bubble>
  )
}
