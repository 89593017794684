import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TrashIcon } from '@ubnt/icons'
import { Checkbox } from '@ubnt/ui-components/Checkbox'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'

interface Props {
  isManageActive: boolean
  setIsManageActive: React.Dispatch<React.SetStateAction<boolean>>
  setCheckedBackups: () => void
  clearAllChecked: () => void
  isChecked: boolean
  handleDelete: () => void
  checkedBackups: string[]
}

export const ManageButton: React.FC<Props> = ({
  isManageActive,
  setIsManageActive,
  setCheckedBackups,
  isChecked,
  clearAllChecked,
  handleDelete,
  checkedBackups,
}) => {
  return (
    <>
      {isManageActive ? (
        <ManageSection>
          <DeleteButton
            onClick={handleDelete}
            variant="inline"
            color="danger"
            disabled={checkedBackups.length < 1}
          >
            <TrashIcon size="original" />
            <DeleteText>
              <FormattedMessage id="COMMON_ACTION_DELETE" />
            </DeleteText>
          </DeleteButton>
          <CancelButton
            onClick={() => {
              clearAllChecked()
              setIsManageActive(false)
            }}
            variant="inline"
          >
            <FormattedMessage id="COMMON_ACTION_CANCEL" />
          </CancelButton>
          <StyledCheckbox
            reversed
            id="checkbox"
            checked={isChecked}
            onChange={setCheckedBackups}
            labelClassName="backupsCheckboxLabel"
          ></StyledCheckbox>
        </ManageSection>
      ) : (
        <OpenManageSettingsButton onClick={() => setIsManageActive(true)}>
          <FormattedMessage id="COMMON_ACTION_MANAGE" />
        </OpenManageSettingsButton>
      )}
    </>
  )
}

const StyledCheckbox = styled(Checkbox)`
  margin: auto 0;
  margin-left: 8px;
  .backupsCheckboxLabel {
    margin-right: 0;
  }
`

const DeleteButton = styled(Button)`
  display: flex;
  flex-direction: row;
  margin-right: 16px !important;
  text-decoration: none !important;
`
const DeleteText = styled('div')`
  margin: auto 0 auto 4px;
`

const CancelButton = styled(Button)`
  text-decoration: none !important;
  margin: auto 0;
  cursor: pointer;
`

const OpenManageSettingsButton = styled('div')`
  color: ${(p) => p.theme.ublue06};
  font-weight: normal;
  cursor: pointer;
`
const ManageSection = styled('div')`
  font-weight: normal;
  display: flex;
  flex-direction: row;
`
