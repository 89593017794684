import React from 'react'

import { SettingsLink } from 'types/types'
import { GlobalMenu } from 'components/global-menu/GlobalMenu'
import { useIntl } from 'react-intl'
import { INavigationItem, ROUTES, Route } from 'types/routes'

interface Props {
  isAuthenticated?: boolean
  navItems?: SettingsLink[]
  setMobileNavOpen?: (value: boolean) => void
  mobileNavOpen?: boolean
}

const GlobalNav: React.FC<Props> = ({
  isAuthenticated,
  navItems = [],
  setMobileNavOpen,
  mobileNavOpen,
}) => {
  const intl = useIntl()

  const OTHER_LINKS: INavigationItem[] = [
    {
      id: Route.UniFi,
      title: 'UniFi',
      link: ROUTES[Route.UniFi],
    },
    {
      id: Route.UISP,
      title: 'UISP',
      link: ROUTES[Route.UISP],
    },
    {
      id: Route.Community,
      title: intl.formatMessage({ id: 'COMMON_LABEL_COMMUNITY' }),
      link: ROUTES[Route.Community],
      newTab: true,
    },
    {
      id: Route.Store,
      title: intl.formatMessage({ id: 'COMMON_LABEL_STORE' }),
      link: ROUTES[Route.Store],
      newTab: true,
    },
  ]

  return (
    <GlobalMenu
      otherItems={OTHER_LINKS}
      isAuthenticated={isAuthenticated}
      navItems={navItems}
      setMobileNavOpen={setMobileNavOpen}
      mobileNavOpen={mobileNavOpen}
    />
  )
}

export default React.memo(GlobalNav)
