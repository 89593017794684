import { SupportFormState } from '../types'

export enum SpecificIssue {
  cantActivate = 'umr_cannot_activate_my_device',
  appearsOffline = 'umr_device_still_appears_offline_after_activation',
  wontTurnOn = 'umr_device_won’t_turn_on_or_keep_booting',
  carrierSpeedPerformance = 'umr_carrier_speed_or_performance',
  subscriptionIssue = 'umr_subscription_issue',
  wifiSpeedPerformance = 'umr_wifi_speed_or_performance',
  lcmIssue = 'umr_lcm_issue',
  other = 'umr_other',
}

export enum Console {
  umr = 'uosfrm_umr',
  umrUnlisted = 'uosfrm_umr_unlisted',
}

export enum UserType {
  home = 'home_user',
  business = 'business_user',
}

export interface ZDFormValueType {
  id: string
  label: string
  icon?: JSX.Element
}

type NextSection = {
  section: string
}

type ConfigElement = {
  fieldId: number
  fieldTitle: string | null
  options: ZDFormValueType[] | null
  nextSection: NextSection | null
  hide?: boolean
}

type AllData = SupportFormState & {
  attachments?: string
  rawData?: string
  mac?: string
}

export type FormConfig = Record<keyof AllData, ConfigElement>
