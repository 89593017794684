import { Button } from '@ubnt/ui-components/Button'
import { EntityToast } from '@ubnt/ui-components/Toast'
import { radius, size, typography } from 'theme'
import styled from 'theme/styled'

const LoaderContainer = styled.div`
  display: flex;
  margin: 50px;
  justify-content: center;
`
const StyledEntityToast = styled(EntityToast)`
  width: 316px;
`

const StyledPaymentMethodIcon = styled.img`
  width: ${size['desktop-sizing-base-10']} !important;
  height: ${size['desktop-sizing-base-08']} !important;
`

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.neutral03};
  border-radius: 8px;
  height: 181px;
`

const ContentWrapper = styled.div`
  padding: 16px 16px 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 145px;
`

const Title = styled.h6`
  margin: 0;
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.text1};
`

const Description = styled.p`
  margin: 0;
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text2};
`

const ImageWrap = styled.div`
  margin-top: 4px;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-top: 1px solid ${({ theme }) => theme.neutral03};
`

const DefaultText = styled.span`
  display: flex;
  align-items: center;
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.text2};

  svg {
    margin-right: 4px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledButton = styled(Button)`
  font: ${typography['desktop-typography-body']} !important;
`

const FooterElement = styled.div`
  display: flex;
  gap: 8px;
`

const MethodIcon = styled.img`
  width: ${size['desktop-sizing-base-16']};
  height: ${size['desktop-sizing-base-10']};
  border-radius: ${radius['desktop-radius-base']};
`

export {
  Container,
  ContentWrapper,
  DefaultText,
  Description,
  Footer,
  FooterElement,
  ImageWrap,
  LoaderContainer,
  MethodIcon,
  StyledButton,
  StyledEntityToast,
  StyledPaymentMethodIcon,
  Title,
  HeaderContainer,
}
