import ArrowDownPrimaryIcon from '@ubnt/icons/ArrowDownPrimaryIcon'
import styled from 'theme/styled'

export const StyledDropdownArrow = styled(ArrowDownPrimaryIcon, {
  shouldForwardProp: (props) => props !== '$isDropdownOpen',
})<{ $isDropdownOpen: boolean }>`
  color: ${({ theme }) => theme.neutral08};
  transform: ${({ $isDropdownOpen }) =>
    $isDropdownOpen ? `rotate(180deg);` : `none`};
  transition: transform 0.3s;
`
