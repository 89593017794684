import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { uniq } from 'lodash-es'
import { BillingCardsApi } from 'api/billing/billingCards'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { regionsWithMultipleCountries } from 'features/stripe/config'
import { QueryKey } from '../types'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'

const billingCardsApi = new BillingCardsApi()

export const useBillingCardsQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const cards = useQuery({
    queryKey: [QueryKey.BILLING_CARDS],
    queryFn: billingCardsApi.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const customerCards = useMemo(() => {
    if (!cards.data) {
      return []
    }

    const cardRegions = Object.keys(cards.data) as StripeRegionCode[]

    if (!cardRegions.length) {
      return []
    }

    const userCards = cardRegions.flatMap((region) => {
      return (
        cards.data[region]?.data.map((card) => {
          return { ...card, region }
        }) || []
      )
    })

    return userCards
  }, [cards.data])

  const cardCountries = useMemo(() => {
    const userCountries: string[] = []
    if (!cards.data) return []
    const cardRegions = Object.keys(cards.data) as StripeRegionCode[]

    cardRegions.map((region) => {
      const paymentMethods = cards.data[region]?.data

      if (
        paymentMethods?.length &&
        regionsWithMultipleCountries.includes(region)
      ) {
        paymentMethods.forEach((card) => {
          if (card.card?.country) {
            const { country } = card.card
            userCountries.push(country.toLowerCase())
          }
        })
      }

      if (
        paymentMethods?.length &&
        !regionsWithMultipleCountries.includes(region)
      ) {
        userCountries.push(region)
      }
    })

    return uniq(userCountries)
  }, [cards.data])

  return {
    cards: cards.data,
    customerCards,
    cardCountries,
    isCardsLoading: cards.isFetching,
  }
}
