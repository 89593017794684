import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import { Dispatch } from 'redux'

export const withConfirmation =
  (MODAL_ID: string, onConfirm: any, modalProps?: any, closeModal = true) =>
  (dispatch: Dispatch) => {
    dispatch(
      setVisibleModal(MODAL_ID, {
        onConfirm: (args: any) => {
          if (args) dispatch(onConfirm(args))
          else dispatch(onConfirm)
          closeModal && dispatch(closeVisibleModal())
        },
        ...(modalProps ? modalProps : {}),
      })
    )
  }
