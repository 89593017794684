import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'

import {
  selectBusinessProfile,
  selectIsBusinessProfileLoading,
} from 'features/subscriptions/module/businessProfile'
import { useSelector } from 'react-redux'
import styled from 'theme/styled'

export const BusinessProfile = () => {
  const businessProfile = useSelector(selectBusinessProfile)
  const isBusinessProfileLoading = useSelector(selectIsBusinessProfileLoading)

  if (isBusinessProfileLoading) {
    return (
      <SkeletonLoader>
        <Wrapper>
          <Text width={80} />
          <Text width={180} />
          <Text width={180} />
        </Wrapper>
      </SkeletonLoader>
    )
  }

  if (!businessProfile) {
    return null
  }

  const { name, support_address } = businessProfile

  return (
    <>
      <span>{name}</span>
      <span>
        {support_address?.line1}
        {support_address?.line2 ? ` - ${support_address.line2}, ` : ''}
      </span>
      <span>
        {support_address?.city}, {support_address?.postal_code},
        {support_address?.state ? ` ${support_address.state}, ` : ''}
        {support_address?.country}
      </span>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
