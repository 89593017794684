import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { TicketsApi } from 'api/zendesk/tickets'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { QueryKey } from 'store/types'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'

const ticketsApi = new TicketsApi()

export const useCCdTicketsQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const ccdTicketsResponse = useQuery({
    queryKey: [QueryKey.CCD_TICKETS],
    queryFn: ticketsApi.getAllCcd,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const ccdTickets = useMemo(
    () => ccdTicketsResponse.data?.tickets ?? [],
    [ccdTicketsResponse.data]
  )

  const totalCcdTickets = useMemo(
    () => ccdTicketsResponse.data?.totalTickets ?? 0,
    [ccdTicketsResponse.data?.totalTickets]
  )

  return {
    ccdTickets: ccdTickets,
    areCcdTicketsFetched: ccdTicketsResponse.isFetched,
    areCcdTicketsLoading: ccdTicketsResponse.isFetching,
    ccdTicketsError: ccdTicketsResponse.error,
    refetchCcdTickets: ccdTicketsResponse.refetch,
    totalCcdTickets,
  }
}
