import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  BackupIcon,
  KeyholeSecurityIcon,
  PaymentIcon,
  QuestionMarkIcon,
  UserAccountIcon,
} from '@ubnt/icons'

import { INavigationItem, ROUTES, Route } from 'types/routes'
import { IconContainer, StyledLink, Wrapper } from './Navigation.styles'

export const NAVIGATION_CONFIG: INavigationItem[] = [
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_PROFILE" />,
    link: ROUTES[Route.Profile],
    id: Route.Profile,
    Icon: UserAccountIcon,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_SECURITY" />,
    link: ROUTES[Route.Security],
    id: Route.Security,
    Icon: KeyholeSecurityIcon,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_SUBSCRIPTIONS" />,
    link: ROUTES[Route.PaymentsAndSubscriptions],
    id: Route.PaymentsAndSubscriptions,
    Icon: PaymentIcon,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_CLOUD_BACKUPS" />,
    link: ROUTES[Route.Backups],
    id: Route.Backups,
    Icon: BackupIcon,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_MYSUPPORT" />,
    link: ROUTES[Route.Support],
    id: Route.Support,
    Icon: QuestionMarkIcon,
  },
]

export const SidebarNavigation = () => {
  return (
    <Wrapper>
      {NAVIGATION_CONFIG.map(({ link, id, Icon, title }) => (
        <StyledLink to={link} key={id} activeClassName="active">
          <IconContainer>{Icon && <Icon />}</IconContainer>

          {title}
        </StyledLink>
      ))}
    </Wrapper>
  )
}
