import { fetchRequestWithSigner } from 'api/axios'
import { Device, UcoreDevice } from './types'

const {
  api: { nca },
} = __CONFIG__

export class NcaDevicesApi {
  public getAll = async () => {
    const date = new Date()
    const threeMonthsAgo = date.setMonth(date.getMonth() - 3).toString()

    const searchParams = new URLSearchParams([
      ['withUserData', 'true'],
      ['type', 'all'],
      ['lastSeenAfter', threeMonthsAgo],
      ['sort', '-latestBackupTimestamp'],
      ['filterShadow', 'false'],
    ])

    const pathWithParams = `${nca.paths.devices}?${searchParams}`

    const res = await fetchRequestWithSigner(pathWithParams)

    const data: (Device | UcoreDevice)[] = await res.json()

    return data
  }
}
