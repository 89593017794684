import React, { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'

import styled from 'theme/styled'
import { GenericMFAModal } from 'pages/security/components/GenericMFAModal'
import { LoginMFAOptions } from 'features/auth/ui/LoginMFAOptions'
import { MFAAuthenticator } from 'modules/types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MFA_OPTIONS_MODAL_ID = 'MFA_OPTIONS_MODAL_ID'

interface Props {
  authenticators?: MFAAuthenticator[] | null
  currentAuthenticator: MFAAuthenticator | undefined
  setCurrentAuthenticator: Dispatch<
    SetStateAction<MFAAuthenticator | undefined>
  >
}

export const MFAOptionsModal: React.FC<Props> = ({
  authenticators,
  setCurrentAuthenticator,
  currentAuthenticator,
}) => {
  const intl = useIntl()

  return (
    <GenericMFAModal
      modalId={MFA_OPTIONS_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_OPTIONS_OTHER_METHODS',
      })}
    >
      <Wrapper>
        <LoginMFAOptions
          authenticators={authenticators}
          setCurrentAuthenticator={setCurrentAuthenticator}
          currentAuthenticator={currentAuthenticator}
        />
      </Wrapper>
    </GenericMFAModal>
  )
}
