import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'

import {
  storedInnerRedirectKey,
  storedOuterRedirectKey,
} from 'features/redirect-after-register'

import { selectIsAuthenticated } from '../../../features/auth/modules/auth'
import tokenApiHandler from '../utils/tokenApi'

const useSubmit = () => {
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [data, setData] = useState<string | null>(null)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const intl = useIntl()
  const history = useHistory()

  const sessionStorageTokenKey = 'activationToken'

  const successState = () => {
    setLoginSuccess(true)
    setIsLoading(false)
  }

  const resetStates = () => {
    setData(null)
    setError(null)
    setLoginSuccess(false)
  }

  const errorMessage = useCallback(
    (err: AxiosError): string => {
      if (err.response && err.response.status === 400) {
        return intl.formatMessage({ id: 'BAD_REQUEST_ERROR_MESSAGE' })
      }
      if (err.response && err.response.status === 404) {
        return intl.formatMessage({ id: 'WRONG_CODE_ERROR_MESSAGE' })
      }
      if (err.response && err.response.status === 401) {
        return intl.formatMessage({ id: 'UNAUTHORIZED_ERROR_MESSAGE' })
      } else {
        return intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE' })
      }
    },
    [intl]
  )

  const handleSubmit = useCallback(
    async (token: string) => {
      if (isAuthenticated) {
        setIsLoading(true)
        try {
          const deviceName = await tokenApiHandler(token, intl)

          setData(deviceName)
          successState()
        } catch (e) {
          setIsLoading(false)
          if (axios.isAxiosError(e)) {
            setError(errorMessage(e))
          } else {
            setError(intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE' }))
          }
        }
      } else {
        sessionStorage.setItem(sessionStorageTokenKey, token)

        localStorage.setItem(
          storedInnerRedirectKey,
          JSON.stringify({
            url: `/activate`,
            createdAt: DateTime.utc().toISO(),
          })
        )
        localStorage.removeItem(storedOuterRedirectKey)
        setIsLoading(true)
        setTimeout(() => history.push(`/login`), 500)
      }
    },
    [errorMessage, history, intl, isAuthenticated]
  )

  return {
    handleSubmit,
    isLoading,
    error,
    loginSuccess,
    resetStates,
    setError,
    data,
    sessionStorageTokenKey,
  }
}

export default useSubmit
