import React, { useContext, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StatusBar, ToastContext } from '@ubnt/ui-components'
import { UserAccountIcon } from '@ubnt/icons'

import styled from 'theme/styled'
import useFakeStatus from 'utils/fakeStatusHook'
import StyledEntityToast from 'components/EntityToast'

interface Props {
  fileName?: string
  isUploadingPicture?: boolean
  onClose?: () => void
}

const ProfilePictureUpload: React.FC<Props> = ({
  fileName,
  isUploadingPicture,
  onClose,
}) => {
  const [progress] = useFakeStatus(isUploadingPicture)

  const intl = useIntl()
  const toast = useContext(ToastContext)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null
    if (progress === 100) {
      timer = setTimeout(() => {
        onClose?.()
        toast.createNotification(
          <StyledEntityToast
            id="profilePictureUpdated"
            centerText={true}
            icon={<UserAccountIcon />}
            stateIndicator="success"
            onClose={() => {
              toast.removeNotification('profilePictureUpdated')
            }}
            title={intl.formatMessage({
              id: 'SETTINGS_PICTURE_UPLOAD_SUCCESS_TOAST',
            })}
            duration={5000}
          />
        )
      }, 1500)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [progress, onClose, toast, intl])

  return (
    <Container>
      <StatusContainer>
        <FormattedMessage
          id="SETTINGS_PICTURE_UPLOAD_MODAL_PROGRESS_UPLOADING"
          values={{ fileName }}
          tagName="div"
        />
        <Progress>{`${progress}%`}</Progress>
      </StatusContainer>
      <StatusBar value={progress} />
    </Container>
  )
}

export default ProfilePictureUpload

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
  border: 1px dashed ${(p) => p.theme.neutral08};
  border-radius: 4px;
  color: ${(p) => p.theme.neutral08};
`

const StatusContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  > :first-child {
    overflow-wrap: anywhere;
  }
`

const Progress = styled.div`
  margin-left: auto;
`
