import { ReduxAction, RootState } from 'types/types'

export const dataKey = 'modals'

const SET_VISIBLE_MODAL = `${dataKey}/SET_VISIBLE_MODAL`
const CLOSE_VISIBLE_MODAL = `${dataKey}/CLOSE_VISIBLE_MODAL`

export const selectVisibleModal = (state: RootState): ModalState =>
  state[dataKey]

export interface ModalState {
  visibleModal?: string
  props?: any
}

const initialState: ModalState = {
  visibleModal: undefined,
  props: undefined,
}

export const setVisibleModal = (modalId: string, props: any = {}) => {
  return {
    type: SET_VISIBLE_MODAL,
    payload: {
      modalId,
      props,
    },
  }
}

export const closeVisibleModal = () => {
  return {
    type: CLOSE_VISIBLE_MODAL,
  }
}

export const modalsReducer = (
  state = initialState,
  action: ReduxAction
): ModalState => {
  switch (action.type) {
    case SET_VISIBLE_MODAL: {
      const { modalId, props } = action.payload
      return {
        ...state,
        visibleModal: modalId,
        props,
      }
    }
    case CLOSE_VISIBLE_MODAL: {
      return {
        ...state,
        visibleModal: undefined,
        props: undefined,
      }
    }
    default:
      return state
  }
}
