import React from 'react'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'

interface Props {
  options: number[]
  changeNumberOfDevices: (option: number) => void
  devicesPerPage: number
}

const ChangeDeviceNumberButtons: React.FC<Props> = ({
  options,
  changeNumberOfDevices,
  devicesPerPage,
}) => {
  return (
    <>
      {options.map((option, i) => (
        <PageDevicesButton
          key={option}
          onClick={() => changeNumberOfDevices(option)}
          isSelected={option === devicesPerPage}
          noWrap
          isLastButton={i + 1 === options.length}
        >
          {option}
        </PageDevicesButton>
      ))}
    </>
  )
}

export default ChangeDeviceNumberButtons

const PageDevicesButton = styled(Button)<{
  isSelected?: boolean
  isLastButton?: boolean
}>`
  width: 28px;
  height: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.ublue06 : 'transparent'};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.neutral00 : theme.text3};
  margin-right: 4px;
`
