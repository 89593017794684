import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { QueryKey } from '../types'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { BillingTaxIdApi } from 'api/billing/billingTaxId'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { CustomTaxId } from 'features/payment-methods/modules/types'

const billingTaxIdApi = new BillingTaxIdApi()

export const useBillingTaxIdQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const taxId = useQuery({
    queryKey: [QueryKey.BILLING_TAX_ID],
    queryFn: billingTaxIdApi.get,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const parsedTaxId = useMemo(() => {
    if (!taxId.data) return null
    const regions = Object.keys(taxId.data) as StripeRegionCode[]

    for (let i = 0; i < regions.length; i++) {
      if (taxId.data?.[regions[i]]?.data.length) {
        return taxId.data?.[regions[i]]?.data[0] as CustomTaxId
      }
    }
  }, [taxId.data])

  return {
    taxId: parsedTaxId,
    isTaxIdFetched: taxId.isFetched,
    taxIdError: taxId.error,
    isTaxIdLoading: taxId.isFetching,
  }
}
