import { config } from './config'
import { AddMFAOptions } from './types'

export const getMfaOptions = (shouldDefaultToEmail: boolean): AddMFAOptions => {
  const { email, totp, uiVerify } = config

  if (shouldDefaultToEmail) {
    return {
      featured: email,
      other: [totp, uiVerify],
    }
  }

  return {
    featured: uiVerify,
    other: [totp, email],
  }
}
