import React from 'react'
import { RadioList } from '@ubnt/ui-components/Radio'
import { RadioProps } from '@ubnt/ui-components/Radio/Radio'
import { Divider } from '@ubnt/ui-components/Divider'

import styled from 'theme/styled'
import { HeaderFadeIn } from 'components/animations'

type Props = {
  items: RadioProps[]
}

export const ExtraSelector: React.FC<Props> = ({ items }) => (
  <>
    <StyledDivider />
    <StyledRadioList direction="row" items={items} variant="boxed" />
  </>
)

const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 0;
`

const StyledRadioList = styled(RadioList)`
  animation: ${HeaderFadeIn} 0.2s linear;
`
