import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { typography } from '@ubnt/ui-components/styles/designToken'

import styled from 'theme/styled'
import PortalImage from 'assets/images/portalImage.png'
import PortalImageLow from 'assets/images/portalImageLow.png'
import AssistantLogo from 'assets/svgs/assistantLogo.svg'
import AppStore from 'assets/svgs/appStore.svg'
import GooglePlay from 'assets/svgs/googlePlay.svg'

const AppStoreLink = 'https://apps.apple.com/app/id6451146502'
const GooglePlayLink =
  'https://play.google.com/store/apps/details?id=com.ui.portal'

export const PortalTile = () => {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src = PortalImage
    imageToLoad.onload = () => setImageLoaded(true)
  }, [])

  return (
    <Container>
      <img src={AssistantLogo} className="assistant-logo" />
      <Title>
        <FormattedMessage id="SUPPORT_UNIFI_INFO_PORTAL" />
      </Title>
      <SubTitle>
        <FormattedMessage id="SUPPORT_UNIFI_INFO_PORTAL_DESCRIPTION" />
      </SubTitle>
      <img
        src={imageLoaded ? PortalImage : PortalImageLow}
        className="portal-image"
      />
      <span>
        <a href={AppStoreLink} target="_blank">
          <img src={AppStore} className="appStore-logo" />
        </a>
        <a href={GooglePlayLink} target="_blank">
          <img src={GooglePlay} className="googlePlay-logo" />
        </a>
      </span>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.neutral01};
  border-radius: 8px;
  padding: 26px 10px;
  gap: 2px;

  .assistant-logo {
    object-fit: cover;
    width: 48px;
    height: 48px;
  }

  .portal-image {
    width: 100%;
    padding-bottom: 18px;
    object-fit: contain;
  }

  .appStore-logo .googlePlay-logo {
    object-fit: cover;
    width: 24px;
    height: 24px;
  }

  .appStore-logo {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid ${({ theme }) => theme.neutral04};
  }
`

const Title = styled.span`
  font: ${typography['desktop-heading-large']};
  color: ${({ theme }) => theme.text1};
`

const SubTitle = styled.span`
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text3};
`
