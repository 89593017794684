import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { History } from 'history'
import { FormikProps, withFormik } from 'formik'
import { Button } from '@ubnt/ui-components'

import {
  AuthError,
  loginMFA,
  selectErrors,
  selectIsLoading,
} from 'features/auth/modules/auth'
import { LoginMFAData, RootState } from 'types/types'
import { Description } from 'components/Description'
import authorizedRedirect from 'components/authorizedRedirect'
import styled from 'theme/styled'
import { TokenInput } from 'components/TokenInput'

import { AuthForm, ButtonContainer } from '../styles'

interface Props {
  isLoading: boolean
  isAuthenticated: boolean
  apiErrors: AuthError
  handleLogin: typeof loginMFA
  history: History
  children: React.ReactNode
}

type FormValues = LoginMFAData

const LoginMFATOTP: React.FC<Props & FormikProps<FormValues>> = ({
  isLoading,
  apiErrors,
  errors,
  values,
  setFieldValue,
  children,
  submitForm,
}) => {
  const intl = useIntl()
  const [currentError, setCurrentError] = useState<undefined | string>()

  useEffect(() => {
    if (values.token.length === 6) {
      submitForm()
    }
  }, [submitForm, values.token])

  useEffect(() => {
    setCurrentError(apiErrors?.detail)
  }, [apiErrors?.detail])

  return (
    <AuthForm id="login-mfa-form">
      <StyledDescription>
        {intl.formatMessage({ id: 'LOGIN_MFA_TOTP_DESCRIPTION' })}
      </StyledDescription>

      <TokenInput
        currentError={errors.token || currentError}
        setCurrentError={setCurrentError}
        setFieldValue={setFieldValue}
        disabled={values.token.length === 6}
      />
      <ButtonContainer>
        <Button
          variant="primary"
          disabled={values.token.length < 6}
          loader={isLoading ? 'dots' : undefined}
        >
          {intl.formatMessage({ id: 'COMMON_ACTION_CONFIRM' })}
        </Button>
      </ButtonContainer>
      {children}
    </AuthForm>
  )
}
const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { handleLogin, history } }) =>
    handleLogin(values, { history }),
  mapPropsToValues: () => ({
    token: '',
  }),
})
const mapStateToProps = (state: RootState) => ({
  apiErrors: selectErrors(state),
  isLoading: selectIsLoading(state),
})
const mapDispatchToProps = {
  handleLogin: loginMFA,
}

export default authorizedRedirect(
  connect(mapStateToProps, mapDispatchToProps)(enchance(LoginMFATOTP))
)

const StyledDescription = styled(Description)`
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
