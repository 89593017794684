import React, { useCallback, useEffect, useRef } from 'react'
import { DropdownOption } from '@ubnt/ui-components'

import { FormDropdown } from 'components/support/Dropdown'
import { Bubble } from 'components/support/Bubble'
import { useSessionsQuery } from 'store/queries/useSessionsQuery'
import { config } from './config/formConfig'
import { BubbleProps, SupportFormState } from './types'
import { FormValue } from './config/types'
import { mapCountryToStore } from './utils/mapCountryToStore'

const mappedOptions: DropdownOption[] = config.country.options!.map(
  (country) => ({
    value: country.id,
    label: country.label,
  })
)

export const CountryBubble: React.FC<BubbleProps> = ({
  handleChange,
  shouldScroll,
  values,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { currentSessionLocation } = useSessionsQuery()

  const getNextSection = useCallback(
    (value: FormValue): string | undefined => {
      const nextSectionData = config.country.nextSection

      if (nextSectionData && nextSectionData.length > 1) {
        const section = nextSectionData.find((nextSection) => {
          if (nextSection.condition?.field === 'country') {
            return nextSection.condition?.value.includes(value)
          }
          return nextSection.condition?.value.includes(
            values[nextSection.condition?.field as keyof SupportFormState]
          )
        })?.section
        return section
      }
      return nextSectionData?.[0].section
    },
    [values]
  )

  const handleClick = useCallback(
    (value?: string) => {
      const section = getNextSection(value)
      handleChange('country', value, section)
    },
    [getNextSection, handleChange]
  )

  useEffect(() => {
    const storeLocation = mapCountryToStore(currentSessionLocation)
    if (storeLocation && !values.country) {
      handleClick(storeLocation)
    }
  }, [currentSessionLocation, handleClick, values.country])

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  return (
    <Bubble question={config.country.fieldTitle}>
      <FormDropdown
        value={values.country}
        options={mappedOptions}
        handleChange={({ value }) => handleClick(value.toString())}
      />
    </Bubble>
  )
}
