import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import { FormattedMessage } from 'react-intl'

import { Section } from 'components/Section.styles'
import {
  HeaderTitle,
  HeaderWrapper,
  PageHeader,
  TitleWrapper,
} from 'features/backups/Backups.styles'

const BackupsSkeleton = () => (
  <Section $omitMarginTop>
    <PageHeader>
      <HeaderWrapper>
        <TitleWrapper>
          <HeaderTitle>
            <FormattedMessage id="COMMON_SIDE_NAV_CONSOLE" />
          </HeaderTitle>
        </TitleWrapper>
        <SkeletonLoader width={220}>
          <Block width={220} height={26} />
        </SkeletonLoader>
      </HeaderWrapper>
    </PageHeader>
    <SkeletonLoader>
      {[...Array(4)].map((_, index) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          key={index.toString()}
        >
          <div>
            <div>
              <Block width={200} height={26} />
            </div>
          </div>
          <div>
            <Block width={200} height={26} />
          </div>
        </div>
      ))}
    </SkeletonLoader>
  </Section>
)

export default BackupsSkeleton
