import { BrowserUtils } from 'utils/browserUtils'
import { FormType } from './types'

export const getFormTypeFromQueryString = () => {
  const supportFormType = BrowserUtils.getQueryValue(
    window.location.href,
    'formtype'
  )

  const formTypeLower = supportFormType?.toString()?.toLowerCase()

  switch (formTypeLower) {
    case 'unifi':
      return FormType.UNIFI
    case 'uisp':
      return FormType.UISP
    case 'umr':
      return FormType.UMR
    case 'store':
      return FormType.STORE
    case 'billing':
      return FormType.BILLING
    default:
      return FormType.UNIFI
  }
}
