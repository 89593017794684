import React from 'react'
import { UbiquitiLogo } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components'
import { CommunityLogoWrap, UbiquitiIconWrapper } from './styles'

export const CommunityPlaceholder = () => {
  return (
    <CommunityLogoWrap>
      <UbiquitiIconWrapper>
        <UbiquitiLogo variant="neutral" size="full" />
      </UbiquitiIconWrapper>
      <Text size="header-l" color="secondary">
        Community
      </Text>
    </CommunityLogoWrap>
  )
}
