import { SSO_EVENT_NAME } from './enums'
import {
  ActivityItem,
  ActivitySSOBackupCodesUsed,
  ActivitySSOEmailChanged,
  ActivitySSOLogin,
  ActivitySSOMFAMethodAdded,
  ActivitySSOMFAMethodRemoved,
  ActivitySSOMFAPrimaryMethodChanged,
  ActivitySSOPasswordChanged,
  ActivitySSOPasswordReset,
  ActivitySSOSessionTimeoutChanged,
} from './types'

export function isActivitySSOMethodAdded(
  item: ActivityItem
): item is ActivitySSOMFAMethodAdded {
  return (
    (item as ActivitySSOMFAMethodAdded).eventName ===
    SSO_EVENT_NAME.SSO_MFA_METHOD_ADDED
  )
}

export function isActivitySSOMethodRemoved(
  item: ActivityItem
): item is ActivitySSOMFAMethodRemoved {
  return (
    (item as ActivitySSOMFAMethodRemoved).eventName ===
    SSO_EVENT_NAME.SSO_MFA_METHOD_REMOVED
  )
}

export function isActivitySSOPrimaryMethodChanged(
  item: ActivityItem
): item is ActivitySSOMFAPrimaryMethodChanged {
  return (
    (item as ActivitySSOMFAPrimaryMethodChanged).eventName ===
    SSO_EVENT_NAME.SSO_MFA_PRIMARY_METHOD_CHANGED
  )
}

export function isActivitySSOBackupCodesUsed(
  item: ActivityItem
): item is ActivitySSOBackupCodesUsed {
  return (
    (item as ActivitySSOBackupCodesUsed).eventName ===
    SSO_EVENT_NAME.SSO_BACKUP_CODES_USED
  )
}

export function isActivitySSOSessionTimeoutChanged(
  item: ActivityItem
): item is ActivitySSOSessionTimeoutChanged {
  return (
    (item as ActivitySSOSessionTimeoutChanged).eventName ===
    SSO_EVENT_NAME.SSO_SESSION_TIMEOUT_CHANGED
  )
}

export function isActivitySSOLogin(
  item: ActivityItem
): item is ActivitySSOLogin {
  return (item as ActivitySSOLogin).eventName === SSO_EVENT_NAME.SSO_LOGIN
}

export function isActivitySSOPasswordReset(
  item: ActivityItem
): item is ActivitySSOPasswordReset {
  return (
    (item as ActivitySSOPasswordReset).eventName === SSO_EVENT_NAME.SSO_PW_RESET
  )
}

export function isActivitySSOPasswordChanged(
  item: ActivityItem
): item is ActivitySSOPasswordChanged {
  return (
    (item as ActivitySSOPasswordChanged).eventName ===
    SSO_EVENT_NAME.SSO_PW_CHANGED
  )
}

export function isActivitySSOEmailChanged(
  item: ActivityItem
): item is ActivitySSOEmailChanged {
  return (
    (item as ActivitySSOEmailChanged).eventName ===
    SSO_EVENT_NAME.SSO_EMAIL_CHANGED
  )
}
