import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { RootState } from 'types/types'
import styled from 'theme/styled'
import requiresAuth from 'components/requiresAuth'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import ProfilePictureModal from 'features/profile-picture/ui/ProfilePictureModal'
import { MaintenanceInfo } from 'features/status/ui'
import { useAppView } from 'utils/useAppView'
import { SidebarLayoutProvider } from 'components/sidebarLayoutProvider/SidebarLayoutProvider'
import { Page } from 'components/Page'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import MFAToasts from './MFAToasts'
import { Toasts } from './Toasts'
import GlobalNav from './GlobalNav'

interface StateProps {
  isAuthenticated?: boolean
}

interface Props {
  renderMargin?: boolean
  renderFooter?(): React.ReactNode
  hideUserSection?: boolean
  children: React.ReactNode
}

const ProtectedPage: React.FC<Props & StateProps> = ({
  isAuthenticated,
  renderFooter,
  children,
  hideUserSection,
}) => {
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false)

  const [isAppView] = useAppView()

  const { isMaxMediumV2 } = useContext(MediaSizingContext)

  useEffect(() => {
    if (!isMaxMediumV2) setMobileNavOpen(false)
  }, [isMaxMediumV2])

  return (
    <>
      {Toasts()}
      <MFAToasts />
      {!isAppView && (
        <StyledGlobalNav
          isAuthenticated={isAuthenticated}
          key="global-nav"
          setMobileNavOpen={setMobileNavOpen}
          mobileNavOpen={mobileNavOpen}
        />
      )}
      {!hideUserSection ? (
        <SidebarLayoutProvider>
          <MaintenanceInfo />
          <ProfilePictureModal />
          <Page renderFooter={renderFooter}>{children}</Page>
        </SidebarLayoutProvider>
      ) : (
        <SupportWrapper $showNavBar={isAuthenticated && !isAppView}>
          {children}
        </SupportWrapper>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState, _props: any) => ({
  isAuthenticated: selectIsAuthenticated(state),
})

export default connect(mapStateToProps)(
  withRouter(requiresAuth(ProtectedPage as any) as any) as any
)

const StyledGlobalNav = styled(GlobalNav)`
  position: fixed !important;
`

const SupportWrapper = styled.div<{ $showNavBar?: boolean }>`
  background: ${({ theme }) => theme.neutral01};
  width: 100%;
  min-height: 100%;

  ${({ $showNavBar }) =>
    $showNavBar &&
    `
    position: relative;
    padding-top: 50px;
  `}
`
