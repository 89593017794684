import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMotif } from '@ubnt/ui-components'
import { AttachmentIcon } from '@ubnt/icons'
import parse, { HTMLReactParserOptions } from 'html-react-parser'

import {
  Link,
  LinkWrapper,
  Message,
  MessageAuthor,
  MessageWrapper,
} from './styles'
import { FullScaleImage } from './FullScaleImage'

const CommentMessage: FC<{
  isUserComment: boolean
  isTicketOpen: boolean
  showAuthorName: boolean
  attachmentUrl?: string | null
  author_name: string
  html_body: string
}> = ({
  isUserComment,
  isTicketOpen,
  showAuthorName,
  attachmentUrl,
  author_name,
  html_body,
}) => {
  const { motif } = useMotif()
  const isDarkTheme = motif === 'dark'

  // Need to parse the html that comes from the BE, to inject modal functionality
  const options: HTMLReactParserOptions = {
    transform(domNode) {
      const parent = domNode as JSX.Element

      if (
        !parent.props?.children ||
        (!Array.isArray(parent.props?.children) &&
          parent.props?.children.type !== 'img')
      ) {
        return domNode as JSX.Element
      }

      if (!Array.isArray(parent.props?.children)) {
        return <FullScaleImage src={parent.props?.children?.props?.src} />
      }

      const parsedChildren = parent.props.children.map((child: JSX.Element) => {
        if (child.type === 'img') {
          return <FullScaleImage src={child.props.src}>{child}</FullScaleImage>
        }
        return child
      })

      const parsedNode = {
        ...parent,
        props: { ...parent.props, children: parsedChildren },
      }
      return parsedNode as JSX.Element
    },
  }

  return (
    <MessageWrapper
      $isDarkTheme={isDarkTheme}
      $isUserComment={isUserComment}
      $isTicketOpen={isTicketOpen}
    >
      {showAuthorName && <MessageAuthor>{author_name}</MessageAuthor>}
      <Message $haslink={!!attachmentUrl}>{parse(html_body, options)}</Message>
      {attachmentUrl && (
        <LinkWrapper>
          <AttachmentIcon size={20} variant="twoTone" isActive />
          <Link href={attachmentUrl} target="_blank">
            <FormattedMessage id="REQUEST_MESSAGE_SEE_ATTACHMENT" />
          </Link>
        </LinkWrapper>
      )}
    </MessageWrapper>
  )
}

export default CommentMessage
