import { api } from 'api'
import { UmrDevice } from 'api/umrDevices'

export const getUmrSupportFile = async (
  selectedConsole?: UmrDevice
): Promise<{ download_url: string | undefined }> => {
  try {
    if (!selectedConsole?.id) {
      throw new Error('Problem retrieving console information')
    }

    const result = await api.createUmrSupportFile(selectedConsole.id)
    return result
  } catch (error) {
    console.error(error)

    return Promise.resolve({ download_url: undefined })
  }
}
