import { BrowserUtils } from 'utils/browserUtils'
import { UniFiStateValues } from '../config/types'

export const parseQueryValues = (url: string) => {
  return {
    consoleId: BrowserUtils.getQueryValue(url, UniFiStateValues.CONSOLE),
    app:
      BrowserUtils.getQueryValue(url, 'application') ||
      BrowserUtils.getQueryValue(url, 'category'),
    customTags: BrowserUtils.getQueryValue(url, 'tags'),
    sentryUserId: BrowserUtils.getQueryValue(url, 'sentryUserId'),
    sentryEventId: BrowserUtils.getQueryValue(url, 'sentryEventId'),
  }
}
