import { api } from 'api'
import { UcoreDevice } from 'api/nca/types'

export const getSupportFile = async (
  selectedConsole?: UcoreDevice
): Promise<{ downloadUrl: string | undefined }> => {
  try {
    if (!selectedConsole?.hardwareId) {
      throw new Error('Problem retrieving console information')
    }

    return await api.createSupportFile(selectedConsole.id)
  } catch (error) {
    console.error(error)

    return Promise.resolve({ downloadUrl: undefined })
  }
}
