import { useEffect, useState } from 'react'
import { api } from 'api'
import { UI_VERIFY_BLACKLIST_COUNTRIES } from './constants'

export const useVerifyCountryLimitation = () => {
  const [hasVerifyCountryLimitation, setHasVerifyCountryLimitation] =
    useState<boolean>(false)

  useEffect(() => {
    const getCountryFromIP = async () => {
      const res = await api.getLocationByIPAddress()
      setHasVerifyCountryLimitation(
        UI_VERIFY_BLACKLIST_COUNTRIES.includes(res.data.country_name)
      )
    }

    getCountryFromIP()
  }, [])

  return { hasVerifyCountryLimitation }
}
