import React from 'react'
import { useSelector } from 'react-redux'

import { selectIsTwoFactorAuthEnabled } from 'modules/profile'
import { Section } from 'components/Section.styles'

import {
  MultiFactorContent,
  MultiFactorHeader,
  RecoveryCodes,
} from './components/MultiFactor'
import { SetupMFAModal } from './components/SetupMFAModal'
import {
  TOTPCompletedModal,
  TOTPQRCodeModal,
  TOTPSecretCodeModal,
  TOTPVerifyCodeModal,
  WrappedTOTPRemoveModal,
} from './components/TOTP'
import {
  EmailCompletedModal,
  EmailInputEmailModal,
  EmailVerifyCodeModal,
  WrappedEmailRemoveModal,
} from './components/Email'
import {
  PushCompletedModal,
  PushFailedModal,
  PushQRCodeModal,
  WrappedPushRemoveModal,
} from './components/Push'
import { WrappedSubmitPasswordModal } from './SubmitPasswordModal'
import { BackupCodesFailedModal } from './components/BackupCodes/BackupCodesFailedModal'
import { BackupCodesModal } from './components/BackupCodes/BackupCodesModal'
import { BackupCodesPromptModal } from './components/BackupCodes/BackupCodesPromptModal'
import { WrappedPasskeyFailedModal } from './components/Passkey/FailedModal'
import { PasskeyLoadingModal } from './components/Passkey/LoadingModal'
import { PasskeyNoBrowserSupportModal } from './components/Passkey/NoBrowserSupportModal'
import { WrappedPasskeyRemoveModal } from './components/Passkey/RemoveModal'
import { SMSCompletedModal } from './components/SMS/CompletedModal'
import { WrappedSMSRemoveModal } from './components/SMS/RemoveModal'
import { TrustedDevices } from './components/MultiFactor/TrustedDevices'
import { useTrustedDevicesQuery } from 'store/queries/useTrustedDevicesQuery'
import { WrappedEditMFANameModal } from './components/MultiFactor/EditMFANameModal'
import PasskeyPromptPrimaryModalWrapped from './components/Passkey/PromptPrimaryModal'

export const MFASection: React.FC = () => {
  const isMFAEnabled = useSelector(selectIsTwoFactorAuthEnabled)
  const { hasTrustedDevices } = useTrustedDevicesQuery()

  return (
    <>
      <Section>
        <MultiFactorHeader />
        <MultiFactorContent />
        {isMFAEnabled ? <RecoveryCodes /> : null}
        {hasTrustedDevices ? <TrustedDevices /> : null}
      </Section>
      <SetupMFAModal />
      <PasskeyLoadingModal />
      <WrappedPasskeyFailedModal />
      <PasskeyNoBrowserSupportModal />
      <WrappedPasskeyRemoveModal />
      <TOTPQRCodeModal />
      <TOTPSecretCodeModal />
      <TOTPVerifyCodeModal />
      <TOTPCompletedModal />
      <WrappedTOTPRemoveModal />
      <SMSCompletedModal />
      <WrappedSMSRemoveModal />
      <BackupCodesModal />
      <BackupCodesPromptModal />
      <BackupCodesFailedModal />
      <EmailInputEmailModal />
      <EmailCompletedModal />
      <EmailVerifyCodeModal />
      <WrappedEmailRemoveModal />
      <PushQRCodeModal />
      <PushCompletedModal />
      <PushFailedModal />
      <WrappedPushRemoveModal />
      <WrappedSubmitPasswordModal />
      <WrappedEditMFANameModal />
      <PasskeyPromptPrimaryModalWrapped />
    </>
  )
}
