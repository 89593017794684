import React, { ReactElement } from 'react'
import { LineLoader } from '@ubnt/ui-components'

import styled from 'theme/styled'

enum FeedbackColor {
  'green' = '#39CC64',
  'orange' = '#F5A623',
  'red' = '#F03A3E',
}

export interface PasswordFeedbackData {
  is_acceptable_password: boolean | null
  suggestions: string[] | null
  score: 0 | 1 | 2 | 3 | 4 | null
  barWidth: number | null
  feedbackColor?: FeedbackColor | null
  text: ReactElement | null
  error: string | null
}

interface Props {
  passwordLongEnough: boolean
  passwordCheckLoading: boolean
  passwordData: PasswordFeedbackData | null
  apiErrors: any
}

export const passwordScoreBarWidth = (passwordScore: number) => {
  if (passwordScore === 0) return 1
  return (passwordScore / 4) * 100
}

export const passwordScoreText = (passwordScore: number) => {
  switch (passwordScore) {
    case 4:
      return 'COMMON_AUTH_REGISTER_PASSWORD_CHECK_4'
    case 3:
      return 'COMMON_AUTH_REGISTER_PASSWORD_CHECK_3'
    case 2:
      return 'COMMON_AUTH_REGISTER_PASSWORD_CHECK_2'
    case 1:
      return 'COMMON_AUTH_REGISTER_PASSWORD_CHECK_1'
    case 0:
    default:
      return 'COMMON_AUTH_REGISTER_PASSWORD_CHECK_0'
  }
}

export const passwordScoreColor = (passwordScore: number) => {
  switch (passwordScore) {
    case 4:
      return FeedbackColor['green']
    case 3:
      return FeedbackColor['green']
    case 2:
      return FeedbackColor['orange']
    case 1:
    case 0:
    default:
      return FeedbackColor['red']
  }
}

const PasswordFeedback: React.FC<Props> = ({
  passwordLongEnough,
  passwordCheckLoading,
  passwordData,
  apiErrors,
}) => {
  return (
    <PasswordFeedbackWrapper isLongEnough={passwordLongEnough}>
      {passwordCheckLoading && (
        <LineLoaderContainer>
          <StyledLineLoader isLoading={true} inline={true} />
        </LineLoaderContainer>
      )}
      {apiErrors?.password ? (
        <ErrorMessageDiv feedbackColor={FeedbackColor['red']}>
          {apiErrors?.password}
        </ErrorMessageDiv>
      ) : (
        <PasswordFeedbackContainer
          display={passwordLongEnough}
          visible={!passwordCheckLoading}
        >
          {passwordData?.text && passwordLongEnough && (
            <>
              <PasswordScoreText feedbackColor={passwordData?.feedbackColor}>
                {passwordData?.text}
              </PasswordScoreText>
            </>
          )}
        </PasswordFeedbackContainer>
      )}
    </PasswordFeedbackWrapper>
  )
}

export default PasswordFeedback

const ErrorMessageDiv = styled('div')<{ feedbackColor?: string | null }>`
  margin: 15px 0;
  position: relative;
  font-size: 12px;
  color: ${(p) => p.feedbackColor ?? p.theme.neutral08};
  line-height: 1.6;
  span {
    position: relative;
    left: -5px;
  }
`

const PasswordFeedbackContainer = styled('div')<{
  display?: boolean
  visible?: boolean
}>`
  display: ${(p) => (p.display ? 'inherit' : 'none')};
  visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};
`

const PasswordScoreText = styled('div')<{
  feedbackColor?: FeedbackColor | null
}>`
  text-align: left;
  display: flex;
  margin-bottom: 2px;
  font-size: 12px;
  color: ${(props) => props.feedbackColor ?? FeedbackColor['red']};
  b {
    font-weight: bold;
  }
`

const LineLoaderContainer = styled('div')`
  position: relative;
  width: 100%;
`

const StyledLineLoader = styled(LineLoader)`
  position: absolute;
`

const PasswordFeedbackWrapper = styled('div')<{
  isLongEnough: boolean | undefined
}>`
  text-align: left;
`
