import React from 'react'
import { HomeIcon, OfficeIcon } from '@ubnt/icons'

import { Console, SpecificIssue, UserType, ZDFormValueType } from './types'

export const umrSpecificIssue: ZDFormValueType[] = [
  { id: SpecificIssue.cantActivate, label: 'Cannot activate my device' },
  {
    id: SpecificIssue.appearsOffline,
    label: 'Device still appears offline after activation',
  },
  {
    id: SpecificIssue.wontTurnOn,
    label: "Device won't turn on or keep booting",
  },
  {
    id: SpecificIssue.carrierSpeedPerformance,
    label: 'Carrier speed or Performance (US only)',
  },
  { id: SpecificIssue.subscriptionIssue, label: 'Subscription issue' },
  {
    id: SpecificIssue.wifiSpeedPerformance,
    label: 'WiFi Speed or Performance',
  },
  { id: SpecificIssue.lcmIssue, label: 'LCM issue' },
  { id: SpecificIssue.other, label: 'Other' },
]

export const console: ZDFormValueType[] = [
  {
    id: Console.umr,
    label: 'UMR',
  },
  {
    id: Console.umrUnlisted,
    label: 'I have an offline UMR that I don’t see here',
  },
]

export const userType: ZDFormValueType[] = [
  {
    id: UserType.home,
    label: 'Home User',
    icon: <HomeIcon size="large" />,
  },
  {
    id: UserType.business,
    label: 'Business User',
    icon: <OfficeIcon size="large" />,
  },
]
