import React from 'react'
import { useIntl } from 'react-intl'

import { GenericCompletedMFAModal } from '../GenericCompletedMFAModal'

const PUSH_COMPLETED_MODAL_ID = 'PUSH_COMPLETED_MODAL_ID'

export const PushCompletedModal: React.FC = () => {
  const intl = useIntl()

  return (
    <GenericCompletedMFAModal
      modalId={PUSH_COMPLETED_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_PUSH_MODAL_TITLE' })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_PUSH_COMPLETED_DESCRIPTION',
      })}
    />
  )
}
