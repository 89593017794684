import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
} from 'features/support/constants'
import { object, string } from 'yup'
import { ProductLine } from './types'

export const UispFormValidationSchema = object({
  productLine: string().label('COMMON_LABEL_APPLICATION'),
  category: string().label('COMMON_LABEL_CATEGORY'),
  cloudHostname: string().when('productLine', {
    is: (productLine: ProductLine) => productLine === ProductLine.uispCloud,
    then: (schema) => schema.required().label('COMMON_LABEL_CLOUD_HOST_NAME'),
    otherwise: (schema) => schema.label('COMMON_LABEL_CLOUD_HOST_NAME')
  }),
  description: string()
    .max(DESCRIPTION_MAX_LENGTH)
    .min(DESCRIPTION_MIN_LENGTH)
    .required()
    .label('SETTINGS_SUPPORT_FORM_LABEL_DESCRIPTION'),
})
