import React, { useCallback, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Croppie from 'croppie'
import { CloseWithCircleIcon } from '@ubnt/icons'

import { RootState } from 'types/types'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import styled from 'theme/styled'

import {
  resetProfilePictureErrors,
  selectIsProfilePictureLoading,
  selectProfilePictureErrors,
  updateProfilePicture,
} from '../module/profilePicture'
import ProfilePictureDropzone from './ProfilePictureDropzone'
import ProfilePictureCrop from './ProfilePictureCrop'
import ProfilePicturePreview from './ProfilePicturePreview'
import ProfilePictureUpload from './ProfilePictureUpload'

enum Step {
  SELECT_IMAGE = 'SELECT_IMAGE',
  CROP = 'CROP',
  PREVIEW = 'PREVIEW',
  UPLOAD = 'UPLOAD',
}

interface Props extends ModalProps {
  handleSubmit?(file: File): void
  isLoading?: boolean
}

const ProfilePictureModal: React.FC<Props> = ({
  isOpen,
  onClose,
  handleSubmit,
  isLoading,
}) => {
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState(Step.SELECT_IMAGE)
  const [profilePicture, setProfilePicture] = useState<null | File>(null)
  const [croppie, setCroppie] = useState<null | Croppie>(null)
  const [croppedPicture, setCroppedPicture] = useState<null | Blob>(null)

  const profilePictureErrors = useSelector(selectProfilePictureErrors)
  const resetErrors = useSelector(resetProfilePictureErrors)

  const handleSelect = useCallback((file: File) => {
    setProfilePicture(file)
    setActiveStep(Step.CROP)
  }, [])

  const handleCrop = useCallback(async () => {
    if (!croppie) return
    const croppedPicture = await croppie.result({
      type: 'blob',
      size: 'viewport',
      circle: false,
    })

    setCroppedPicture(croppedPicture)
    setActiveStep(Step.PREVIEW)
  }, [croppie, setCroppedPicture])

  const handlePreview = useCallback(async () => {
    if (!croppie) return
    const file: any = croppedPicture as File
    file.name = profilePicture!.name
    file.lastModified = profilePicture!.lastModified

    if (handleSubmit) {
      handleSubmit(file)
      setActiveStep(Step.UPLOAD)
    }
  }, [croppie, handleSubmit, profilePicture, croppedPicture])

  let content: React.ReactNode | undefined
  if (typeof profilePictureErrors === 'string' && profilePictureErrors.length) {
    content = (
      <ErrorContainer>
        <StyledFailIcon />
        <FormattedMessage id="GENERIC_ERROR_MESSAGE_PLEASE_TRY_AGAIN" />
      </ErrorContainer>
    )
  } else if (activeStep === Step.SELECT_IMAGE) {
    content = <ProfilePictureDropzone handleFile={handleSelect} />
  } else if (activeStep === Step.CROP && profilePicture) {
    content = (
      <ProfilePictureCrop
        file={profilePicture}
        handleCroppieInit={setCroppie}
      />
    )
  } else if (activeStep === Step.PREVIEW && croppedPicture) {
    const image = URL.createObjectURL(croppedPicture)
    content = <ProfilePicturePreview croppedPicture={image} />
  } else if (activeStep === Step.UPLOAD) {
    content = (
      <ProfilePictureUpload
        onClose={onClose}
        fileName={profilePicture!.name}
        isUploadingPicture={isLoading}
      />
    )
  }

  const renderActions = () => {
    const isError =
      typeof profilePictureErrors === 'string' && profilePictureErrors.length
    if (!isError && !isLoading && activeStep !== Step.UPLOAD) {
      const text =
        activeStep === Step.CROP ? (
          <FormattedMessage id="COMMON_ACTION_CROP" />
        ) : (
          <FormattedMessage id="COMMON_ACTION_CONFIRM" />
        )
      const onClick = () => {
        if (activeStep === Step.CROP) {
          return handleCrop()
        } else if (activeStep === Step.PREVIEW) {
          return handlePreview()
        }
      }
      return [
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text,
          variant: 'primary',
          disabled: activeStep === Step.SELECT_IMAGE,
          onClick,
          type: 'button',
        },
      ]
    } else if (isError) {
      return [
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_TRY_AGAIN" />,
          variant: 'primary',
          disabled: activeStep === Step.SELECT_IMAGE,
          onClick: () => {
            dispatch(resetErrors)
            setActiveStep(Step.SELECT_IMAGE)
          },
          type: 'button',
        },
      ]
    } else {
      return [
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
        },
      ]
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={!isLoading ? onClose : undefined}
      size="small"
      title={<FormattedMessage id="SETTINGS_PICTURE_UPLOAD_MODAL_TITLE" />}
      actions={renderActions() as any}
      onAfterClose={() => dispatch(resetErrors)}
    >
      {content}
    </GenericModal>
  )
}

export const PROFILE_PICTURE_MODAL_ID = 'PROFILE_PICTURE_MODAL_ID'

const mapStateToProps = (state: RootState) => ({
  isLoading: selectIsProfilePictureLoading(state),
})

const mapDispatchToProps = {
  handleSubmit: updateProfilePicture,
}

const WrappedProfilePictureModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ handleSubmit, isLoading }) => (
  <ModalWrapper modalId={PROFILE_PICTURE_MODAL_ID}>
    <ProfilePictureModal handleSubmit={handleSubmit} isLoading={isLoading} />
  </ModalWrapper>
))

export default WrappedProfilePictureModal

const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.red06};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 25px 0;
`

const StyledFailIcon = styled(CloseWithCircleIcon)`
  margin: 15px;
  width: 25.6px;
  height: 25.6px;
  color: ${({ theme }) => theme.red06};
`
