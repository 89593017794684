import React, { Fragment, useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@ubnt/ui-components/Button/Button'
import { BasicToast, ToastContext } from '@ubnt/ui-components/Toast'
import { MultiSelectionIcon } from '@ubnt/icons'
import { useMotif } from '@ubnt/ui-components'
import axios, { isAxiosError } from 'axios'
import { typography } from 'theme'
import styled from 'theme/styled'
import { StatusPill } from 'components/RequestsStatusPill'
import { StyledLoader } from 'pages/request/styles'
import { FlexWrapper } from 'components/SharedComponents'
import { getFormattedTime, pendingClosure } from './utils'
import { RequestsContext } from './RequestsContext'
import { RequestsError } from './RequestsError'
import { EmptyResults } from './EmptyResults'
import { RequestsLoader } from './SkeletonLoaders/RequestsLoader'
import { ErrCode, ErrMessage, TicketStatus } from 'api/zendesk/types'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import { useClosedTicketsQuery } from 'store/queries/useClosedTicketsQuery'
import { useCCdTicketsQuery } from 'store/queries/useCCdTicketsQuery'

const {
  api: { accountBE },
} = __CONFIG__

export const RequestsGrid: React.FC = () => {
  const intl = useIntl()
  const {
    paginatedTickets,
    areTicketsLoading,
    hasMultiplePages,
    ticketsError,
    searchData,
    statusToShow,
  } = useContext(RequestsContext)
  const { openTicketsError, refetchOpenTickets } = useOpenTicketsQuery()
  const { refetchClosedTickets } = useClosedTicketsQuery()
  const { refetchCcdTickets } = useCCdTicketsQuery()
  const { createNotification } = useContext(ToastContext)
  const { motif } = useMotif()
  const isDarkTheme = motif === 'dark'
  const [isTicketSubmitLoading, setIsTicketSubmitLoading] = useState<
    string | null
  >(null)

  const createGenericErrorNotfication = useCallback(() => {
    createNotification(
      <BasicToast
        stateIndicator="danger"
        title={<FormattedMessage id="SUPPORT_SUBMIT_FAILED_TOAST_TITLE" />}
        details={<FormattedMessage id="REQUEST_COMMENT_ERROR" />}
      />
    )
  }, [createNotification])

  const handleMarkAsClosed = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
      e.preventDefault()
      setIsTicketSubmitLoading(id)
      const url = `${accountBE.base}/${accountBE.paths.zendeskMarkTicketAsClosed}/${id}`
      await axios
        .put(url, {}, { withCredentials: true })
        .then(() => {
          refetchOpenTickets()
          refetchClosedTickets()
          refetchCcdTickets()
          setIsTicketSubmitLoading(null)
        })
        .catch(() => {
          setIsTicketSubmitLoading(null)
          createGenericErrorNotfication()
        })
    },
    [
      createGenericErrorNotfication,
      refetchCcdTickets,
      refetchClosedTickets,
      refetchOpenTickets,
    ]
  )

  if (areTicketsLoading) {
    return <RequestsLoader />
  }

  if (
    ticketsError &&
    isAxiosError(openTicketsError) &&
    openTicketsError.response?.data.code !== ErrCode.NOT_FOUND &&
    openTicketsError.response?.data.message !==
      ErrMessage.USER_NOT_FOUND_IN_ZENDESK
  ) {
    return <RequestsError />
  }

  if (!!searchData.error || (!paginatedTickets.length && !areTicketsLoading)) {
    return <EmptyResults />
  }

  return (
    <Fragment>
      <TicketsGridRow>
        <ColumnTitle>
          <FormattedMessage id="SUPPORT_MY_REQUESTS_DESCRIPTION_AND_ID" />
        </ColumnTitle>
        <ColumnTitle className="date">
          <FormattedMessage id="SUPPORT_MY_REQUESTS_LATEST_ACTIVITY" />
        </ColumnTitle>
        <ColumnTitle className="date">
          <FormattedMessage id="SUPPORT_MY_REQUESTS_CREATED" />
        </ColumnTitle>
        <ColumnTitle>
          <FormattedMessage id="SUPPORT_MY_REQUESTS_STATUS" />
        </ColumnTitle>
      </TicketsGridRow>
      {paginatedTickets.map((ticket, i) => {
        return (
          <HoverDiv key={ticket.id}>
            <Link to={`/requests/${ticket.id}`}>
              <TicketsGridRow
                $omitBorder={
                  !hasMultiplePages && i === paginatedTickets.length - 1
                }
              >
                <TicketTitle>
                  <div title={ticket.description} className="title">
                    {statusToShow === 'ccd' && (
                      <FlexWrapper alignItems="center" gap={4}>
                        <MultiSelectionIcon variant="fill" />
                        <span className="cc">
                          <FormattedMessage id="SUPPORT_MY_REQUESTS_CC" />
                        </span>
                      </FlexWrapper>
                    )}
                    <span>{ticket.description}</span>
                  </div>
                  <div className="id">
                    #{ticket.id}
                    {ticket.is_pending && (
                      <span className="pending">
                        <FormattedMessage id="SUPPORT_MY_REQUESTS_AWAITING" />
                      </span>
                    )}
                    {ticket.status === TicketStatus.Answered && (
                      <Button
                        variant="inline"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                          handleMarkAsClosed(e, ticket.id)
                        }
                      >
                        <FormattedMessage id="REQUEST_CLOSE" />
                        {isTicketSubmitLoading === ticket.id && (
                          <StyledLoader variant="primary" type="spinner" />
                        )}
                      </Button>
                    )}
                  </div>
                  <div className="date-mobile">
                    {getFormattedTime(ticket.updated_at, intl, true)}
                  </div>
                </TicketTitle>
                <span className="date">
                  {getFormattedTime(ticket.updated_at, intl, true)}
                </span>
                <span className="date">
                  {getFormattedTime(ticket.created_at, intl, true)}
                </span>
                <StatusPill
                  $isDarkTheme={isDarkTheme}
                  className={ticket.status.toLowerCase()}
                >
                  {ticket.status !== TicketStatus.Answered
                    ? ticket.status
                    : pendingClosure}
                </StatusPill>
              </TicketsGridRow>
            </Link>
          </HoverDiv>
        )
      })}
    </Fragment>
  )
}

const ColumnTitle = styled.div`
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.text1};

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    &.date {
      display: none;
    }
  }
`

const TicketsGridRow = styled.div<{ $omitBorder?: boolean }>`
  width: 100%;
  max-width: 920px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, 1fr) 120px 120px 90px;
  column-gap: 16px;
  padding: 8px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.neutral03}`};

  ${({ $omitBorder }) =>
    $omitBorder &&
    `
      border-bottom: none;
  `}

  span.date {
    font: ${typography['desktop-body']};
    color: ${({ theme }) => theme.text3};
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    grid-template-columns: minmax(0, 1fr) 90px;

    span.date {
      display: none;
    }
  }
`

const TicketTitle = styled.div`
  display: flex;
  flex-direction: column;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};

  .date-mobile {
    display: none;
  }

  .title {
    display: flex;
    .cc {
      font: ${typography['desktop-typography-heading-small']};
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 4px;
      font: ${typography['desktop-typography-body']};
    }
  }

  .id {
    display: flex;
    gap: 8px;
    font: ${typography['desktop-caption']};
    color: ${({ theme }) => theme.text3};

    .pending {
      color: ${({ theme }) => theme.orange06};
      font-weight: 700;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    .date-mobile {
      display: flex;
      font: ${typography['desktop-caption']};
      color: ${({ theme }) => theme.text3};
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    .title {
      span {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
`

const HoverDiv = styled.div`
  div:hover {
    background-color: ${({ theme }) => theme.neutral01};
  }
`
