import { BrowserUtils } from './browserUtils'
import { useSessionStorage } from './useSessionStorage'

export const useAppView = () => {
  const [isAppView, setIsAppView] = useSessionStorage<boolean>('appview', false)

  const headerQuery = BrowserUtils.getQueryValue(
    window.location.toString(),
    'appview'
  )

  if (headerQuery === 'false' || headerQuery === 'true') {
    const newVal = headerQuery === 'true'
    if (newVal !== isAppView) {
      setIsAppView(newVal)
    }
  }

  return [isAppView, setIsAppView]
}
