import React, { useContext } from 'react'
import Dropdown from '@ubnt/ui-components/Dropdown/Dropdown'
import { DropdownOption } from '@ubnt/ui-components/Dropdown/types'

import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { SupportConsole } from 'features/support/unifi/types'

type Props = {
  handleChange: (value: DropdownOption) => void
  options: DropdownOption[]
  value?: SupportConsole | string
}

const getValue = (value: Props['value']) => {
  if (typeof value === 'string') {
    return value
  }

  return value?.mac || value?.id || '' // Undefined will not unselect. We need a string that does not match the id to unselect
}

export const FormDropdown: React.FC<Props> = ({
  handleChange,
  options,
  value,
}) => {
  const { isMaxMobileXLarge } = useContext(MediaSizingContext)

  return (
    <Dropdown
      value={getValue(value)}
      variant="secondary"
      searchable
      onChange={handleChange}
      options={options}
      width={isMaxMobileXLarge ? '100%' : '408px'}
    />
  )
}
