import { fetchRequestWithCookies, postRequestWithCookies } from './axios'

const {
  api: { accountBE },
} = __CONFIG__

export class PasskeyBannerApi {
  get = async () => {
    const url = new URL(accountBE.paths.showPasskeyPrompt, accountBE.base)
    const { data } = await fetchRequestWithCookies<{
      showPasskeyPrompt: boolean
    }>(url.href)
    return data
  }

  update = async () => {
    const url = new URL(`${accountBE.paths.showPasskeyPrompt}`, accountBE.base)
    await postRequestWithCookies(url.href, {})
  }
}
