import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'

interface TestCallbackArgs {
  label: string
  min: number
  max: number
}

Yup.addMethod(Yup.string, 'username', function () {
  const messageChars: any = (
    <FormattedMessage id="COMMON_AUTH_REGISTER_USERNAME_NOT_ALLOWED_CHARS" />
  )
  const messageUi: any = (
    <FormattedMessage id="COMMON_AUTH_REGISTER_USERNAME_NOT_ALLOWED_UI" />
  )
  const messageSpace: any = (
    <FormattedMessage id="COMMON_AUTH_REGISTER_USERNAME_NOT_ALLOWED_SPACE" />
  )

  return this.test('test-usernameChars', 'test error', function (value) {
    if (!value) return false
    const regexChars = new RegExp('[@{}()<>/+*\'";=%]+', 'i')
    const regexUi = new RegExp(
      '^UBNT|^U[ILł1|]-|^U[ILł1|]$|^UBIC_AUTH$|^ubiquiti',
      'i'
    )
    const regexSpace = /^\s|\s$/g

    if (regexChars.test(value))
      return this.createError({ message: messageChars })
    if (regexUi.test(value)) return this.createError({ message: messageUi })
    if (regexSpace.test(value))
      return this.createError({ message: messageSpace })
    return true
  })
})

Yup.addMethod(Yup.string, 'password', function () {
  const errorMessage: any = (
    <FormattedMessage id="COMMON_VALIDATION_PASSWORD_CHARS_ERROR" />
  )

  return this.test('test-passwordChars', '', function (value) {
    if (!value) return false
    const regexUppercase = /[A-Z]+/g
    const regexLowercase = /[a-z]+/g
    const regexDigits = /\d+/g
    const regexSymbols = /[\W_]+/g

    if (
      !regexUppercase.test(value) ||
      !regexLowercase.test(value) ||
      !regexDigits.test(value) ||
      !regexSymbols.test(value)
    ) {
      return this.createError({ message: errorMessage })
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'recaptcha', function () {
  return this.test('test-recaptcha', '', (value) => {
    return !value
  })
})

Yup.addMethod(Yup.string, 'emptyInput', function () {
  return this.test('test-emptyInput', 'empty input error', function (value) {
    const trimmedValue = value?.trim()
    const errorMessage: any = (
      <FormattedMessage id="LOGIN_MFA_FEEDBACK_ERROR_INVALID" />
    )
    if (!trimmedValue?.length) {
      return this.createError({ message: errorMessage })
    }
    return true
  })
})

Yup.setLocale({
  mixed: {
    required: ({ label }: TestCallbackArgs) => {
      const values = label ? { name: <FormattedMessage id={label} /> } : {}
      return <FormattedMessage id="COMMON_VALIDATION_EMPTY" values={values} />
    },
  },
  string: {
    email: ({ label }: TestCallbackArgs) => (
      <FormattedMessage
        id="COMMON_VALIDATION_EMAIL"
        values={{ name: <FormattedMessage id={label} /> }}
      />
    ),
    min: ({ min, label }: TestCallbackArgs) => (
      <FormattedMessage
        id="COMMON_VALIDATION_MIN_CHARS"
        values={{ name: <FormattedMessage id={label} />, min }}
      />
    ),
    max: ({ max, label }: TestCallbackArgs) => (
      <FormattedMessage
        id="COMMON_VALIDATION_MAX_CHARS"
        values={{ name: <FormattedMessage id={label} />, max }}
      />
    ),
  },
} as any) // type definitions expect each key to be mapped to string, but api actually supports function values

export default Yup
