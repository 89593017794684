import { useMutation, useQueryClient } from '@tanstack/react-query'
import { NcaBackupsApi } from 'api/nca/ncaBackups'
import { BackupsContext } from 'features/backups/BackupsContext'
import { useToasts } from 'features/backups/useToasts'
import { closeVisibleModal } from 'modules/modals'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { QueryKey } from 'store/types'

const ncaBackupsApi = new NcaBackupsApi()

export const useDeleteBackups = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { backupsDeleteSuccess } = useToasts()
  const { deletedBackups } = useContext(BackupsContext)

  const deleteBackups = useMutation({
    mutationFn: ncaBackupsApi.deleteMany,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.NCA_BACKUPS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.NCA_ARCHIVED_BACKUPS],
      })
      backupsDeleteSuccess(deletedBackups)
      dispatch(closeVisibleModal())
    },
  })

  return {
    deleteBackups: deleteBackups.mutate,
    areDeleteBackupsLoading: deleteBackups.isPending,
  }
}
