import React from 'react'
import { Text } from '@ubnt/ui-components/aria'
import styled from 'theme/styled'

type ChangeEmailHeaderProps = {
  title: React.ReactNode
  subtitle: React.ReactNode
}

export const ChangeEmailHeader: React.FC<ChangeEmailHeaderProps> = ({
  title,
  subtitle,
}) => {
  return (
    <Wrapper>
      <Text variant="heading-large">{title}</Text>
      <Text>{subtitle}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
