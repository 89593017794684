import { useEffect, useState } from 'react'
import { useMarkPasskeyPromptAsSeen } from 'store/mutations/account/useMarkPasskeyPromptAsSeen'
import { usePasskeyAvailableQuery } from 'store/queries/usePasskeyAvailableQuery'
import { useShouldDisplayPasskeyBannerQuery } from 'store/queries/useShouldDisplayPasskeyBannerQuery'

export const useShowPasskeyBanner = () => {
  const [displayBanner, setDisplayBanner] = useState(false)
  const { isPasskeyAvailable } = usePasskeyAvailableQuery()
  const { shouldDisplayBanner } = useShouldDisplayPasskeyBannerQuery()
  const { markPasskeyPromptAsSeen } = useMarkPasskeyPromptAsSeen()

  useEffect(() => {
    if (shouldDisplayBanner && isPasskeyAvailable) {
      setDisplayBanner(true)
    }
  }, [shouldDisplayBanner, isPasskeyAvailable])

  const handleCloseBanner = () => {
    markPasskeyPromptAsSeen()
    setDisplayBanner(false)
  }

  return { displayBanner, handleCloseBanner }
}
