import { SelfData } from 'types/types'
import { getFormattedRequesterName } from './getFormattedRequesterName'

export const getRequester = (profile: SelfData) => {
  const { email, first_name, last_name } = profile
  if (!email) throw new Error('No valid email exist for user')
  const requester = {
    email,
    name: getFormattedRequesterName(first_name, last_name),
  }
  return requester
}
