import { fetchRequestWithCookies } from './axios'

const {
  api: { accountBE },
} = __CONFIG__

export class LoginIssueFeedbackApi {
  public get = async (
    mfaId?: string | null
  ): Promise<{ isAllowed: boolean }> => {
    if (!mfaId) {
      return { isAllowed: false }
    }

    const url = new URL(
      `${accountBE.paths.loginIssueFeedback}/${mfaId}`,
      accountBE.base
    )

    const { data } = await fetchRequestWithCookies<any>(url.href)
    return data
  }
}
