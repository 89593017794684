import React, { useEffect, useRef } from 'react'

import { Bubble } from 'components/support/Bubble'
import { Description } from 'components/support/Description'

import { config } from './config/formConfig'
import { BubbleProps } from './config/types'

export const DescriptionBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldTouched,
  shouldScroll,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.productLine, values.category])

  const onChange = (value?: string) => {
    handleChange('description', value)
  }

  return (
    <Bubble question={config.description.fieldTitle} ref={ref}>
      <Description
        handleChange={onChange}
        value={values.description || ''}
        error={touched?.description && errors?.description}
        onBlur={() => setFieldTouched('description', true)}
      />
    </Bubble>
  )
}
