import React from 'react'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { FormattedMessage } from 'react-intl'

import {
  EmptyBackupContentWrapper,
  IconContainer,
  Text,
  TextWrap,
  UnifiLink,
} from './Backups.styles'

const {
  api: { unifi },
} = __CONFIG__

export const NoBackupsAvailable: React.FC<{
  deviceId: string
  hideRedirect: boolean
}> = ({ deviceId, hideRedirect }) => {
  return (
    <EmptyBackupContentWrapper>
      <IconContainer>
        <ExclamationMarkIcon variant="fill" />
      </IconContainer>

      <TextWrap>
        <Text>
          <FormattedMessage id="SETTINGS_BACKUPS_NO_BACKUPS_AVAILABLE" />
        </Text>
        {hideRedirect ? null : (
          <Text>
            <FormattedMessage
              id="SETTINGS_BACKUPS_ENABLE_BACKUPS"
              values={{
                link: (
                  <UnifiLink
                    href={`${unifi.base}/device/${deviceId}/console-settings`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="SETTINGS_BACKUPS_CONSOLE_SETTINGS" />
                  </UnifiLink>
                ),
              }}
            />
          </Text>
        )}
      </TextWrap>
    </EmptyBackupContentWrapper>
  )
}
