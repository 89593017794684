import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@ubnt/ui-components'
import { selectVisibleModal, setVisibleModal } from 'modules/modals'
import styled from 'theme/styled'
import { DELETE_ACCOUNT_MODAL } from 'features/delete-account/ui/DeleteAccountModal'

const DeleteAccountButton: React.FC = () => {
  const dispatch = useDispatch()
  const [loaderShown, setLoaderShown] = useState(false)
  const visibleModal = useSelector(selectVisibleModal)

  useEffect(() => {
    if (visibleModal.visibleModal === 'DELETE_ACCOUNT_LOADING_MODAL') {
      setLoaderShown(true)
    }
    if (loaderShown && !visibleModal.visibleModal) {
      setLoaderShown(false)
    }
  }, [loaderShown, visibleModal.visibleModal])

  const onClick = () => {
    dispatch(setVisibleModal(DELETE_ACCOUNT_MODAL))
  }

  return (
    <DeleteWrapper>
      <StyledButton variant="inline" size="medium" onClick={onClick}>
        <FormattedMessage id="SETTINGS_SECURITY_DELETE_MY_ACCOUNT" />
      </StyledButton>
    </DeleteWrapper>
  )
}

const DeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    flex-direction: column;
  }
`

export default DeleteAccountButton

const StyledButton = styled(Button)<{ isDisabled?: boolean }>`
  color: ${(p) => p.theme.red06};
  &:hover {
    color: rgb(240, 89, 94);
  }
`
