import React from 'react'
import CurrentCycle from 'features/subscriptions/ui/CurrentCycle'
import { InvoiceTable } from 'features/subscriptions/ui/InvoiceTable'
import ProtectedPage from 'pages/ProtectedPage'
import styled from 'theme/styled'
import AddPaymentMethodModal from 'features/payment-methods/ui/AddPaymentMethodModal'
import ChoosePaymentMethodModal from 'features/payment-methods/ui/ChoosePaymentMethodModal'
import TaxId from 'features/payment-methods/ui/tax/TaxId'
import PaymentCards from 'features/payment-methods/ui/cards/PaymentCards'
import AppViewHeader from 'components/AppViewHeader'
import { Loader } from '@ubnt/ui-components/Loader'
import { BrowserUtils } from 'utils/browserUtils'
import { useBillingInvoicesQuery } from 'store/queries/useBillingInvoicesQuery'
import { useBillingSubscriptionsQuery } from 'store/queries/useBillingSubscriptionsQuery'

const Subscriptions = () => {
  const { userSubscriptions, areSubscriptionsLoading } =
    useBillingSubscriptionsQuery()
  const { invoices, areInvoicesLoading } = useBillingInvoicesQuery()

  const isLoading = [areSubscriptionsLoading, areInvoicesLoading].some(Boolean)

  const areAnySubsAndInvoicesLoaded =
    userSubscriptions.length && invoices.length
  const invoiceParam = BrowserUtils.getLocationQueryValue('invoiceId')

  return (
    <>
      <ProtectedPage renderMargin={true}>
        <AppViewHeader headerId="COMMON_SIDE_NAV_SUBSCRIPTIONS" />
        <PaymentCards />
        <TaxId />
        {isLoading && (invoiceParam || !areAnySubsAndInvoicesLoaded) ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <Wrapper>
            <CurrentCycle />
            <InvoiceTable invoiceId={invoiceParam} />
          </Wrapper>
        )}
        <AddPaymentMethodModal />
        <ChoosePaymentMethodModal />
      </ProtectedPage>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

export default Subscriptions
