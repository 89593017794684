export enum FormType {
  UNIFI = 'UNIFI',
  UISP = 'UISP',
  UMR = 'UMR',
  STORE = 'STORE',
  BILLING = 'BILLING',
}

export enum Dropzone {
  UNIFI = 'unifi-dropzone',
  UISP = 'uisp-dropzone',
  STORE = 'store-dropzone',
  BILLING = 'billing-dropzone',
  UMR = 'umr-dropzone',
  REQUEST = 'request-dropzone',
}

export type FollowUpTicket = {
  followUpTicketId: string
  followUpTicketDesc: string
}
