import { StripeRegionCode } from 'features/stripe/ui/types'
import {
  GenericStripeTypeRegionResponse,
  GenericStripeTypeResponse,
} from './billingTypes'

export const mergeStripeData = <T>(
  state: GenericStripeTypeResponse<T>,
  extraData: GenericStripeTypeRegionResponse<T>,
  region: StripeRegionCode
) => {
  if (!extraData || !extraData[region]) {
    return state
  }

  return {
    ...state,
    [region]: {
      ...extraData[region],
      data: [...state[region].data, ...extraData[region].data],
    },
  }
}

export const getRegionsFromData = <T>(data: GenericStripeTypeResponse<T>) => {
  return Object.keys(data) as StripeRegionCode[]
}
