import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { UbiquitiLogo } from '@ubnt/icons'

import { formatUTCtoHumanReadable } from 'utils/dateTime'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { media, typography } from 'theme'
import styled from 'theme/styled'
import { FlexWrapper } from 'components/SharedComponents'
import { StripeStatus } from 'sharedConstants'

import { StatusTransitions } from './types'
import InvoiceIssuingInfo from './InvoiceIssuingInfo'
import { BusinessProfile } from './BusinessProfile'
import { UiTaxIds } from './UiTaxIds'

const createInvoiceStatusTexts = (
  statusTransitions: StatusTransitions,
  created: number
) => ({
  [StripeStatus.DRAFT]: (
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_INVOICE_STATUS_DRAFT"
      values={{ date: formatUTCtoHumanReadable(created) }}
    />
  ),
  [StripeStatus.OPEN]: (
    <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_STATUS_OPEN" />
  ),
  [StripeStatus.PAID]: statusTransitions.paid_at ? (
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_INVOICE_STATUS_PAID"
      values={{ date: formatUTCtoHumanReadable(statusTransitions.paid_at) }}
    />
  ) : null,
  [StripeStatus.VOID]: statusTransitions.voided_at ? (
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_INVOICE_STATUS_VOID"
      values={{ date: formatUTCtoHumanReadable(statusTransitions.voided_at) }}
    />
  ) : null,
  [StripeStatus.UNCOLLECTIBLE]: statusTransitions.marked_uncollectible_at ? (
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_INVOICE_STATUS_UNCOLLECTIBLE"
      values={{
        date: formatUTCtoHumanReadable(
          statusTransitions.marked_uncollectible_at
        ),
      }}
    />
  ) : null,
})

const InvoiceHeader: React.FC<{
  invoice: StripeInvoice
  siteName: string
}> = ({ invoice, siteName }) => {
  const invoiceDate = useMemo(() => {
    if (!invoice?.status_transitions.finalized_at) {
      return null
    }

    return formatUTCtoHumanReadable(invoice.status_transitions.finalized_at)
  }, [invoice.status_transitions])

  const statusText = useMemo(() => {
    if (!invoice.status) return null
    const content = createInvoiceStatusTexts(
      invoice.status_transitions,
      invoice.created
    )

    return content[invoice.status] ?? null
  }, [invoice])

  return (
    <Wrapper>
      <UbiquitiContainer>
        <FlexWrapper width={80}>
          <UbiquitiLogo size="full" variant="aqua" />
        </FlexWrapper>
        <div>
          <UbiquitiTitle>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_RECIEPT" />
          </UbiquitiTitle>
          <UbiquitiInfo>
            <BusinessProfile />
            <UiTaxIds />
          </UbiquitiInfo>
        </div>
      </UbiquitiContainer>

      <InfoWrapper>
        <InvoiceIssuingInfo invoice={invoice} siteName={siteName} />

        <InvoiceInfoContainer>
          <Description>
            <Title>
              <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_NUMBER" />
            </Title>
            <Text>{invoice.number}</Text>
          </Description>

          {invoiceDate && (
            <Description>
              <Title>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_DATE" />
              </Title>
              <Text>{invoiceDate}</Text>
            </Description>
          )}

          {statusText && (
            <Description>
              <Title className="align-left">
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_STATUS" />
              </Title>
              <Text>{statusText}</Text>
            </Description>
          )}
        </InvoiceInfoContainer>
      </InfoWrapper>
    </Wrapper>
  )
}

export default InvoiceHeader

const UbiquitiTitle = styled.h6`
  color: ${({ theme }) => theme.text1};
  font: ${typography['desktop-heading-xlarge-2']};
  margin: 0;
  text-align: right;
`

const Title = styled.p`
  margin: 0;
  text-align: left;
  width: 100%;
`

const UbiquitiContainer = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 20px;
`

const Description = styled.p`
  color: ${({ theme }) => theme.text2};
  display: flex;
  font: ${typography['desktop-body']};
  margin: 0;
  width: 100%;
`

const UbiquitiInfo = styled.div`
  color: ${({ theme }) => theme.text2};
  font: ${typography['desktop-body']};
  display: flex;
  flex-direction: column;
  text-align: right;
`

const Text = styled.span`
  color: ${({ theme }) => theme.text1};
  font: ${typography['desktop-body']};
  margin-left: 8px;
  min-width: fit-content;
  text-align: right;
  width: 100%;
`

const Wrapper = styled.header`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;

  @media (max-width: ${media.mobileXLarge}) {
    flex-direction: column;
  }
`

const InvoiceInfoContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
