import { deleteRequestWithSigner, fetchRequestWithSigner } from 'api/axios'
import { BackupItem, Backups, DeviceBackups, UcoreDevice } from './types'

const {
  api: { nca },
} = __CONFIG__

const DEFAULT_DEVICES_PER_FETCH = 30 as const
const MAX_ARCHIVED_BACKUPS_ITERATIONS = 10 as const

export class NcaBackupsApi {
  private parseBackups = (backups?: DeviceBackups[]) => {
    if (!backups) {
      return []
    }

    const parsedBackups = backups.map((backup) => {
      if (!backup.name) {
        backup.name = 'Unknown'
      }
      const timeStampArray = backup.backups.map((detail) => detail.time)
      backup.latestBackupTimestamp = timeStampArray.length
        ? Math.max(...timeStampArray)
        : 0
      return backup
    })
    return parsedBackups
  }

  private get = async (stringifiedIds: string) => {
    const searchParams = new URLSearchParams([
      ['deviceIds', stringifiedIds],
      ['sort', '-filename'],
      ['includeArchivedBackup', 'false'],
    ])

    const pathWithParams = `${nca.paths.deviceBackups}?${searchParams}`

    const res = await fetchRequestWithSigner(pathWithParams)

    const data: Backups = await res.json()

    return data
  }

  private getArchived = async () => {
    const res = await fetchRequestWithSigner(nca.paths.archivedDeviceBackups)

    const data: Backups = await res.json()

    return data
  }

  public getAllArchived = async () => {
    let nextPage = null
    const data: DeviceBackups[] = []
    let iterator = 0

    do {
      const { backups, nextToken } = await this.getArchived()
      iterator++
      nextPage = nextToken
      data.push(...backups)
    } while (nextPage && iterator < MAX_ARCHIVED_BACKUPS_ITERATIONS)

    return this.parseBackups(data)
  }

  public getAll = async (allDevices: UcoreDevice[]) => {
    // check if backups return null for next token always
    const deviceBackups: DeviceBackups[] = []
    let iterationNumber = 0
    while (deviceBackups.length < allDevices.length) {
      const devicesList = allDevices
        .slice(iterationNumber, iterationNumber + DEFAULT_DEVICES_PER_FETCH)
        .reduce((acc, value) => `${acc}${value.id},`, '')

      const { backups }: Backups = await this.get(devicesList)
      deviceBackups.push(...backups)

      iterationNumber += DEFAULT_DEVICES_PER_FETCH
    }
    // Archived backups should be outside the loop since we do not paginate them currently so
    // we don't want to fetch the same archived backups on every iteration of the wile loop
    // const archivedBackups = await fetchArchivedBackups()
    // data.backups.push(...archivedBackups)
    return this.parseBackups(deviceBackups)
  }

  public download = async () => {}

  private delete = async ({
    deviceId,
    key,
  }: {
    deviceId: string
    key: string
  }) => {
    await deleteRequestWithSigner(
      `${nca.paths.backup}/${deviceId}/${encodeURIComponent(key)}`
    )
  }

  public deleteMany = async ({ backups }: { backups: BackupItem[] }) => {
    await Promise.all(
      backups.map(
        async ({ deviceId, key }) => await this.delete({ deviceId, key })
      )
    )
  }
}
