import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TrustedDevicesApi } from 'api/trustedDevices'
import { QueryKey } from 'store/types'

const trustedDevicesApi = new TrustedDevicesApi()

export const useAddTrustedDevice = () => {
  const queryClient = useQueryClient()

  const addTrustedDevice = useMutation({
    mutationFn: trustedDevicesApi.create,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.TRUSTED_DEVICES],
      })
    },
  })

  return {
    addTrustedDevice: addTrustedDevice.mutate,
  }
}
