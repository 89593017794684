import prd from './prd'
import df from './df'
import stg from './stg'
import dev from './dev'

export enum Environment {
  Prod = 'prd',
  Df = 'df',
  Stg = 'stg',
  Dev = 'dev',
  Local = 'local',
}

export const CONFIG_BY_ENV = {
  [Environment.Prod]: prd,
  [Environment.Df]: df,
  [Environment.Stg]: stg,
  [Environment.Dev]: dev,
  [Environment.Local]: stg,
}
