export const typography = {
  'device-name': "700 14px/normal 'UI Sans', Lato, Arial, sans-serif",
  'tile-label': "400 14px/18px 'UI Sans', Lato, Arial, sans-serif",
  'tile-label-active': "700 14px/18px 'UI Sans', Lato, Arial, sans-serif",
  'support-header': "700 32px/36px 'UI Sans', Lato, Arial, sans-serif",
  'body-large': "400 14px/20px 'UI Sans', Lato, Arial, sans-serif",
  'header-label': "400 14px/normal 'UI Sans', Lato, Arial, sans-serif",
  'success-header': "700 24px/32px 'UI Sans', Lato, Arial, sans-serif",
  'mini-caption': "700 10px/16px 'UI Sans', Lato, Arial, sans-serif",
}
