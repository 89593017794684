import { PasswordRequirementState } from './PasswordRequirements'

export const PASSWORD_MIN_LENGTH = 12

export const checkPasswordRequirements = (
  password: string | undefined
): PasswordRequirementState | null => {
  const regexUppercase = /[A-Z]+/g
  const regexLowercase = /[a-z]+/g
  const regexDigits = /\d+/g
  const regexSymbols = /[\W_]+/g

  if (!password) {
    return null
  }
  return {
    length: password.length >= PASSWORD_MIN_LENGTH,
    uppercase: regexUppercase.test(password),
    lowercase: regexLowercase.test(password),
    digits: regexDigits.test(password),
    symbols: regexSymbols.test(password),
  }
}
