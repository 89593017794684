import { useQuery } from '@tanstack/react-query'
import { LegalApi } from 'api/legal'
import { QueryKey } from '../types'

const legalApi = new LegalApi()

export const useLegalQuery = () => {
  const legalInfo = useQuery({
    queryKey: [QueryKey.LEGAL],
    queryFn: legalApi.get,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return { legalInfo: legalInfo.data, isLegalInfoLoading: legalInfo.isLoading }
}
