import React from 'react'
import { ProductName } from 'types/enums'
import { TalkSubscriptionList } from './subscriptionTypes/talk/TalkSubscriptionList'
import { CloudConsoleSubscriptionList } from './subscriptionTypes/cloudConsole/CloudConsoleSubscriptionList'
import { UMRSubscriptionList } from './subscriptionTypes/mobileRouter/UMRSubscriptionList'
import { ULTESubscriptionList } from './subscriptionTypes/ULTE/ULTESubscriptionList'
import { SiteSupportSubscriptionList } from './subscriptionTypes/SiteSupport/SiteSupportSubscriptionList'
import { UispCloudConsoleSubscriptionList } from './subscriptionTypes/uispCloudConsole/UispCloudConsoleSubscriptionList'
import { EnhancedThreatUpdatesList } from './subscriptionTypes/enhancedThreatUpdates/EnhancedThreatUpdatesList'
import { FullSubscription } from '../fullSubscriptions/useFullSubscriptions'

interface Props {
  fullSubscriptions: FullSubscription[]
  subscriptionType: string
  mixedRegions: boolean
}

export const CurrentCycleGroupList: React.FC<Props> = ({
  subscriptionType,
  ...restProps
}: Props) => {
  if (!restProps.fullSubscriptions.length) return <></>

  switch (subscriptionType) {
    case ProductName.ULTE:
      return <ULTESubscriptionList {...restProps} />
    case ProductName.UNIFI_TALK:
      return <TalkSubscriptionList {...restProps} />
    case ProductName.HOSTING:
      return <CloudConsoleSubscriptionList {...restProps} />
    case ProductName.MOBILITY:
      return <UMRSubscriptionList {...restProps} />
    case ProductName.SITE_SUPPORT:
      return <SiteSupportSubscriptionList {...restProps} />
    case ProductName.UISP_CLOUD:
      return <UispCloudConsoleSubscriptionList {...restProps} />
    case ProductName.ENHANCED_TREAT_UPDATES:
      return <EnhancedThreatUpdatesList {...restProps} />
    default:
      return null
  }
}
