import React from 'react'

interface Props extends React.HTMLProps<HTMLFormElement> {
  children: React.ReactNode
}

export const ModalForm = ({ children, ...restProps }: Props) => {
  return (
    <form {...restProps}>
      {children}
      <input type="submit" style={{ position: 'absolute', left: '-9999px' }} />
    </form>
  )
}
