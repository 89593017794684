import React, { useMemo } from 'react'
import { RouterProps, withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'
import groupBy from 'lodash-es/groupBy'
import Accordion from '@ubnt/ui-components/Accordion/Accordion'
import { Text } from '@ubnt/ui-components/Text'

import styled from 'theme/styled'
import WrappedExternalCancelSubscriptionCloudConsoleModal from 'pages/subscriptions/ExternalCancelSubscriptionCloudConsoleModal'
import WrappedCancelSubscriptionTalkModal from 'pages/subscriptions/CancelSubscriptionTalkModal'
import WrappedCancelSubscriptionLTEModal from 'pages/subscriptions/CancelSubscriptionLTEModal'
import WrappedExternalCancelSubscriptionUMRModal from 'pages/subscriptions/ExternalCancelSubscriptionUMRModal'
import { Section, SectionHeader } from 'components/Section.styles'
import WrappedCancelSubscriptionSiteSupportModal from 'pages/subscriptions/CancelSubscriptionSiteSupportModal'
import { StyledEmptyResult } from 'components/SharedComponents'

import WrappedExternalCancelSubscriptionUispCloudConsoleModal from 'pages/subscriptions/ExternalCancelSubscriptionUispCloudConsoleModal'
import WrappedCancelSubscriptionEnhancedThreatUpdatesModal from 'pages/subscriptions/CancelSubscriptionEnhancedThreatUpdatesModal'

import { CurrentCycleGroupList } from './CurrentCycleGroupList'
import { CurrentCycleGroup } from './CurrentCycleGroup'
import {
  FullSubscription,
  useFullSubscriptions,
} from '../fullSubscriptions/useFullSubscriptions'

const CurrentCycle: React.FC<Readonly<RouterProps>> = () => {
  const { fullSubscriptionsToDisplay: fullSubscriptions } =
    useFullSubscriptions()

  const stripeRegions = fullSubscriptions
    .map((fullSubscription: FullSubscription) => fullSubscription.region)
    .filter((region, index, regions) => regions.indexOf(region) === index)

  const hasMultipleRegions = stripeRegions.length > 1

  const hasFailed = (group: string) =>
    !!fullSubscriptions.find(
      (fullSubscription) =>
        fullSubscription.isFailed && fullSubscription.name === group
    )
  const hasCardNotFound = (group: string) =>
    !!fullSubscriptions.find(
      (fullSubscription) =>
        fullSubscription.cardNotFound && fullSubscription.name === group
    )

  const groupedSubscriptions = groupBy(
    fullSubscriptions,
    (fullSubscription) => fullSubscription.name
  )

  const groups = useMemo(
    () => Object.keys(groupedSubscriptions),
    [groupedSubscriptions]
  )

  const containsCancelAtPeriodEnd = (
    activeSubscriptions: FullSubscription[]
  ): boolean => {
    return activeSubscriptions.some(
      ({ cancelAtPeriodEnd }) => !!cancelAtPeriodEnd
    )
  }

  return (
    <Section>
      {groups.length ? (
        <>
          <SectionHeader>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_HEADER" />
          </SectionHeader>
          <StyledAccordion
            variant="secondary"
            multiOpen
            contentContainerClassName="contentContainer"
            items={groups
              .sort((a, b) => a.localeCompare(b))
              .map((group, i) => {
                return {
                  id: i.toString(),
                  multiOpen: true,
                  openByDefault: hasCardNotFound(group) || hasFailed(group),
                  renderLabel: (itemExpanded, togglePanel) => (
                    <CurrentCycleGroup
                      itemExpanded={itemExpanded}
                      handleToggle={() => togglePanel(i.toString())}
                      isFailedSubscription={hasFailed(group)}
                      cardNotFound={hasCardNotFound(group)}
                      productName={group}
                      cancelAtPeriodEnd={containsCancelAtPeriodEnd(
                        groupedSubscriptions[group]
                      )}
                    />
                  ),
                  renderContent: () => (
                    <ContentWrapper>
                      <CurrentCycleGroupList
                        subscriptionType={group}
                        fullSubscriptions={groupedSubscriptions[group]}
                        mixedRegions={hasMultipleRegions}
                      />
                      {hasFailed(group) && (
                        <StyledText>
                          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FAILED_PAYMENT_INFO" />
                        </StyledText>
                      )}
                    </ContentWrapper>
                  ),
                }
              })}
          />
        </>
      ) : (
        <>
          <Container>
            <StyledEmptyResult
              description={
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_EMPTY_MESSAGE" />
              }
              size="large"
            />
          </Container>
        </>
      )}
      <WrappedCancelSubscriptionTalkModal />
      <WrappedCancelSubscriptionLTEModal />
      <WrappedExternalCancelSubscriptionCloudConsoleModal />
      <WrappedExternalCancelSubscriptionUispCloudConsoleModal />
      <WrappedExternalCancelSubscriptionUMRModal />
      <WrappedCancelSubscriptionSiteSupportModal />
      <WrappedCancelSubscriptionEnhancedThreatUpdatesModal />
    </Section>
  )
}

export default withRouter(CurrentCycle)

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 10px;
`

const StyledAccordion = styled(Accordion)`
  padding: 16px 16px 0 16px;
  margin-bottom: -8px;
  li {
    border-bottom: 1px solid ${(p) => p.theme.neutral03} !important;
    :last-of-type {
      border-bottom: none !important;
    }
  }
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    padding: 16px;
    li {
      padding: 16px 0;
      :first-of-type {
        padding-top: 0;
      }
      :last-of-type {
        padding-bottom: 0;
      }
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledText = styled(Text)`
  font-size: 14px;
  margin: 0;
  color: ${({ theme }) => theme.red06};
`
