import React, { ErrorInfo } from 'react'

import { Nullable } from 'types/types'

import { ErrorPageGeneric } from './ErrorPages'

interface BoundaryState {
  hasError: boolean
  error: Nullable<Error>
  errorInfo: Nullable<ErrorInfo>
  eventId: Nullable<string>
}

class GlobalErrorBoundry extends React.Component<
  { children: JSX.Element },
  BoundaryState
> {
  constructor(props: { children: JSX.Element }) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      eventId: null,
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPageGeneric />
    }

    return this.props.children
  }
}

export default GlobalErrorBoundry
