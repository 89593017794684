import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { selectProfileData } from 'modules/profile'
import { getProfileNameToDisplay } from 'utils/getProfileNameToDisplay'

import {
  ProfileWrapper,
  StyledAvatar,
  Subtitle,
  TextContainer,
  Title,
} from './ProfileCard.styles'

export const ProfileCard = () => {
  const profile = useSelector(selectProfileData)

  const displayName = getProfileNameToDisplay(profile)

  return (
    <ProfileWrapper>
      {profile && (
        <Fragment>
          <StyledAvatar isClickable={false} />
          <TextContainer>
            <Title data-testid="displayName" title={displayName}>
              {displayName}
            </Title>
            <Subtitle>{profile?.email || ''}</Subtitle>
          </TextContainer>
        </Fragment>
      )}
    </ProfileWrapper>
  )
}
