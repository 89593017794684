import type {
  FormSections,
  SupportFormState,
} from 'features/support/unifi/types'

export enum Assistance {
  setupAdopt = 'acc-ass-setup_adopt',
  hwFailure = 'acc-ass-hw_failure',
  adminUpdates = 'acc-ass-admin_updates',
  performOperation = 'acc-ass-perform_operation',
  featureConfig = 'acc-ass-feature_config',
}

export enum Console {
  other = 'uosfrm_cons_other',
  cloudGatewayUnlisted = 'uosfrm_cons_cloud_gateway_unlisted',
  cloudKeyUnlisted = 'uosfrm_cons_uck_unlisted',
  unvrUnlisted = 'uosfrm_cons_unvr_unlisted',
  cloudUnlisted = 'uosfrm_cons_cloud_unlisted',
  selfHosted = 'uosfrm_cons_no-console',
  standalone = 'cons_standalone__independent',
  unifiPlayDevice = 'uosfrm_cons_unifi_playdevice',
  unifiNas = 'uosfrm_cons_unas_unlisted',
}

export enum Device {
  gatewayConsole = 'acc-setup_gtwy_cnsl',
  ap = 'acc-setup_ap',
  switch = 'acc-setup_switch',
  camera = 'acc-setup_camera',
  talkDevice = 'acc-setup_talkdevice',
  accessDevice = 'acc-setup_accessdevice',
  connectDevice = 'acc-setup_connectdevice',
  playDevice = 'acc-setup_playdevice',
}

export enum Model {
  cloudGateway = 'acc_setup_cloud_gtwy',
  cloudKey = 'acc_setup_cloud_key',
  officialCloudHosting = 'acc_setup_cloud_hosting',
  networkVideoRecorder = 'acc_setup_nvrecorder',
  selfHostedNetServer = 'acc_setup_unifi_server',
  standaloneAp = 'acc_setup_standalone_ap',
  unifiPlayDevice = 'acc_setup_unifi_playdevice',
  unifiNas = 'acc_setup_nas',
}

export enum Tech {
  wifi = 'accfrom_wifi',
  routingAndSwitching = 'accfrom_r/s',
  securityAndVPN = 'accfrom_sec/vpn',
  protect = 'accfrom_protect',
  talk = 'accfrom_talk',
  access = 'accfrom_access',
  identity = 'accfrom_uid',
  connect = 'accfrom_connect',
  drive = 'accfrom_drive',
  other = 'other',
}

export enum Trouble {
  firstTimeSetup = 'acc-trbl-1st_time_setup',
  deviceAdoption = 'device-adoption',
  noPowerOn = 'acc-no-poweron',
  crashing = 'acc-crashing',
  performance = 'acc-performance',
  appNoStart = 'acc-app-nostart',
  updates = 'acc-updates',
  noSignIn = 'acc-no-signin',
  adminUsersPermissions = 'acc-admin-user-perm',
}

export enum App {
  network = 'accform_network',
  protect = 'accfrom_protect',
  talk = 'accfrom_talk',
  access = 'accfrom_access',
  identity = 'accfrom_uid',
  connect = 'accfrom_connect',
  drive = 'accfrom_drive',
  other = 'other',
}

export enum AdoptionTrouble {
  deviceNoShow = 'acc-newdevice-noshow',
  adoptionFailed = 'acc-adoption-failed',
}

export enum UserType {
  home = 'home_user',
  business = 'business_user',
}

export enum TalkVip {
  vip = 'acc_talkvip_yes',
  regular = 'acc_talkvip_no',
}

export enum UniFiStateValues {
  ASSISTANCE = 'assistance',
  TROUBLE = 'trouble',
  ADOPTION_TROUBLE = 'adoptionTrouble',
  CONSOLE = 'console',
  DEVICE = 'device',
  MODEL = 'model',
  TECH = 'tech',
  APP = 'app',
  TALK_VIP = 'talkVip',
  USER_TYPE = 'userType',
  DESCRIPTION = 'description',
  SUPPORT_FILE = 'supportFile',
}

type FormState = keyof SupportFormState

export interface ZDFormValueType {
  id: string
  label: string
  icon?: JSX.Element
  activeIcon?: JSX.Element
  condition?: { id: string; field: FormState }
  extraConditions?: { id: string[]; field: FormState }
  hasFooter?: boolean
}

export type NextSection = {
  section: FormSections
  condition?: { field: string; value: any }
}

type UserConsoleData = {
  mac?: string
  version?: string
  fwVersion?: string
  releaseChannel?: string
  appVersion?: string
  appVersions?: string
  apps?: string[]
  totalDevices?: string
  totalConsoles?: string
}

type ConfigElement = {
  fieldId: number
  fieldTitle: string | null
  options: ZDFormValueType[] | null
  nextSection: NextSection[] | null
  hide?: boolean
}

type AllData = SupportFormState &
  Omit<UserConsoleData, 'apps'> & {
    attachments?: string
    supportFile?: string
    sentryUserId?: string
    sentryEventId?: string
    rawData?: string
    info?: string
  }

export type FormConfig = Record<keyof AllData, ConfigElement>
