import React from 'react'

export const UniFiLogoIcon: React.FC<{ fill?: string }> = ({
  fill = 'white',
}) => {
  return (
    <svg
      width="42"
      height="14"
      viewBox="0 0 42 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5798 2.48147C24.5798 3.26588 25.2312 3.91649 26.0348 3.91649C26.8192 3.91649 27.4698 3.28499 27.4698 2.48147C27.4698 1.67796 26.8391 1.02734 26.0348 1.02734C25.2304 1.02734 24.5798 1.69707 24.5798 2.48147Z"
        fill={fill}
      />
      <path
        d="M37.6232 2.48147C37.6232 3.26588 38.2746 3.91649 39.0781 3.91649C39.8625 3.91649 40.5131 3.28499 40.5131 2.48147C40.5131 1.67796 39.8824 1.02734 39.0781 1.02734C38.2738 1.02734 37.6232 1.69707 37.6232 2.48147Z"
        fill={fill}
      />
      <path d="M27.2763 4.92705H24.7741V12.9232H27.2763V4.92705Z" fill={fill} />
      <path
        d="M36.4526 1.38092H29.3683V12.9232H31.8704V8.56317H35.7255V6.38118H31.8704V3.56291H36.4526V1.38092Z"
        fill={fill}
      />
      <path d="M40.3204 4.92705H37.8183V12.9232H40.3204V4.92705Z" fill={fill} />
      <path
        d="M10.2114 6.38118V4.92466H11.6647V6.3788H13.118V6.83908C13.118 7.37184 13.0726 8.00255 12.9683 8.49867C12.9102 8.7758 12.8218 9.05133 12.7182 9.31572C12.6123 9.58568 12.4897 9.8445 12.3567 10.077C12.1831 10.3796 11.9816 10.6711 11.7483 10.9443L11.7208 10.9767L11.7199 10.9778L11.7193 10.9785L11.7191 10.9787L11.7189 10.979C11.6622 11.046 11.6061 11.1123 11.5436 11.1768C11.4728 11.2532 11.3987 11.3249 11.3238 11.3966C10.3937 12.2925 9.18088 12.8515 7.91388 12.9805C7.76179 12.9964 7.45519 13.0124 7.30229 13.0124C7.14938 13.0116 6.8428 12.9956 6.69071 12.9805C5.42291 12.8515 4.21087 12.2925 3.28074 11.3966L3.2794 11.3953C3.205 11.3232 3.13139 11.252 3.06095 11.1768C2.99518 11.1081 2.93532 11.0375 2.87479 10.9661L2.85628 10.9443C2.62296 10.6711 2.42148 10.3804 2.24788 10.077C2.11488 9.84529 1.99226 9.58648 1.88634 9.31572C1.78281 9.05133 1.69443 8.7758 1.63628 8.49867C1.53197 8.00255 1.48657 7.37104 1.48657 6.83908V1.38092H4.39324V6.38118C4.39324 6.38118 4.39324 6.76423 4.39802 6.89005C4.40438 7.06286 4.41075 7.23089 4.42827 7.39891C4.47606 7.84805 4.5756 8.2741 4.78027 8.63405C4.8392 8.73837 4.89972 8.83951 4.97139 8.93507C5.40858 9.51799 6.07431 9.95598 6.90412 10.0778C7.00287 10.0922 7.20275 10.1049 7.30229 10.1049C7.40263 10.1049 7.60172 10.0922 7.70047 10.0778C8.53025 9.95598 9.19601 9.51799 9.6332 8.93507C9.70566 8.83871 9.76539 8.73837 9.82432 8.63405C10.029 8.2733 10.1285 7.84805 10.1763 7.39891C10.1937 7.23936 10.1996 7.07908 10.2056 6.91603V6.91542L10.2066 6.89005C10.2114 6.76423 10.2114 6.38118 10.2114 6.38118Z"
        fill={fill}
      />
      <path d="M11.6647 3.47054H13.118V4.92466H11.6647V3.47054Z" fill={fill} />
      <path d="M11.6647 3.47054H10.5745V2.38034H11.6647V3.47054Z" fill={fill} />
      <path d="M12.0278 1.29014H12.7549V2.0172H12.0278V1.29014Z" fill={fill} />
      <path
        d="M22.6821 12.9232H20.1968V8.22234C20.1968 8.22234 20.1967 8.05749 20.1943 8.00334C20.192 7.92928 20.1896 7.85681 20.1816 7.78435C20.1609 7.59083 20.1187 7.40847 20.0303 7.25318C20.0048 7.20859 19.9793 7.16558 19.9483 7.12417C19.7603 6.87332 19.4745 6.68539 19.1177 6.63283C19.0747 6.62646 18.9895 6.62088 18.9465 6.62088C18.9035 6.62088 18.8175 6.62646 18.7753 6.63283C18.4185 6.68539 18.1326 6.87332 17.9447 7.12417C17.9136 7.16479 17.8881 7.20859 17.8627 7.25318C17.7743 7.40847 17.732 7.59163 17.7114 7.78435C17.7034 7.85681 17.701 7.92928 17.6986 8.00334C17.6962 8.05749 17.6962 8.22234 17.6962 8.22234V12.9144H15.2108V4.91829H17.6054V5.64536C17.6054 5.64536 18.4583 4.58383 20.1593 4.65868C21.6883 4.72637 22.6821 5.89939 22.6821 7.49368V12.9232Z"
        fill={fill}
      />
    </svg>
  )
}
