import { StripeRegionCode } from './ui/types'

export const regionsWithMultipleCountries = [
  StripeRegionCode.EU,
  StripeRegionCode.ROW,
  StripeRegionCode.US,
  StripeRegionCode.AU,
  StripeRegionCode.GB,
]

export const regionsThatAllowDifferentCardCountries = [
  StripeRegionCode.EU,
  StripeRegionCode.US,
  StripeRegionCode.AU,
  StripeRegionCode.GB,
]

export const regionsWithTaxIdSupport = [
  StripeRegionCode.EU,
  StripeRegionCode.GB,
]
