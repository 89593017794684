import React from 'react'
import styled from 'theme/styled'
import { MotifPaletteType } from 'theme/UICPalette'

interface Props {
  children: React.ReactNode
}

interface ExtraProps {
  className?: string
  margin?: string
  visibility?: string
  cursor?: string
  color?: keyof MotifPaletteType
  padding?: string
}

export const IconWrapper: React.FC<Props & ExtraProps> = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <Wrapper className={className} {...restProps}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<ExtraProps>`
  margin: ${({ margin }) => margin ?? 0};
  visibility: ${({ visibility }) => visibility ?? 'visible'};
  cursor: ${({ cursor }) => cursor ?? 'default'};
  padding: ${({ padding }) => padding ?? 0};
  color: ${({ color }) => color ?? 'unset'};
`
