import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  InvoicesTableGrid,
  TableHeaderWrapper,
  TitleWrap,
} from './InvoiceTable.styles'

const COLUMNS = [
  { id: 'SETTINGS_SUBSCRIPTIONS_TABLE_COL_SUBSCRIPTION' },
  { id: 'SETTINGS_SUBSCRIPTIONS_TABLE_COL_NAME', className: 'name-column' },
  {
    id: 'SETTINGS_SUBSCRIPTIONS_TABLE_COL_BILLING_DATE',
    className: 'date-column',
  },
  {
    id: 'SETTINGS_SUBSCRIPTIONS_TABLE_COL_BILLED_TO',
    className: 'billed-column',
  },
  { id: 'SETTINGS_SUBSCRIPTIONS_TABLE_COL_STATUS' },
  { id: 'SETTINGS_SUBSCRIPTIONS_TABLE_COL_AMOUNT' },
  { id: 'SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_ACTION' },
]

export const InvoiceTableHeader = () => {
  return (
    <TableHeaderWrapper>
      <InvoicesTableGrid>
        {COLUMNS.map(({ id, className }) => (
          <TitleWrap key={id} className={className ?? ''}>
            <FormattedMessage id={id} />
          </TitleWrap>
        ))}
      </InvoicesTableGrid>
    </TableHeaderWrapper>
  )
}
