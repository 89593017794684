import React, { PropsWithChildren } from 'react'
import { useAppView } from 'utils/useAppView'

type Props = PropsWithChildren<{
  href: string
}>

const ExternalLink: React.FC<Props> = ({ href, children }) => {
  const [isAppView] = useAppView()

  const normalLinkBehaviour = {
    target: '_blank',
    rel: 'noreferrer',
  }

  const appViewLinkBehaviour = {
    target: '_self',
  }

  const linkBehaviour = isAppView ? appViewLinkBehaviour : normalLinkBehaviour

  return (
    <a href={href} {...linkBehaviour}>
      {children}
    </a>
  )
}

export default ExternalLink
