export enum QuickSupportQueryKey {
  Title = 'title',
  Description = 'description',
  Platform = 'platform',
  Link = 'link',
}

export interface IQuickSupportModel {
  title: string
  description: string
  platform: PlatformType
  link: string
}

export enum PlatformType {
  Reddit = 'reddit',
  Facebook = 'facebook',
  X = 'x',
  UICommunity = 'ui_community',
}

export interface IQuickSupportContentProps {
  onSubmit: () => void
  data: IQuickSupportModel
  isLoading: boolean
}
