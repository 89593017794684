import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ClassNames } from '@emotion/react'
import { Popover } from '@ubnt/ui-components'
import {
  ArrowDownPrimaryIcon,
  ArrowUpPrimaryIcon,
  CheckIconL,
} from '@ubnt/icons'
import styled from 'theme/styled'
import {
  getLanguageOptions,
  LocaleContext,
  SupportedLanguages,
} from 'modules/LocaleContext'
import { IconWrapper } from './IconWrapper'

export const LanguageSelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { language, selectLanguage } = useContext(LocaleContext)
  const languageOptions = getLanguageOptions()

  useEffect(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <ClassNames>
      {({ css }) => (
        <StyledLanguageSelector
          onChange={() => setIsOpen(isOpen ? false : true)}
          renderToggle={() => (
            <span>
              <FormattedMessage id="COMMON_LABEL_LANGUAGE" />:{' '}
              <LanguageAbrv>{language}</LanguageAbrv>{' '}
              {isOpen ? (
                <ArrowUpPrimaryIcon width={10} height={10} />
              ) : (
                <ArrowDownPrimaryIcon width={10} height={10} />
              )}
            </span>
          )}
          classNameCard={css`
            padding: 0;
            width: 70px;
            .is-active .checkIcon {
              visibility: visible;
            }
          `}
          align="topLeft"
        >
          {languageOptions.map(({ value, label }) => (
            <Option
              onClick={() => selectLanguage?.(value as SupportedLanguages)}
              key={value}
              className={language === value ? 'is-active' : ''}
            >
              <IconWrapper visibility="hidden" className="checkIcon">
                <CheckIconL />
              </IconWrapper>
              {label}
            </Option>
          ))}
        </StyledLanguageSelector>
      )}
    </ClassNames>
  )
}

const StyledLanguageSelector = styled(Popover)`
  cursor: pointer;
  float: right;
  font-size: 12px;
  color: #000000;
  hover {
    color: #000000d9;
  }
`

const Option = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :hover {
    background: ${({ theme }) => theme.neutral02};
  }
  :first-child {
    padding-top: 10px;
  }
  :last-child {
    padding-bottom: 10px;
  }
`

const LanguageAbrv = styled.span`
  text-transform: uppercase;
`
