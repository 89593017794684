import React, { Fragment, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import {
  CellColumnLabel,
  ChargeLabelCell,
  Container,
  LabelCell,
  Row,
} from '../Styles'
import { SubscriptionItem } from './SubscriptionItem'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'

interface Props {
  fullSubscriptions: FullSubscription[]
  mixedRegions: boolean
}

export const UispCloudConsoleSubscriptionList: React.FC<Readonly<Props>> = ({
  fullSubscriptions,
  mixedRegions,
}) => {
  const { isMaxXSmall } = useContext(MediaSizingContext)

  if (!fullSubscriptions.length) return null

  const shouldRenderSubscriptionItems = (fullSub: FullSubscription) =>
    fullSub.items.data.length > 1

  return (
    <Container>
      <Row>
        <CellColumnLabel $mixedRegions={mixedRegions}>
          {mixedRegions && <LabelCell />}
          <LabelCell>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_PLAN" />
          </LabelCell>
          {!isMaxXSmall && (
            <>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_QUANTITY" />
              </LabelCell>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_BILLING_DATE" />
              </LabelCell>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_PAYMENT_METHOD" />
              </LabelCell>
            </>
          )}
          <ChargeLabelCell>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_CHARGE" />
          </ChargeLabelCell>
        </CellColumnLabel>
      </Row>
      {fullSubscriptions.map(
        (fullSubscription: FullSubscription, fullSubIndex: number) => {
          if (shouldRenderSubscriptionItems(fullSubscription)) {
            return (
              <Fragment key={fullSubscription.id}>
                {fullSubscription.items.data.map((item, itemIndex) => (
                  <SubscriptionItem
                    key={fullSubIndex + itemIndex}
                    item={item}
                    mixedRegions={mixedRegions}
                    fullSubscription={fullSubscription}
                    cardId={fullSubIndex + itemIndex}
                  />
                ))}
              </Fragment>
            )
          }

          return (
            <SubscriptionItem
              key={fullSubIndex}
              mixedRegions={mixedRegions}
              fullSubscription={fullSubscription}
              cardId={fullSubIndex}
            />
          )
        }
      )}
    </Container>
  )
}
