import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import styled from 'theme/styled'
import { ModalProps } from 'components/ModalWrapper'
import { closeVisibleModal } from 'modules/modals'

import { GenericMFAModal } from '../GenericMFAModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  svg {
    width: 30px;
    height: 30px;
  }
`

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(p) => p.theme.neutral08};
  margin: 16px 0;
`

export const PASSKEY_NO_BROWSER_SUPPORT_MODAL_ID =
  'PASSKEY_NO_BROWSER_SUPPORT_MODAL_ID'

interface Props extends ModalProps {
  isLoading?: boolean
}
export const PasskeyNoBrowserSupportModal: React.FC<Props> = ({
  isLoading,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return (
    <GenericMFAModal
      modalId={PASSKEY_NO_BROWSER_SUPPORT_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_MODAL_TITLE',
      })}
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CLOSE' }),
          variant: 'secondary',
          disabled: isLoading,
          onClick: () => dispatch(closeVisibleModal()),
        },
      ]}
      isLoading={isLoading}
    >
      <Wrapper>
        <Description>
          {intl.formatMessage({
            id: 'SETTINGS_MFA_PASSKEY_ERROR_NO_BROWSER_SUPPORT',
          })}
        </Description>
      </Wrapper>
    </GenericMFAModal>
  )
}
