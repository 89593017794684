import React, { useState } from 'react'
import styled from 'theme/styled'
import { ShowOtherButton } from 'components/showOtherButton/ShowOtherButton'
import { HideOptions } from 'components/hideOptions/HideOptions'

import { useGetMFAOptions } from 'features/auth/ui/useGetMFAOptions'

export const MFAOptions: React.FC = () => {
  const [showOtherMethods, setShowOtherMethods] = useState(false)

  const mfaOptions = useGetMFAOptions()

  return (
    <Wrapper>
      {mfaOptions.featured}
      <HideOptions isShown={showOtherMethods}>{mfaOptions.other}</HideOptions>
      <ButtonWrapper>
        <ShowOtherButton
          handleClick={() => setShowOtherMethods(!showOtherMethods)}
          isOpen={showOtherMethods}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`

const ButtonWrapper = styled.div`
  text-align: left;
  margin-left: 10px;
`
