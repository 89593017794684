import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import isMobile from 'is-mobile'

import styled from 'theme/styled'

interface Props {
  handleFile?(file: File): void
}

const ProfilePictureDropzone: React.FC<Props> = ({ handleFile }) => {
  const isMobileDevice = isMobile()
  const [fileDialogOpened, setFileDialogOpened] = useState(false)

  const onDrop = useCallback(
    (files: File[]) => {
      handleFile && files.length && handleFile(files[0])
    },
    [handleFile]
  )
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg', '.gif'],
    },
  })

  useEffect(() => {
    if (isMobileDevice && !fileDialogOpened) {
      setFileDialogOpened(true)
      open()
    }
  }, [fileDialogOpened, isMobileDevice, open])

  return (
    <Container {...getRootProps()} isDragActive={isDragActive}>
      <input {...getInputProps()} />
      <Text>
        {isMobileDevice ? (
          <FormattedMessage id="SETTINGS_PICTURE_UPLOAD_MODAL_DROPZONE_INFO_MOBILE" />
        ) : (
          <>
            <FormattedMessage id="SETTINGS_PICTURE_UPLOAD_MODAL_DROPZONE_INFO" />
            <span>
              <FormattedMessage id="SETTINGS_PICTURE_UPLOAD_MODAL_DROPZONE_INFO_LINK" />
            </span>
          </>
        )}
      </Text>
    </Container>
  )
}

export default ProfilePictureDropzone

interface DropzoneProps {
  isDragActive?: boolean
}

const Container = styled.div<DropzoneProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 12px 20px;
  border: 1px dashed ${(p) => p.theme.neutral08};
  border-radius: 4px;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  ${({ isDragActive, theme }) =>
    isDragActive && `background-color: ${theme.neutral03};`}
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  line-height: 21px;
  color: ${({ theme }) => theme.text2};

  span {
    color: ${({ theme }) => theme.ublue06};
  }
`
