import React, { useEffect } from 'react'
import ModalWrapper, { ModalProps } from './ModalWrapper'
import { GenericModal } from './generic-modal/GenericModal'
import { Text } from '@ubnt/ui-components/aria'
import styled from 'theme/styled'
import { useIntl } from 'react-intl'
import { redirectAfterSpecifiedTime } from 'utils/redirectIfIpIsBlacklisted'

type BlacklistedIpInfoModalProps = ModalProps & {
  country?: string
}

const BlacklistedIpInfoModal: React.FC<BlacklistedIpInfoModalProps> = ({
  isOpen,
  country,
}) => {
  const intl = useIntl()

  useEffect(() => {
    const timerId = redirectAfterSpecifiedTime()

    return () => clearTimeout(timerId)
  }, [])

  return (
    <GenericModal
      isOpen={isOpen}
      footer={<></>}
      header={<></>}
      size="small"
      overrideFullScreen
    >
      <TextWrapper>
        <Text>
          {intl.formatMessage(
            { id: 'SUPPORT_BLACKLISTED_IP_COUNTRY_TEXT' },
            { country: <b>{country}</b> }
          )}
        </Text>
        <Text>
          {intl.formatMessage({ id: 'SUPPORT_BLACKLISTED_IP_EXPLANATION' })}
        </Text>
        <Text> {intl.formatMessage({ id: 'SUPPORT_BLACKLISTED_IP_CTA' })}</Text>
      </TextWrapper>
    </GenericModal>
  )
}

export const BLACKLISTED_IP_INFO_MODAL = 'BLACKLISTED_IP_INFO_MODAL'

export const WrappedBlacklistedIpInfoModal = () => (
  <ModalWrapper modalId={BLACKLISTED_IP_INFO_MODAL}>
    <BlacklistedIpInfoModal />
  </ModalWrapper>
)

const TextWrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
