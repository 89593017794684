import { useMemo } from 'react'
import { HeaderTab, SearchData } from 'api/zendesk/types'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import { useClosedTicketsQuery } from 'store/queries/useClosedTicketsQuery'
import { useCCdTicketsQuery } from 'store/queries/useCCdTicketsQuery'

export const useGetRequests = (
  statusToShow: HeaderTab,
  searchData: SearchData
) => {
  const {
    openTickets,
    areOpenTicketsFetched,
    hasMoreOpenTickets,
    openTicketsError,
    totalOpenTickets,
    areOpenTicketsLoading,
  } = useOpenTicketsQuery()

  const {
    closedTickets,
    areClosedTicketsFetched,
    hasMoreClosedTickets,
    closedTicketsError,
    totalClosedTickets,
    areClosedTicketsLoading,
  } = useClosedTicketsQuery()

  const {
    ccdTickets,
    areCcdTicketsFetched,
    ccdTicketsError,
    totalCcdTickets,
    areCcdTicketsLoading,
  } = useCCdTicketsQuery()

  const allTickets = useMemo(() => {
    if (searchData.results.length > 0 || searchData.error) {
      return searchData.results
    }
    if (statusToShow === 'open') {
      return openTickets
    }
    if (statusToShow === 'closed') {
      return closedTickets
    }
    return ccdTickets
  }, [
    statusToShow,
    ccdTickets,
    closedTickets,
    openTickets,
    searchData.error,
    searchData.results,
  ])

  const hasMore: boolean = useMemo(() => {
    if (statusToShow === 'open') {
      return hasMoreOpenTickets
    }
    if (statusToShow === 'closed') {
      return hasMoreClosedTickets
    }
    return false
  }, [hasMoreClosedTickets, hasMoreOpenTickets, statusToShow])

  const totalTickets: number = useMemo(() => {
    if (searchData.results.length > 0 || searchData.error) {
      return searchData.results.length
    }
    if (statusToShow === 'open') {
      return totalOpenTickets
    }
    if (statusToShow === 'closed') {
      return totalClosedTickets
    }
    return totalCcdTickets
  }, [
    searchData.results.length,
    searchData.error,
    totalClosedTickets,
    totalOpenTickets,
    totalCcdTickets,
    statusToShow,
  ])

  const ticketsError: boolean | null = useMemo(() => {
    if (statusToShow === 'open') {
      return !!openTicketsError
    }
    if (statusToShow === 'closed') {
      return !!closedTicketsError
    }
    if (statusToShow === 'ccd') {
      return !!ccdTicketsError
    }

    return null
  }, [ccdTicketsError, closedTicketsError, openTicketsError, statusToShow])

  const hasFetchedOnce: boolean = useMemo(() => {
    if (statusToShow === 'open') {
      return areOpenTicketsFetched
    }
    if (statusToShow === 'closed') {
      return areClosedTicketsFetched
    }
    return areCcdTicketsFetched
  }, [
    areCcdTicketsFetched,
    areClosedTicketsFetched,
    areOpenTicketsFetched,
    statusToShow,
  ])

  const isFetching: boolean = useMemo(() => {
    if (statusToShow === 'open') {
      return areOpenTicketsLoading
    }
    if (statusToShow === 'closed') {
      return areClosedTicketsLoading
    }
    return areCcdTicketsLoading
  }, [
    areCcdTicketsLoading,
    areClosedTicketsLoading,
    areOpenTicketsLoading,
    statusToShow,
  ])

  return {
    allTickets,
    hasMore,
    ticketsError,
    totalTickets,
    hasFetchedOnce,
    isFetching,
    totalFetchedTickets: allTickets.length,
  }
}
