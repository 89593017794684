import React, { Component } from 'react'

interface Props {
  onDidMount(): void
}

export const withDidMount = (WrappedComponent: any) => {
  return class extends Component<Props> {
    static displayName = 'withDidMount'

    componentDidMount() {
      if (typeof this.props.onDidMount === 'function') {
        this.props.onDidMount()
      }
    }

    render() {
      const { onDidMount, ...props } = this.props

      return <WrappedComponent {...props} />
    }
  }
}
