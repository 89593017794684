import React from 'react'
import { FormattedMessage } from 'react-intl'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'

const ExternalCancelSubscriptionUMRModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<Title />}
      size="small"
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
        },
      ]}
    >
      <FormattedMessage
        id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_HEAD"
        tagName="div"
      />
      <ol>
        <li>
          <FormattedMessage
            id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_1"
            values={{
              mobilityLink: (
                <a href="https://mobility.ui.com">mobility.ui.com</a>
              ),
            }}
          />
        </li>
        <li>
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_2" />
        </li>
        <li>
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_3" />
        </li>
        <li>
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_4" />
        </li>
        <li>
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_5" />
        </li>
      </ol>
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_EXT_UMR_MODAL_ID =
  'CANCEL_SUBSCRIPTION_EXT_UMR_MODAL_ID'

const WrappedExternalCancelSubscriptionUMRModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_EXT_UMR_MODAL_ID}>
    <ExternalCancelSubscriptionUMRModal />
  </ModalWrapper>
)

export default WrappedExternalCancelSubscriptionUMRModal

const Title = () => (
  <TitleWrapper>
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
      tagName="div"
    />
  </TitleWrapper>
)

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`
