import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'
import CommunityLogo from 'assets/svgs/communityLogo.svg'
import CommunityImage from 'assets/images/communityBackground.png'
import CommunityImageLow from 'assets/images/communityBackgroundLow.png'
import CommunityImageSmall from 'assets/images/communityBackgroundSmall.png'
import CommunityImageSmallLow from 'assets/images/communityBackgroundSmallLow.png'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

export const CommunityTile = () => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { isMaxTabletLarge, isMaxXSmall } = useContext(MediaSizingContext)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src =
      isMaxTabletLarge && !isMaxXSmall ? CommunityImageSmall : CommunityImage
    imageToLoad.onload = () => setImageLoaded(true)
  }, [isMaxTabletLarge, isMaxXSmall])

  return (
    <Container>
      <Wrapper>
        <img src={CommunityLogo} className="community-logo" />
        <SubTitle>
          <FormattedMessage id="SUPPORT_UNIFI_INFO_COMMUNITY_DESCRIPTION" />
        </SubTitle>
        <Button<'a'>
          component="a"
          href="https://community.ui.com"
          target="_blank"
          variant="primary"
        >
          <FormattedMessage id="SUPPORT_UNIFI_INFO_COMMUNITY_ASK" />
        </Button>
      </Wrapper>
      <StyledBackground $imageLoaded={imageLoaded} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.neutral01};
  border-radius: 8px;
`

const StyledBackground = styled.div<{
  $imageLoaded: boolean
}>`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: ${({ $imageLoaded }) =>
    $imageLoaded ? `url(${CommunityImage})` : `url(${CommunityImageLow})`};

  @media (max-width: ${({ theme }) => theme.media.tabletLarge}) {
    background-image: ${({ $imageLoaded }) =>
      $imageLoaded
        ? `url(${CommunityImageSmall})`
        : `url(${CommunityImageSmallLow})`};
  }
  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    height: 74px;
    background-image: ${({ $imageLoaded }) =>
      $imageLoaded ? `url(${CommunityImage})` : `url(${CommunityImageLow})`};
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 24px;

  .community-logo {
    max-height: 20px;
    object-fit: contain;
    object-position: left center;
  }

  @media (max-width: ${({ theme }) => theme.media.tabletLarge}) {
    align-items: center;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    padding-bottom: 0;
  }
`

const SubTitle = styled.span`
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text3};

  @media (max-width: ${({ theme }) => theme.media.tabletLarge}) {
    align-items: center;
  }
`
