import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import EmptyResult from '@ubnt/ui-components/EmptyResult/EmptyResult'
import { History } from 'history'

import styled from 'theme/styled'
import { GenericMFAModal } from 'pages/security/components/GenericMFAModal'
import { closeVisibleModal } from 'modules/modals'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

export const MFA_LOST_ACCESS_MODAL_ID = 'MFA_LOST_ACCESS_MODAL_ID'

interface Props {
  history: History
}

export const LostAccessModal: React.FC<Props> = ({ history }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return (
    <GenericMFAModal
      modalId={MFA_LOST_ACCESS_MODAL_ID}
      title={intl.formatMessage({
        id: 'LOGIN_MFA_LOST_ACCESS_MODAL',
      })}
      primaryButton={{
        text: intl.formatMessage({
          id: 'LOGIN_MFA_LOST_ACCESS_MODAL_PRIMARY',
        }),
        onClick: () => {
          dispatch(closeVisibleModal())
          history.push('/register/')
        },
      }}
    >
      <Wrapper>
        <EmptyResult
          description={
            <FormattedMessage
              id="LOGIN_MFA_LOST_ACCESS_MODAL_DESCRIPTION"
              values={{
                b: (msg) => <b className="intl-message">{msg}</b>,
              }}
            />
          }
          size="large"
        />
      </Wrapper>
    </GenericMFAModal>
  )
}
