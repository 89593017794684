import { TooltipProps } from '@ubnt/ui-components'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import React from 'react'

export const withTooltip = (
  tooltipProps: TooltipProps,
  children: React.ReactNode,
  shouldTrigger: boolean
): React.ReactNode => {
  if (shouldTrigger) {
    return <Tooltip {...tooltipProps}>{children}</Tooltip>
  }

  return children
}
