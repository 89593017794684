import React, { PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import SkeletonLoader from '@ubnt/ui-components/SkeletonLoader/SkeletonLoader'
import CheckmarkIcon from '@ubnt/icons/CheckmarkIcon'
import CloseWithCircleIcon from '@ubnt/icons/CloseWithCircleIcon'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'

import ProtectedPage from 'pages/ProtectedPage'
import styled from 'theme/styled'
import { typography } from 'theme'

import { AlertSlackChannelState } from './config'

const Wrapper = styled.div`
  width: 100%;
  padding: 48px 24px;
  border-radius: 8px;
  z-index: 10;
  background: ${({ theme }) => theme.neutral00};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.media.mobileMedium}) {
    padding: 16px;
    top: 240px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const Header = styled.span`
  font: ${typography['success-header']};
  color: ${({ theme }) => theme.text0};
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  color: ${({ theme }) => theme.green06};
`

const StyledCloseWithCircleIcon = styled(CloseWithCircleIcon)`
  color: ${({ theme }) => theme.red06};
`

const AlertSlackChannelWrapper: React.FC<
  PropsWithChildren<{
    isLoading?: boolean
  }>
> = ({ children, isLoading }) => {
  return (
    <Wrapper>
      <ContentWrapper>
        {isLoading ? (
          <SkeletonLoader>
            <ContentBlock>{children}</ContentBlock>
          </SkeletonLoader>
        ) : (
          <ContentBlock>{children}</ContentBlock>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

export const AlertSlackChannelStateMap = {
  [AlertSlackChannelState.LOADING]: (
    <ProtectedPage>
      <AlertSlackChannelWrapper isLoading>
        <Block height="48px" width="48px" />
        <Text width={220} />
        <Text width={500} />
      </AlertSlackChannelWrapper>
    </ProtectedPage>
  ),
  [AlertSlackChannelState.FAILURE]: (
    <ProtectedPage>
      <AlertSlackChannelWrapper>
        <StyledCloseWithCircleIcon size={48} variant="fill" />
        <Header>
          <FormattedMessage id="SUPPORT_SUBMIT_SUCCESS_SLACK_CHANNEL_ALERT_TITLE_FAILURE" />
        </Header>
        <FormattedMessage id="SUPPORT_SUBMIT_SUCCESS_SLACK_CHANNEL_ALERT_DESCRIPTION_FAILURE" />
      </AlertSlackChannelWrapper>
    </ProtectedPage>
  ),
  [AlertSlackChannelState.SUCCESS]: (
    <ProtectedPage>
      <AlertSlackChannelWrapper>
        <StyledCheckmarkIcon size={48} variant="fill" />
        <Header>
          <FormattedMessage id="SUPPORT_SUBMIT_SUCCESS_SLACK_CHANNEL_ALERT_TITLE_SUCCESS" />
        </Header>
        <FormattedMessage id="SUPPORT_SUBMIT_SUCCESS_SLACK_CHANNEL_ALERT_DESCRIPTION_SUCCESS" />
      </AlertSlackChannelWrapper>
    </ProtectedPage>
  ),
}
