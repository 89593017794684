import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Loader } from '@ubnt/ui-components/Loader'
import { isEqual } from 'lodash-es'
import { ToastContext } from '@ubnt/ui-components/Toast'
import { selectVisibleModal, setVisibleModal } from 'modules/modals'
import { PaymentCardsBlock } from 'features/payment-methods/ui/cards/PaymentCardsBlock'
import { validStripeRegionMap } from 'features/stripe/ui/regions'
import usePrevious from 'utils/usePrevious'
import cardOtherSVG from 'assets/svgs/cardOther.svg'
import { ALL_KEY } from 'sharedConstants'
import { CardType } from '../modules/types'
import { StripeRegionContext } from '../../stripe/ui/Region'
import PaymentMethodInfo from './PaymentMethodInfo'
import NotSupportedRegionModal, {
  CHANGE_REGION_MODAL_ID,
} from './ChangeRegionModal'
import RemovePaymentMethodModal, {
  DELETE_PAYMENT_METHOD_MODAL_ID,
} from './RemovePaymentMethodModal'
import EditPaymentMethodModal, {
  EDIT_PAYMENT_METHOD_MODAL_ID,
} from './EditPaymentMethodModal'
import { ADD_PAYMENT_METHOD_MODAL_ID } from './AddPaymentMethodModal'
import {
  LoaderContainer,
  StyledEntityToast,
  StyledPaymentMethodIcon,
} from './Payments.styles'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { useFullSubscriptions } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { useCreateCard } from 'store/mutations/billingCards/useCreateCard'
import { useUpdateCustomer } from 'store/mutations/billingCustomer/useUpdateCustomer'

interface Props {
  hasMultipleCountries: boolean
  addPaymentView?: boolean
  filter?: string
}

const pushDefaultsFirst = (a: CardType, b: CardType) =>
  !a.is_default && b.is_default ? 1 : -1

export const PaymentMethods: React.FC<Props> = ({
  addPaymentView = false,
  filter = ALL_KEY,
  hasMultipleCountries,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [settingDefault, setSettingDefault] = useState<string | null>(null)
  const { isBillingCustomerLoading, customerDefaultCards } =
    useBillingCustomerQuery()
  const { updateBillingCustomer } = useUpdateCustomer()
  const { isCardsLoading, customerCards } = useBillingCardsQuery()
  const { resetCreateCardErrors } = useCreateCard()
  const visibleModal = useSelector(selectVisibleModal)
  const { region } = useContext(StripeRegionContext)
  const { getOngoingTalkRegions } = useFullSubscriptions()
  const talkRegions = getOngoingTalkRegions()

  const stripeRegion = validStripeRegionMap.get(region)?.code

  const cardsToDisplay = useMemo(() => {
    const extendedCards = customerCards.map((card) => {
      const isDefault =
        customerDefaultCards &&
        Object.values(customerDefaultCards).some((id) => id === card.id)
      return { ...card, is_default: isDefault }
    })

    if (filter === ALL_KEY) return extendedCards.sort(pushDefaultsFirst)

    return extendedCards
      .filter((card) => card.card?.country?.toLowerCase() === filter)
      .sort(pushDefaultsFirst)
  }, [customerCards, customerDefaultCards, filter])

  const toast = useContext(ToastContext)
  const previousDefaultMethod = usePrevious(customerDefaultCards)

  const defaultMethodUpdatedSuccessfully =
    customerDefaultCards &&
    previousDefaultMethod &&
    !isEqual(customerDefaultCards, previousDefaultMethod)

  useEffect(() => {
    if (defaultMethodUpdatedSuccessfully) {
      toast.createNotification(
        <StyledEntityToast
          id="changedDefaultMethod"
          icon={<StyledPaymentMethodIcon src={cardOtherSVG} />}
          stateIndicator="success"
          onClose={() => {
            toast.removeNotification('changedDefaultMethod')
          }}
          title={intl.formatMessage({
            id: 'SETTINGS_PAYMENTS_UPDATE_METHOD_SUCCESS',
          })}
        />
      )
    }
  }, [customerDefaultCards, defaultMethodUpdatedSuccessfully, intl, toast])

  useEffect(() => {
    if (settingDefault && !isBillingCustomerLoading) {
      setSettingDefault(null)
    }
  }, [isBillingCustomerLoading, settingDefault])

  const handleEditPayment = useCallback(
    (card: CardType) => {
      resetCreateCardErrors()
      dispatch(
        setVisibleModal(EDIT_PAYMENT_METHOD_MODAL_ID, {
          card,
          cardRegion: card.region,
        })
      )
    },
    [dispatch, resetCreateCardErrors]
  )

  const handleRemovePayment = useCallback(
    (card: CardType) =>
      dispatch(
        setVisibleModal(DELETE_PAYMENT_METHOD_MODAL_ID, {
          card,
        })
      ),
    [dispatch]
  )

  const handleAddPayment = useCallback(
    (props?: any) => {
      if (!stripeRegion) {
        dispatch(
          setVisibleModal(CHANGE_REGION_MODAL_ID, {
            notSupported: true,
          })
        )
      }
      if (
        visibleModal.visibleModal !== 'ADD_PAYMENT_METHOD_MODAL_ID' &&
        stripeRegion
      ) {
        resetCreateCardErrors()
        dispatch(
          setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID, {
            ...props,
          })
        )
      }
    },
    [dispatch, resetCreateCardErrors, stripeRegion, visibleModal.visibleModal]
  )

  useEffect(() => {
    if (
      addPaymentView &&
      visibleModal.visibleModal !== ADD_PAYMENT_METHOD_MODAL_ID
    ) {
      resetCreateCardErrors()
      dispatch(
        setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID, {
          addPaymentView: true,
        })
      )
    }
  }, [addPaymentView, visibleModal, dispatch, region, resetCreateCardErrors])

  const setDefault = useCallback(
    (cardId: string, cardRegion: StripeRegionCode) => {
      updateBillingCustomer({
        data: { default_source: cardId },
        region: cardRegion,
      })
      setSettingDefault(cardId)
    },
    [updateBillingCustomer]
  )

  if (addPaymentView || isBillingCustomerLoading || isCardsLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  const displayFlag = hasMultipleCountries && filter === ALL_KEY

  return (
    <>
      <PaymentCardsBlock
        onAddNewClick={handleAddPayment}
        addNewTitle={intl.formatMessage({
          id: 'SETTINGS_PAYMENTS_MODAL_TITLE_ADD',
        })}
        cardCount={cardsToDisplay?.length ?? 0}
      >
        {cardsToDisplay.map((card, index) => (
          <PaymentMethodInfo
            key={`${card?.id}-${index}`}
            handleEdit={() => handleEditPayment(card)}
            handleRemove={() => handleRemovePayment(card)}
            isLoading={isBillingCustomerLoading}
            isDefault={card.is_default}
            card={card}
            talkRegions={talkRegions}
            handleSetDefault={() => setDefault(card.id, card.region)}
            displayFlag={displayFlag}
          />
        ))}
      </PaymentCardsBlock>
      <EditPaymentMethodModal />
      <RemovePaymentMethodModal />
      <NotSupportedRegionModal />
    </>
  )
}
