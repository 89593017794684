import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'
import { useDeleteTaxId } from 'store/mutations/billingTaxId/useDeleteTaxId'

const RemoveButton: React.FC = () => {
  const { isDeleteTaxIdLoading, deleteTaxId } = useDeleteTaxId()

  const handleClick = () => {
    deleteTaxId()
  }

  return (
    <StyledButton
      color="danger"
      variant="inline"
      onClick={handleClick}
      loader={isDeleteTaxIdLoading ? 'dots' : undefined}
    >
      <FormattedMessage id="COMMON_ACTION_REMOVE" />
    </StyledButton>
  )
}

export default RemoveButton

const StyledButton = styled(Button)`
  align-self: flex-end;
`
