import { palette } from '@ubnt/ui-components/styles/designToken'

export type MotifPaletteType = {
  [Property in keyof typeof light]: string
}

export const light = {
  text0: palette['desktop-color-text-0-light'],
  text1: palette['desktop-color-text-1-light'],
  text2: palette['desktop-color-text-2-light'],
  text3: palette['desktop-color-text-3-light'],
  text4: palette['desktop-color-text-4-light'],
  ublue01: palette['desktop-color-ublue-01-light'],
  ublue02: palette['desktop-color-ublue-02-light'],
  ublue03: palette['desktop-color-ublue-03-light'],
  ublue04: palette['desktop-color-ublue-04-light'],
  ublue05: palette['desktop-color-ublue-05-light'],
  ublue06: palette['desktop-color-ublue-06-light'],
  ublue07: palette['desktop-color-ublue-07-light'],
  ublue08: palette['desktop-color-ublue-08-light'],
  ublue09: palette['desktop-color-ublue-09-light'],
  ublue10: palette['desktop-color-ublue-10-light'],
  neutral00: palette['desktop-color-neutral-00-light'],
  neutral01: palette['desktop-color-neutral-01-light'],
  neutral02: palette['desktop-color-neutral-02-light'],
  neutral03: palette['desktop-color-neutral-03-light'],
  neutral04: palette['desktop-color-neutral-04-light'],
  neutral05: palette['desktop-color-neutral-05-light'],
  neutral06: palette['desktop-color-neutral-06-light'],
  neutral07: palette['desktop-color-neutral-07-light'],
  neutral08: palette['desktop-color-neutral-08-light'],
  neutral09: palette['desktop-color-neutral-09-light'],
  neutral10: palette['desktop-color-neutral-10-light'],
  neutral11: palette['desktop-color-neutral-11-light'],
  neutral12: palette['desktop-color-neutral-12-light'],
  green01: palette['desktop-color-green-01-light'],
  green02: palette['desktop-color-green-02-light'],
  green03: palette['desktop-color-green-03-light'],
  green04: palette['desktop-color-green-04-light'],
  green05: palette['desktop-color-green-05-light'],
  green06: palette['desktop-color-green-06-light'],
  green07: palette['desktop-color-green-07-light'],
  green08: palette['desktop-color-green-08-light'],
  green09: palette['desktop-color-green-09-light'],
  green10: palette['desktop-color-green-10-light'],
  red01: palette['desktop-color-red-01-light'],
  red02: palette['desktop-color-red-02-light'],
  red03: palette['desktop-color-red-03-light'],
  red04: palette['desktop-color-red-04-light'],
  red05: palette['desktop-color-red-05-light'],
  red06: palette['desktop-color-red-06-light'],
  red07: palette['desktop-color-red-07-light'],
  red08: palette['desktop-color-red-08-light'],
  red09: palette['desktop-color-red-09-light'],
  red10: palette['desktop-color-red-10-light'],
  orange01: palette['desktop-color-orange-01-light'],
  orange02: palette['desktop-color-orange-02-light'],
  orange03: palette['desktop-color-orange-03-light'],
  orange04: palette['desktop-color-orange-04-light'],
  orange05: palette['desktop-color-orange-05-light'],
  orange06: palette['desktop-color-orange-06-light'],
  orange07: palette['desktop-color-orange-07-light'],
  orange08: palette['desktop-color-orange-08-light'],
  orange09: palette['desktop-color-orange-09-light'],
  orange10: palette['desktop-color-orange-10-light'],
  purple01: palette['desktop-color-purple-01-light'],
  purple02: palette['desktop-color-purple-02-light'],
  purple03: palette['desktop-color-purple-03-light'],
  purple04: palette['desktop-color-purple-04-light'],
  purple05: palette['desktop-color-purple-05-light'],
  purple06: palette['desktop-color-purple-06-light'],
  purple07: palette['desktop-color-purple-07-light'],
  purple08: palette['desktop-color-purple-08-light'],
  purple09: palette['desktop-color-purple-09-light'],
  purple10: palette['desktop-color-purple-10-light'],
  navy01: palette['desktop-color-navy-01-light'],
  navy02: palette['desktop-color-navy-02-light'],
  navy03: palette['desktop-color-navy-03-light'],
  navy04: palette['desktop-color-navy-04-light'],
  navy05: palette['desktop-color-navy-05-light'],
  navy06: palette['desktop-color-navy-06-light'],
  navy07: palette['desktop-color-navy-07-light'],
  navy08: palette['desktop-color-navy-08-light'],
  navy09: palette['desktop-color-navy-09-light'],
  navy10: palette['desktop-color-navy-10-light'],
  aqua01: palette['desktop-color-aqua-01-light'],
  aqua02: palette['desktop-color-aqua-02-light'],
  aqua03: palette['desktop-color-aqua-03-light'],
  aqua04: palette['desktop-color-aqua-04-light'],
  aqua05: palette['desktop-color-aqua-05-light'],
  aqua06: palette['desktop-color-aqua-06-light'],
  aqua07: palette['desktop-color-aqua-07-light'],
  aqua08: palette['desktop-color-aqua-08-light'],
  aqua09: palette['desktop-color-aqua-09-light'],
  aqua10: palette['desktop-color-aqua-10-light'],
  lime01: palette['desktop-color-lime-01-light'],
  lime02: palette['desktop-color-lime-02-light'],
  lime03: palette['desktop-color-lime-03-light'],
  lime04: palette['desktop-color-lime-04-light'],
  lime05: palette['desktop-color-lime-05-light'],
  lime06: palette['desktop-color-lime-06-light'],
  lime07: palette['desktop-color-lime-07-light'],
  lime08: palette['desktop-color-lime-08-light'],
  lime09: palette['desktop-color-lime-09-light'],
  lime10: palette['desktop-color-lime-10-light'],
  yellow01: palette['desktop-color-yellow-01-light'],
  yellow02: palette['desktop-color-yellow-02-light'],
  yellow03: palette['desktop-color-yellow-03-light'],
  yellow04: palette['desktop-color-yellow-04-light'],
  yellow05: palette['desktop-color-yellow-05-light'],
  yellow06: palette['desktop-color-yellow-06-light'],
  yellow07: palette['desktop-color-yellow-07-light'],
  yellow08: palette['desktop-color-yellow-08-light'],
  yellow09: palette['desktop-color-yellow-09-light'],
  yellow10: palette['desktop-color-yellow-10-light'],
  aquagradient: palette['desktop-color-aqua-gradient-light'],
  navygradient: palette['desktop-color-navy-gradient-light'],
  downloadgradient: palette['desktop-color-download-gradient-light'],
  uploadgradient: palette['desktop-color-upload-gradient-light'],
}

export const dark = {
  text0: palette['desktop-color-text-0-dark'],
  text1: palette['desktop-color-text-1-dark'],
  text2: palette['desktop-color-text-2-dark'],
  text3: palette['desktop-color-text-3-dark'],
  text4: palette['desktop-color-text-4-dark'],
  ublue01: palette['desktop-color-ublue-01-dark'],
  ublue02: palette['desktop-color-ublue-02-dark'],
  ublue03: palette['desktop-color-ublue-03-dark'],
  ublue04: palette['desktop-color-ublue-04-dark'],
  ublue05: palette['desktop-color-ublue-05-dark'],
  ublue06: palette['desktop-color-ublue-06-dark'],
  ublue07: palette['desktop-color-ublue-07-dark'],
  ublue08: palette['desktop-color-ublue-08-dark'],
  ublue09: palette['desktop-color-ublue-09-dark'],
  ublue10: palette['desktop-color-ublue-10-dark'],
  neutral00: palette['desktop-color-neutral-00-dark'],
  neutral01: palette['desktop-color-neutral-01-dark'],
  neutral02: palette['desktop-color-neutral-02-dark'],
  neutral03: palette['desktop-color-neutral-03-dark'],
  neutral04: palette['desktop-color-neutral-04-dark'],
  neutral05: palette['desktop-color-neutral-05-dark'],
  neutral06: palette['desktop-color-neutral-06-dark'],
  neutral07: palette['desktop-color-neutral-07-dark'],
  neutral08: palette['desktop-color-neutral-08-dark'],
  neutral09: palette['desktop-color-neutral-09-dark'],
  neutral10: palette['desktop-color-neutral-10-dark'],
  neutral11: palette['desktop-color-neutral-11-dark'],
  neutral12: palette['desktop-color-neutral-12-dark'],
  green01: palette['desktop-color-green-01-dark'],
  green02: palette['desktop-color-green-02-dark'],
  green03: palette['desktop-color-green-03-dark'],
  green04: palette['desktop-color-green-04-dark'],
  green05: palette['desktop-color-green-05-dark'],
  green06: palette['desktop-color-green-06-dark'],
  green07: palette['desktop-color-green-07-dark'],
  green08: palette['desktop-color-green-08-dark'],
  green09: palette['desktop-color-green-09-dark'],
  green10: palette['desktop-color-green-10-dark'],
  red01: palette['desktop-color-red-01-dark'],
  red02: palette['desktop-color-red-02-dark'],
  red03: palette['desktop-color-red-03-dark'],
  red04: palette['desktop-color-red-04-dark'],
  red05: palette['desktop-color-red-05-dark'],
  red06: palette['desktop-color-red-06-dark'],
  red07: palette['desktop-color-red-07-dark'],
  red08: palette['desktop-color-red-08-dark'],
  red09: palette['desktop-color-red-09-dark'],
  red10: palette['desktop-color-red-10-dark'],
  orange01: palette['desktop-color-orange-01-dark'],
  orange02: palette['desktop-color-orange-02-dark'],
  orange03: palette['desktop-color-orange-03-dark'],
  orange04: palette['desktop-color-orange-04-dark'],
  orange05: palette['desktop-color-orange-05-dark'],
  orange06: palette['desktop-color-orange-06-dark'],
  orange07: palette['desktop-color-orange-07-dark'],
  orange08: palette['desktop-color-orange-08-dark'],
  orange09: palette['desktop-color-orange-09-dark'],
  orange10: palette['desktop-color-orange-10-dark'],
  purple01: palette['desktop-color-purple-01-dark'],
  purple02: palette['desktop-color-purple-02-dark'],
  purple03: palette['desktop-color-purple-03-dark'],
  purple04: palette['desktop-color-purple-04-dark'],
  purple05: palette['desktop-color-purple-05-dark'],
  purple06: palette['desktop-color-purple-06-dark'],
  purple07: palette['desktop-color-purple-07-dark'],
  purple08: palette['desktop-color-purple-08-dark'],
  purple09: palette['desktop-color-purple-09-dark'],
  purple10: palette['desktop-color-purple-10-dark'],
  navy01: palette['desktop-color-navy-01-dark'],
  navy02: palette['desktop-color-navy-02-dark'],
  navy03: palette['desktop-color-navy-03-dark'],
  navy04: palette['desktop-color-navy-04-dark'],
  navy05: palette['desktop-color-navy-05-dark'],
  navy06: palette['desktop-color-navy-06-dark'],
  navy07: palette['desktop-color-navy-07-dark'],
  navy08: palette['desktop-color-navy-08-dark'],
  navy09: palette['desktop-color-navy-09-dark'],
  navy10: palette['desktop-color-navy-10-dark'],
  aqua01: palette['desktop-color-aqua-01-dark'],
  aqua02: palette['desktop-color-aqua-02-dark'],
  aqua03: palette['desktop-color-aqua-03-dark'],
  aqua04: palette['desktop-color-aqua-04-dark'],
  aqua05: palette['desktop-color-aqua-05-dark'],
  aqua06: palette['desktop-color-aqua-06-dark'],
  aqua07: palette['desktop-color-aqua-07-dark'],
  aqua08: palette['desktop-color-aqua-08-dark'],
  aqua09: palette['desktop-color-aqua-09-dark'],
  aqua10: palette['desktop-color-aqua-10-dark'],
  lime01: palette['desktop-color-lime-01-dark'],
  lime02: palette['desktop-color-lime-02-dark'],
  lime03: palette['desktop-color-lime-03-dark'],
  lime04: palette['desktop-color-lime-04-dark'],
  lime05: palette['desktop-color-lime-05-dark'],
  lime06: palette['desktop-color-lime-06-dark'],
  lime07: palette['desktop-color-lime-07-dark'],
  lime08: palette['desktop-color-lime-08-dark'],
  lime09: palette['desktop-color-lime-09-dark'],
  lime10: palette['desktop-color-lime-10-dark'],
  yellow01: palette['desktop-color-yellow-01-dark'],
  yellow02: palette['desktop-color-yellow-02-dark'],
  yellow03: palette['desktop-color-yellow-03-dark'],
  yellow04: palette['desktop-color-yellow-04-dark'],
  yellow05: palette['desktop-color-yellow-05-dark'],
  yellow06: palette['desktop-color-yellow-06-dark'],
  yellow07: palette['desktop-color-yellow-07-dark'],
  yellow08: palette['desktop-color-yellow-08-dark'],
  yellow09: palette['desktop-color-yellow-09-dark'],
  yellow10: palette['desktop-color-yellow-10-dark'],
  aquagradient: palette['desktop-color-aqua-gradient-dark'],
  navygradient: palette['desktop-color-navy-gradient-dark'],
  downloadgradient: palette['desktop-color-download-gradient-dark'],
  uploadgradient: palette['desktop-color-upload-gradient-dark'],
}
