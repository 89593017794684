import React, { useContext } from 'react'
import { DevicesGrid } from './TrustedDevicesList'
import styled from 'theme/styled'
import { getFormattedTime, getTrustedDeviceImage } from './utils'
import { FormattedMessage, useIntl } from 'react-intl'
import { Checkbox } from '@ubnt/ui-components/Checkbox'
import { TruncatedText } from 'components/TruncatedText'
import theme, { typography } from 'theme'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

export type SortedDevice = {
  id: string
  name: string
  date: string
  model: string
}

interface Props {
  device: SortedDevice
  isCurrentDevice: boolean
  isManaging: boolean
  isSelected: boolean
  toggleSelectId: (id: string) => void
}

export const TrustedDeviceRow: React.FC<Props> = ({
  device,
  isCurrentDevice,
  isManaging,
  isSelected,
  toggleSelectId,
}) => {
  const { name, id, date, model } = device
  const intl = useIntl()
  const { isMaxMobileLarge } = useContext(MediaSizingContext)
  return (
    <DevicesGrid $isManaging={isManaging}>
      {isManaging && (
        <DeviceCell>
          <Checkbox
            checked={isSelected}
            id={id}
            onChange={() => toggleSelectId(id)}
          />
        </DeviceCell>
      )}
      <DeviceCell>
        <Device>
          <DeviceLogoImage
            src={getTrustedDeviceImage(name, model)}
            alt={name}
          />
          <TruncatedText
            text={name}
            truncateAt={isMaxMobileLarge && isCurrentDevice ? 18 : 60}
          />
        </Device>
        {isCurrentDevice && (
          <CurrentDevice>
            <FormattedMessage id="SETTINGS_TRUSTED_DEVICES_CURRENT_DEVICE" />
          </CurrentDevice>
        )}
      </DeviceCell>
      <DeviceCell className="date">
        {getFormattedTime(Date.parse(date), intl)}
      </DeviceCell>
    </DevicesGrid>
  )
}

const DeviceCell = styled.div`
  display: flex;
  align-items: center;
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.text2};
  padding: 4px 0;
  gap: 4px;
  @media (max-width: ${theme.media.mobileXLarge}) {
    &.date {
      display: none;
    }
    justify-content: space-between;
  }
`

const Device = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const DeviceLogoImage = styled.img`
  object-fit: cover;
  width: 24px;
`

const CurrentDevice = styled.div`
  height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.ublue01};
  border-radius: 16px;
  color: ${({ theme }) => theme.ublue06};
  font-size: 10px;
  line-height: 16px;
  padding: 0 9px;
  width: max-content;
  margin-left: 8px;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    padding: 0;
    width: -webkit-fill-available;
    max-width: 100px;
    margin: 0;
  }
`
