import React from 'react'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '@ubnt/ui-components/EmptyResult/EmptyResult'
import Button from '@ubnt/ui-components/Button/Button'
import styled from 'theme/styled'

export const RequestError: React.FC<{ isNotFound?: boolean }> = ({
  isNotFound,
}) => {
  if (isNotFound) {
    return (
      <Wrapper>
        <EmptyResult title={<FormattedMessage id="REQUEST_NOT_FOUND" />} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <EmptyResult
        title={<FormattedMessage id="GENERIC_PAGE_ERROR_OOPS" />}
        description={<FormattedMessage id="GENERIC_PAGE_ERROR_TRY_AGAIN" />}
      />
      <Button onClick={() => window.location.reload()} variant="primary">
        <FormattedMessage id="COMMON_ACTION_TRY_AGAIN" />
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 16px;
  svg {
    width: 80px;
    height: 80px;
  }
`
