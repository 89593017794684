import React, { useContext, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContext } from '@ubnt/ui-components'
import { ThreatManagementIcon } from '@ubnt/icons'

import { selectMFAAuthenticators } from 'modules/mfa'
import { MFAType } from 'modules/types'
import { resetMFASetupData, selectMFAAddedMethod } from 'modules/mfaAdded'
import { methodTitle } from 'utils/mfa'
import { selectProfileData } from 'modules/profile'
import { MFAAuthenticatorStatus } from 'enums'
import parseLocalStorage from 'utils/localStorage'
import { LastMethodPushLocalStorage } from 'types/types'
import StyledEntityToast from 'components/EntityToast'

const BACKUP_EMAIL_TOAST_ID = 'BACKUP_EMAIL_TOAST_ID'

const MFAToasts = () => {
  const dispatch = useDispatch()

  const profileData = useSelector(selectProfileData)
  const authenticators = useSelector(selectMFAAuthenticators)

  const { createNotification, removeNotification } = useContext(ToastContext)

  const intl = useIntl()

  const addedMethod = useSelector(selectMFAAddedMethod)

  const activeMethods = useMemo(
    () =>
      authenticators?.filter(
        (method) => method.status === MFAAuthenticatorStatus.ACTIVE
      ),
    [authenticators]
  )
  const deactivatedMethods = useMemo(
    () =>
      authenticators?.filter(
        (method) => method.status === MFAAuthenticatorStatus.DEACTIVATED
      ),
    [authenticators]
  )

  const lastMethodIsPush =
    activeMethods?.length === 1 && activeMethods[0].type === MFAType.push

  const lastMethodIsAccountEmail =
    activeMethods?.length === 1 &&
    activeMethods[0].type === MFAType.email &&
    activeMethods[0].email === profileData?.email

  useEffect(() => {
    const deactivatedData =
      parseLocalStorage<LastMethodPushLocalStorage>('lastMethodPush')

    if (lastMethodIsPush && !deactivatedData) {
      localStorage.setItem(
        'lastMethodPush',
        JSON.stringify({ deactivated: false, emailAddedToastShown: false })
      )
    }
    if (activeMethods?.length > 1 && deactivatedData) {
      localStorage.removeItem('lastMethodPush')
    }
    if (
      lastMethodIsAccountEmail &&
      deactivatedMethods?.length >= 1 &&
      deactivatedData &&
      !deactivatedData.emailAddedToastShown
    ) {
      createNotification(
        <StyledEntityToast
          id={BACKUP_EMAIL_TOAST_ID}
          icon={<ThreatManagementIcon />}
          stateIndicator="success"
          title={intl.formatMessage({
            id: 'SETTINGS_MFA_EMAIL_BACKUP_TOAST',
          })}
          details={intl.formatMessage({
            id: 'SETTINGS_MFA_EMAIL_BACKUP_TOAST_DESCRIPTION',
          })}
          duration={0}
          primaryButton={{
            label: intl.formatMessage({ id: 'COMMON_ACTION_OKAY' }),
            onClick: () => removeNotification(BACKUP_EMAIL_TOAST_ID),
          }}
        />
      )
      localStorage.setItem(
        'lastMethodPush',
        JSON.stringify({ deactivated: true, emailAddedToastShown: true })
      )
    }
  }, [
    activeMethods,
    createNotification,
    deactivatedMethods,
    deactivatedMethods?.length,
    intl,
    lastMethodIsAccountEmail,
    lastMethodIsPush,
    removeNotification,
  ])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null
    if (addedMethod.method) {
      createNotification(
        <StyledEntityToast
          icon={<ThreatManagementIcon />}
          stateIndicator="success"
          title={intl.formatMessage(
            { id: 'SETTINGS_MFA_TOAST' },
            { method: methodTitle(addedMethod) }
          )}
          duration={5000}
        />
      )
    }
    if (addedMethod.firstMethod) {
      timer = setTimeout(() => {
        createNotification(
          <StyledEntityToast
            id={BACKUP_EMAIL_TOAST_ID}
            icon={<ThreatManagementIcon />}
            stateIndicator="success"
            title={intl.formatMessage({
              id: 'SETTINGS_MFA_EMAIL_BACKUP_TOAST',
            })}
            details={intl.formatMessage({
              id: 'SETTINGS_MFA_EMAIL_BACKUP_TOAST_DESCRIPTION',
            })}
            duration={0}
            primaryButton={{
              label: intl.formatMessage({ id: 'COMMON_ACTION_OKAY' }),
              onClick: () => removeNotification(BACKUP_EMAIL_TOAST_ID),
            }}
          />
        )
      }, 500)
    }
    dispatch(resetMFASetupData())
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [addedMethod, createNotification, dispatch, intl, removeNotification])

  return <></>
}

export default MFAToasts
