import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'

import styled from 'theme/styled'
import { ModalProps } from 'components/ModalWrapper'
import { closeVisibleModal } from 'modules/modals'

import { GenericMFAModal } from '../GenericMFAModal'
import { TokenInput } from '../../../../components/TokenInput'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
`

const InputWrapper = styled('div')`
  display: flex;
  justify-content: center;
  position: relative;
`

export interface FormValues {
  token: string
}

interface Props extends ModalProps {
  modalId: string
  title: string
  description: string | React.ReactNode
  handleOnSubmit: (values: FormValues) => void
  isLoading?: boolean
  errorMessage?: string
  onAfterClose?: () => void
  isSubmitSuccess?: boolean
}

export const GenericVerifyTokenModal: React.FC<Props> = ({
  modalId,
  title,
  description,
  handleOnSubmit,
  isLoading,
  errorMessage,
  onAfterClose,
  isSubmitSuccess,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [currentError, setCurrentError] = useState<undefined | string>()

  const { values, handleSubmit, errors, setFieldValue, resetForm } =
    useFormik<FormValues>({
      initialValues: {
        token: '',
      },
      onSubmit: () => {
        handleOnSubmit(values)
      },
    })

  useEffect(() => {
    setCurrentError?.(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (values.token.length === 6) {
      handleSubmit()
    }
  }, [handleSubmit, values.token.length])

  return (
    <GenericMFAModal
      modalId={modalId}
      title={title}
      actions={[
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CANCEL',
          }),
          variant: 'tertiary',
          onClick: () => dispatch(closeVisibleModal()),
        },
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CONTINUE',
          }),
          variant: 'primary',
          onClick: () => handleSubmit(),
          disabled: !isLoading,
          loader: isLoading ? 'dots' : isSubmitSuccess ? 'success' : undefined,
        },
      ]}
      isLoading={isLoading || values.token.length === 6}
      onAfterClose={() => {
        resetForm()
        onAfterClose?.()
      }}
      overrideFullScreen
      size="auto"
    >
      <Wrapper>
        <Description>{description}</Description>
        <InputWrapper>
          <TokenInput
            currentError={errors.token || currentError}
            setCurrentError={setCurrentError}
            setFieldValue={setFieldValue}
            disabled={values.token.length === 6}
          />
        </InputWrapper>
      </Wrapper>
    </GenericMFAModal>
  )
}
