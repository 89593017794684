import styled from 'theme/styled'
import { typography } from 'theme'
import RedditLogo from 'assets/svgs/redditLogo.svg?react'

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 48px 24px;
  border-radius: 8px;
  z-index: 10;
  background: ${({ theme }) => theme.neutral00};
  text-align: center;
  margin-top: 0;

  @media (max-width: ${({ theme }) => theme.media.mobileMedium}) {
    padding: 16px;
    top: 240px;
  }
`

export const FormContainer = styled.div`
  max-width: 1020px;
  background: ${({ theme }) => theme.neutral01};
  width: 100%;
  height: 100%;
  padding: 0 20px 90px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`

export const Header = styled.span`
  font: ${typography['success-header']};
  color: ${({ theme }) => theme.text0};
`

export const Text = styled.span<{ secondary?: boolean }>`
  font: ${typography['header-label']};
  color: ${({ theme, secondary }) =>
    secondary ? theme.neutral08 : theme.text2};
`

export const IssueContainer = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.neutral03};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  width: 100%;
`
export const DisclaimerContainer = styled.div`
  max-width: 60%;
  text-align: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.mobileMedium}) {
    max-width: 100%;
  }
`

export const StyledRedditLogo = styled(RedditLogo)`
  height: 64px;
`

export const FacebookContainer = styled.div`
  svg {
    height: 48px;
  }
`

export const IconContainer = styled.div`
  svg {
    height: 64px;
  }
`

export const UbiquitiIconWrapper = styled.div`
  svg {
    height: 88px;
  }
`

export const StyledLink = styled.a`
  font: ${typography['desktop-caption']};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
`

export const SubmitSuccessWrapper = styled.div`
  display: flex;
  width: 100%;

  div {
    margin-top: 0 !important;
  }
`

export const CommunityLogoWrap = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`
