import { fetchRequestWithCookies } from './axios'

const {
  api: { cap },
} = __CONFIG__

export type UmrDevice = {
  id: string
  application_name: string
  activation_code: string
  mac: string
  name?: string
}

export class UmrDevicesApi {
  public getAll = async () => {
    const url = new URL(cap.paths.devices, cap.base)
    const { data } = await fetchRequestWithCookies<{ data: UmrDevice[] }>(
      url.href
    )
    return data
  }
}
