import type Stripe from 'stripe'
import {
  fetchRequestWithCookies,
  postRequestWithCookies,
  putRequestWithCookies,
} from '../axios'
import { StripeRegionCode } from 'features/stripe/ui/types'

const {
  api: { billing },
} = __CONFIG__

interface CustomerData {
  userId?: string
  customerId?: string
  createdAt?: string
  region?: StripeRegionCode
  stripeData?: Stripe.Customer
}

export type GlobalCustomerData = {
  [key in StripeRegionCode]?: CustomerData
}

type BillingCustomerUpdateData = { default_source: string }

type UpdateBillingCustomerArgs = {
  data: BillingCustomerUpdateData
  region: StripeRegionCode
}

export class BillingCustomerApi {
  public get = async () => {
    const url = new URL(billing.paths.customer, billing.base)
    const { data } = await fetchRequestWithCookies<GlobalCustomerData>(url.href)
    return data
  }

  public create = async (region: StripeRegionCode) => {
    const url = new URL(billing.paths.customer, billing.base)
    url.searchParams.append('region', region)
    await postRequestWithCookies(url.href)
  }

  public update = async (data: UpdateBillingCustomerArgs) => {
    const url = new URL(billing.paths.customer, billing.base)
    url.searchParams.append('region', data.region)
    await putRequestWithCookies(url.href, data.data)
  }
}
