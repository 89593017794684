import { StripeCountry, StripeRegionCode } from '../types'

export const usStripeCountries: Readonly<StripeCountry[]> = [
  {
    code: 'us',
    name: 'United States',
    region: StripeRegionCode.US,
  },
  {
    code: 'as',
    name: 'American Samoa',
    region: StripeRegionCode.US,
  },
  {
    code: 'fm',
    name: 'Micronesia, Federated States of',
    region: StripeRegionCode.US,
  },
  {
    code: 'gu',
    name: 'Guam',
    region: StripeRegionCode.US,
  },
  {
    code: 'mh',
    name: 'Marshall Islands',
    region: StripeRegionCode.US,
  },
  {
    code: 'mp',
    name: 'Northern Mariana Islands',
    region: StripeRegionCode.US,
  },
  {
    code: 'pw',
    name: 'Palau',
    region: StripeRegionCode.US,
  },
  {
    code: 'pr',
    name: 'Puerto Rico',
    region: StripeRegionCode.US,
  },
  {
    code: 'vi',
    name: 'Virgin Islands, U.S',
    region: StripeRegionCode.US,
  },
]
