import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillingCustomerApi } from 'api/billing/billingCustomer'
import { QueryKey } from 'store/types'

const billingCustomerApi = new BillingCustomerApi()

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient()

  const updateBillingCustomer = useMutation({
    mutationFn: billingCustomerApi.update,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKey.BILLING_CUSTOMER] }),
  })

  return {
    updateBillingCustomer: updateBillingCustomer.mutate,
  }
}
