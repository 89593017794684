import { BackupDetail, BackupItem } from 'api/nca/types'
import moment from 'moment'

const KEY_LENGTH = 128

export const getBackupsForTable = (
  initialBackups: BackupDetail[],
  deviceId: string
): BackupItem[] => {
  return initialBackups
    .sort((a, b) => b?.time - a?.time)
    .map(({ metadata, time, fileKey, filename }) => ({
      id: metadata.id,
      time: moment(time).format('YYYY-MM-DD HH:mm'),
      deviceId,
      key: fileKey,
      isArchived: !!metadata['x-amz-expiration'],
      filename,
    }))
}

export const makePasswordHash = async (
  password: string,
  salt: string
): Promise<string> => {
  if (!password) throw new Error('Password is empty')
  if (!salt) throw new Error('Salt is empty')

  const enc = new TextEncoder()
  const passwordBuffer = enc.encode(password)
  const saltBuffer = enc.encode(salt)

  const passwordKey = await window.crypto.subtle.importKey(
    'raw',
    passwordBuffer,
    { name: 'PBKDF2' },
    false,
    ['deriveBits']
  )

  const derivedKey = await window.crypto.subtle.deriveBits(
    {
      name: 'PBKDF2',
      salt: saltBuffer,
      iterations: 100000,
      hash: 'SHA-512',
    },
    passwordKey,
    KEY_LENGTH
  )

  let hex = ''
  new Uint8Array(derivedKey).forEach((byte) => {
    hex += byte.toString(16).padStart(2, '0')
  })

  return hex
}

export const parseFileName = (encryptedFileName: string): string => {
  const encryptedFileNameFormat = /^.*\.tar\.crypted$/i
  if (encryptedFileNameFormat.test(encryptedFileName) === false) {
    throw new Error('Input filename string is invalid format')
  }

  return encryptedFileName.replace('.tar.crypted', '.unifi')
}

/* 
Hardware Shortname can come in weird form like '\"UDM SE\"' 
so we need to clean it to "UDM SE" to be able to render correct image
*/
const CLEAN_SHORTNAME_REGEX = /^["\\]+|["\\]+$/g
export const cleanHardwareShortname = (shortname: string): string =>
  shortname?.replace(CLEAN_SHORTNAME_REGEX, '') || ''
