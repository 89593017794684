import { createSelector } from 'reselect'

import { RootState } from '../types/types'

export const mfaSetupDataKey = 'mfaSetup'

type MFASetupDefaultState =
  | {
      id: string
    }
  | Record<string, never>
export interface MFASetupState {
  deactivatedPush: MFASetupDefaultState
  push: MFASetupDefaultState
  sms:
    | {
        id: string
        phoneNumber: string
      }
    | Record<string, never>
  email:
    | {
        id: string
        email: string
      }
    | Record<string, never>
  totp:
    | {
        id: string
        name?: string
      }
    | Record<string, never>
  webauthn:
    | {
        id: string
        name?: string
      }
    | Record<string, never>
  removeTOTP: MFASetupDefaultState
  removeEmail: MFASetupDefaultState
  removeSMS: MFASetupDefaultState
  removePasskey: MFASetupDefaultState
  removePush: MFASetupDefaultState
}

const initialState: MFASetupState = {
  deactivatedPush: {},
  push: {},
  sms: {},
  email: {},
  totp: {},
  webauthn: {},
  removeTOTP: {},
  removeEmail: {},
  removeSMS: {},
  removePasskey: {},
  removePush: {},
}

export enum MFASetupActionTypes {
  MFA_SETUP_DEACTIVATED_PUSH = 'MFA_SETUP_DEACTIVATED_PUSH',
  MFA_DEACTIVATED_PUSH_RESET = 'MFA_DEACTIVATED_PUSH_RESET',
  MFA_SETUP_PUSH = 'MFA_SETUP_PUSH',
  MFA_SETUP_EMAIL = 'MFA_SETUP_EMAIL',
  MFA_SETUP_TOTP = 'MFA_SETUP_TOTP',
  MFA_SETUP_WEBAUTHN = 'MFA_SETUP_WEBAUTHN',
  MFA_RESET_EMAIL = 'MFA_RESET_EMAIL',
  MFA_RESET_SMS = 'MFA_RESET_SMS',
  MFA_REMOVE_TOTP = 'MFA_REMOVE_TOTP',
  MFA_REMOVE_EMAIL = 'MFA_REMOVE_EMAIL',
  MFA_REMOVE_SMS = 'MFA_REMOVE_SMS',
  MFA_REMOVE_PASSKEY = 'MFA_REMOVE_PASSKEY',
  MFA_REMOVE_PUSH = 'MFA_REMOVE_PUSH',
}

type Action =
  | {
      type: MFASetupActionTypes.MFA_SETUP_DEACTIVATED_PUSH
      payload: { id: string }
    }
  | {
      type: MFASetupActionTypes.MFA_DEACTIVATED_PUSH_RESET
    }
  | {
      type: MFASetupActionTypes.MFA_SETUP_PUSH
      payload: { id: string }
    }
  | {
      type: MFASetupActionTypes.MFA_SETUP_EMAIL
      payload: { id: string; email: string }
    }
  | {
      type: MFASetupActionTypes.MFA_SETUP_TOTP
      payload: { id: string; name?: string }
    }
  | {
      type: MFASetupActionTypes.MFA_SETUP_WEBAUTHN
      payload: { id: string; name?: string }
    }
  | {
      type:
        | MFASetupActionTypes.MFA_RESET_EMAIL
        | MFASetupActionTypes.MFA_RESET_SMS
    }
  | {
      type: MFASetupActionTypes.MFA_REMOVE_TOTP
      payload: { id: string }
    }
  | {
      type: MFASetupActionTypes.MFA_REMOVE_EMAIL
      payload: { id: string }
    }
  | {
      type: MFASetupActionTypes.MFA_REMOVE_SMS
      payload: { id: string }
    }
  | {
      type: MFASetupActionTypes.MFA_REMOVE_PASSKEY
      payload: { id: string }
    }
  | {
      type: MFASetupActionTypes.MFA_REMOVE_PUSH
      payload: { id: string }
    }

export const mfaSetupReducer = (state = initialState, action: Action) => {
  let newState: MFASetupState

  switch (action.type) {
    case MFASetupActionTypes.MFA_SETUP_DEACTIVATED_PUSH:
      newState = {
        ...state,
        deactivatedPush: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_DEACTIVATED_PUSH_RESET:
      newState = {
        ...state,
        deactivatedPush: {},
      }
      return newState
    case MFASetupActionTypes.MFA_SETUP_PUSH:
      newState = {
        ...state,
        push: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_SETUP_EMAIL:
      newState = {
        ...state,
        email: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_SETUP_TOTP:
      newState = {
        ...state,
        totp: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_SETUP_WEBAUTHN:
      newState = {
        ...state,
        webauthn: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_RESET_EMAIL:
      newState = {
        ...state,
        email: {},
      }
      return newState
    case MFASetupActionTypes.MFA_RESET_SMS:
      newState = {
        ...state,
        sms: {},
      }
      return newState
    case MFASetupActionTypes.MFA_REMOVE_TOTP:
      newState = {
        ...state,
        removeTOTP: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_REMOVE_EMAIL:
      newState = {
        ...state,
        removeEmail: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_REMOVE_SMS:
      newState = {
        ...state,
        removeSMS: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_REMOVE_PASSKEY:
      newState = {
        ...state,
        removePasskey: action.payload,
      }
      return newState
    case MFASetupActionTypes.MFA_REMOVE_PUSH:
      newState = {
        ...state,
        removePush: action.payload,
      }
      return newState
    default:
      return state
  }
}

/**
 * Actions
 */

export const resetMFADeactivatedPush = (): Action => ({
  type: MFASetupActionTypes.MFA_DEACTIVATED_PUSH_RESET,
})

export const setupMFAEmail = (id: string, email: string): Action => ({
  type: MFASetupActionTypes.MFA_SETUP_EMAIL,
  payload: {
    id,
    email,
  },
})

export const setupMFATOTP = (id: string, name?: string): Action => ({
  type: MFASetupActionTypes.MFA_SETUP_TOTP,
  payload: {
    id,
    name,
  },
})

export const resetMFASetupEmail = (): Action => ({
  type: MFASetupActionTypes.MFA_RESET_EMAIL,
})

/**
 * Selectors
 */

export const selectMFASetup = (state: RootState) => state[mfaSetupDataKey]

export const selectMFADeactivatedPush = createSelector(
  selectMFASetup,
  ({ deactivatedPush }) => deactivatedPush
)

export const selectMFASetupEmail = createSelector(
  selectMFASetup,
  ({ email }) => email
)

export const selectMFASetupTOTP = createSelector(
  selectMFASetup,
  ({ totp }) => totp
)
