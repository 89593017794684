import { all, spawn } from 'redux-saga/effects'

import { api } from 'api'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'

const {
  api: { sso },
} = __CONFIG__

export const sendMFASMSDataKey = 'MFASendSMS'

const SendMFASMSActions = {
  send: `${sendMFASMSDataKey}/CREATE`,
}

type SendMFASMSState = Record<string, never>

const dataModule = createDataModule<SendMFASMSState, SSOAPIError>(
  sendMFASMSDataKey,
  `${sso.paths.mfa}/sms`,
  api.ssoBase
)

const { selectIsLoading, selectErrors, resetData } = dataModule

export {
  selectIsLoading as selectSendMFASMSIsLoading,
  selectErrors as selectSendMFASMSErrors,
  resetData as resetSendMFASMSData,
}

export const sendMFASMS = (id: string) => {
  return {
    type: SendMFASMSActions.send,
    urlPath: `/${id}/send`,
  }
}

export function* sendMFASMSRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}

export const sendMFASMSReducer = dataModule.reducer
