import React, { MouseEventHandler, useCallback } from 'react'
import { useExternalLink } from './useExternalLink'

export const ExternalLink: React.FC<{
  link: string
  children: React.ReactNode
}> = ({ link, children }) => {
  const externalLink = useExternalLink()

  const handleOnClick: MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      event.stopPropagation()
      externalLink(link)
    },
    [externalLink, link]
  )

  return <a onClick={handleOnClick}>{children}</a>
}
