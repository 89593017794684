import { useCallback, useEffect } from 'react'

const { GOOGLE_RECAPTCHA_SCRIPT_URL } = __CONFIG__

export const useGoogleReCaptchaV3 = (siteKeyId: string, actionName: string) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = `${GOOGLE_RECAPTCHA_SCRIPT_URL}?render=${siteKeyId}`
    document.body.appendChild(script)
  }, [siteKeyId, actionName])

  const getReCaptchaToken = useCallback(async () => {
    const token = await grecaptcha.enterprise.execute(siteKeyId, {
      action: actionName,
    })
    return token
  }, [actionName, siteKeyId])

  return { getReCaptchaToken }
}
