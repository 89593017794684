import axios from 'axios'
import aws from './aws'

type TResponseBody = unknown
type TRequestBody = Record<PropertyKey, unknown>

export const fetchRequestWithCookies = async <TResponse = TResponseBody>(
  url: string,
  customHeaders?: Record<string, string>
) => {
  return await axios.get<TResponse>(url, {
    withCredentials: true,
    headers: { ...customHeaders },
  })
}

export const patchRequestWithCookies = async <
  TRequest = TRequestBody,
  TResponse = TResponseBody,
>(
  url: string,
  data?: TRequest
) => {
  return await axios.patch<TResponse>(url, data, {
    withCredentials: true,
  })
}

export const postRequestWithCookies = async <
  TRequest = TRequestBody,
  TResponse = TResponseBody,
>(
  url: string,
  data?: TRequest,
  customHeaders?: Record<string, string>
) => {
  return await axios.post<TResponse>(url, data, {
    withCredentials: true,
    headers: { ...customHeaders },
  })
}

export const putRequestWithCookies = async <
  TRequest = TRequestBody,
  TResponse = TResponseBody,
>(
  url: string,
  data?: TRequest
) => {
  return await axios.put<TResponse>(url, data, {
    withCredentials: true,
  })
}

export const deleteRequestWithCookies = async (url: string) => {
  await axios.delete(url, { withCredentials: true })
}

export const fetchRequestWithSigner = async (url: string) => {
  return await aws.fetch(url, { method: 'GET' })
}

export const deleteRequestWithSigner = async (url: string) => {
  return await aws.fetch(url, { method: 'DELETE' })
}
