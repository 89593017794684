import { IntlShape } from 'react-intl'

import macbookLogo from 'assets/images/sessionDevices/macbook.png'
import iphoneLogo from 'assets/images/sessionDevices/iphone.png'
import ipadLogo from 'assets/images/sessionDevices/ipad.png'
import tvLogo from 'assets/images/sessionDevices/appleTv.png'
import androidLogo from 'assets/images/sessionDevices/android.png'
import windowsLogo from 'assets/images/sessionDevices/windows.png'
import unifiLogo from 'assets/images/sessionDevices/unifi.png'

type SimplifiedUserAgent = {
  agent: string | null
  is_ui_product: boolean
  os: string | null
}

export type FormattedSession = {
  device: string
  uaInfo: string
  device_os?: string
}

type formatSessionParams = {
  simplifiedUA: SimplifiedUserAgent
  deviceName: string | null
  userAgent: string | null
  deviceModel: string | null
}

type deviceImageParams = {
  deviceModel: string | null
  device?: string | null
  deviceOs?: string | null
}

export const formatSession = (
  sessionData: formatSessionParams
): FormattedSession => {
  const { simplifiedUA, deviceName, userAgent, deviceModel } = sessionData
  const { is_ui_product, os, agent } = simplifiedUA
  if (is_ui_product && deviceName && os && /\s\(/.test(deviceName)) {
    // for iOS apps the device name follows this format: "model (systemName systemVersion)" if the device model exists
    // it means the device name is user given and we should not parse it, otherwise we keep parsing
    const device_name = deviceModel ? deviceName : deviceName.split(' (')[0]
    let device = deviceName.split(' (')[1].split(')')[0]
    if (
      (/iPhone/.test(device_name) && !deviceModel) ||
      (/iPad/.test(device_name) && !deviceModel)
    ) {
      device = device_name
    }
    return {
      uaInfo: `${device_name} - ${os}`,
      device,
    }
  }
  if (is_ui_product && deviceName && os) {
    // for Android apps the device name follows this format: "manufacturer model" -> Samsung Galaxy S22 for example
    return {
      uaInfo: `${deviceName} - ${os}`,
      device: deviceName,
      device_os: 'Android',
    }
  }
  if (is_ui_product && !deviceName && os) {
    // for some reason the WiFiman app does not give us any information about device at all
    return { uaInfo: os, device: 'Unknown' }
  }
  if (!is_ui_product && os && deviceName) {
    // Connect and Access Android sessions, that for some reason are not marked as being ui product nor do they have information on what app they come from at all
    return {
      uaInfo: deviceName,
      device: os,
    }
  }
  if (!is_ui_product && os && agent) {
    // Web sessions
    let parsedOs = os

    if (/Mac/.test(parsedOs)) {
      parsedOs = 'Mac'
    }
    if (/iOS/.test(parsedOs) && userAgent && /iPad/.test(userAgent)) {
      parsedOs = 'iPad'
    }
    if (/iOS/.test(parsedOs)) {
      parsedOs = 'iPhone'
    }

    return { uaInfo: `${parsedOs} - ${agent}`, device: parsedOs }
  }
  return { uaInfo: 'Unknown', device: 'Unknown' }
}

export const formatTime = (time: string, intl: IntlShape) => {
  const date = new Date(time)
  const now = new Date()

  const minutesAgo = Math.floor((now.getTime() - date.getTime()) / 60000)
  const hoursAgo = Math.floor(minutesAgo / 60)
  const daysAgo = Math.floor(minutesAgo / 1440)

  if (minutesAgo < 1) {
    return intl.formatMessage({ id: 'SETTINGS_SESSION_TIME_NOW' })
  }

  if (minutesAgo < 60) {
    return `${minutesAgo} ${intl.formatMessage({
      id: 'SETTINGS_SESSION_TIME_MINUTES',
    })}`
  }

  if (hoursAgo < 24) {
    return `${hoursAgo} ${intl.formatMessage({
      id: 'SETTINGS_SESSION_TIME_HOURS',
    })}`
  }

  if (daysAgo === 1) {
    return `${daysAgo} ${intl.formatMessage({
      id: 'SETTINGS_SESSION_TIME_DAY',
    })}`
  }

  return `${daysAgo} ${intl.formatMessage({
    id: 'SETTINGS_SESSION_TIME_DAYS',
  })}`
}

export const getDeviceImage = (deviceInfo: deviceImageParams): string => {
  const { deviceModel, device, deviceOs } = deviceInfo
  const model = deviceModel ?? ''
  if (/iphone/i.test(model) || /iphone/i.test(device ?? '')) {
    return iphoneLogo
  }
  if (/ipad/.test(model) || /ipad/i.test(device ?? '')) {
    return ipadLogo
  }
  if (
    /mac/.test(model) ||
    device === 'macOS' ||
    device === 'Ubuntu' ||
    device === 'Mac'
  ) {
    return macbookLogo
  }
  if (
    /windows/.test(model) ||
    device === 'Windows' ||
    device === 'Linux' ||
    (/linux/.test(model) && !/android/.test(model))
  ) {
    return windowsLogo
  }
  if (/tv/.test(model) || /tv/i.test(device ?? '')) {
    return tvLogo
  }
  if (
    /google/.test(model) ||
    /samsung/.test(model) ||
    /lg/.test(model) ||
    /motorola/.test(model) ||
    /nokia/.test(model) ||
    /android/.test(model) ||
    device === 'Android' ||
    (deviceOs && deviceOs === 'Android')
  ) {
    return androidLogo
  }

  return unifiLogo
}
