import {
  BLACKLISTED_COUNTRY,
  FIFTEEN_SECS_IN_MILLISECONDS,
} from 'pages/constants'
import { getCountryFromIP } from './getCountryFromIp'

const {
  api: { helpCenter },
} = __CONFIG__

export const isIpBlacklisted = async () => {
  const country = await getCountryFromIP()
  const blacklistedCountries = Object.values<string>(BLACKLISTED_COUNTRY)
  const isBlacklisted = blacklistedCountries.includes(country)

  return { isBlacklisted, country }
}

export const redirectAfterSpecifiedTime = (
  time = FIFTEEN_SECS_IN_MILLISECONDS
) => {
  return setTimeout(() => {
    window.location.href = helpCenter.base
  }, time)
}
