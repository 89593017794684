import { useQueries } from '@tanstack/react-query'
import { BillingInvoicesApi } from 'api/billing/billingInvoices'
import { QueryKey } from '../types'
import { useBillingSubscriptionsQuery } from './useBillingSubscriptionsQuery'
import { useMemo } from 'react'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'

const billingInvoicesApi = new BillingInvoicesApi()

export const useBillingUpcomingInvoicesQuery = () => {
  const { subscriptionsWithUpcomingInvoices } = useBillingSubscriptionsQuery()

  const billingUpcomingInvoices = useQueries({
    queries:
      subscriptionsWithUpcomingInvoices?.map(({ id, region }) => {
        return {
          queryKey: [QueryKey.BILLING_UPCOMING_INVOICES, id],
          queryFn: () => billingInvoicesApi.getUpcoming(id, region),
          staleTime: FIVE_MINUTES_IN_MILLISECONDS,
          retry: false,
          retryOnMount: false,
          refetchOnWindowFocus: false,
        }
      }) ?? [],
  })

  const upcomingInvoices: StripeInvoice[] = useMemo(() => {
    return billingUpcomingInvoices
      .map(({ data }) => {
        if (!data) {
          return []
        }

        const upcomingInvoiceRegions = Object.keys(data) as StripeRegionCode[]

        if (!upcomingInvoiceRegions.length) {
          return []
        }

        return upcomingInvoiceRegions.map((region) => {
          return { ...data[region], region: region }
        })
      })
      .flat()
  }, [billingUpcomingInvoices])

  return { upcomingInvoices }
}
