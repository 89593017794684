import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { FormikProps, withFormik } from 'formik'
import { Button, Input } from '@ubnt/ui-components'
import { History } from 'history'

import {
  AuthError,
  loginMFA,
  selectErrors,
  selectIsLoading,
} from 'features/auth/modules/auth'
import { LoginMFAData, RootState } from 'types/types'
import { Description } from 'components/Description'
import authorizedRedirect from 'components/authorizedRedirect'
import styled from 'theme/styled'

import { AuthForm, ButtonContainer } from '../styles'

interface Props {
  isLoading: boolean
  isAuthenticated: boolean
  apiErrors: AuthError
  handleLogin: typeof loginMFA
  history: History
  children: React.ReactNode
}

type FormValues = LoginMFAData

const LoginMFABackupCodes: React.FC<Props & FormikProps<FormValues>> = ({
  isLoading,
  apiErrors,
  values,
  setFieldValue,
  children,
}) => {
  const intl = useIntl()
  const [currentError, setCurrentError] = useState<undefined | string>()
  const [isWaiting, setIsWaiting] = React.useState(false)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null
    if (currentError) {
      timer = setTimeout(() => {
        setIsWaiting(false)
      }, 500)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [currentError, setFieldValue])

  useEffect(() => {
    setCurrentError(apiErrors?.detail)
  }, [apiErrors?.detail])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('token', e.target.value)
    setCurrentError('')
  }

  return (
    <AuthForm id="login-mfa-form">
      <StyledDescription>
        {intl.formatMessage({ id: 'LOGIN_MFA_BACKUP_CODE_DESCRIPTION' })}
      </StyledDescription>
      <InputContainer>
        <StyledInput
          value={values.token}
          maxLength={13}
          onChange={handleChange}
          invalid={currentError}
          disabled={isLoading || isWaiting}
        />
      </InputContainer>

      <ButtonContainer>
        <Button
          type="submit"
          disabled={
            isLoading ||
            isWaiting ||
            !values.token.match(/^[a-zA-Z0-9]{6}-?[a-zA-Z0-9]{6}$/gm)
          }
          loader={isLoading ? 'dots' : undefined}
          variant="primary"
          full
        >
          {intl.formatMessage({ id: 'COMMON_ACTION_CONFIRM' })}
        </Button>
      </ButtonContainer>
      {children}
    </AuthForm>
  )
}

const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { handleLogin, history } }) =>
    handleLogin(values, { history }),
  mapPropsToValues: () => ({
    token: '',
  }),
})

const mapStateToProps = (state: RootState) => ({
  apiErrors: selectErrors(state),
  isLoading: selectIsLoading(state),
})

const mapDispatchToProps = {
  handleLogin: loginMFA,
}

export default authorizedRedirect(
  connect(mapStateToProps, mapDispatchToProps)(enchance(LoginMFABackupCodes))
)

const StyledDescription = styled(Description)`
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const StyledInput = styled(Input)`
  margin: 20px 0 30px;
  font-size: 20px;
  font-family: 'Lato';
  color: ${(p) => p.theme.text2};
  width: 100% !important;
`

const InputContainer = styled.div`
  width: 276px;
`
