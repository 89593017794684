import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import theme from 'theme'
import styled from 'theme/styled'
import { selectAssuranceLevel } from 'features/auth/modules/assuranceLevel'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import {
  DeviceSortingOptions,
  getTrustedDeviceNameForList,
  sortDevices,
} from './utils'
import { TrustedDeviceRow } from './TrustedDevice'
import { TrustedDevicesListHeader } from './TrustedDevicesListHeader'
import { TrustedDevicesListFooter } from './TrustedDevicesListFooter'
import { useTrustedDevicesQuery } from 'store/queries/useTrustedDevicesQuery'

export const TrustedDevicesList = () => {
  const { trustedDevices, currentTrustedDevice } = useTrustedDevicesQuery()
  const [isManaging, setIsManaging] = useState(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [sorting, setSorting] = useState<DeviceSortingOptions>(
    DeviceSortingOptions.NAME_ASC
  )
  const devicesIds = trustedDevices?.map((device) => device.device_id)
  const assuranceLevel = useSelector(selectAssuranceLevel)
  const assuranceLocked = assuranceLevel.data.level === 1
  const { isMaxMobileXLarge } = useContext(MediaSizingContext)

  const currentDeviceId = currentTrustedDevice?.device_id

  useEffect(() => {
    if (assuranceLocked && (isManaging || selectedIds.length)) {
      setIsManaging(false)
      setSelectedIds([])
    }
  }, [assuranceLocked, isManaging, selectedIds.length])

  const toggleManaging = () => {
    if (selectedIds.length) {
      setSelectedIds([])
    }
    setIsManaging(!isManaging)
  }

  useEffect(() => {
    if (
      isMaxMobileXLarge &&
      (sorting === DeviceSortingOptions.DATE_ASC ||
        sorting === DeviceSortingOptions.DATE_DESC)
    ) {
      setSorting(DeviceSortingOptions.NAME_ASC)
    }
  }, [isMaxMobileXLarge, sorting])

  const areAllIdsSelected = devicesIds.every((id) => selectedIds.includes(id))

  const getIsIdSelected = (id: string) => selectedIds.includes(id)

  const toggleSelectAllDeviceIds = () => {
    if (areAllIdsSelected) {
      return setSelectedIds([])
    }
    setSelectedIds(devicesIds)
  }

  const toggleSelectId = (id: string) => {
    if (getIsIdSelected(id)) {
      return setSelectedIds((prev) => prev.filter((prevId) => prevId !== id))
    }
    return setSelectedIds((prev) => prev.concat(id))
  }

  const sortedDevices = useMemo(() => {
    const displayDeviceData = trustedDevices.map((device) => {
      const trustedDeviceName = getTrustedDeviceNameForList({
        model: device.device_model,
        name: device.device_name,
        isUiProduct: !!device.simplified_agent?.is_ui_product,
      })
      const trustedDeviceFallbackName = '-'
      const deviceName = trustedDeviceName ?? trustedDeviceFallbackName
      return {
        name: deviceName,
        id: device.device_id,
        date: device.created_at,
        model: device.device_model,
      }
    })
    return sortDevices(displayDeviceData, sorting)
  }, [sorting, trustedDevices])

  const toggleSorting = (type: 'name' | 'date') => {
    if (type === 'name' && sorting === DeviceSortingOptions.NAME_ASC) {
      return setSorting(DeviceSortingOptions.NAME_DESC)
    }
    if (type === 'name') {
      return setSorting(DeviceSortingOptions.NAME_ASC)
    }
    if (type === 'date' && sorting === DeviceSortingOptions.DATE_ASC) {
      return setSorting(DeviceSortingOptions.DATE_DESC)
    }
    return setSorting(DeviceSortingOptions.DATE_ASC)
  }

  return (
    <>
      <TrustedDevicesListHeader
        isManaging={isManaging}
        sorting={sorting}
        areAllIdsSelected={areAllIdsSelected}
        toggleSelectAllDeviceIds={toggleSelectAllDeviceIds}
        toggleSorting={toggleSorting}
      />
      {sortedDevices.map((device) => {
        const isCurrentDevice = currentDeviceId === device.id
        return (
          <TrustedDeviceRow
            isCurrentDevice={isCurrentDevice}
            device={device}
            isManaging={isManaging}
            key={device.id}
            toggleSelectId={toggleSelectId}
            isSelected={getIsIdSelected(device.id)}
          />
        )
      })}
      <TrustedDevicesListFooter
        isManaging={isManaging}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        currentDeviceId={currentDeviceId}
        toggleManaging={toggleManaging}
      />
    </>
  )
}

export const DevicesGrid = styled.div<{ $isManaging: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isManaging }) =>
    $isManaging ? '24px 360px 1fr' : '360px 1fr'};
  border-bottom: ${({ theme }) => `1px solid ${theme.neutral03}`};

  @media (max-width: ${theme.media.mobileXLarge}) {
    grid-template-columns: ${({ $isManaging }) =>
      $isManaging ? '24px 1fr' : '1fr'};
  }
`
