import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PasskeyBannerApi } from 'api/passkeyBanner'
import { QueryKey } from 'store/types'

const passkeyBannerApi = new PasskeyBannerApi()

export const useMarkPasskeyPromptAsSeen = () => {
  const queryClient = useQueryClient()

  const markPasskeyPromptAsSeen = useMutation({
    mutationFn: passkeyBannerApi.update,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SHOULD_DISPLAY_PASSKEY_BANNER],
      }),
  })

  return {
    markPasskeyPromptAsSeen: markPasskeyPromptAsSeen.mutate,
  }
}
