import React, { useEffect, useState } from 'react'
import PopoverCard from '@ubnt/ui-components/Popover/PopoverCard'
import styled from 'theme/styled'
import { PasswordRequirement } from './PasswordRequirement'
import { checkPasswordRequirements } from './checkPasswprdRequirements'
import theme from 'theme'

export type PasswordRequirementState = {
  length?: boolean
  uppercase?: boolean
  lowercase?: boolean
  digits?: boolean
  symbols?: boolean
}

export const PasswordRequirements: React.FC<{
  passwordFocused: boolean
  password?: string
  top?: string
}> = ({ passwordFocused, password, top }) => {
  const [isValidPassword, setIsValidPassword] =
    useState<PasswordRequirementState | null>(null)

  useEffect(() => {
    setIsValidPassword(checkPasswordRequirements(password))
  }, [password])

  return (
    <StyledPopoverCard open={passwordFocused} size="auto" $top={top}>
      <PasswordRequirement
        hasBeenMeet={!!isValidPassword?.length}
        copy="PASSWORD_VALIDATION_SIZE"
      />
      <PasswordRequirement
        hasBeenMeet={!!isValidPassword?.uppercase}
        copy="PASSWORD_VALIDATION_UPPERCASE"
      />
      <PasswordRequirement
        hasBeenMeet={!!isValidPassword?.lowercase}
        copy="PASSWORD_VALIDATION_LOWERCASE"
      />
      <PasswordRequirement
        hasBeenMeet={!!isValidPassword?.digits}
        copy="PASSWORD_VALIDATION_NUMBER"
      />
      <PasswordRequirement
        hasBeenMeet={!!isValidPassword?.symbols}
        copy="PASSWORD_VALIDATION_SYMBOL"
      />
    </StyledPopoverCard>
  )
}

const StyledPopoverCard = styled(PopoverCard)<{ $top?: string }>`
  position: absolute;
  top: ${({ $top }) => $top ?? '56px'};
  z-index: 25;
  padding: 12px 8px;
  gap: 8px;

  @media (max-width: ${theme.media.mobileLarge}) {
    top: -160px;
  }
`
