export enum BLACKLISTED_COUNTRY {
  RUSSIA = 'Russia',
  BELARUS = 'Belarus',
  CUBA = 'Cuba',
  IRAN = 'Iran',
  NORTH_KOREA = 'North Korea',
  SYRIA = 'Syria',
}

export const FIFTEEN_SECS_IN_MILLISECONDS = 15000
