import { typography } from '@ubnt/ui-components/styles/designToken'
import styled from 'theme/styled'

const Section = styled.div<{
  $omitMarginTop?: boolean
  $omitMarginBottom?: boolean
  $marginBottomDesktop?: string
}>`
  margin-bottom: ${({ $omitMarginBottom, $marginBottomDesktop }) => {
    if ($omitMarginBottom) {
      return 0
    }
    if ($marginBottomDesktop) {
      return $marginBottomDesktop
    }
    return '16px'
  }};
  margin-top: ${({ $omitMarginTop }) => ($omitMarginTop ? 0 : `16px`)};
  padding: 16px;
  border: solid 1px ${({ theme }) => theme.neutral03};
  border-radius: 10px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    margin-bottom: ${({ $omitMarginBottom }) => {
      if ($omitMarginBottom) {
        return 0
      }
      return '16px'
    }};
  }
`

const SectionHeader = styled.div<{ $marginBottom?: number }>`
  font: ${typography['desktop-heading-large']};
  color: ${({ theme }) => theme.text1};
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? `${$marginBottom}px` : '4px'};
  display: flex;
`

const SectionBody = styled.div`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text3};
`

const InputLabel = styled.div`
  margin-bottom: 2px;
  padding-top: 4px;
  align-items: flex-start;
  color: ${({ theme }) => theme.text2};
  display: flex;
  flex-direction: row;
  font: ${typography['desktop-body']};
`

export { InputLabel, Section, SectionHeader, SectionBody }
