import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Input } from '@ubnt/ui-components'
import { FormikProps, withFormik } from 'formik'

import {
  resetApiErrors,
  selectErrors,
  selectIsEmailSent,
  selectIsLoading,
  sendResetPassword,
} from 'features/auth/modules/resetPassword'
import styled from 'theme/styled'
import Yup from 'validators/yupLocaleConfig'
import authorizedRedirect from 'components/authorizedRedirect'
import PublicPage from 'pages/PublicPage'
import { ApiUpdateError, RootState } from 'types/types'
import { CheckboxReCaptcha } from 'features/RecaptchaCheckbox/CheckboxReCaptcha'
import { SsoErrorCode } from 'features/RecaptchaCheckbox/types'
import { useReCaptcha } from 'components/ReCaptcha'

import { AuthForm, Description } from './styles'

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required().label('COMMON_LABEL_EMAIL'),
  captcha: Yup.string().recaptcha(),
})

interface Props {
  isLoading?: boolean
  isEmailSent: boolean
  apiErrors: ApiUpdateError
  handleSubmit(data: {
    email?: string
    captcha?: string
    site_key?: string
  }): void
}

interface FormValues {
  email: string
  site_key: string
}

const { GOOGLE_RECAPTCHA_SECRET_SCORE, GOOGLE_RECAPTCHA_SECRET_CHALLENGE } =
  __CONFIG__

const ForgotPasswordForm: React.FC<Props & FormikProps<FormValues>> = ({
  touched,
  values,
  handleChange,
  errors,
  apiErrors,
  isEmailSent,
  isLoading,
  setFieldValue,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { ref: recaptchaRef } = useReCaptcha()
  const [isCaptchaLoading, setCaptchaLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [tryAgainTimeLeft, setTryAgainTimeLeft] = useState<number>(5)

  const checkBoxCaptchaIsVisible = useMemo(
    () =>
      apiErrors.error_code === SsoErrorCode.CHALLENGE_RECAPTCHA_TOKEN_REQUIRED,

    [apiErrors]
  )

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null
    if (tryAgainTimeLeft > 0 && isSubmitLoading) {
      timer = setTimeout(() => setTryAgainTimeLeft(tryAgainTimeLeft - 1), 1000)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [tryAgainTimeLeft, isSubmitLoading])

  useEffect(() => {
    if (isLoading && !isSubmitLoading) {
      setIsSubmitLoading(true)
      setTryAgainTimeLeft(5)
    }
    isSubmitLoading &&
      !isLoading &&
      tryAgainTimeLeft === 0 &&
      setIsSubmitLoading(false)
  }, [isLoading, isSubmitLoading, tryAgainTimeLeft])

  useEffect(() => {
    isCaptchaLoading &&
      // make sure that captcha stops loading in case the challenge window has been closed
      setTimeout(() => isCaptchaLoading && setCaptchaLoading(false), 1000)
  }, [isCaptchaLoading, setCaptchaLoading])

  const handleSubmitWithRecaptcha = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!recaptchaRef) return

      try {
        ForgotPasswordSchema.validateSync(values)
      } catch {
        return
      }

      setCaptchaLoading(true)
      isEmailSent && recaptchaRef.reset()

      const token = await recaptchaRef.executeAsync()
      setCaptchaLoading(false)

      if (token) {
        return dispatch(sendResetPassword({ ...values, captcha: token }))
      }
    },
    [dispatch, isEmailSent, recaptchaRef, values]
  )

  const resetErrors = useCallback(() => {
    dispatch(resetApiErrors())
    setError(null)
  }, [dispatch])

  useEffect(() => {
    if (apiErrors && Object.keys(apiErrors).length) {
      recaptchaRef?.reset()
    }
  }, [apiErrors, setFieldValue, recaptchaRef])

  useEffect(() => {
    if (apiErrors.detail === 'Request not found or expired') {
      resetErrors()
    }
    if (apiErrors.detail === 'Not found.') {
      return setError(
        intl.formatMessage({
          id: 'COMMON_AUTH_FORGOT_PASSWORD_EMAIL_NOT_FOUND',
        })
      )
    }
    if (apiErrors.detail) {
      return setError(intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE' }))
    }
    setError(null)
  }, [apiErrors, intl, resetErrors])

  const handleCheckboxRecaptcha = (token: string) => {
    dispatch(
      sendResetPassword({
        ...values,
        site_key: GOOGLE_RECAPTCHA_SECRET_CHALLENGE,
        captcha: token,
      })
    )
  }

  return (
    <PublicPage
      title={intl.formatMessage({ id: 'COMMON_AUTH_FORGOT_PASSWORD_TITLE' })}
    >
      <Wrapper>
        <StyledAuthForm
          id="forgot-password-form"
          onSubmit={handleSubmitWithRecaptcha}
        >
          {!isEmailSent ? (
            <>
              <StyledDescription>
                {intl.formatMessage({ id: 'COMMON_AUTH_FORGOT_PASSWORD' })}
              </StyledDescription>
              <StyledInput
                variant="secondary"
                type="email"
                label={intl.formatMessage({ id: 'COMMON_LABEL_EMAIL' })}
                name="email"
                value={values.email}
                onChange={(e) => {
                  apiErrors.detail && resetErrors()
                  handleChange(e)
                }}
                invalid={(touched.email && errors.email) || error || undefined}
                disabled={isLoading}
                full
              />
              <StyledButton
                variant="primary"
                type="submit"
                disabled={isLoading || checkBoxCaptchaIsVisible}
                loader={isLoading ? 'dots' : undefined}
              >
                {intl.formatMessage({
                  id: 'COMMON_AUTH_ACTION_RESET_PASSWORD',
                })}
              </StyledButton>
              {checkBoxCaptchaIsVisible && (
                <CheckboxReCaptcha
                  paddingTop={0}
                  paddingBottom={20}
                  onVerify={handleCheckboxRecaptcha}
                />
              )}
              <Link to="/login">
                <Button variant="inline" disabled={isLoading} full>
                  {intl.formatMessage({ id: 'COMMON_ACTION_BACK' })}
                </Button>
              </Link>
            </>
          ) : (
            <>
              <Description>
                <FormattedMessage
                  id="COMMON_AUTH_FORGOT_PASSWORD_SENT"
                  values={{
                    b: () => <b className="intl-message">{values.email}</b>,
                  }}
                />
              </Description>
              <NoEmailError>
                {intl.formatMessage({ id: 'COMMON_AUTH_NO_EMAIL_RECEIVED' })}{' '}
                <Button
                  variant="inline"
                  type="submit"
                  disabled={isSubmitLoading || checkBoxCaptchaIsVisible}
                >
                  {isSubmitLoading ? (
                    <FormattedMessage
                      id="COMMON_ACTION_TRY_AGAIN_TIMER"
                      values={{ seconds: tryAgainTimeLeft }}
                    />
                  ) : (
                    <FormattedMessage id="COMMON_ACTION_TRY_AGAIN" />
                  )}
                </Button>
              </NoEmailError>
              {checkBoxCaptchaIsVisible && (
                <CheckboxReCaptcha
                  paddingTop={20}
                  paddingBottom={0}
                  onVerify={handleCheckboxRecaptcha}
                />
              )}
              <ButtonContainer>
                <Link to="/login">
                  <Button variant="primary" full>
                    {intl.formatMessage({ id: 'COMMON_ACTION_BACK' })}
                  </Button>
                </Link>
              </ButtonContainer>
            </>
          )}
        </StyledAuthForm>
      </Wrapper>
    </PublicPage>
  )
}

const ForgotPasswordFormWrapped = withFormik<Props, FormValues>({
  handleSubmit: (values, { props }) => props.handleSubmit(values),
  mapPropsToValues: () => ({
    email: '',
    site_key: GOOGLE_RECAPTCHA_SECRET_SCORE,
  }),
  validationSchema: ForgotPasswordSchema,
})(ForgotPasswordForm)

const mapStateToProps = (state: RootState) => ({
  isEmailSent: selectIsEmailSent(state),
  isLoading: selectIsLoading(state),
  apiErrors: selectErrors(state),
})

const mapDispatchToProps = {
  handleSubmit: sendResetPassword,
}

export default authorizedRedirect(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordFormWrapped)
)

const StyledAuthForm = styled(AuthForm)`
  padding: 0;
  &:nth-child(2) {
    margin: 0;
  }
`

const StyledDescription = styled(Description)`
  height: 94px;
  margin-bottom: 0;
`

const StyledInput = styled(Input)`
  height: 64px;
`

const ButtonContainer = styled.div`
  margin-top: 24px;
`

const StyledButton = styled(Button)`
  height: 40px;
  margin-bottom: 24px;
`

const NoEmailError = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  padding: 0;
`

const Wrapper = styled.div`
  width: 100%;
`
