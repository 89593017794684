import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'
import LargeProjectLogo from 'assets/svgs/largeProjectLogo.svg'
import LargeProjectImage from 'assets/images/largeProjectBackground.png'
import LargeProjectImageLow from 'assets/images/largeProjectBackgroundLow.png'

export const LargeProjectTile = () => {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src = LargeProjectImage
    imageToLoad.onload = () => setImageLoaded(true)
  }, [])

  return (
    <Container $imageLoaded={imageLoaded}>
      <img src={LargeProjectLogo} className="large-project-logo" />
      <SubTitle>
        <FormattedMessage id="SUPPORT_UNIFI_INFO_LARGE_PROJECTS_DESCRIPTION" />
      </SubTitle>
      <Button<'a'>
        component="a"
        href="https://experts.ui.com/"
        target="_blank"
        variant="primary"
      >
        <FormattedMessage id="SUPPORT_UNIFI_INFO_LARGE_PROJECTS_INQUIRE" />
      </Button>
    </Container>
  )
}

const Container = styled.div<{
  $imageLoaded: boolean
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 28px 34px;
  gap: 12px;
  background-size: cover;
  background-position: center center;
  background-image: ${({ $imageLoaded }) =>
    $imageLoaded
      ? `url(${LargeProjectImage})`
      : `url(${LargeProjectImageLow})`};

  .large-project-logo {
    max-height: 20px;
    object-fit: contain;
    object-position: left center;
  }
`

const SubTitle = styled.span`
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.neutral00};
  text-align: center;
  padding-bottom: 24px;
`
