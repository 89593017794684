import DOMPurify from 'dompurify'
import { IQuickSupportModel, PlatformType, QuickSupportQueryKey } from './types'

const { ZENDESK_SOCIAL_TICKET_FORM_KEY } = __CONFIG__
const ONE_CLICK_SOCIAL_TAG = '1click-social'
const SANITIZE_OPTIONS = { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }

export const parseQueryParams = (search: string): IQuickSupportModel => {
  const searchParams = new URLSearchParams(search)

  return Object.values(QuickSupportQueryKey).reduce((model, key) => {
    return searchParams.has(key)
      ? {
          ...model,
          [key]: DOMPurify.sanitize(
            searchParams.get(key) as string,
            SANITIZE_OPTIONS
          ),
        }
      : model
  }, {} as IQuickSupportModel)
}

export const mapModelToZendeskDto = (model: IQuickSupportModel) => {
  return {
    subject: `${model.platform}: ${model.title}`,
    comment: { body: `${model.description} \n original issue: ${model.link}` },
    ticket_form_id: ZENDESK_SOCIAL_TICKET_FORM_KEY,
    tags: [ONE_CLICK_SOCIAL_TAG],
  }
}

export const getPlatformTitle = (platform: PlatformType) => {
  return (
    {
      [PlatformType.Reddit]: 'Reddit',
      [PlatformType.Facebook]: 'Facebook',
      [PlatformType.X]: 'X',
      [PlatformType.UICommunity]: 'UI Community',
    }[platform] ?? null
  )
}
