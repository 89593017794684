import React, { useCallback, useContext } from 'react'
import { QuoteIcon } from '@ubnt/icons'
import { EntityToast, ToastContext } from '@ubnt/ui-components'
import { useIntl } from 'react-intl'
import { ToastType } from '../types'

const useTaxIdToast = () => {
  const { createNotification, removeNotification } = useContext(ToastContext)
  const intl = useIntl()

  const taxIdSuccessToast = useCallback(
    (type: ToastType) => {
      createNotification(
        <EntityToast
          id={`${type}-taxId-success`}
          icon={<QuoteIcon />}
          stateIndicator={'success'}
          onClose={(_, id) => id && removeNotification(id)}
          title={intl.formatMessage(
            { id: 'SETTINGS_PAYMENTS_TAX_ID_SUCCESS' },
            {
              action: type,
            }
          )}
        />
      )
    },
    [createNotification, intl, removeNotification]
  )

  const taxIdErrorToast = useCallback(
    (type: ToastType, errorMessage: string) => {
      createNotification(
        <EntityToast
          id={`${type}-taxId-error`}
          icon={<QuoteIcon />}
          stateIndicator={'danger'}
          onClose={(_, id) => id && removeNotification(id)}
          title={errorMessage}
        />
      )
    },
    [createNotification, removeNotification]
  )

  return { taxIdSuccessToast, taxIdErrorToast }
}

export default useTaxIdToast
