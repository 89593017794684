import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import createDataModule from 'utils/moduleCreator'
import { doDoubleLogin } from 'features/auth/modules/auth'

const {
  api: { sso },
} = __CONFIG__

export const loginPushDataKey = 'MFALoginPush'

const MFALoginPushActions = {
  fetch: `${loginPushDataKey}/FETCH`,
  create: `${loginPushDataKey}/CREATE`,
}

type LoginPushState = Record<string, never>

const dataModule = createDataModule<LoginPushState, any>(
  loginPushDataKey,
  `${sso.paths.mfa}/push`,
  api.ssoBase,
  undefined,
  undefined,
  undefined,
  true
)

const { fetchCancel, selectErrors, resetErrors, FETCH_DONE, CREATE_DONE } =
  dataModule

export {
  fetchCancel as pushLoginCancel,
  selectErrors as selectLoginPushErrors,
  resetErrors as resetLoginPushErrors,
}

export const initiateLoginMFAPush = () => ({
  type: MFALoginPushActions.create,
  urlPath: `/send`,
})

export const loginMFAPush = () => ({
  type: MFALoginPushActions.fetch,
  urlPath: `/poll-login`,
})

function* pushInitiateDoneSaga() {
  yield put(loginMFAPush())
}

function* subscribeToInitiatePushDoneSaga() {
  yield takeEvery(CREATE_DONE, pushInitiateDoneSaga)
}

function* pushDoneSaga() {
  yield put(doDoubleLogin())
}

function* subscribeToPushDoneSaga() {
  yield takeEvery(FETCH_DONE, pushDoneSaga)
}

export function* loginPushRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToInitiatePushDoneSaga),
    spawn(subscribeToPushDoneSaga),
  ])
}

export const loginPushReducer = dataModule.reducer
