import React from 'react'
import { Loader } from '@ubnt/ui-components'

import styled from 'theme/styled'

import { SessionItem } from './SessionItem'
import { useSessionsQuery } from 'store/queries/useSessionsQuery'

export const SessionContent: React.FC = () => {
  const { isSessionsLoading, currentSession, nonCurrentSessions } =
    useSessionsQuery()

  if (isSessionsLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  return (
    <Wrapper>
      {currentSession && (
        <SessionItem session={currentSession} isCurrentSession />
      )}
      {nonCurrentSessions?.map((session, i) => (
        <SessionItem session={session} key={i} />
      ))}
    </Wrapper>
  )
}

const LoaderContainer = styled.div`
  display: flex;
  margin: 50px;
  justify-content: center;
`

const Wrapper = styled.div`
  > div:last-child {
    border: none;
  }
`
