import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmationCode from '@ubnt/ui-components/ConfirmationCode/ConfirmationCode'

import { typography } from 'theme'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import {
  resetProfileApiErrors,
  selectIsEmailChanged,
  selectProfileData,
  selectProfileErrors,
  selectProfileIsLoading,
  updateProfileData,
} from 'modules/profile'
import { GenericModal } from 'components/generic-modal/GenericModal'
import styled from 'theme/styled'
import { setVisibleModal } from 'modules/modals'

import { TOO_MANY_REQUESTS_CODE_MODAL_ID } from './TooManyRequestsModal'

interface Props {
  email?: string
}

const EmailVerifyCodeModal: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl, email }) => {
  const dispatch = useDispatch()
  const profile = useSelector(selectProfileData)
  const [value, setValue] = useState<string>('')

  const requestIsLoading = useSelector(selectProfileIsLoading)
  const updateError = useSelector(selectProfileErrors)

  const isEmailChanged = useSelector(selectIsEmailChanged)

  const errorMessage = useMemo(() => {
    if (!updateError) return undefined
    if (updateError.email) {
      // Error if there was a problem with the update profile (email) itself
      return intl.formatMessage({
        id: 'GENERIC_ERROR_MESSAGE_PLEASE_TRY_AGAIN',
      })
    }
    if (updateError[0] === 'Email change request not found') {
      // Error if the code expired or the request was not correctly submited
      return intl.formatMessage({
        id: 'SETTINGS_PROFILE_CHANGE_EMAIL_REQUEST_ERROR',
      })
    }
    if (updateError[0] === 'The email change code you entered is incorrect') {
      return intl.formatMessage({
        id: 'SETTINGS_PROFILE_CHANGE_EMAIL_TOKEN_ERROR',
      })
    }

    if (updateError?.detail === 'Too many requests') {
      const emailChangeBan = Math.floor(Date.now() / 1000).toString()

      localStorage.setItem(
        `emailChangeBan-${profile?.username}`,
        emailChangeBan
      )
      dispatch(setVisibleModal(TOO_MANY_REQUESTS_CODE_MODAL_ID))
      return
    }
  }, [dispatch, intl, profile?.username, updateError])

  const handleOnSubmit = useCallback(() => {
    if (value.length !== 6) return
    localStorage.removeItem(`emailChangeBan-${profile?.username}`)
    dispatch(
      updateProfileData({
        email,
        email_change_verification_code: value,
      })
    )
  }, [dispatch, email, profile?.username, value])

  const handleClose = () => {
    dispatch(resetProfileApiErrors())
    onClose?.()
  }

  useEffect(() => {
    if (value.length === 6) {
      handleOnSubmit()
    }
  }, [value, handleOnSubmit])

  if (!email) return <></>

  const getLoader = () => {
    if (requestIsLoading) return 'dots'
    if (isEmailChanged) return 'success'
    return
  }

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage id="SETTINGS_PROFILE_CHANGE_EMAIL" tagName="div" />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: handleClose,
          variant: 'default',
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CHANGE' }),
          type: 'submit',
          variant: 'primary',
          onClick: handleOnSubmit,
          style: { outline: 'none' },
          disabled: value.length !== 6,
          loader: getLoader(),
        },
      ]}
    >
      <FormattedMessage
        id="SETTINGS_PROFILE_CHANGE_EMAIL_DESCRIPTION"
        values={{
          email,
        }}
        tagName="div"
      />
      <Wrapper>
        <div>
          <ConfirmationCode
            tokens={6}
            type="number"
            onChange={(changedToken: string) => {
              setValue(changedToken)
            }}
            className="tokenInput"
            value={value}
          />
          {errorMessage && <ValidationError>{errorMessage}</ValidationError>}
        </div>
      </Wrapper>
    </GenericModal>
  )
}

const EmailVerifyCodeModalConnected = injectIntl(EmailVerifyCodeModal)

export const VERIFY_EMAIL_CODE_MODAL_ID = 'VERIFY_EMAIL_CODE_MODAL_ID'

export default () => (
  <ModalWrapper modalId={VERIFY_EMAIL_CODE_MODAL_ID}>
    <EmailVerifyCodeModalConnected />
  </ModalWrapper>
)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`

const ValidationError = styled.div`
  position: relative;
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.red06};
  margin-top: -16px;
`
