import React from 'react'
import {
  type LogoProps,
  NetworkLogo,
  TalkLogo,
  UispLogo,
  UmrLogo,
} from '@ubnt/icons'
import styled from 'theme/styled'
import { ProductName } from 'types/enums'
import cloudConsoleIcon from 'assets/images/devices/cloudConsoleIcon.png'
import siteSupportLogo from 'assets/images/devices/siteSupportLogo.svg'
import enhancedThreatLogo from 'assets/svgs/enhancedThreatLogo.svg'
import { FullSubscription } from '../fullSubscriptions/useFullSubscriptions'

export const getSubscriptionIcon = (
  productLine: string,
  dimensions = '40px',
  variant: LogoProps['variant'] = 'bgRoundedSquare',
  padding = '0'
): React.ReactNode => {
  switch (productLine) {
    case ProductName.ULTE: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <NetworkLogo
            variant={variant}
            height={dimensions}
            width={dimensions}
          />
        </Wrapper>
      )
    }
    case ProductName.UNIFI_TALK: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <TalkLogo variant={variant} height={dimensions} width={dimensions} />
        </Wrapper>
      )
    }
    case ProductName.HOSTING: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <img
            src={cloudConsoleIcon}
            alt={ProductName.HOSTING}
            width={dimensions}
          />
        </Wrapper>
      )
    }
    case ProductName.MOBILITY: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <UmrLogo variant={variant} height={dimensions} width={dimensions} />
        </Wrapper>
      )
    }
    case ProductName.SITE_SUPPORT: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <img
            src={siteSupportLogo}
            alt={ProductName.SITE_SUPPORT}
            width="32px"
          />
        </Wrapper>
      )
    }
    case ProductName.UISP_CLOUD: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <UispLogo variant={variant} height={dimensions} width={dimensions} />
        </Wrapper>
      )
    }
    case ProductName.ENHANCED_TREAT_UPDATES: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <StyledImage
            src={enhancedThreatLogo}
            alt={ProductName.ENHANCED_TREAT_UPDATES}
            width="32px"
          />
        </Wrapper>
      )
    }
    default:
      return null
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 45px;
`

const StyledImage = styled.img`
  border: 1px solid ${({ theme }) => theme.ublue04};
  border-radius: 9px;
`

export const getSubscriptionBillingDate = (
  fullSubscription: FullSubscription
) => {
  const { isSuspended, upcomingInvoice, current_period_end } = fullSubscription
  return isSuspended && upcomingInvoice
    ? upcomingInvoice.period_end
    : current_period_end
}
