import { useDispatch, useSelector } from 'react-redux'
import {
  resetRemoveEmailErrors,
  selectRemoveEmailErrors,
} from '../Email/modules/removeEmail'
import {
  resetRemoveTOTPErrors,
  selectRemoveTOTPErrors,
} from '../TOTP/modules/removeTOTP'
import {
  resetRemovePushErrors,
  selectRemovePushErrors,
} from '../Push/modules/removePush'
import {
  resetRemoveSMSErrors,
  selectRemoveSMSErrors,
} from '../SMS/modules/removeMFASMS'
import {
  resetRemovePasskeyErrors,
  selectRemovePasskeyErrors,
} from '../Passkey/modules/removePasskey'

export const useGetErrorMessage = () => {
  const dispatch = useDispatch()

  const hasEmailError = useSelector(selectRemoveEmailErrors)
  const hasTOTPError = useSelector(selectRemoveTOTPErrors)
  const hasPushError = useSelector(selectRemovePushErrors)
  const hasSMSError = useSelector(selectRemoveSMSErrors)
  const hasPasskeyError = useSelector(selectRemovePasskeyErrors)

  const errorMessage =
    hasEmailError?.detail ||
    hasTOTPError?.detail ||
    hasPushError?.detail ||
    hasSMSError?.detail ||
    hasPasskeyError?.detail ||
    null

  const resetErrors = () => {
    if (hasEmailError?.detail) {
      dispatch(resetRemoveEmailErrors())
    }
    if (hasTOTPError?.detail) {
      dispatch(resetRemoveTOTPErrors())
    }
    if (hasPushError?.detail) {
      dispatch(resetRemovePushErrors())
    }
    if (hasSMSError?.detail) {
      dispatch(resetRemoveSMSErrors())
    }
    if (hasPasskeyError?.detail) {
      dispatch(resetRemovePasskeyErrors())
    }
  }

  return {
    errorMessage,
    resetErrors,
  }
}
