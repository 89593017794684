import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'

import { DeleteNotAllowedType } from './types'
import { DeleteNotAllowedModalBody } from './DeleteNotAllowedModalBody'
import { DeleteAccountWarning } from './DeleteAccountWarning'

type DeleteAccountModalBodyProps = {
  isLoading: boolean
  pendingItems: DeleteNotAllowedType | null
}

export const DeleteAccountModalBody: React.FC<DeleteAccountModalBodyProps> = ({
  isLoading,
  pendingItems,
}) => {
  if (isLoading) {
    return (
      <SkeletonLoader>
        <Text width={300} />
        <Text width={350} />
        <Text width={250} />
      </SkeletonLoader>
    )
  }

  if (pendingItems) {
    return <DeleteNotAllowedModalBody type={pendingItems} />
  }

  return <DeleteAccountWarning />
}
