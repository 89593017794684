import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { Checkbox } from '@ubnt/ui-components'

import styled from 'theme/styled'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { InputGroup, InputGroups } from 'components/form'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import { selectProfileUuid } from 'modules/profile'
import Yup from 'validators/yupLocaleConfig'

import { addBetaRole, selectIsRolesLoading } from '../module/roles'
import { EARLY_ACCESS_LEARN_MORE_MODAL_ID } from './LearnMoreModal'

const EarlyAccessAboutModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsRolesLoading)
  const profileUuid = useSelector(selectProfileUuid)

  const formik = useFormik({
    initialValues: {
      joinBeta: false,
    },
    onSubmit: () => {
      dispatch(addBetaRole({ profileUuid }))
      dispatch(closeVisibleModal())
    },
    validationSchema: Yup.object().shape({
      joinBeta: Yup.boolean().required().oneOf([true]),
    }),
    enableReinitialize: true,
  })

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<FormattedMessage id="SETTINGS_EARLY_ACCESS_ABOUT" />}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
          variant: 'default',
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_JOIN_NOW" />,
          onClick: () => formik.handleSubmit(),
          variant: 'primary',
          disabled: !formik.values.joinBeta || isLoading,
          loader: isLoading ? 'dots' : undefined,
        },
      ]}
    >
      <Container>
        <TextWrapper>
          {intl.formatMessage({ id: 'SETTINGS_EARLY_ACCESS_PURPOSE' })}
        </TextWrapper>
        <TextWrapper>
          {intl.formatMessage(
            { id: 'SETTINGS_EARLY_ACCESS_LEARN_MORE' },
            {
              link: (
                <a
                  onClick={() =>
                    dispatch(
                      setVisibleModal(EARLY_ACCESS_LEARN_MORE_MODAL_ID, {
                        sourceModalId: EARLY_ACCESS_ABOUT_MODAL_ID,
                      })
                    )
                  }
                >
                  {intl.formatMessage({ id: 'SETTINGS_EARLY_ACCESS_LINK' })}
                </a>
              ),
            }
          )}
        </TextWrapper>
        <InputGroups>
          <InputGroup full>
            <StyledCheckbox
              id="beta-program"
              name="joinBeta"
              checked={formik.values.joinBeta}
              onChange={formik.handleChange}
              disabled={isLoading}
            >
              {intl.formatMessage({ id: 'SETTINGS_EARLY_ACCESS_AGREE' })}
            </StyledCheckbox>
          </InputGroup>
        </InputGroups>
      </Container>
    </GenericModal>
  )
}

export const EARLY_ACCESS_ABOUT_MODAL_ID = 'EARLY_ACCESS_ABOUT_MODAL_ID'

export const WrappedEarlyAccessAboutModal = connect()(() => (
  <ModalWrapper modalId={EARLY_ACCESS_ABOUT_MODAL_ID}>
    <EarlyAccessAboutModal />
  </ModalWrapper>
))

const TextWrapper = styled.div`
  margin-bottom: 24px;
`

const StyledCheckbox = styled(Checkbox)`
  margin: auto 0;
`

const Container = styled('div')`
  padding-bottom: 10px;
  text-align: left;
  p {
    font-size: 14px;
    color: ${(p) => p.theme.neutral08};
    line-height: 22px;
  }
`
