import React from 'react'
import { FormattedMessage } from 'react-intl'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { Button } from '@ubnt/ui-components/Button'
import { InfoIcon } from '@ubnt/icons'

import styled from 'theme/styled'
import { LockTooltip } from 'pages/security/LockTooltip'
import { useSessionsQuery } from 'store/queries/useSessionsQuery'
import { useRevokeAllNonCurrentSessions } from 'store/mutations/sessions/useRevokeAllNonCurrentSessions'

export const SessionHeader = () => {
  const { hasMultipleSessions } = useSessionsQuery()
  const { revokeNonCurrentSessions } = useRevokeAllNonCurrentSessions()

  return (
    <Wrapper>
      <TextWrapper>
        <Text>
          <Circle />
          <FormattedMessage id="SETTINGS_SESSION_HEADER_TITLE" />
          <IconContainer>
            <LockTooltip message="">
              <Tooltip
                tooltipClassName="customTooltipWidth"
                description={
                  <FormattedMessage id="SETTINGS_SECURITY_SESSIONS_INFO_TOOLTIP" />
                }
              >
                <InfoIcon size="navigation" isActive />
              </Tooltip>
            </LockTooltip>
          </IconContainer>
        </Text>
      </TextWrapper>
      {hasMultipleSessions && (
        <LockTooltip inline>
          <FlexContainer>
            <StyledButton
              variant="link"
              size="medium"
              weight="normal"
              onClick={revokeNonCurrentSessions}
            >
              <FormattedMessage id="SETTINGS_SECURITY_REMOVE_ALL_SESSIONS" />
            </StyledButton>
            <Tooltip
              tooltipClassName="customTooltipWidth"
              description={
                <FormattedMessage id="SETTINGS_SECURITY_REMOVE_ALL_SESSIONS_TOOLTIP" />
              }
            >
              <InfoIcon size="navigation" isActive />
            </Tooltip>
          </FlexContainer>
        </LockTooltip>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 16px;
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    padding-right: 0;
    padding-left: 0;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 280px);
  @media (max-width: ${({ theme }) => theme.media.small}) {
    max-width: 100%;
  }
`

const Text = styled.div`
  font-weight: bold;
  font: ${typography['desktop-typography-heading-large']};
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
`

const Circle = styled.div`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.green06};
  border-radius: 50%;
  margin-right: 10px;
`

const IconContainer = styled.span`
  margin-left: 5px;
`

const StyledButton = styled(Button)<{ isMobile?: boolean }>`
  line-height: 24px;
  margin-right: 4px;
  padding: ${({ isMobile }) => (isMobile ? '0' : 'inherit')};
`

const FlexContainer = styled.div`
  display: flex;
`
