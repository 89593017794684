import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ErrorPage_Default } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/Button'

export const ErrorPageFourOhFour: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  return (
    <ErrorPage_Default
      title={intl.formatMessage({ id: 'GENERIC_ERROR_BOUNDARY_TITLE_404' })}
      actionButton={
        <Button onClick={() => history.push('/')} variant="primary">
          <FormattedMessage id="GENERIC_ERROR_BOUNDARY_ACTION_404" />
        </Button>
      }
      description={intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_DESCRIPTION_404',
      })}
    />
  )
}

export const ErrorPageGeneric: React.FC = () => {
  const intl = useIntl()
  return (
    <ErrorPage_Default
      title={intl.formatMessage({ id: 'GENERIC_ERROR_BOUNDARY_TITLE' })}
      actionButton={
        <Button onClick={() => window.location.reload()} variant="primary">
          <FormattedMessage id="GENERIC_ERROR_BOUNDARY_ACTION" />
        </Button>
      }
      description={intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_DESCRIPTION',
      })}
    />
  )
}
