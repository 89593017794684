import { api } from 'api'
import { DeviceApplication } from 'features/support/unifi/types'

export const getTotalNumberOfDevices = async (
  deviceIds?: string[]
): Promise<string | undefined> => {
  try {
    let totalDevices = 0

    if (deviceIds?.length) {
      const result: DeviceApplication[] = await api.getTotalDevices(deviceIds)

      result.forEach((console) => {
        totalDevices += console.data.length
      })
    }

    return totalDevices.toString()
  } catch (error) {
    console.error(error)

    return undefined
  }
}
