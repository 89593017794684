import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { QueryKey } from '../types'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { NcaBackupsApi } from 'api/nca/ncaBackups'
import { useNcaDevicesQuery } from './useNcaDevicesQuery'
import { useMemo } from 'react'
import {
  DeviceBackups,
  DeviceBackupsWithShadow,
  UcoreDevice,
} from 'api/nca/types'

const ncaBackupsApi = new NcaBackupsApi()

export const useNcaBackupsQuery = () => {
  const { selectOwnedUcoreDevices } = useNcaDevicesQuery()

  const ncaBackups = useQuery({
    queryKey: [QueryKey.NCA_BACKUPS],
    queryFn: () => ncaBackupsApi.getAll(selectOwnedUcoreDevices),
    enabled: !!selectOwnedUcoreDevices.length,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  const backupsFromAllDevices = useMemo(() => {
    if (!ncaBackups.data) {
      return []
    }
    return ncaBackups.data.reduce(
      (result: DeviceBackupsWithShadow[], item: DeviceBackups) => {
        const resultCopy = result.slice()
        selectOwnedUcoreDevices?.forEach((device: UcoreDevice) => {
          if (device.hardwareId === item.hardwareId) {
            resultCopy.push({ ...item, shadow: device.shadow })
          }
        })
        return resultCopy
      },
      []
    )
  }, [ncaBackups.data, selectOwnedUcoreDevices])

  return {
    ncaBackups: ncaBackups.data,
    areNcaBackupsLoading: ncaBackups.isLoading,
    backupsFromAllDevices,
    ncaBackupsError: ncaBackups.error,
  }
}
