import moment from 'moment'

enum DateFormat {
  MONTH_DATE = 'MMM D',
  HUMAN_READABLE = 'MMM Do, YYYY',
  HUMAN_READABLE_WITH_TIME = 'MMM Do, YYYY h:mm a',
}

const formatUTCtoHumanReadable = (date: number | null): string => {
  if (!date) {
    return ''
  }

  return moment(date * 1000).format(DateFormat.HUMAN_READABLE)
}

const formatUTCtoHumanReadableWithTime = (date: number | null): string => {
  if (!date) {
    return ''
  }

  return moment(date * 1000).format(DateFormat.HUMAN_READABLE_WITH_TIME)
}

const formatUTCtoMonthDay = (date: number | null): string => {
  if (!date) {
    return ''
  }

  return moment(date * 1000).format(DateFormat.MONTH_DATE)
}

export {
  formatUTCtoHumanReadable,
  formatUTCtoHumanReadableWithTime,
  formatUTCtoMonthDay,
}
