import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { PasskeyBannerApi } from 'api/passkeyBanner'
import {
  selectHaveAuthenticatorsFetchedOnce,
  selectMFAAuthenticators,
} from 'modules/mfa'
import { MFAType } from 'modules/types'
import { QueryKey } from 'store/types'

const passkeyBannerApi = new PasskeyBannerApi()

export const useShouldDisplayPasskeyBannerQuery = () => {
  const areAuthenticatorsFetched = useSelector(
    selectHaveAuthenticatorsFetchedOnce
  )
  const authenticators = useSelector(selectMFAAuthenticators)

  const hasPasskey = useMemo(() => {
    return authenticators.some(({ type }) => type === MFAType.webAuthn)
  }, [authenticators])

  const shouldDisplayPasskeyBanner = useQuery({
    queryKey: [QueryKey.SHOULD_DISPLAY_PASSKEY_BANNER],
    queryFn: passkeyBannerApi.get,
    staleTime: Infinity,
    enabled: areAuthenticatorsFetched && !hasPasskey,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const shouldDisplayBanner = useMemo(() => {
    return !!shouldDisplayPasskeyBanner.data?.showPasskeyPrompt
  }, [shouldDisplayPasskeyBanner.data])

  return { shouldDisplayBanner }
}
