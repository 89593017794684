import React, { useEffect, useState } from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { CheckmarkIcon } from '@ubnt/icons'

import PublicPage from 'pages/PublicPage'
import styled from 'theme/styled'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCanChangeEmail } from 'features/auth/modules/auth'

export const ChangeEmailSuccess = () => {
  const intl = useIntl()
  const history = useHistory<{ email: string }>()
  const canChangeEmail = useSelector(selectCanChangeEmail)

  if (!canChangeEmail) {
    history.push('/login')
  }

  const [counter, setCounter] = useState<number>(3)

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    } else {
      history.push('/login')
    }
  }, [counter, history])

  return (
    <PublicPage formHeight="468px">
      <TitleWrapper>
        <StyledCheckmarkIcon variant="fill" size="large" />
        <Text variant="heading-xlarge-1">
          {intl.formatMessage({ id: 'LOGIN_CHANGE_EMAIL_SUCCESS_TITLE' })}
        </Text>
      </TitleWrapper>
      <DescriptionWrapper>
        <Text>
          {intl.formatMessage(
            { id: 'LOGIN_CHANGE_EMAIL_SUCCESS_DESCRIPTION_1' },
            { b: () => <b>{history.location.state?.email}</b> }
          )}
        </Text>
        <Text>
          {intl.formatMessage({
            id: 'LOGIN_CHANGE_EMAIL_SUCCESS_DESCRIPTION_2',
          })}
        </Text>
      </DescriptionWrapper>
      <RedirectWrapper>
        <Text variant="caption">
          {intl.formatMessage(
            { id: 'LOGIN_CHANGE_EMAIL_SUCCESS_REDIRECT' },
            { span: () => <span>{counter}</span> }
          )}
        </Text>
      </RedirectWrapper>
    </PublicPage>
  )
}

const TitleWrapper = styled.div`
  margin-top: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  color: ${({ theme }) => theme.ublue06};
`

const DescriptionWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const RedirectWrapper = styled.div`
  margin-top: 32px;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.neutral01};
  text-align: center;
`
