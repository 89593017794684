import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SubscriptionNameCell } from 'features/subscriptions/ui/cells/SubscriptionNameCell'
import { getProductIdFromInvoice } from 'features/subscriptions/utils'
import { DeleteNotAllowedType } from './types'
import { useBillingInvoicesQuery } from 'store/queries/useBillingInvoicesQuery'
import { useFullSubscriptions } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'

type DeleteNotAllowedModalBodyProps = {
  type?: DeleteNotAllowedType
}

export const DeleteNotAllowedModalBody: React.FC<
  DeleteNotAllowedModalBodyProps
> = ({ type }) => {
  const { fullSubscriptions } = useFullSubscriptions()
  const { invoices } = useBillingInvoicesQuery()

  if (type === DeleteNotAllowedType.SUBSCRIPTION) {
    const activeSubscriptions = fullSubscriptions?.filter(
      ({ isActive, cancelAtPeriodEnd }) => isActive && !cancelAtPeriodEnd
    )

    return (
      <>
        <FormattedMessage id="SETTINGS_SECURITY_DELETE_NOT_ALLOWED_SUBSCRIPTION" />
        <ul>
          {activeSubscriptions.map((sub) => (
            <li>{sub.name}</li>
          ))}
        </ul>
      </>
    )
  }

  const openInvoices = invoices.filter(
    ({ status }) => status === 'open' || status === 'draft'
  )

  return (
    <>
      <FormattedMessage id="SETTINGS_SECURITY_DELETE_NOT_ALLOWED_INVOICE" />
      <ul>
        {openInvoices.map((inv) => (
          <li>
            <SubscriptionNameCell
              productId={getProductIdFromInvoice(inv)}
              invoice={inv}
              overrideMediaQueries
            />
          </li>
        ))}
      </ul>
    </>
  )
}
