import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import { fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { closeVisibleModal, selectVisibleModal } from 'modules/modals'

import { PASSKEY_REMOVE_MODAL_ID } from '../RemoveModal'

const {
  api: { sso },
} = __CONFIG__

export const removePasskeyDataKey = 'MFARemovePasskey'

const removePasskeyActions = {
  remove: `${removePasskeyDataKey}/REMOVE`,
}

type RemovePasskeyState = Record<string, never>

const dataModule = createDataModule<RemovePasskeyState, SSOAPIError>(
  removePasskeyDataKey,
  `${sso.paths.mfa}/webauthn`,
  api.ssoBase
)

export const {
  selectErrors: selectRemovePasskeyErrors,
  resetErrors: resetRemovePasskeyErrors,
} = dataModule

export const removeMFAPasskey = (id: string, token?: string) => ({
  type: removePasskeyActions.remove,
  payload: { oneTimePassword: token },
  urlPath: `/${id}`,
})

function* closeModalSaga() {
  yield put(fetchMFA())
  const modalsToClose = [PASSKEY_REMOVE_MODAL_ID]
  const { visibleModal } = yield select(selectVisibleModal)
  if (modalsToClose.includes(visibleModal)) {
    yield put(closeVisibleModal())
  }
}

function* subscribeToRemovedPasskeyDoneSaga() {
  yield takeEvery(dataModule.REMOVE_DONE, closeModalSaga)
}

export function* removePasskeyRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToRemovedPasskeyDoneSaga),
  ])
}

export const removePasskeyReducer = dataModule.reducer
