export enum SSO_EVENT_NAME {
  SSO_LOGIN = 'SSO_LOGIN',
  SSO_MFA_ENABLED = 'SSO_MFA_ENABLED',
  SSO_MFA_METHOD_ADDED = 'SSO_MFA_METHOD_ADDED',
  SSO_MFA_METHOD_REMOVED = 'SSO_MFA_METHOD_REMOVED',
  SSO_MFA_PRIMARY_METHOD_CHANGED = 'SSO_MFA_PRIMARY_METHOD_CHANGED',
  SSO_MFA_DISABLED = 'SSO_MFA_DISABLED',
  SSO_BACKUP_CODES_GENERATED = 'SSO_BACKUP_CODES_GENERATED',
  SSO_BACKUP_CODES_USED = 'SSO_BACKUP_CODES_USED',
  SSO_PW_RESET = 'SSO_PW_RESET',
  SSO_PW_CHANGED = 'SSO_PW_CHANGED',
  SSO_SESSION_TIMEOUT_CHANGED = 'SSO_SESSION_TIMEOUT_CHANGED',
  SSO_EMAIL_CHANGED = 'SSO_EMAIL_CHANGED',
}
