import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

const { GOOGLE_MAPS_SECRET } = __CONFIG__

export const useAutocomplete = () => {
  return usePlacesAutocompleteService({
    apiKey: GOOGLE_MAPS_SECRET,
    options: {
      input: '',
      types: ['address'],
      componentRestrictions: { country: 'US' },
    },
    language: 'en',
  })
}
