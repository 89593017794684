import React, { SetStateAction, useCallback, useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import axios, { AxiosError } from 'axios'
import { BasicToast, ToastContext } from '@ubnt/ui-components/Toast'
import { TextArea } from '@ubnt/ui-components/TextArea'
import { Button } from '@ubnt/ui-components/Button'
import ExternalLink from 'components/ExternalLink'
import { uiLinks } from 'redirectLinks'

import styled from 'theme/styled'
import { isAxiosError } from 'utils/isAxiosError'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import { useGoogleReCaptchaV3 } from 'features/ReCaptchaV3/useGoogleReCaptchaV3'
import { ZendeskTicketWithComments, CaptchaAction } from 'api/zendesk/types'

const {
  api: { accountBE },
  RECAPTCHA_ACCOUNT_BACKEND_SITE_KEY_ID,
} = __CONFIG__

type Props = {
  ticketId: string
  setTicketWithComments: React.Dispatch<
    SetStateAction<{
      loading: boolean
      ticket?: ZendeskTicketWithComments
    }>
  >
  handleSendSafelyUpload: () => Promise<string | undefined>
  handleDropzoneReinitialize: () => void
  isAttachmentUploading: boolean
}

export const CreateComment: React.FC<Props> = ({
  ticketId,
  setTicketWithComments,
  handleSendSafelyUpload,
  handleDropzoneReinitialize,
  isAttachmentUploading,
}) => {
  const intl = useIntl()
  const { createNotification } = useContext(ToastContext)
  const [newComment, setNewComment] = useState<string>('')
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const { isMaxMobileLarge } = useContext(MediaSizingContext)
  const { getReCaptchaToken } = useGoogleReCaptchaV3(
    RECAPTCHA_ACCOUNT_BACKEND_SITE_KEY_ID,
    CaptchaAction.ZENDESK_CREATE_TICKET_COMMENT
  )

  const createGenericErrorNotfication = useCallback(
    (isRateLimitError?: boolean) => {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={
            <FormattedMessage
              id={
                isRateLimitError
                  ? 'SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_RATE_LIMIT_TITLE'
                  : 'SUPPORT_SUBMIT_FAILED_TOAST_TITLE'
              }
            />
          }
          details={
            <FormattedMessage
              id={
                isRateLimitError
                  ? 'SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_RATE_LIMIT'
                  : 'REQUEST_COMMENT_ERROR'
              }
              {...(isRateLimitError
                ? {
                    values: {
                      a: (text) => (
                        <ExternalLink href={uiLinks.helpUiRequests}>
                          {text}
                        </ExternalLink>
                      ),
                    },
                  }
                : {})}
            />
          }
        />
      )
    },
    [createNotification]
  )

  const createComment = useCallback<
    () => Promise<ZendeskTicketWithComments>
  >(async () => {
    const url = `${accountBE.base}/${accountBE.paths.zendeskCreateComment}/${ticketId}`
    const attachmentsUrl = await handleSendSafelyUpload()
    const urlToAttach = attachmentsUrl ? `Attachments: ${attachmentsUrl}` : ''
    const comment = `${newComment} \n\n ${urlToAttach} `

    const token = await getReCaptchaToken()

    const { data } = await axios.post(
      url,
      { comment },
      // Send Recaptcha Token in Headers
      { withCredentials: true, headers: { 'x-captcha-token': token } }
    )

    return data.ticket
  }, [getReCaptchaToken, handleSendSafelyUpload, newComment, ticketId])

  const handleClick = useCallback(
    () =>
      grecaptcha.enterprise.ready(async () => {
        try {
          setIsUpdateLoading(true)
          const updatedTicket = await createComment()

          setTicketWithComments({
            loading: false,
            ticket: updatedTicket,
          })

          setIsUpdateLoading(false)
          setNewComment('')
          // Reset Widget After Comment Submit
          handleDropzoneReinitialize()
        } catch (error) {
          setIsUpdateLoading(false)

          if (isAxiosError(error)) {
            const axiosError = error as AxiosError
            if (
              axiosError.response?.data &&
              (axiosError.response.data as { code?: string }).code ===
                'EXTERNAL_RATE_LIMIT'
            ) {
              return createGenericErrorNotfication(true)
            }
          }

          createGenericErrorNotfication()
        }
      }),
    [
      createComment,
      createGenericErrorNotfication,
      handleDropzoneReinitialize,
      setTicketWithComments,
    ]
  )

  return (
    <Wrapper>
      <StyledTextArea
        placeholder={intl.formatMessage({
          id: 'REQUEST_CREATE_COMMENT_PLACEHOLDER',
        })}
        value={newComment}
        onChange={({ target }) => setNewComment(target.value)}
        variant="secondary"
        full
        height={84}
      />
      <StyledButton
        variant="primary"
        onClick={handleClick}
        disabled={!newComment.length || isAttachmentUploading}
        loader={isUpdateLoading ? 'dots' : undefined}
        full={isMaxMobileLarge}
      >
        <FormattedMessage id="REQUEST_SEND_NEW_COMMENT" />
      </StyledButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  // Main Request Container have gap:16px but there shouldn't be a gap between Attach files and Text Area
  margin-top: -16px;
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    flex-direction: column;
  }
`

const StyledButton = styled(Button)`
  height: 32px;
`

const StyledTextArea = styled(TextArea)`
  textarea {
    min-height: 84px;
    resize: vertical;
  }
`
