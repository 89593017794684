import { FormConfig } from '../config/types'
import { FormValue, SupportConsole, SupportFormState } from '../types'

const isSupportConsole = (value: FormValue): value is SupportConsole => {
  return (value as SupportConsole)?.id !== undefined
}

export const getNextSection = (
  config: FormConfig,
  value: FormValue,
  currentSection: keyof FormConfig,
  values: SupportFormState
) => {
  const nextSectionData = config[currentSection].nextSection

  if (nextSectionData && nextSectionData.length > 1) {
    const section = nextSectionData.find((nextSection) => {
      if (nextSection.condition?.field === currentSection) {
        const parsedValue = isSupportConsole(value) ? value.id : value

        return nextSection.condition?.value.includes(parsedValue)
      }
      return nextSection.condition?.value.includes(
        values[nextSection.condition?.field as keyof SupportFormState]
      )
    })?.section
    return section
  }
  return nextSectionData?.[0].section
}
