import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'

import { RootState } from 'types/types'
import { REDIRECT_QUERY_PARAM_NAME } from 'components/requiresAuth'

import {
  selectIsAuthenticated,
  selectIsLoading,
} from '../features/auth/modules/auth'

const locationHelper = locationHelperBuilder({
  redirectQueryParamName: 'innerRedirect',
})

export default connectedRouterRedirect<any, RootState>({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: (state) => !selectIsAuthenticated(state),
  authenticatingSelector: (state: RootState) => selectIsLoading(state),
  redirectQueryParamName: REDIRECT_QUERY_PARAM_NAME,
})
