import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { TalkInstallsApi } from 'api/talkInstalls'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

const talkInstallsApi = new TalkInstallsApi()

export const useTalkIntallsQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)
  const queryClient = useQueryClient()

  const talkInstalls = useQuery({
    queryKey: [QueryKey.TALK_INSTALLS],
    queryFn: talkInstallsApi.get,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const resetTalkInstalls = () => {
    queryClient.removeQueries({ queryKey: [QueryKey.TALK_INSTALLS] })
  }

  return {
    talkInstalls: talkInstalls.data,
    areTalkInstallsLoading: talkInstalls.isFetching,
    talkInstallsErrors: talkInstalls.error,
    resetTalkInstalls,
    areTalkInstallsFetched: talkInstalls.isFetched,
  }
}
