import { StripeRegionCode } from 'features/stripe/ui/types'
import {
  deleteRequestWithCookies,
  fetchRequestWithCookies,
  patchRequestWithCookies,
  postRequestWithCookies,
} from '../axios'
import { STRIPE_FETCH_LIMIT } from '../constants'
import { Subscription, Subscriptions } from './billingTypes'
import { getRegionsFromData, mergeStripeData } from './billingUtils'
import { TalkHeaders } from 'api/talkInstalls'

const {
  api: { billing, lte, siteSupport, enhancedThreat, talk },
} = __CONFIG__

export class BillingSubscriptionsApi {
  private fetchSubscriptions = async (
    startingAfter?: string,
    region?: StripeRegionCode
  ) => {
    const url = new URL(billing.paths.subscriptions, billing.base)
    url.searchParams.append('status', 'all')
    url.searchParams.append('limit', STRIPE_FETCH_LIMIT)

    startingAfter && url.searchParams.append('starting_after', startingAfter)
    region && url.searchParams.append('region', region)

    const { data } = await fetchRequestWithCookies<Subscriptions>(url.href)
    return data
  }

  public getAll = async () => {
    let t = 0
    let subscriptions = await this.fetchSubscriptions()

    const regions = getRegionsFromData<Subscription>(subscriptions)

    for (let i = 0; i < regions.length; i++) {
      const region = regions[i]

      while (subscriptions[region].has_more && t < 10) {
        t++
        const totalFetchedSubscriptions = subscriptions[region].data.length
        const lastFetchedSubscriptionId =
          subscriptions[region].data[totalFetchedSubscriptions - 1].id

        const extraData = await this.fetchSubscriptions(
          lastFetchedSubscriptionId,
          region
        )

        subscriptions = mergeStripeData<Subscription>(
          subscriptions,
          extraData,
          region
        )
      }
    }

    return subscriptions
  }

  public update = async ({
    id,
    region,
    default_source,
    default_payment_method,
  }: {
    id: string
    region: StripeRegionCode
    default_source?: string
    default_payment_method?: string
  }) => {
    const url = new URL(`${billing.paths.subscriptions}/${id}`, billing.base)

    url.searchParams.append('region', region)

    const payload = default_source
      ? { default_source }
      : { default_payment_method }

    await patchRequestWithCookies(url.href, payload)
  }

  public cancelLte = async ({ id }: { id: string }) => {
    const url = new URL(`${lte.paths.subscriptions}/${id}`, lte.base)

    await deleteRequestWithCookies(url.href)
  }

  public cancelSiteSupport = async ({ id }: { id: string }) => {
    const url = new URL(
      `${siteSupport.paths.subscriptions}/${id}`,
      siteSupport.base
    )

    await deleteRequestWithCookies(url.href)
  }

  public cancelEnhancedThreat = async ({
    item,
    id,
  }: {
    item: string
    id: string
  }) => {
    const url = new URL(
      `${enhancedThreat.paths.cancelSubscription}/${item}/subscription/${id}`,
      enhancedThreat.base
    )

    await deleteRequestWithCookies(url.href)
  }

  public cancelTalk = async (payload: { install: number }) => {
    const url = new URL(`${talk.paths.cancel}`, talk.base)

    let customHeaders: TalkHeaders = undefined

    if (talk.base.includes('.stg.')) {
      customHeaders = {
        'x-unifi-sso-env': 'stg',
      }
    } else if (talk.base.includes('.dev.')) {
      customHeaders = {
        'x-unifi-sso-env': 'dev',
      }
    }

    // for some reason the request to delete a subscription for talk is a post
    await postRequestWithCookies(url.href, payload, customHeaders)
  }
}
