import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { closeVisibleModal } from 'modules/modals'

import { GenericMFAModal } from '../GenericMFAModal'
import { MFAOptions } from './MFAOptions'

export const SETUP_MFA_MODAL_ID = 'SETUP_MFA_MODAL_ID'

export const SetupMFAModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return (
    <GenericMFAModal
      modalId={SETUP_MFA_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_ENABLE' })}
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: () => dispatch(closeVisibleModal()),
          variant: 'tertiary',
        },
      ]}
      overrideFullScreen
      size="auto"
    >
      <MFAOptions />
    </GenericMFAModal>
  )
}
