import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getAssuranceLevel,
  selectAssuranceLevel,
} from 'features/auth/modules/assuranceLevel'

const AssuranceLevelTimer = () => {
  const assuranceLevel = useSelector(selectAssuranceLevel)

  const dispatch = useDispatch()

  const [timeLeft, setTimeLeft] = useState<number>(
    assuranceLevel?.data.time_left_seconds ?? 0
  )

  useEffect(() => {
    setTimeLeft(assuranceLevel?.data.time_left_seconds)
  }, [assuranceLevel])

  useEffect(() => {
    if (timeLeft < 1) {
      dispatch(getAssuranceLevel())
    }

    const countdown = setInterval(() => {
      if (timeLeft > 0) setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(countdown)
  }, [dispatch, timeLeft])
  return <></>
}

export default AssuranceLevelTimer
