import React, { useEffect, useRef } from 'react'

import { Bubble } from 'components/support/Bubble'
import { TilesWrapper } from 'components/support/Wrappers'
import { QuestionTile } from 'components/support/QuestionTile'

import { config } from './config/formConfig'
import { BubbleProps, FormValue } from './config/types'
import { productLine } from './config/ConfigOptions'
import { SupportFormState } from './types'

export const ProductLineBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  const getNextSection = (value: FormValue): string | undefined => {
    const nextSectionData = config.productLine.nextSection

    if (nextSectionData && nextSectionData.length > 1) {
      const section = nextSectionData.find((nextSection) => {
        if (nextSection.condition?.field === 'productLine') {
          return nextSection.condition?.value.includes(value)
        }
        return nextSection.condition?.value.includes(
          values[nextSection.condition?.field as keyof SupportFormState]
        )
      })?.section
      return section
    }
    return nextSectionData?.[0].section
  }

  const handleClick = (value?: string) => {
    const section = getNextSection(value)
    handleChange('productLine', value, section)
  }

  return (
    <Bubble question={config.productLine.fieldTitle} ref={ref}>
      <TilesWrapper>
        {productLine.map(({ image, label, id }) => (
          <QuestionTile
            key={id}
            imageSrc={image}
            label={label}
            onClick={() => handleClick(id)}
            isActive={values.productLine === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
