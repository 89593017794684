import { useBillingCardsQuery } from './queries/useBillingCardsQuery'
import { useBillingCustomerQuery } from './queries/useBillingCustomerQuery'
import { useBillingInvoicesQuery } from './queries/useBillingInvoicesQuery'
import { useBillingProductsQuery } from './queries/useBillingProductsQuery'
import { useBillingSubscriptionsQuery } from './queries/useBillingSubscriptionsQuery'
import { useBillingTaxIdQuery } from './queries/useBillingTaxIdQuery'
import { useBillingUpcomingInvoicesQuery } from './queries/useBillingUpcomingInvoices'
import { useNcaArchivedBackupsQuery } from './queries/useNcaArchivedBackupsQuery'
import { useNcaBackupsQuery } from './queries/useNcaBackupsQuery'
import { useNcaDevicesQuery } from './queries/useNcaDevicesQuery'
import { useShouldDisplayPasskeyBannerQuery } from './queries/useShouldDisplayPasskeyBannerQuery'
import { useTrustedDevicesQuery } from './queries/useTrustedDevicesQuery'
import { useUmrDeviceQuery } from './queries/useUmrDeviceQuery'
import { useUmrDevicesQuery } from './queries/useUmrDevicesQuery'

export const usePrefetchQueries = () => {
  useBillingCustomerQuery()
  useBillingCardsQuery()
  useBillingTaxIdQuery()
  useUmrDevicesQuery()
  useBillingSubscriptionsQuery()
  useBillingProductsQuery()
  useBillingUpcomingInvoicesQuery()
  useBillingInvoicesQuery()
  useUmrDeviceQuery()
  useNcaDevicesQuery()
  useNcaBackupsQuery()
  useNcaArchivedBackupsQuery()
  useShouldDisplayPasskeyBannerQuery()
  useTrustedDevicesQuery()
}
