import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../types'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { BillingSubscriptionsApi } from 'api/billing/billingSubscriptions'
import { StripeRegionCode } from 'features/stripe/ui/types'

const billingSubscriptionsApi = new BillingSubscriptionsApi()

export const useBillingSubscriptionsQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const subscriptions = useQuery({
    queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
    queryFn: billingSubscriptionsApi.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const userSubscriptions = useMemo(() => {
    if (!subscriptions.data) {
      return []
    }
    const { data } = subscriptions

    const subscriptionRegions = Object.keys(data) as StripeRegionCode[]

    const userSubscriptions = subscriptionRegions.flatMap((region) => {
      return (
        data[region].data?.map((subscription) => {
          return { ...subscription, region }
        }) ?? []
      )
    })

    return userSubscriptions
  }, [subscriptions])

  const activeSubscriptions = useMemo(() => {
    return userSubscriptions.filter(({ status }) => status !== 'canceled')
  }, [userSubscriptions])

  const subscriptionsWithUpcomingInvoices = useMemo(() => {
    return userSubscriptions.filter(
      ({ status, cancel_at_period_end }) =>
        (status === 'active' || status === 'trialing') && !cancel_at_period_end
    )
  }, [userSubscriptions])

  return {
    subscriptions: subscriptions.data,
    areSubscriptionsLoading: subscriptions.isFetching,
    areSubscriptionsRefetching: subscriptions.isRefetching,
    userSubscriptions,
    activeSubscriptions,
    subscriptionsWithUpcomingInvoices,
  }
}
