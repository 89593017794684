import React from 'react'

import { BubbleProps } from '../types'
import { CountryBubble } from '../Country'
import { OrderIssueBubble } from '../OrderIssue'
import { OrderNumberBubble } from '../OrderNumber'
import { DescriptionBubble } from '../Description'
import { InfoBubble } from '../Info'

export const renderStoreSection = (
  sectionID: string,
  props: BubbleProps
): React.ReactElement => {
  const section = {
    country: <CountryBubble key={sectionID} {...props} />,
    orderIssue: <OrderIssueBubble key={sectionID} {...props} />,
    info: <InfoBubble key={sectionID} {...props} />,
    orderNumber: <OrderNumberBubble key={sectionID} {...props} />,
    description: <DescriptionBubble key={sectionID} {...props} />,
  }[sectionID]

  return section ?? <></>
}
