import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import isString from 'lodash-es/isString'
import styled from 'theme/styled'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { selectIsEmployeeAccount } from 'modules/profile'
import { NewTaxIdSate, TaxIdState } from '../types'
import {
  assertIfTaxIdIsValid,
  deriveCountryFromValue,
  disableAddOrUpdatedButton,
  getValidationRegex,
} from './utils'
import TaxIdDropdown from './TaxIdDropdown'
import TaxIdInput from './TaxIdInput'
import { useBillingTaxIdQuery } from 'store/queries/useBillingTaxIdQuery'
import { useUpdateTaxId } from 'store/mutations/billingTaxId/useUpdateTaxId'
import { useCreateTaxId } from 'store/mutations/billingTaxId/useCreateTaxId'

const TaxIdModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const { taxId, isTaxIdFetched } = useBillingTaxIdQuery()
  const isEmployee = useSelector(selectIsEmployeeAccount)
  const { updateTaxId, isUpdateTaxIdLoading } = useUpdateTaxId()
  const { createTaxId, isCreateTaxIdLoading } = useCreateTaxId()

  const [isTaxIdFormatValid, setIsTaxIdFormatValid] = useState(false)

  const [taxIdState, setTaxIdState] = useState<TaxIdState>({
    type: 'eu_vat',
    value: '',
    country: '',
    dropdownValue: '',
  })

  const validationRegex = useMemo(
    () => getValidationRegex(taxIdState.dropdownValue),
    [taxIdState.dropdownValue]
  )

  useEffect(() => {
    if (isTaxIdFetched) {
      setTaxIdState({
        dropdownValue: deriveCountryFromValue(taxId?.value || '', taxId?.type),
        type: taxId?.type,
        value: taxId?.value || '',
        country: taxId?.country?.toLowerCase() || '',
      })
    }
  }, [isTaxIdFetched, taxId, taxId?.country, taxId?.type, taxId?.value])

  useEffect(() => {
    const { isValidForEmployee, isTaxIdValid } = assertIfTaxIdIsValid(
      taxIdState.value || '',
      validationRegex
    )
    setIsTaxIdFormatValid(isEmployee ? isValidForEmployee : isTaxIdValid)
  }, [isEmployee, taxIdState.value, validationRegex])

  const ActionButton = useMemo(
    () => disableAddOrUpdatedButton(taxId, taxIdState, isTaxIdFormatValid),
    [taxId, taxIdState, isTaxIdFormatValid]
  )

  const handleInputChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: string | number
  ) => {
    if (isString(value)) {
      const { isValidForEmployee, isTaxIdValid } = assertIfTaxIdIsValid(
        value,
        validationRegex
      )
      setTaxIdState({ ...taxIdState, value })
      setIsTaxIdFormatValid(isEmployee ? isValidForEmployee : isTaxIdValid)
    }
  }

  const handleDropdownChange = (newTaxIdState: NewTaxIdSate) => {
    setTaxIdState({ ...taxIdState, ...newTaxIdState })
  }

  const handleClick = useCallback(() => {
    const { type, value } = taxIdState || {}

    if (!type) return

    if (taxId?.value) {
      updateTaxId({ type, value })
      return
    }
    createTaxId({ type, value })
  }, [taxIdState, taxId?.value, createTaxId, updateTaxId])

  const loader = useMemo(() => {
    if (isUpdateTaxIdLoading || isCreateTaxIdLoading) {
      return 'dots'
    }
    return undefined
  }, [isUpdateTaxIdLoading, isCreateTaxIdLoading])

  return (
    <StyledGenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage
          id={
            taxId?.value
              ? 'SETTINGS_PAYMENTS_TAX_ID_CHANGE'
              : 'SETTINGS_PAYMENTS_TAX_ID_ADD'
          }
        />
      }
      size="small"
      contentClassName="content"
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
        },
        {
          variant: 'primary',
          text: <FormattedMessage id="COMMON_ACTION_SAVE" />,
          onClick: handleClick,
          loader,
          disabled: ActionButton,
        },
      ]}
    >
      <ContentContainer>
        <TaxIdDropdown
          value={taxIdState.dropdownValue}
          onChange={handleDropdownChange}
        />
        <TaxIdInput
          taxIdState={taxIdState}
          onChange={handleInputChange}
          isTaxIdFormatValid={isTaxIdFormatValid}
        />
      </ContentContainer>
    </StyledGenericModal>
  )
}

export const TAX_ID_MODAL_ID = 'TAX_ID_MODAL_ID'

const WrappedTaxIdModal = () => (
  <ModalWrapper modalId={TAX_ID_MODAL_ID}>
    <TaxIdModal />
  </ModalWrapper>
)

export default WrappedTaxIdModal

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: visible;
`

const StyledGenericModal = styled(GenericModal)`
  .content {
    overflow: visible;
  }
  overflow: visible !important;
`
