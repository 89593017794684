import { useCallback } from 'react'
import { useSessionStorage } from './useSessionStorage'

export const useExternalLink = () => {
  const [appView] = useSessionStorage<boolean>('appview')

  const externalLink = useCallback(
    (link?: string) => {
      if (!link) return

      appView
        ? window.open(link, '_self')
        : window.open(link, '_blank', 'noreferrer')
    },
    [appView]
  )

  return externalLink
}
