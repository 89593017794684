import { SSOAPIError } from '../types/types'

export const parseSSOAPIError = (errors: SSOAPIError, key: string) => {
  const value = errors[key]

  if (!value) {
    return value
  }

  if (Array.isArray(value)) {
    return value.join(', ')
  }

  return value
}
