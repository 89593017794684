export const getFormattedAddress = (
  addressDetails: google.maps.places.PlaceResult
) => {
  let line1 = ''
  let city = ''
  let state = ''
  let postal_code = ''

  for (const component of addressDetails.address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'street_number': {
        line1 = `${component.long_name} ${line1}`
        break
      }

      case 'route': {
        line1 += component.short_name
        break
      }

      case 'locality':
        city = component.long_name
        break

      case 'sublocality_level_1':
        city = component.long_name
        break

      case 'postal_town':
        city = component.long_name
        break

      case 'administrative_area_level_1': {
        state = component.short_name
        break
      }

      case 'postal_code': {
        postal_code = component.long_name
        break
      }
    }
  }

  return {
    line1,
    city,
    state,
    postal_code,
  }
}
