import { DeviceBackups } from 'api/nca/types'

export const getSortingFunctionForBackupDevices = (sorting: string) => {
  switch (sorting) {
    case 'asc-name':
      return (a: DeviceBackups, b: DeviceBackups) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
    case 'desc-name':
      return (a: DeviceBackups, b: DeviceBackups) =>
        b.name.localeCompare(a.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
    case 'asc-date':
      return (a: DeviceBackups, b: DeviceBackups) =>
        a.latestBackupTimestamp - b.latestBackupTimestamp
    default:
      return (a: DeviceBackups, b: DeviceBackups) =>
        b.latestBackupTimestamp - a.latestBackupTimestamp
  }
}
