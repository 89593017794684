import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { BillingTaxIdApi } from 'api/billing/billingTaxId'
import { closeVisibleModal } from 'modules/modals'
import { QueryKey } from 'store/types'
import { getMutationErrorData } from '../utils'
import { useIntl } from 'react-intl'
import useTaxIdToast from 'features/payment-methods/ui/tax/useTaxIdToast'
import { isSpecificError } from './utils'

const billingTaxIdApi = new BillingTaxIdApi()

export const useUpdateTaxId = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { taxIdSuccessToast, taxIdErrorToast } = useTaxIdToast()

  const updateTaxId = useMutation({
    mutationFn: billingTaxIdApi.update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.BILLING_TAX_ID] })
      dispatch(closeVisibleModal())
      taxIdSuccessToast('updated')
    },
    onError: (error) => {
      const errorData = getMutationErrorData(error)
      let errorMessage = ''

      if (isSpecificError(errorData)) {
        errorMessage = intl.formatMessage({
          id: 'SETTINGS_PAYMENTS_TAX_ID_VALIDATION_ERROR',
        })
      } else {
        errorMessage = intl.formatMessage({
          id: 'SETTINGS_PAYMENTS_TAX_ID_FAILURE',
        })
      }

      taxIdErrorToast('updated', errorMessage)
    },
  })

  return {
    updateTaxId: updateTaxId.mutate,
    isUpdateTaxIdLoading: updateTaxId.isPending,
  }
}
