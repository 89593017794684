import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, designToken } from '@ubnt/ui-components'
import { Text } from '@ubnt/ui-components/aria'

import styled from 'theme/styled'
import { useGetErrorMessage } from './useGetErrorMessage'

type Props = {
  userHasTwoMFAMethodsAndIsActive?: boolean
  userWillOnlyHaveWebAuthnActive?: boolean
  isMFATypeEmail?: boolean
  checked?: boolean
  handleCheckbox?: () => void
  isAllMFAModal?: boolean
}

const ModalContent: React.FC<Props> = ({
  userHasTwoMFAMethodsAndIsActive,
  userWillOnlyHaveWebAuthnActive,
  isMFATypeEmail,
  checked,
  handleCheckbox,
  isAllMFAModal,
}) => {
  const intl = useIntl()
  const { errorMessage, resetErrors } = useGetErrorMessage()

  useEffect(() => {
    if (errorMessage) {
      return () => {
        resetErrors()
      }
    }
  }, [errorMessage, resetErrors])

  if (isMFATypeEmail) {
    return (
      <Wrapper>
        {intl.formatMessage({ id: 'SETTINGS_MFA_REMOVE_EMAIL_WARNING' })}
        <CheckboxWrapper>
          <Checkbox
            id="basic-checkbox"
            motif="light"
            checked={checked}
            onChange={handleCheckbox}
          >
            <Text weight="bold">
              {intl.formatMessage({
                id: 'SETTINGS_MFA_REMOVE_WARNING_CHECKBOX_TEXT',
              })}
            </Text>
          </Checkbox>
        </CheckboxWrapper>
        {errorMessage && (
          <Text color="red-06" size="small">
            {errorMessage}
          </Text>
        )}
      </Wrapper>
    )
  }

  if (userHasTwoMFAMethodsAndIsActive && userWillOnlyHaveWebAuthnActive) {
    return (
      <Wrapper>
        {intl.formatMessage({
          id: 'SETTINGS_MFA_REMOVE_WARNING_ONLY_WEBAUTHN',
        })}
      </Wrapper>
    )
  }

  if (userHasTwoMFAMethodsAndIsActive) {
    return (
      <Wrapper>
        {intl.formatMessage({
          id: 'SETTINGS_MFA_REMOVE_WARNING',
        })}
      </Wrapper>
    )
  }

  if (isAllMFAModal) {
    return (
      <Wrapper>
        {intl.formatMessage({
          id: 'SETTINGS_MFA_REMOVE_MODAL_DESCRIPTION_ALL_MFA',
        })}
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      {intl.formatMessage({
        id: 'SETTINGS_MFA_REMOVE_MODAL_DESCRIPTION',
      })}
    </Wrapper>
  )
}

export default ModalContent

const Wrapper = styled(Text)``

const CheckboxWrapper = styled.div`
  margin-top: ${designToken.alias['spacing-base-04']};
`
