import { Button } from '@ubnt/ui-components/Button'
import { extendAssuranceLevel } from 'features/auth/modules/assuranceLevel'
import { setVisibleModal } from 'modules/modals'
import { selectIsTwoFactorAuthEnabled } from 'modules/profile'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { SETUP_MFA_MODAL_ID } from '../SetupMFAModal'
import { selectMFAAuthenticators } from 'modules/mfa'
import { MFAType } from 'modules/types'
import { removePendingMFATOTP } from '../TOTP/modules/removePendingTOTP'
import { removePendingMFAPush } from '../Push/modules/removePendingPush'
import styled from 'theme/styled'

type MFAHeaderActionButtonProps = {
  disabled?: boolean
}

export const MFAHeaderActionButton: React.FC<MFAHeaderActionButtonProps> = ({
  disabled,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isMFAEnabled = useSelector(selectIsTwoFactorAuthEnabled)
  const authenticators = useSelector(selectMFAAuthenticators)

  const removePendingAuthenticators = useCallback(() => {
    authenticators.forEach((authenticator) => {
      if (
        authenticator.type === MFAType.totp &&
        authenticator.status === 'pending'
      ) {
        dispatch(removePendingMFATOTP(authenticator.id))
      }
      if (
        authenticator.type === MFAType.push &&
        authenticator.status === 'pending'
      ) {
        dispatch(removePendingMFAPush(authenticator.id))
      }
    })
  }, [authenticators, dispatch])

  return (
    <StyledButton
      variant="secondary"
      onClick={() => {
        removePendingAuthenticators()
        dispatch(extendAssuranceLevel())
        dispatch(setVisibleModal(SETUP_MFA_MODAL_ID))
      }}
      disabled={disabled}
    >
      {intl.formatMessage({
        id: isMFAEnabled
          ? 'SETTINGS_MFA_ADD_BACKUP_ACTION'
          : 'COMMON_ACTION_ENABLE',
      })}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.media.small}) {
    margin-bottom: 16px;
    width: max-content;
  }
`
