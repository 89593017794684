import thunk from 'redux-thunk'
import { middleware as reduxPackMiddleware } from 'redux-pack'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { middleware as sagaThunk } from 'redux-saga-thunk'
import { IRedux } from 'types/types'
import { Environment } from '../config/config'
import { createRootReducer } from './modules'

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: [],
  },
  createRootReducer()
)

const composeEnhancers =
  import.meta.env.VITE_ENV !== Environment.Prod &&
  !!window &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

function configureStore(): IRedux {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [thunk, sagaThunk, reduxPackMiddleware, sagaMiddleware]

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  const persistor = persistStore(store)

  return { store, sagaMiddleware, persistor }
}

export default configureStore()
