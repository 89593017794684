import React from 'react'
import { connect } from 'react-redux'

import { withDidMount } from 'utils/withDidMount'

import { logout } from '../modules/auth'

const Logout: React.FC = () => null

const mapDispatchToProps = {
  onDidMount: logout,
}

export default connect(undefined, mapDispatchToProps)(withDidMount(Logout))
