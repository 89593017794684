import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Loader } from '@ubnt/ui-components'
import { useHistory } from 'react-router'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { useTalkIntallsQuery } from 'store/queries/useTalkInstallsQuery'
import { useCancelTalkSubscription } from 'store/mutations/billingSubscriptions/useCancelTalkSubscription'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
}

const CancelSubscriptionTalkModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
}) => {
  const history = useHistory()
  const {
    cancelTalkSubscription,
    isCancelTalkSubscriptionLoading,
    cancelTalkSubscriptionErrors,
    resetCancelTalkSubscriptionErrors,
  } = useCancelTalkSubscription()
  const {
    talkInstalls,
    talkInstallsErrors,
    areTalkInstallsLoading,
    resetTalkInstalls,
    areTalkInstallsFetched,
  } = useTalkIntallsQuery()

  const foundInstall = useMemo(
    () =>
      fullSubscription &&
      talkInstalls &&
      Array.isArray(talkInstalls) &&
      talkInstalls.find(
        ({ subscription }: { subscription: string }) =>
          subscription === fullSubscription.id
      ),
    [fullSubscription, talkInstalls]
  )

  const error = useMemo(() => {
    if (
      typeof talkInstallsErrors === 'string' ||
      (areTalkInstallsFetched && !foundInstall)
    ) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
    } else if (
      cancelTalkSubscriptionErrors &&
      typeof cancelTalkSubscriptionErrors === 'string'
    ) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_ERROR" />
    } else {
      return null
    }
  }, [
    talkInstallsErrors,
    cancelTalkSubscriptionErrors,
    foundInstall,
    areTalkInstallsFetched,
  ])

  const renderContent = useMemo(() => {
    if (error) {
      return <ErrorMessage>{error}</ErrorMessage>
    } else if (areTalkInstallsLoading) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )
    } else {
      return (
        <FormattedMessage
          id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE_TALK"
          tagName="div"
        />
      )
    }
  }, [error, areTalkInstallsLoading])

  const onConfirm = useCallback(() => {
    if (error) {
      history.push('/supportform?formtype=billing')
    } else if (foundInstall) {
      cancelTalkSubscription({
        install: foundInstall.id,
      })
    }
  }, [error, foundInstall, history, cancelTalkSubscription])

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<Title />}
      size="small"
      onAfterClose={() => {
        resetCancelTalkSubscriptionErrors()
        resetTalkInstalls()
      }}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: error ? (
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_CONFIRM" />
          ),
          variant: 'primary',
          onClick: onConfirm,
          loader:
            (!error && areTalkInstallsLoading) ||
            isCancelTalkSubscriptionLoading
              ? 'dots'
              : undefined,
          type: 'submit',
          disabled:
            (!error && areTalkInstallsLoading) ||
            isCancelTalkSubscriptionLoading,
        },
      ]}
    >
      {renderContent}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_TALK_MODAL_ID =
  'CANCEL_SUBSCRIPTION_TALK_MODAL_ID'

const WrappedCancelSubscriptionTalkModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_TALK_MODAL_ID}>
    <CancelSubscriptionTalkModal />
  </ModalWrapper>
)

export default WrappedCancelSubscriptionTalkModal

const Title = () => (
  <TitleWrapper>
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
      tagName="div"
    />
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.red06};
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`
