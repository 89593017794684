const observeDOM = (function () {
  const MutationObserver = window.MutationObserver

  return function (id: string, callback: () => void) {
    const obj = document.getElementById(id)
    if (!obj || obj.nodeType !== 1) return

    if (MutationObserver) {
      const mutationObserver = new MutationObserver(callback)

      mutationObserver.observe(obj, { childList: true, subtree: true })
      return mutationObserver
    }
    obj.addEventListener('DOMNodeInserted', callback, false)
    obj.addEventListener('DOMNodeRemoved', callback, false)
  }
})()

export default observeDOM
