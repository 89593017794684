import React from 'react'

import { Bubble } from 'components/support/Bubble'
import { QuestionTile } from 'components/support/QuestionTile'
import { TilesWrapper } from 'components/support/Wrappers'

import { BubbleProps } from './types'
import { assistance } from './config/ConfigOptions'
import { UniFiStateValues } from './config/types'
import { setNextSection } from './handleClicks'

export const AssistanceBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  config,
}) => {
  return (
    <Bubble question={config.assistance.fieldTitle}>
      <TilesWrapper>
        {assistance.map(({ icon, label, hasFooter, id }) => (
          <QuestionTile
            key={id}
            icon={icon}
            label={label}
            hasFooter={hasFooter}
            onClick={() =>
              setNextSection(
                config,
                id,
                UniFiStateValues.ASSISTANCE,
                values,
                handleChange
              )
            }
            isActive={values.assistance === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
