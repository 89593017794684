import styled from 'theme/styled'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { NavLink } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledLink = styled(NavLink)`
  align-items: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.text1};
  display: flex;
  font: ${typography['desktop-body']};
  padding: 8px;
  transition: color 0.2s ease-in;
  will-change: color;

  svg {
    transition: color 0.2s ease-in;
  }

  &.active,
  &.active svg {
    background: ${({ theme }) => theme.neutral01};
    color: ${({ theme }) => theme.ublue06};
  }

  &:hover,
  &:hover svg {
    background: ${({ theme }) => theme.neutral01};
    color: ${({ theme }) => theme.ublue06};
  }
`

const IconContainer = styled.span`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-right: 8px;
  width: 20px;

  svg,
  img {
    color: ${({ theme }) => theme.neutral08};
  }

  img {
    object-fit: none;
  }
`

const MobileNavigationContainer = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  overflow: hidden;
  z-index: 999;
`

const StyledMobileNavLink = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== '$isAppView',
})<{ $isAppView?: boolean }>`
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.neutral02};
  border-bottom: 1px solid ${({ theme }) => theme.neutral04};
  color: ${({ theme }) => theme.text1};
  display: flex;
  font: ${typography['desktop-body']};
  padding: 16px;
  transition: color 0.2s linear;
  width: 100%;
  will-change: color;
  ${({ $isAppView, theme }) =>
    $isAppView &&
    `
    margin-top: 0;
    margin-bottom: 4px;
    border-bottom: none;
    border-radius: 8px;
    background: ${theme.neutral01};
  `}

  &:hover,
  &:hover svg,
  &.active,
  &.active svg {
    color: ${({ theme }) => theme.ublue06};
  }

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: ${({ theme }) => theme.media.mediumV2}) {
    ${({ $isAppView }) => $isAppView && 'display: none'}
  }
`

const StyledMobileNavLinkInner = styled.div`
  display: flex;
  align-items: center;
`

const StyledExternalLink = styled.a`
  align-items: center;
  background: ${({ theme }) => theme.neutral02};
  border-bottom: 1px solid ${({ theme }) => theme.neutral04};
  color: ${({ theme }) => theme.neutral10};
  display: flex;
  font: ${typography['desktop-body']};
  padding: 16px;
  transition: color 0.2s linear;
  width: 100%;
  will-change: color;

  &:hover {
    color: ${({ theme }) => theme.ublue06};
  }

  &:last-child {
    border-bottom: none;
  }
`

export {
  Wrapper,
  StyledLink,
  IconContainer,
  MobileNavigationContainer,
  StyledMobileNavLink,
  StyledMobileNavLinkInner,
  StyledExternalLink,
}
