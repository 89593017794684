import { AxiosResponse } from 'axios'

const BE_VALIDATION_FAILED_ERROR_CODE = 6005
const TAX_AUTORITY_VALIDATION_FAILED_ERROR_CODE = 6007
const specificErrorCodes = [
  BE_VALIDATION_FAILED_ERROR_CODE,
  TAX_AUTORITY_VALIDATION_FAILED_ERROR_CODE,
]

export const isSpecificError = (error: AxiosResponse['data']): boolean => {
  return (
    error.metaData?.code && specificErrorCodes.includes(error.metaData.code)
  )
}
