import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { ArrowRightPrimaryIcon } from '@ubnt/icons'

import styled from 'theme/styled'
import { space } from 'theme'

export const Badge = styled.div`
  font-size: 10px;
  color: ${(p) => p.theme.ublue06};
  background-color: ${(p) => p.theme.ublue01};
  border-radius: 10px;
  padding: 2px 7px;
  width: fit-content;
`

const Wrapper = styled.div<{ cursor: string }>`
  text-align: left;
  display: flex;
  border-radius: 12px;
  padding: ${space['desktop-spacing-base-04']} 0;
  justify-content: space-between;
  cursor: ${(p) => p.cursor};
`

const InnerWrapper = styled.div`
  display: flex;
`

export const IconMFAWrapper = styled.div<{ iconBorder?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border: ${({ iconBorder, theme }) =>
    iconBorder ? `1px solid ${theme.neutral04}` : 'none'};
  border-radius: 11px;
  margin-right: 16px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: ${(props) => props.theme.text2};
`

const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text3};
  margin-top: 4px;
`

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`
const StyledArrow = styled(ArrowRightPrimaryIcon)`
  margin: auto 0;
  color: ${({ theme }) => theme.neutral08};
`

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.neutral03};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledMFAImg = styled.img<{ size?: string }>`
  width: ${(p) => p.size ?? '30px'};
  height: ${(p) => p.size ?? '30px'};
  border-radius: ${(p) => (p.size ? '11px' : 'none')};
`
interface Props {
  iconLink?: string
  icon?: JSX.Element
  title?: string
  description: string
  onClick?: () => void
  tabIndex?: number
  focusOnLoad?: boolean
  isRecommended?: boolean
  showBorder?: boolean
  iconBorder?: boolean
  largerIcon?: boolean
  cursor?: string
}

export const MFAOption: React.FC<Props> = ({
  iconLink,
  icon,
  title,
  description,
  onClick,
  tabIndex,
  isRecommended,
  showBorder = true,
  iconBorder = true,
  largerIcon = false,
  cursor = 'pointer',
}) => {
  const optionRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <Wrapper
        tabIndex={tabIndex}
        ref={optionRef}
        onClick={onClick}
        cursor={cursor}
      >
        <InnerWrapper>
          <IconMFAWrapper iconBorder={(!!iconLink || !!icon) && iconBorder}>
            {iconLink && (
              <StyledMFAImg
                src={iconLink}
                alt={title}
                size={largerIcon ? '48px' : undefined}
              />
            )}
            {icon && icon}
          </IconMFAWrapper>
          <TextWrapper>
            <BadgeWrapper>
              <Title>{title}</Title>{' '}
              {isRecommended ? (
                <Badge>
                  <FormattedMessage id="COMMON_LABEL_RECOMMENDED" />
                </Badge>
              ) : null}
            </BadgeWrapper>
            <Description>{description}</Description>
          </TextWrapper>
        </InnerWrapper>
        <StyledArrow size="large" />
      </Wrapper>
      {showBorder ? <Divider /> : null}
    </>
  )
}
