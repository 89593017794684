import { Input } from '@ubnt/ui-components/Input'
import { media } from 'theme'
import styled from 'theme/styled'

export const FormInput = styled(Input)`
  width: 408px;
  @media (max-width: ${media.mobileXLarge}) {
    width: 100% !important;
  }
`
