import styled from 'theme/styled'
import { Form } from 'formik'

export const AuthForm = styled(Form)<{ buttonAlign?: boolean }>`
  position: relative;
  width: 100%;
  padding: ${(p) => (p.buttonAlign ? '10px 0 0 0' : '10px 0')};

  h1 {
    font-size: 28px;
  }
`

export const ButtonContainer = styled('div')`
  margin-top: 10px;

  > * {
    margin: 10px 0;
  }
`

export const Description = styled('p')`
  font-size: 14px;
  color: #8c8c8c;
  margin-top: 0px;
  margin-bottom: 49px;
  line-height: 24px;
`

export const ErrorAlert = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 10px;
  color: ${(p) => p.theme.red06};
  background-color: #fdebec;
  border-radius: 4px;
  font-size: 12px;
  > svg {
    margin-right: 10px;
  }
`
