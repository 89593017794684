import React, { useCallback, useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import isMobile from 'is-mobile'
import { Button } from '@ubnt/ui-components/Button'
import { ToastContext } from '@ubnt/ui-components/Toast'
import { ValidationMessage } from '@ubnt/ui-components/ValidationMessage'
import { ThreatManagementIcon } from '@ubnt/icons'
import { Loader } from '@ubnt/ui-components'

import styled from 'theme/styled'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import StyledEntityToast from 'components/EntityToast'
import { getErrorMessage } from 'utils/mfa'

import { GenericMFAModal } from '../GenericMFAModal'
import { TOTP_VERIFY_CODE_MODAL_ID } from './VerifyCodeModal'
import { TOTP_QR_CODE_MODAL_ID } from './QRCodeModal'
import {
  selectInitiateTOTPData,
  selectInitiateTOTPErrors,
  selectInitiateTOTPIsError,
} from './modules/initiateTOTP'

const parseSecretFromProvisioningURI = (
  provisioningUri?: string
): string | undefined => {
  if (!provisioningUri) {
    return undefined
  }

  const decodedProvisioningURI = decodeURI(provisioningUri)
  const matches = decodedProvisioningURI.match(/secret=([\d\w]*)/)
  const result = matches?.[1]

  return result
}

export const TOTP_SECRET_CODE_MODAL_ID = 'TOTP_SECRET_CODE_MODAL_ID'

export const TOTPSecretCodeModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { createNotification, removeNotification } = useContext(ToastContext)

  const [setupButtonClicked, setSetupButtonClicked] = useState(false)

  const { provisioning_uri } = useSelector(selectInitiateTOTPData)
  const apiError = useSelector(selectInitiateTOTPErrors)
  const isError = useSelector(selectInitiateTOTPIsError)

  const isMobileDevice = isMobile()

  const TOTP_PRIVATE_CODE = parseSecretFromProvisioningURI(provisioning_uri)

  const isCopySupported = document.queryCommandSupported('copy')

  const handleOnCopy = useCallback(() => {
    if (!isCopySupported) {
      return
    }

    const textField = document.createElement('textarea')
    textField.innerText = TOTP_PRIVATE_CODE || ''
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()

    createNotification(
      <StyledEntityToast
        icon={<ThreatManagementIcon />}
        stateIndicator="success"
        title={intl.formatMessage({
          id: 'SETTINGS_MFA_TOTP_SECRET_CODE_COPY_TOAST_TITLE',
        })}
        duration={5000}
        details={intl.formatMessage({
          id: 'SETTINGS_MFA_TOTP_SECRET_CODE_COPY_TOAST_DESCRIPTION',
        })}
        onClose={(e, id) => {
          if (id) {
            removeNotification(id)
          }
        }}
      />
    )
  }, [
    isCopySupported,
    TOTP_PRIVATE_CODE,
    intl,
    createNotification,
    removeNotification,
  ])

  let contentToRender: JSX.Element = <Loader />

  if (isError) {
    contentToRender = (
      <ValidationMessage>
        {getErrorMessage({
          apiError,
          defaultMessage: intl.formatMessage({
            id: 'GENERIC_ERROR_BOUNDARY_TITLE',
          }),
          fields: ['detail'],
        })}
      </ValidationMessage>
    )
  } else if (TOTP_PRIVATE_CODE) {
    if (!setupButtonClicked && isMobileDevice) {
      contentToRender = (
        <>
          <Button
            variant="primary"
            full
            href={provisioning_uri}
            onClick={() => setSetupButtonClicked(true)}
          >
            {intl.formatMessage({
              id: 'SETTINGS_MFA_TOTP_MODAL_OPEN_APP_BUTTON',
            })}
          </Button>
        </>
      )
    } else {
      contentToRender = (
        <>
          <CodeWrapper>
            <CodeText>{TOTP_PRIVATE_CODE}</CodeText>
            {isCopySupported && (
              <CopyButton variant="inline" onClick={handleOnCopy}>
                {intl.formatMessage({
                  id: 'COMMON_ACTION_COPY',
                })}
              </CopyButton>
            )}
          </CodeWrapper>
          <ScanBarcodeButton
            variant="inline"
            size="medium"
            weight="bold"
            onClick={() => dispatch(setVisibleModal(TOTP_QR_CODE_MODAL_ID))}
          >
            {intl.formatMessage({
              id: 'SETTINGS_MFA_TOTP_MODAL_ACTION_SCAN_BARCODE',
            })}
          </ScanBarcodeButton>
        </>
      )
    }
  }

  return (
    <GenericMFAModal
      modalId={TOTP_SECRET_CODE_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_TOTP_MODAL_TITLE' })}
      description={
        <DescriptionWrapper>
          <FormattedMessage id="SETTINGS_MFA_TOTP_MODAL_DESCRIPTION" />
        </DescriptionWrapper>
      }
      onAfterClose={() => setSetupButtonClicked(false)}
      actions={[
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CANCEL',
          }),
          variant: 'tertiary',
          onClick: () => dispatch(closeVisibleModal()),
        },
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CONTINUE',
          }),
          onClick: () => dispatch(setVisibleModal(TOTP_VERIFY_CODE_MODAL_ID)),
          disabled: isMobileDevice && !setupButtonClicked,

          variant: 'primary',
        },
      ]}
      overrideFullScreen
      size="auto"
    >
      <Wrapper>{contentToRender}</Wrapper>
    </GenericMFAModal>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`

const CopyButton = styled(Button)`
  font-size: 16px;
  line-height: 22px;
  margin-left: 45px;
`

const ScanBarcodeButton = styled(Button)`
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-decoration: none !important;
`

const CodeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 14px;
  font-size: 16px;
  line-height: 22px;
  color: ${(p) => p.theme.neutral08};
  width: 100%;
  background-color: ${(p) => p.theme.neutral02};
`

const CodeText = styled.span`
  overflow: auto;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
`

const DescriptionWrapper = styled.div`
  line-height: 24px;
`
