import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { QueryKey } from '../types'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { TicketsApi } from 'api/zendesk/tickets'
import { ZendeskTicketsResponse } from 'api/zendesk/types'

const ticketsApi = new TicketsApi()

export const useOpenTicketsQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const getNextPage = (
    lastPageData: ZendeskTicketsResponse,
    allPagesData: ZendeskTicketsResponse[],
    lastPage: number
  ) => {
    const totalFetched = allPagesData.flatMap((page) => page.tickets).length

    const { totalTickets } = lastPageData

    if (totalFetched < totalTickets) {
      return lastPage + 1
    }
  }

  const openTicketsPages = useInfiniteQuery({
    queryKey: [QueryKey.OPEN_TICKETS],
    queryFn: ticketsApi.getAllOpen,
    initialPageParam: 1,
    getNextPageParam: getNextPage,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const openTickets = useMemo(() => {
    if (!openTicketsPages.data) {
      return []
    }

    return openTicketsPages.data.pages.flatMap((page) => page.tickets)
  }, [openTicketsPages.data])

  const totalOpenTickets = useMemo(
    () => openTicketsPages.data?.pages[0].totalTickets ?? 0,
    [openTicketsPages.data?.pages]
  )

  const hasNextPage = useMemo(
    () => openTickets.length < totalOpenTickets,
    [openTickets.length, totalOpenTickets]
  )

  return {
    openTickets,
    areOpenTicketsFetched: openTicketsPages.isFetched,
    areOpenTicketsLoading: openTicketsPages.isFetching,
    openTicketsError: openTicketsPages.error,
    refetchOpenTickets: openTicketsPages.refetch,
    fetchMoreOpenTickets: openTicketsPages.fetchNextPage,
    hasMoreOpenTickets: hasNextPage,
    totalOpenTickets,
  }
}
