import React, { useEffect, useRef } from 'react'

import { Bubble } from 'components/support/Bubble'
import { TilesWrapper } from 'components/support/Wrappers'
import { QuestionTile } from 'components/support/QuestionTile'

import { BubbleProps } from './types'
import { adoptionTrouble } from './config/ConfigOptions'
import { setNextSection } from './handleClicks'
import { UniFiStateValues } from './config/types'

export const AdoptionTroubleBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  return (
    <Bubble question={config.adoptionTrouble.fieldTitle} ref={ref}>
      <TilesWrapper>
        {adoptionTrouble.map(({ id, label }) => {
          return (
            <QuestionTile
              key={id}
              label={label}
              onClick={() =>
                setNextSection(
                  config,
                  id,
                  UniFiStateValues.ADOPTION_TROUBLE,
                  values,
                  handleChange
                )
              }
              isActive={values.adoptionTrouble === id}
            />
          )
        })}
      </TilesWrapper>
    </Bubble>
  )
}
