import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SessionsApi } from 'api/sessions'
import { QueryKey } from 'store/types'

const sessionsApi = new SessionsApi()

export const useRevokeAllNonCurrentSessions = () => {
  const queryClient = useQueryClient()

  const revokeNonCurrentSessions = useMutation({
    mutationFn: sessionsApi.revokeNonCurrent,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKey.SESSIONS] }),
  })

  return {
    revokeNonCurrentSessions: revokeNonCurrentSessions.mutate,
  }
}
