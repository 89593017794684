import React from 'react'
import { useSelector } from 'react-redux'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'

import { typography } from 'theme'
import styled from 'theme/styled'
import {
  selectIsUiTaxIdsLoading,
  selectUiTaxIds,
} from 'features/subscriptions/module/uiTaxIds'

export const UiTaxIds = () => {
  const uiTaxIds = useSelector(selectUiTaxIds)
  const uiTaxIdsLoading = useSelector(selectIsUiTaxIdsLoading)

  if (uiTaxIdsLoading) {
    return (
      <SkeletonLoader>
        <Wrapper>
          <UiTaxIdHeader>Tax Ids</UiTaxIdHeader>
          <Text width={180} />
        </Wrapper>
      </SkeletonLoader>
    )
  }

  return (
    <>
      <UiTaxIdHeader>Tax Ids</UiTaxIdHeader>
      <UiTaxIdsWrapper>
        {uiTaxIds?.map((item, i) => (
          <span key={i}>{item}</span>
        ))}
      </UiTaxIdsWrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const UiTaxIdHeader = styled.span`
  margin-top: 4px;
  font: ${typography['desktop-typography-heading-large']};
`

const UiTaxIdsWrapper = styled.div`
  max-width: 70%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2px;
  column-gap: 8px;
  justify-content: flex-end;
  align-self: flex-end;
`
