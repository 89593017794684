import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'

type HeaderTabLabelProps = {
  label: string
  totalTickets: number
}

export const HeaderTabLabel: React.FC<HeaderTabLabelProps> = ({
  label,
  totalTickets,
}) => (
  <LabelWrapper>
    <FormattedMessage id={label} />
    {totalTickets > 0 && <span>({totalTickets})</span>}
  </LabelWrapper>
)

const LabelWrapper = styled.div`
  display: flex;
  gap: 4px;
`
