import { api } from 'api'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'

const {
  api: { sso },
} = __CONFIG__

export const removePendingTOTPDataKey = 'MFARemovePendingTOTP'

const removePendingTOTPActions = {
  remove: `${removePendingTOTPDataKey}/REMOVE`,
}

type RemovePendingTOTPState = Record<string, never>

const dataModule = createDataModule<RemovePendingTOTPState, SSOAPIError>(
  removePendingTOTPDataKey,
  `${sso.paths.mfa}/totp`,
  api.ssoBase
)

export const removePendingMFATOTP = (id: string, token?: string) => ({
  type: removePendingTOTPActions.remove,
  payload: { oneTimePassword: token },
  urlPath: `/${id}`,
})

export const removePendingTOTPReducer = dataModule.reducer
