import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'

import { api } from 'api'
import { fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { setupMFATOTP } from 'modules/mfaSetup'
import { checkAuth } from 'features/auth/modules/auth'
import { closeVisibleModal } from 'modules/modals'

import { resetInitiateTOTPData } from './initiateTOTP'

const {
  api: { sso },
} = __CONFIG__

export const verifyTOTPDataKey = 'MFAVerifyTOTP'

const MFATOTPActions = {
  create: `${verifyTOTPDataKey}/CREATE`,
}

export type VerifyTOTPState = Record<string, never>

const dataModule = createDataModule<VerifyTOTPState, SSOAPIError>(
  verifyTOTPDataKey,
  `${sso.paths.mfa}/totp`,
  api.ssoBase
)

const {
  selectData,
  selectIsLoading,
  selectErrors,
  selectIsDone,
  selectIsError,
  CREATE_DONE,
} = dataModule

export {
  selectIsLoading as selectVerifyTOTPIsLoading,
  selectErrors as selectVerifyTOTPErrors,
  selectIsError as selectVerifyTOTPIsError,
  selectIsDone as selectVerifyTOTPIsDone,
}

export const verifyMFATOTP = (id: string, token: string) => ({
  type: MFATOTPActions.create,
  payload: { oneTimePassword: token },
  urlPath: `/${id}/verify`,
})

function* totpDoneSaga() {
  const { id } = yield select(selectData)
  yield put(setupMFATOTP(id))
  yield put(closeVisibleModal())
  yield put(fetchMFA())
  yield put(checkAuth())
  yield put(resetInitiateTOTPData())
}

function* subscribeToTOTPDoneSaga() {
  yield takeEvery(CREATE_DONE, totpDoneSaga)
}

export function* verifyTOTPRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToTOTPDoneSaga)])
}

export const verifyTOTPReducer = dataModule.reducer
