import { AxiosInstance } from 'axios'

function axiosErrorInterceptor(ax: AxiosInstance) {
  ax.interceptors.response.use(
    (data) => data,
    (error) => {
      const { request, response, config } = error
      if (response.status >= 500) {
        console.error(
          `"${response.status} ${response.statusText}": Error while calling "${config.method}" on "${request.responseURL}"`
        )
      }
      return Promise.reject(error)
    }
  )
}

export default axiosErrorInterceptor
